import Collage from './Collage';
import Image from './Image';
import ListItemGroup from './ListItemGroup';
import Modal from './Modal';
import Template from './Template';
import Rating from './Rating';
import Shop from './Shop';
import Text from './Text';
import Video from './Video';
import ButtonReply from './ButtonReply';
import QuickReply from './QuickReply';
import AgeGateModal from './AgeGate';
import LinkModal from './LinkModal';
import PhoneNumberField from './PhoneNumberField';
import ImageCarousel from './ImageCarousel';
import LocationButton from './LocationButton';
import EmailSubmissionForm from './EmailSubmissionForm';
import MapQuest from './MapQuest';
import SKUx from './SKUx';
import InputField from './InputField';

export default {
    Collage,
    Image,
    ListItemGroup,
    Modal,
    Rating,
    Shop,
    Template,
    Text,
    Video,
    ButtonReply,
    QuickReply,
    AgeGateModal,
    LinkModal,
    PhoneNumberField,
    ImageCarousel,
    LocationButton,
    EmailSubmissionForm,
    MapQuest,
    SKUx,
    InputField,
};