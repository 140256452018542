import React, { useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { BsFilm } from 'react-icons/bs';
import { VALID_VIDEO_TYPES, VIDEO_FILE_SIZE_LIMIT } from '../../../config';
import { HeadingEditor, ModuleAnimations, ModuleStyles } from '../SharedConfigurations';
import { checkIfUrlIsValid } from '../../../helpers/regexHelpers';
import { checkSharedMessageError } from './errorHelper';

const Video = ({
    url,
    file,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    styles,
    animation,
}) => {
    const [errorFileUpload, setErrorFileUpload] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [errorURL, setErrorURL] = useState(false);
    const [isFirstTime, setIsFirstTime] = useState(true);

    const handleVideoChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        const file = e.target.files[0];

        if (!VALID_VIDEO_TYPES.includes(file.type) || file.size > VIDEO_FILE_SIZE_LIMIT) {
            setErrorFileUpload(true);
        } else {
            setErrorFileUpload(false);
            setVideoURL('');
            setErrorURL(false);
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        url: URL.createObjectURL(file),
                        file,
                        error: !file ||
                            !VALID_VIDEO_TYPES.includes(file.type) ||
                            file.size > VIDEO_FILE_SIZE_LIMIT ||
                            checkSharedMessageError(selectedMessage.message.props),
                    },
                },
            }));
            // reset value to initial state because if same picture uploaded there is no change of state
            e.target.value = '';
        }
    };

    const handleVideoUrlChange = (e) => {
        if (!e.target.value) {
            setVideoURL('');
            return;
        }
        const { value } = e.target;
        if (!checkIfUrlIsValid(value)) {
            setErrorURL(true);
        } else {
            setErrorURL(false);
        }
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    url: value,
                    file: null,
                    error: !value || !checkIfUrlIsValid(value) ||
                        checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
        setVideoURL(value);
    };

    return (
        <div className="options-container my-3">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            <Row className="shared-module-background mt-3">
                <Col className="m-0 p-0">
                    <div className="shared-heading">
                        Video Upload
                    </div>

                    {/* UPLOAD BUTTON */}
                    <FormGroup>
                        <Label
                            for="video"
                            className="shared-media-upload"
                        >
                            <BsFilm
                                size="20px"
                                color="#756682"
                                style={{ margin: '5px 15px 0 0' }}
                            />
                            <p>Upload Video</p>
                        </Label>
                        {file && file.name && videoURL === '' &&
                            <p className="pt-2">{file.name}</p>
                        }
                    </FormGroup>

                    <div className="shared-image-info">or</div>

                    {/* TEXT BOX */}
                    <FormGroup>
                        <Input
                            className="shared-input"
                            type="textarea"
                            placeholder="Enter video URL"
                            value={url && videoURL === '' && !file ? url : videoURL}
                            onChange={handleVideoUrlChange}
                        />
                        {errorURL &&
                            <p className="error-message">Please enter valid URL</p>
                        }
                    </FormGroup>

                    {/* ERROR MESSAGE */}
                    <FormGroup>
                        <Input
                            type="file"
                            name="video"
                            id="video"
                            onChange={handleVideoChange}
                        />
                        {errorFileUpload &&
                            <p className="error-message">
                                Please upload video in valid format (under 300 Mb)
                            </p>
                        }
                    </FormGroup>
                </Col>
            </Row>
        </div>
    );
};

export default Video;