import React, { useState, useEffect } from 'react';
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { BsCaretDownFill, BsFillEyeFill, BsClockFill } from 'react-icons/bs';
import { FaSmile, FaHeart } from 'react-icons/fa';
import { HiSparkles } from 'react-icons/hi';
import { RiEdit2Fill } from 'react-icons/ri';
import { checkSharedMessageError } from './ModuleConfigurations/errorHelper';

const SharedMessageConfiguration = ({ selectedMessage, setSelectedMessage }) => {
    const { props: messageProps } = selectedMessage.message;
    const [typingDurationValue, setTypingDurationValue] = useState('');
    useEffect(() => {
        if (messageProps.typingDuration) {
            setTypingDurationValue(messageProps.typingDuration);
        } else {
            setTypingDurationValue('');
        }
    }, [selectedMessage]);

    const [showCelebrationMenu, setShowCelebrationMenu] = useState(false);
    const [isCelebrationMenuOpen, setCelebrationMenuOpen] = useState(false);
    useEffect(() => {
        setShowCelebrationMenu(false);
        if (messageProps.showCelebration) {
            setShowCelebrationMenu(true);
        }
    }, [selectedMessage]);

    const celebrations = {
        confetti: {
            name: 'Confetti',
            type: 'confetti',
        },
        smileys: {
            name: 'Smileys',
            type: 'smileys',
        },
        hearts: {
            name: 'Hearts',
            type: 'hearts',
        },
        custom: {
            name: 'Custom',
            type: 'custom',
            emoji: [],
        },
    };

    const [celebration, setCelebration] = useState(celebrations.confetti);
    useEffect(() => {
        if (messageProps.celebration) {
            setCelebration(messageProps.celebration);
        }
    }, [selectedMessage]);

    const [customEmoji, setCustomEmoji] = useState('');
    useEffect(() => {
        if (messageProps.celebration &&
            messageProps.celebration.type === 'custom') {
            const stringOfEmojis = messageProps.celebration.emoji.join('');
            setCustomEmoji(stringOfEmojis);
        }
    }, [selectedMessage]);

    const handleSetTimeDelay = () => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    withDelay: true,
                    error: checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const handleChange = (e, property) => {
        const { value, type } = e.target;
        setSelectedMessage(selectedMessage => {
            const { props } = selectedMessage.message;
            if (type === 'checkbox') {
                props[property] = !props[property];
            } else {
                props[property] = value;
            }

            if (property === 'withDelay' && props.showTyping) {
                props.showTyping = false;
            }

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...props,
                        error: checkSharedMessageError(props),
                    },
                },
            });
        });

        if (property === 'showCelebration') {
            setShowCelebrationMenu(!showCelebrationMenu);
        }
    };

    const toggleCelebrationDropdown = () => {
        setCelebrationMenuOpen(!isCelebrationMenuOpen);
    };

    const handleSelectCelebration = (celebration) => {
        setCelebrationMenuOpen(false);
        let newCelebration = {};
        if (customEmoji) {
            newCelebration = {
                ...celebration,
                emoji: [...customEmoji],
            };
        } else {
            newCelebration = {
                ...celebration,
                emoji: [' '],
            };
        }
        setCelebration(newCelebration);
        setSelectedMessage(selectedMessage => {
            const { props } = selectedMessage.message;
            props.celebration = newCelebration;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...props,
                        error: checkSharedMessageError(props),
                    },
                },
            });
        });
    };

    const handleCustomEmoji = (e) => {
        e.preventDefault();
        setCustomEmoji(e.target.value);
    };

    const selectedStyles = {
        backgroundColor: '#756682',
        color: '#FFF',
    };

    const unselectedStyles = {
        cobackgroundColorlor: '#FFF',
        color: '#756682',
    };

    const celebrationIconStyles = {
        size: '13px',
        style: { margin: '0 10px 0 5px' },
    };

    return (
        <div className="shared-message-configuration">
            {/* TIME INPUT FIELD */}
            <Row>
                <Col xs={4}>
                    <FormGroup
                        check
                        className="shared-set-delay"
                    >
                        <Label
                            check
                            className="shared-message-label"
                        >
                            <Input
                                className="pointer"
                                type="checkbox"
                                checked={messageProps.withDelay || messageProps.showTyping}
                                onChange={e => handleChange(e, 'withDelay')}
                            />
                            <BsClockFill
                                size="16px"
                                color={messageProps.withDelay || messageProps.showTyping ? '#000' : '#BBB'}
                                style={{ margin: '0 2px 0 2px' }}
                            />
                            <div className="shared-message-checkbox-text">
                                Set Delay
                            </div>
                        </Label>
                    </FormGroup>
                </Col>

                {/* TIME INPUT FIELD */}
                {(messageProps.withDelay || messageProps.showTyping) &&
                    <Col xs={8}>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    className="shared-message-number-input"
                                    placeholder="Duration (ms)"
                                    type="number"
                                    value={typingDurationValue}
                                    onChange={(e) => handleChange(e, 'typingDuration')}
                                    invalid={!messageProps.typingDuration ||
                                        messageProps.typingDuration === '0' ||
                                        messageProps.typingDuration === ''}
                                    style={{
                                        border: `${!messageProps.typingDuration ||
                                        messageProps.typingDuration === '0' ||
                                        messageProps.typingDuration === '' ? '1px solid red' : ''}`,
                                    }}
                                />
                                <FormFeedback invalid="true">
                                    Please specify typing duration
                                </FormFeedback>
                            </Label>
                        </FormGroup>
                    </Col>
                }
            </Row>

            {/* SHOW TYPING */}
            <Row>
                <Col>
                    <FormGroup check>
                        <Label
                            check
                            className="shared-message-label"
                        >
                            <Input
                                className="pointer"
                                type="checkbox"
                                checked={messageProps.showTyping}
                                onChange={e => { handleChange(e, 'showTyping'); handleSetTimeDelay(); }}
                            />
                            <BsFillEyeFill
                                size="20px"
                                color={messageProps.showTyping ? '#000' : '#BBB'}
                            />
                            <div className="shared-message-checkbox-text">
                                Show typing
                            </div>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>

            {/* SHOW AVATAR */}
            <Row>
                <Col>
                    <FormGroup check>
                        <Label check className="shared-message-label">
                            <Input
                                className="pointer"
                                type="checkbox"
                                checked={messageProps.showAvatar}
                                onChange={(e) => handleChange(e, 'showAvatar')}
                            />
                            <BsFillEyeFill
                                size="20px"
                                color={messageProps.showAvatar ? '#000' : '#BBB'}
                            />
                            <div className="shared-message-checkbox-text">
                                Show avatar
                            </div>
                        </Label>
                    </FormGroup>
                </Col>
            </Row>

            {/* SHOW CELEBRATION */}
            <Row>
                <Col>
                    <FormGroup check>
                        <Label check className="shared-message-label">
                            <Input
                                className="pointer"
                                type="checkbox"
                                checked={messageProps.showCelebration}
                                onChange={(e) => handleChange(e, 'showCelebration')}
                            />
                            <BsFillEyeFill
                                size="20px"
                                color={messageProps.showCelebration ? '#000' : '#BBB'}
                            />
                            <div className="shared-message-checkbox-text">
                                Show celebration
                            </div>
                        </Label>
                    </FormGroup>
                </Col>

                {showCelebrationMenu &&
                    <Col sm={6}>
                        <button
                            type="button"
                            className="shared-module-button"
                            onClick={() => toggleCelebrationDropdown()}
                            style={{ width: '150px' }}
                        >
                            <h6>{celebration.name}</h6>
                            <BsCaretDownFill
                                size="11px"
                                style={{ marginTop: '-4px' }}
                            />
                        </button>

                        {/* MODULE SELECTION MENU */}
                        {isCelebrationMenuOpen &&
                            <div className="shared-menu celebrations">
                                <button
                                    className="shared-item celebrations"
                                    type="submit"
                                    onClick={() => handleSelectCelebration(celebrations.confetti)}
                                    style={celebration.type === celebrations.confetti.type ? selectedStyles : unselectedStyles}
                                >
                                    <HiSparkles
                                        {...celebrationIconStyles}
                                        color={celebration.type === celebrations.confetti.type ? '#FFF' : '#756682'}
                                    />
                                    {celebrations.confetti.name}
                                </button>
                                <button
                                    className="shared-item celebrations"
                                    type="submit"
                                    onClick={() => handleSelectCelebration(celebrations.smileys)}
                                    style={celebration.type === celebrations.smileys.type ? selectedStyles : unselectedStyles}
                                >
                                    <FaSmile
                                        {...celebrationIconStyles}
                                        color={celebration.type === celebrations.smileys.type ? '#FFF' : '#756682'}
                                    />
                                    {celebrations.smileys.name}
                                </button>
                                <button
                                    className="shared-item celebrations"
                                    type="submit"
                                    onClick={() => handleSelectCelebration(celebrations.hearts)}
                                    style={celebration.type === celebrations.hearts.type ? selectedStyles : unselectedStyles}
                                >
                                    <FaHeart
                                        {...celebrationIconStyles}
                                        color={celebration.type === celebrations.hearts.type ? '#FFF' : '#756682'}
                                    />
                                    {celebrations.hearts.name}
                                </button>
                                <button
                                    className="shared-item celebrations"
                                    type="submit"
                                    onClick={() => handleSelectCelebration(celebrations.custom)}
                                    style={celebration.type === celebrations.custom.type ? selectedStyles : unselectedStyles}
                                >
                                    <RiEdit2Fill
                                        {...celebrationIconStyles}
                                        color={celebration.type === celebrations.custom.type ? '#FFF' : '#756682'}
                                    />
                                    Save Custom
                                </button>
                                <input
                                    className="shared-input"
                                    value={customEmoji}
                                    onChange={(e) => handleCustomEmoji(e)}
                                    style={{ width: '120px' }}
                                />
                            </div>
                        }
                    </Col>
                }
            </Row>
        </div>
    );
};
export default SharedMessageConfiguration;
