import React, { useState } from 'react';
import { Modal as ReactStrapModal, ModalBody, ModalHeader } from 'reactstrap';
import Text from './Text';
import Image from './Image';

const Modal = ({ header, title, text, image }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="modal-component">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={() => setShowModal(!showModal)} className="cursor-pointer">
                <Text text={title} textClassName="font-weight-bold"/>
            </div>
            {showModal &&
                <ReactStrapModal
                    isOpen
                    size="lg"
                    className="modal-module"
                    backdrop={false}
                    toggle={() => setShowModal(!showModal)}
                >
                    <ModalHeader toggle={() => setShowModal(!showModal)}>
                        {header}
                    </ModalHeader>
                    <ModalBody className="custom-content modal-module">
                        {text && <p>{text}</p>}
                        {image && <Image url={image}/>}
                    </ModalBody>
                </ReactStrapModal>
            }
        </div>
    );
};

export default Modal;