import React, { useState, useEffect } from 'react';
import {
    Navbar as TopNav,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row,
    Col,
} from 'reactstrap';
import _get from 'lodash.get';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/all';
import { setLanguage } from '../../../redux/languages/actions';
import { logout } from '../../../redux/auth/actions';
import { getCurrentUserData } from '../../../redux/user/actions';
import { history } from '../../../routes';
import avatar from '../../../assets/images/profilePhoto.jpg';

const Navbar = ({ getCurrentUserData, screen, logout }) => {
    const [toggleArrow, setToggleArrow] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState({
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        profilePicture: '',
    });

    const handleClickOnNavbarItem = (route) => {
        history.push(route);
    };

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const currentUser = await getCurrentUserData();
                setUser({
                    id: currentUser.data.id,
                    firstName: currentUser.data.first_name,
                    lastName: currentUser.data.last_name,
                    email: currentUser.data.email,
                    role: currentUser.data.role,
                    profilePicture: currentUser.data.profile_picture,
                });
                setIsLoading(false);
            } catch (err) {
                const errorStatus = _get(err, 'response.status', null);
                if (errorStatus >= 400 || errorStatus < 500) {
                    logout();
                    history.push('/login');
                } else {
                    toastr.error('Could not retrieve user. Please try again.');
                }
            }
        };

        getCurrentUser();
    }, []);

    return (
        <TopNav className="header top-navigation navbar-expand-md shadow-sm m-0 pt-1 pb-0">
            <div className="w-50 pt-2">
                <Row className="navbar-header pull-left mx-1">
                    {screen === 'home' &&
                        <NavItem className="nav navbar-nav">
                            <Col
                                className="link p-0"
                                onClick={() => handleClickOnNavbarItem('/')}
                            >
                                Campaigns
                                <span className="sr-only">(current)</span>
                            </Col>
                        </NavItem>
                    }

                    {screen === 'insights' &&
                        <NavItem className="nav navbar-nav">
                            <Col className="current-path">
                                Analytics
                                <span className="sr-only">(current)</span>
                            </Col>
                        </NavItem>
                    }
                </Row>
            </div>

            <Nav
                navbar
                className="ml-auto user-info"
            >
                <NavItem>
                    {isLoading ?
                        <img
                            alt="avatar"
                            className="rounded-circle avatar-placeholder"
                            src={avatar}
                        /> :

                        <Row>
                            <Col className="pr-0">
                                <img
                                    alt="avatar"
                                    className="rounded-circle avatar"
                                    src={user.profilePicture ? user.profilePicture : avatar}
                                />
                            </Col>
                            <Col className="m-auto pl-0 pr-0">
                                <UncontrolledDropdown setActiveFromChild>
                                    <DropdownToggle
                                        tag="a"
                                        className="nav-link user-nav-link"
                                    >
                                        {toggleArrow ?
                                            <IoIosArrowUp
                                                onClick={() => setToggleArrow(!toggleArrow)}
                                                className="arrow-color"
                                            /> :
                                            <IoIosArrowDown
                                                onClick={() => setToggleArrow(!toggleArrow)}
                                                className="arrow-color"
                                            />
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu className="menu-items">
                                        <DropdownItem header>
                                            {user.email}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => history.push('/user-info')}>
                                            Account
                                        </DropdownItem>
                                        <DropdownItem onClick={() => logout()}>
                                            Logout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>
                    }
                </NavItem>
            </Nav>
        </TopNav>
    );
};

export default connect((state) => ({
    language: state.language.selectedLanguage,
    screen: state.shared.screen,
}), { setLanguage, getCurrentUserData, logout })(Navbar);
