import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const LocationButton = ({
    // coordinatesFound = 'Invalid Coordinates',
    text = 'Get Location',
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    // eslint-disable-next-line camelcase
    node_branches,
    moduleIndex,
    buttonTextColor,
    buttonColor = '#00C',
    callBack = null,
    // answered = false,
    handleNodeBranching,
    loadWallpaperImage,
    chatbotActive,
    hasBeenClicked,
}) => {
    const buttonRef = useRef();
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };
    // const [isAnswered, setAnswered] = useState(false);
    const [coordinatesValue, setCoordinatesValue] = useState('Invalid Coordinates');
    useEffect(() => {
        /* eslint-env browser */
        const getCoordinates = async () => {
            await navigator.geolocation.getCurrentPosition(
                (position) => {
                    const coordinatesLatLong = [position.coords.latitude, position.coords.longitude];
                    setCoordinatesValue(coordinatesLatLong);

                    if (callBack != null) {
                        callBack();
                    }
                },
                (error) => {
                    console.error(`Error Code = ${error.code} - ${error.message}`);
                    // callBack();
                },
                options,
            );
        };

        getCoordinates();
    }, [hasBeenClicked]);

    const handleBranching = (nodeBranch) => {
        //     const replyMessage = {
        //         type: MODULE_TYPES.MapQuest,
        //         props: {
        //             coordinates: coords,
        //             title: 'map',
        //             node_branches: [nodeBranch],
        //             answered: true,
        //         },
        //     };

        if (horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl,
                horizontalBgSize,
                horizontalBgRepeat,
                wallpaperExternalLinkUrl,
            });
        }

        if (!hasBeenClicked) {
            handleNodeBranching(nodeBranch, moduleIndex);
        }
    };

    const handleLocation = async (nodeBranch) => {
        if (!chatbotActive) {
            return;
        }

        if (buttonRef.current) {
            buttonRef.current.setAttribute('disabled', 'disabled');
        }

        handleBranching(nodeBranch);

        /* eslint-env browser */
        // await navigator.geolocation.getCurrentPosition(
        //     (position) => {
        //         const coordinatesLatLong = [position.coords.latitude, position.coords.longitude];
        //         setCoordinatesValue(coordinatesLatLong);
        //         // setHasBeenClicked(moduleIndex);
        //         setAnswered(true);

        //         if (callBack != null) {
        //             callBack();
        //         } else {
        //             console.log('hasBeenClicked', hasBeenClicked, coordinatesLatLong);
        //             // handleBranching(nodeBranch, coordinatesLatLong);
        //             handleBranching(nodeBranch);
        //         }
        //     },
        //     (error) => {
        //         console.error(`Error Code = ${error.code} - ${error.message}`);
        //         callBack();
        //     },
        //     options,
        // );
    };

    return (
        <div>
            {!hasBeenClicked ?
                <div>
                    <Button
                        style={{
                            color: buttonTextColor,
                            borderColor: buttonColor,
                            backgroundColor: buttonColor,
                        }}
                        ref={buttonRef}
                        onClick={() => handleLocation(node_branches[0])}
                        className="answer-button w-100 unanswered"
                        // className={`answer-button w-100 ${isAnswered ? 'answered' : 'unanswered'}`}
                    >
                        {text}
                    </Button>
                </div> :

                <div className="chat-bubble bold">
                    <p className="m-0">{coordinatesValue}</p>
                </div>
            }
        </div>
    );
};
export default LocationButton;