import Axios from 'axios';
import { API_URL } from '../config';
import { getRequestHeader } from '../helpers/requestHelper';

export function registerUser(user) {
    const userBody = { user };
    return Axios.post(`${API_URL}/api/users/register`, JSON.stringify(userBody), getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function loginUser(user) {
    return Axios.post(`${API_URL}/api/auth/login`, JSON.stringify(user), getRequestHeader(false))
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function confirmEmail(securityKey) {
    const userBody = { securityKey };
    return Axios.put(`${API_URL}/api/users/confirmEmail`, userBody, getRequestHeader(false))
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function checkIfEmailExists(email) {
    return Axios.get(`${API_URL}/api/users/checkEmail/${email}`, getRequestHeader(false))
        .then(response => response.data)
        .catch(err => {
            throw err;
        });
}

export function retrieveUserByEmail(email) {
    return Axios.get(`${API_URL}/api/users/byEmail/${email}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function retrieveCurrentUserData() {
    return Axios.get(`${API_URL}/api/users/personalData`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function retrieveUsers() {
    return Axios.get(`${API_URL}/api/users/`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function deleteUserById(userID) {
    return Axios.delete(`${API_URL}/api/users/${userID}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function userEdit(user) {
    const userBody = { user };
    return Axios.put(`${API_URL}/api/users/`, userBody, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function currentUserEdit(user) {
    const userBody = { user };
    return Axios.put(`${API_URL}/api/users/updateCurrentUserData`, userBody, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function profilePictureUpdate(profilePicture) {
    const body = { profilePicture };
    return Axios.put(`${API_URL}/api/users/updateProfilePicture`, body, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function passwordUpdate(password) {
    const body = { password };
    return Axios.put(`${API_URL}/api/users/updatePassword`, body, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function createUserByAdmin(user) {
    const userBody = { user };
    return Axios.post(`${API_URL}/api/users/`, userBody, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function retrieveRoles() {
    return Axios.get(`${API_URL}/api/users/roles/`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function socialLoginService(data, provider) {
    return Axios.post(`${API_URL}/api/auth/${provider}`, { access_token: data }, getRequestHeader(false))
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function registerUserWithoutPasswordWithProviderService(data, provider) {
    return Axios.post(`${API_URL}/api/auth/${provider}/registerWithoutPassword`, { access_token: data }, getRequestHeader(false))
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function registerUserWithProviderService(data, provider) {
    const tokenAndPassword = {
        access_token: data.accessToken,
        password: data.password,
    };
    return Axios.post(`${API_URL}/api/auth/${provider}/register`, tokenAndPassword, getRequestHeader(false))
        .then(response => response)
        .catch(err => {
            throw err;
        });
}
