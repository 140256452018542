/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const Image = ({
    campaign,
    moduleIndex,
    imageUrl,
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    externalLink,
    chatbotActive,
    showPreview,
    node_branches,
    checkForNodeBranches,
    handleNodeBranching,
    loadWallpaperImage,
    hasBeenClicked,
    userId,
    moduleIdentifier,
}) => {
    const [hasConfiguredNodeBranches, setHasConfiguredNodeBranches] = useState(false);
    useEffect(() => {
        if (node_branches && node_branches[0]) {
            setHasConfiguredNodeBranches(checkForNodeBranches(node_branches[0]));
        }
    });

    // const [externalLinkURL, setExternalLinkURL] = useState('');
    // useEffect(() => {
    //     if (externalLink) {
    //         if (externalLink.includes('http://') || externalLink.includes('https://')) {
    //             setExternalLinkURL(externalLink);
    //             console.log({ externalLink });
    //         } else {
    //             setExternalLinkURL(`https://${externalLink}`);
    //             console.log({ externalLinkURL });
    //         }
    //     }
    // }, []);

    const logUserClick = () => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campaignURL = window.location.href;

        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campaignURL;
        userDataObject['ExternalLink Clicked'] = externalLink || 'No Link';
        userDataObject['Image Clicked'] = imageUrl || 'No Image URL';
        userDataObject.ModuleIdentifier = moduleIdentifier || 'No Identifier';
        userDataObject.TypeOfClick = externalLink ? 'ImageExternalLinkClick' : 'ImageClick';
        userAnalytics.push(userDataObject);
        // console.log('userData\n', JSON.stringify(userInput.userData));

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!:', response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleClick = () => {
        // if (externalLink) {
        //     window.open(externalLink, 'Image Redirect');
        //     logUserClick()
        // }

        if (!node_branches || !(horizontalImageUrl || hasConfiguredNodeBranches)) {
            return;
        }

        if (!campaign.demo && !showPreview) {
            logUserClick();
        }

        if (horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl,
                horizontalBgSize,
                horizontalBgRepeat,
                wallpaperExternalLinkUrl,
            });
        }

        if (!hasBeenClicked) {
            handleNodeBranching(node_branches[0], moduleIndex);
        }
    };

    return (
        <>
            {chatbotActive && externalLink ?
                <a
                    href={externalLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    onClick={() => logUserClick()}
                >
                    <img
                        className="image-module"
                        src={imageUrl}
                        alt={`message module ${moduleIndex}`}
                    />
                </a> :

                <>
                    <button
                        className="image-module-button"
                        type="button"
                        style={{ cursor: !chatbotActive || (node_branches && (horizontalImageUrl || hasConfiguredNodeBranches)) ?
                            'pointer' : 'default',
                        }}
                        onClick={() => handleClick()}
                    >
                        <img
                            className="image-module"
                            src={imageUrl}
                            alt={`message module ${moduleIndex}`}
                        />
                    </button>
                </>
            }
        </>
    );
};

export default Image;
