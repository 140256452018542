import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { BsImage, BsXCircle } from 'react-icons/bs';
import { ImageSizing } from '.';
import { IMAGE_PLACEHOLDER_URL, VALID_IMAGE_TYPES } from '../../../config';
import { checkIfImageUrlValid } from '../../../helpers/regexHelpers';
import { checkSharedMessageError } from '../ModuleConfigurations/errorHelper';

const ButtonWallpaper = ({
    buttonIndex,
    nodeBranch,
    setSelectedMessage,
}) => {
    const [errorImageUpload, setErrorImageUpload] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [errorImageURL, setErrorImageURL] = useState(false);
    const [hasImageUrl, setHasImageUrl] = useState(false);
    const {
        horizontalImageUrl: imageUrl,
        horizontalImageFile: imageFile,
        horizontalBgSize,
        horizontalBgRepeat,
    } = nodeBranch;
    useEffect(() => {
        if (imageUrl && imageUrl !== '' && imageUrl !== IMAGE_PLACEHOLDER_URL) {
            setHasImageUrl(true);
        } else {
            setHasImageUrl(false);
        }
    }, [imageUrl]);

    const [displayFileName, setDisplayFileName] = useState('');
    useEffect(() => {
        if (imageFile && imageFile.name) {
            setDisplayFileName(`${imageFile.name}`);
        } else if (imageUrl) {
            let fileName = '';
            const regexAwsPartialURI = /\.\w{4}-/i; // ex: https://url.com/img/%3A58.539Z-example.png
            const result = regexAwsPartialURI.exec(imageUrl);
            if (result) { fileName = imageUrl.slice(result.index + 6); }
            setDisplayFileName(`${fileName}`);
        }
    }, [imageFile, imageUrl]);

    const handleImageChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        const imageFile = e.target.files[0];
        if (!VALID_IMAGE_TYPES.includes(imageFile.type)) {
            setErrorImageUpload(true);
        } else {
            setDisplayFileName(imageFile.name);
            setErrorImageUpload(false);
            setErrorImageURL(false);
            setImageURL('');
            setSelectedMessage(selectedMessage => {
                const newNodeBranches = [...selectedMessage.message.props.node_branches];
                console.log(buttonIndex, { newNodeBranch: newNodeBranches[buttonIndex] });
                newNodeBranches[buttonIndex] = {
                    ...newNodeBranches[buttonIndex],
                    horizontalImageUrl: URL.createObjectURL(imageFile),
                    horizontalImageFile: imageFile,
                };
                return {
                    ...selectedMessage,
                    message: {
                        ...selectedMessage.message,
                        props: {
                            ...selectedMessage.message.props,
                            node_branches: newNodeBranches,
                            error: !imageFile || checkSharedMessageError(selectedMessage.message.props) ||
                                !VALID_IMAGE_TYPES.includes(imageFile.type),
                        },
                    },
                };
            });
            // resets value to initial state because if same picture is uploaded there is no state change
            e.target.value = '';
        }
    };

    const handleImageUrlChange = (e) => {
        const { value } = e.target;

        if (!checkIfImageUrlValid(value)) {
            setErrorImageURL(true);
        } else {
            setErrorImageURL(false);
        }
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[buttonIndex] = {
                ...newNodeBranches[buttonIndex],
                horizontalImageUrl: value || '',
                horizontalImageFile: null,
            };
            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                        error: !value ||
                            checkSharedMessageError(selectedMessage.message.props) ||
                            !checkIfImageUrlValid(value),
                    },
                },
            };
        });
        setImageURL(value);
    };

    const handleRemoveImage = (index) => {
        setDisplayFileName('');
        setErrorImageUpload(false);
        setErrorImageURL(false);
        setImageURL('');
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[index] = {
                ...newNodeBranches[index],
                horizontalImageUrl: null,
                horizontalImageFile: null,
            };
            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                        error: selectedMessage.message.props.error ||
                            (!imageFile ||
                            checkSharedMessageError(selectedMessage.message.props) ||
                            !VALID_IMAGE_TYPES.includes(imageFile.type)),
                    },
                },
            };
        });
    };

    const RemoveImage = () => (
        <>
            <button
                className="shared-remove-file-button"
                type="submit"
                onClick={() => handleRemoveImage(buttonIndex)}
            >
                <BsXCircle
                    size="15px"
                    style={{ marginTop: '-10px', color: '#F83667' }}
                />
            </button>
        </>
    );

    const setCoverValues = (value) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[buttonIndex] = {
                ...newNodeBranches[buttonIndex],
                horizontalBgSize: `${value}% auto`,
                horizontalBgRepeat: 'repeat',
            };
            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            };
        });
    };

    const setButtonValues = (size, repeat) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[buttonIndex] = {
                ...newNodeBranches[buttonIndex],
                horizontalBgSize: size,
                horizontalBgRepeat: repeat,
            };
            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            };
        });
    };

    return (
        <Row
            className="m-1"
            key={`bwprow_${buttonIndex}_${imageUrl}`}
        >
            <Col>
                <h6 className="shared-heading">
                    <BsImage
                        size="18px"
                        color="#000"
                        style={{ margin: '0 12px 0 5px' }}
                    />
                    Wallpaper Image #
                    {buttonIndex + 1}
                </h6>

                {/* UPLOAD BUTTON */}
                <FormGroup className="m-0 p-0">
                    <Label
                        className="shared-media-upload border"
                        for="horizontalImage"
                    >
                        <p>Upload or drag image here</p>
                    </Label>
                    <div
                        style={{
                            width: '100%',
                            height: '25px',
                            display: 'flex',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <div className="shared-heading">{displayFileName}</div>
                        {(imageFile || hasImageUrl) && <RemoveImage/>}
                    </div>
                    {/* {imageFile && imageFile.name && imageURL === '' &&
                        <p className="pt-2 description-font">{imageFile.name}</p>
                    } */}
                </FormGroup>

                {/* IMAGE URL TEXT BOX */}
                <FormGroup>
                    <Input
                        className="shared-input"
                        type="textarea"
                        placeholder="Or enter image URL"
                        value={imageUrl && imageUrl !== IMAGE_PLACEHOLDER_URL && imageURL === '' && !imageFile ? imageUrl : imageURL}
                        onChange={handleImageUrlChange}
                    />
                    {errorImageURL &&
                        <p className="error-message">Please enter a valid URL</p>
                    }
                </FormGroup>

                {/* HIDDEN INPUT & ERROR MESSAGE */}
                <FormGroup>
                    <Input
                        type="file"
                        name="horizontalImage"
                        id="horizontalImage"
                        onChange={handleImageChange}
                    />
                    {errorImageUpload &&
                        <p className="error-message">
                            Please upload image in valid format (JPG, JPEG, PNG, GIF, BMP)
                        </p>
                    }
                </FormGroup>

                {/* WALLPAPER IMAGE SIZING */}
                {(imageFile || hasImageUrl) &&
                    <ImageSizing
                        stylesBgSize={horizontalBgSize}
                        stylesBgRepeat={horizontalBgRepeat}
                        setCoverValues={setCoverValues}
                        setButtonValues={setButtonValues}
                    />
                }
            </Col>
        </Row>
    );
};

export default ButtonWallpaper;