export const lightenColor = (color) => {
    let c;

    // when it's an RGB based hex (ie: #FFBBAA)
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
        c = color.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        // eslint-disable-next-line no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},0.3)`;
    }

    // when it's a hex with alpha (ie: #FFBBAAFF)
    if (/^#[0-9A-F]{8}$/i.test(color)) {
        c = color.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join('')}`;
        // eslint-disable-next-line no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},0.3)`;
    }

    // throw new Error('Bad Hex');
    return 'rgba(0,0,0,0.3)';
};