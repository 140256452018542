export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAIL = 'GET_USERS_FAIL';
export const GET_ANALYTICS_SUCCESS = 'GET_ANALYTICS_SUCCESS';
export const GET_ENGAGEMENTS_BY_GENDER_SUCCESS = 'GET_ENGAGEMENTS_BY_GENDER_SUCCESS';
export const GET_ENGAGEMENTS_BY_CITY_SUCCESS = 'GET_ENGAGEMENTS_BY_CITY_SUCCESS';
export const GET_ENGAGEMENTS_BY_OPERATING_SYSTEM_SUCCESS = 'GET_ENGAGEMENTS_BY_OPERATING_SYSTEM_SUCCESS';
export const GET_ENGAGEMENTS_BY_DEVICE_CATEGORY_SUCCESS = 'GET_ENGAGEMENTS_BY_DEVICE_CATEGORY_SUCCESS';
export const RESET_ANALYTICS_SUCCESS = 'RESET_ANALYTICS_SUCCESS';
export const ARCHIVE_CAMPAIGN_SUCCESS = 'ARCHIVE_CAMPAIGN_SUCCESS';
export const ARCHIVE_CAMPAIGN_FAIL = 'ARCHIVE_CAMPAIGN_FAIL';
export const RESTORE_CAMPAIGN_SUCCESS = 'RESTORE_CAMPAIGN_SUCCESS';
export const RESTORE_CAMPAIGN_FAIL = 'RESTORE_CAMPAIGN_FAIL';
export const GET_ARCHIVED_CAMPAIGNS_SUCCESS = 'GET_ARCHIVED_CAMPAIGNS_SUCCESS';
export const GET_ARCHIVED_CAMPAIGNS_FAIL = 'GET_ARCHIVED_CAMPAIGNS_FAIL';
export const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAIL = 'CREATE_CAMPAIGN_FAIL';
export const EDIT_CAMPAIGN_SUCCESS = 'EDIT_CAMPAIGN_SUCCESS';
export const EDIT_CAMPAIGN_FAIL = 'EDIT_CAMPAIGN_FAIL';
export const EDIT_MODULE_STYLES_SUCCESS = 'EDIT_MODULE_STYLES_SUCCESS';
export const EDIT_MODULE_STYLES_FAIL = 'EDIT_MODULE_STYLES_FAIL';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL';