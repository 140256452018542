import React from 'react';
import { Button } from 'reactstrap';
import { BsPlusCircle } from 'react-icons/bs';

const AddNewButton = ({
    handleClick,
    text,
}) => (
    <>
        <Button
            className="btn-secondary-custom"
            onClick={() => handleClick()}
        >
            <BsPlusCircle
                size="18px"
                color="#000"
                style={{ margin: '-2px 10px 0 0' }}
            />
            {text}
        </Button>
    </>
);

export default AddNewButton;

