import React, { useState } from 'react';
import { FormGroup, Input } from 'reactstrap';
import { IMAGE_PLACEHOLDER_URL } from '../../../config';
import { checkIfImageUrlValid } from '../../../helpers/regexHelpers';

const ImageUrlInput = ({ image, setImage }) => {
    const [imageURL, setImageURL] = useState('');
    const [errorImageURL, setErrorImageURL] = useState(false);

    const handleImageUrlChange = (e) => {
        const { value } = e.target;

        if (!checkIfImageUrlValid(value)) {
            setErrorImageURL(true);
        } else {
            setErrorImageURL(false);
        }

        setImage(value);
        setImageURL(value);
    };

    return (
        <>
            <div className="shared-media-info">or</div>

            <FormGroup>
                <Input
                    type="textarea"
                    className="shared-input"
                    style={{ height: '90px' }}
                    placeholder="Enter image URL"
                    value={image && image !== IMAGE_PLACEHOLDER_URL && typeof image !== 'object' && !image.includes('amazonaws.com') ?
                        image : imageURL}
                    onChange={handleImageUrlChange}
                />
                {errorImageURL &&
                    <p className="error-message">Please enter valid URL</p>
                }
            </FormGroup>
        </>
    );
};

export default ImageUrlInput;