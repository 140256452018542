import Collage from './Collage';
import EmailSubmissionForm from './EmailSubmissionForm';
import Image from './Image';
import ImageCarousel from './ImageCarousel';
import InputField from './InputField';
import LocationButton from './LocationButton';
import PhoneNumberField from './PhoneNumberField';
import ButtonReply from './ButtonReply';
import QuickReply from './QuickReply';
import Rating from './Rating';
import SKUx from './SKUx';
import Text from './Text';
import Video from './Video';

export default {
    Collage,
    EmailSubmissionForm,
    Image,
    ImageCarousel,
    InputField,
    LocationButton,
    PhoneNumberField,
    ButtonReply,
    QuickReply,
    Rating,
    SKUx,
    Text,
    Video,
};