export const defaultCustomData = {
    isCustomized: false,
    siteTermsTitle: 'Cookie Policy',
    siteTermsLinks: [
        {
            text: 'Full Cookie Policy',
            url: 'https://www.curr3nt.io/privacypolicy',
        },
    ],
    siteTermsText: `Curr3nt works best with cookies enabled. 
        We use this information to improve and customize your experience. To find out more about the cookies we use, see our policies:`,
};

export const defaultCampaignStyles = {
    logoHeight: '50',
    outerBgColor: '#DDDDDD',
    interiorBgColor: '#FFFFFF',
};

export const defaultModuleStyles = {
    colorPalette: {
        colors: ['#000000', '#FFFFFF', '#AAAAAA'],
    },
    ButtonReply: {
        animation: 'fade-in',
        unansweredButtonColor: '#DDDDDD',
        unansweredTextColor: '#000000',
        unansweredBorderColor: '#DDDDDD00',
        answeredButtonColor: '#DDDDDD',
        answeredTextColor: '#000000',
        answeredBorderColor: '#DDDDDD00',
        replyBackgroundColor: '#EEEEEE',
        replyBorderColor: '#000000',
    },
    Collage: {
        animation: 'fade-in',
        replyBackgroundColor: '#CCCCCC',
        replyBorderColor: '#CCCCCC',
    },
    EmailSubmissionForm: {
        animation: 'fade-in',
        backgroundColor: '#BBBBBB',
        emailFieldColor: '#FFFFFF',
        emailTextColor: '#000000',
        buttonBackgroundColor: '#DDDDDD',
        buttonTextColor: '#000000',
    },
    ImageCarousel: {
        animation: 'fade-in',
        carouselBackgroundColor: '#FFFFFF',
        buttonBackgroundColor: '#DDDDD',
        buttonTextColor: '#000000',
        buttonBorderRadius: 0,
        headerTextColor: '#DDDDDD',
        subheaderTextColor: '#DDDDDD',
        headerTextAlignment: 'left',
        isFilled: false,
        isVirtualized: false,
    },
    InputField: {
        animation: 'fade-in',
        inputFieldColor: '#FFFFFF',
        inputTextColor: '#000000',
        buttonBackgroundColor: '#AAAAAA',
        buttonTextColor: '#FFFFFF',
    },
    LocationButton: {
        animation: 'fade-in',
        buttonTextColor: '#000000',
        buttonColor: '#DDDDDD',
    },
    PhoneNumberField: {
        animation: 'fade-in',
        backgroundColor: '#BBBBBB',
        buttonBackgroundColor: '#DDDDDD',
        buttonTextColor: '#000000',
    },
    QuickReply: {
        animation: 'fade-in',
        groupHeading: '',
        unansweredButtonColor: '#DDDDDD',
        unansweredTextColor: '#000000',
        unansweredIconColor: '#000000',
        unansweredIconTextColor: '#DDDDDD',
        unansweredBorderColor: '#DDDDDD00',
        answeredButtonColor: '#DDDDDD',
        answeredTextColor: '#000000',
        answeredIconColor: '#000000',
        answeredIconTextColor: '#DDDDDD',
        answeredBorderColor: '#DDDDDD00',
        buttonBorderRadius: 0,
        replyBackgroundColor: '#EEEEEE',
        replyBorderColor: '#000000',
        isStacked: false,
        isFilled: false,
        hasButtonShadow: false,
        hasBackgroundShadow: false,
    },
    Rating: {
        animation: 'fade-in',
        starCount: 5,
        starColor: '#000000',
        emptyStarColor: '#DDDDDD',
        unselectedBgColor: '#FFFFFF',
        selectedBgColor: '#1A87FB',
    },
    SKUx: {
        animation: 'fade-in',
        backgroundColor: '#DDDDDD',
        buttonColor: '#EEEEEE',
    },
    Text: {
        animation: 'fade-in',
        textColor: '#DDDDDD',
    },
};