import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import localization from '../../helpers/localizationHelper';

const Unauthorized = () => (
    <div>
        <div>
            <h1 className="static-text text-center"> 403 </h1>
            <h1 className="static-text text-center">
                {localization.unauthorizedScreen.unauthorized}
            </h1>
            <br/>
            <br/>
            <br/>
            <br/>
            <h3 className="static-back-button text-center">
                <Link to="/">
                    {localization.unauthorizedScreen.back}
                </Link>
            </h3>
        </div>
    </div>
);
export default connect((state) => ({ language: state.language.selectedLanguage }))(Unauthorized);