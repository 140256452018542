/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import ReactQuill from 'react-quill';
import {
    BranchingPath,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { checkSharedMessageError } from './errorHelper';

const PhoneNumberField = ({
    text = '<p>Reply <b>YES</b> to confirm your subscription!</p><p>(See..how easy was that?)<p>',
    campaignName,
    backgroundColor,
    buttonBackgroundColor,
    buttonTextColor,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    moduleHeading,
    waterfallLayout,
    node_branches,
    setSelectedMessage,
    reference,
    configureNodeBranchingPath,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const emptyValue = '<p><br></p>';
    const placeholderValue = '<p>Add some text here...</p>';
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [textToSend, setTextToSend] = useState(text);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.PhoneNumberField.animation,
                        backgroundColor: moduleStyles.PhoneNumberField.backgroundColor,
                        buttonBackgroundColor: moduleStyles.PhoneNumberField.buttonBackgroundColor,
                        buttonTextColor: moduleStyles.PhoneNumberField.buttonTextColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                PhoneNumberField: {
                    ...moduleStyles.PhoneNumberField,
                    animation,
                    backgroundColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                },
            }));
        }
    }, [animation, backgroundColor, buttonBackgroundColor, buttonTextColor]);

    useEffect(() => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    text,
                    campaignName,
                    error: !node_branches.length,
                },
            },
        }));
    }, [node_branches]);

    // STYLE CHANGES
    const quillFormats = [
        'header',
    ];

    const quillModules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['clean'],
        ],
    };

    const handleTextChange = (text) => {
        setTextToSend(text);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    text,
                    campaignName,
                    error: (!isFirstTime && text === emptyValue) || checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const emptyTextField = () => {
        if (text === placeholderValue) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        text: '',
                    },
                },
            }));
            setIsFirstTime(false);
        }
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('PhoneNumberField');
        } else {
            clearDefaultConfigs('PhoneNumberField');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                PhoneNumberField: {
                    ...moduleStyles.PhoneNumberField,
                    animation,
                    backgroundColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="options-container">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* INPUT FIELD */}
            <Row className="shared-module-background text-input-field mt-4">
                <Col className="m-0 p-0">
                    <h6 className="shared-heading">
                        Message To User
                    </h6>
                    <ReactQuill
                        value={textToSend}
                        onFocus={emptyTextField}
                        onChange={handleTextChange}
                        formats={quillFormats}
                        modules={quillModules}
                    />
                </Col>
            </Row>

            {/* COLOR PICKERS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {/* BUTTON & TEXT */}
                    <Row>
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="buttonBackgroundColor"
                                parentColor={buttonBackgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="buttonTextColor"
                                parentColor={buttonTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* BUTTON BACKGROUND */}
                    <Row className="mt-5">
                        <Col>
                            <ColorPicker
                                heading="Background Color"
                                colorProp="backgroundColor"
                                parentColor={backgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col>
                            <GlobalStylesToggle
                                moduleType="PhoneNumberField"
                                heading="Set as default SMS module colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <BranchingPath
                nodeBranches={node_branches}
                reference={reference}
                configureNodeBranchingPath={configureNodeBranchingPath}
            />

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />

            {/* UPLOAD LEFT WALLPAPER HORIZONTAL LAYOUT */}
            <ImageUpload
                wallpaper
                imageUrl={horizontalImageUrl}
                imageFile={horizontalImageFile}
                horizontalBgSize={horizontalBgSize}
                horizontalBgRepeat={horizontalBgRepeat}
                wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default PhoneNumberField;