import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
// import { Image } from 'react-bootstrap';
import { BsArrow90DegLeft, BsPlusCircle } from 'react-icons/bs';
import { FaFolderOpen } from 'react-icons/fa';
import { history } from '../../routes';
import { getArchivedCampaigns, getCampaigns, resetAnalytics } from '../../redux/campaign/actions';
import { changeScreen } from '../../redux/shared/actions';
import ModalQrCode from '../../components/ModalQRCode';
// import addButton from '../../assets/images/Add.svg';
import loader from '../../assets/img/loader-small.gif';
import CampaignCard from './CampaignCard';
import { FILTERS } from '../../config';

const Home = ({
    campaigns,
    archivedCampaigns,
    getCampaigns,
    changeScreen,
    resetAnalytics,
    loggedUser,
    getArchivedCampaigns,
}) => {
    const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
    const [showModal, setShowModal] = useState(false);
    const [campaign, setCampaign] = useState({});
    const [showArchived, setShowArchived] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState({
        active: true,
        demo: false,
    });
    const [hasFetchedArchived, setHasFetchedArchived] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getCampaigns({
            archived: false,
            company_name: localStorage.getItem('company-name'),
        });
        resetAnalytics();
        if (loggedUser) {
            changeScreen('home');
        } else {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        if (!showArchived) {
            setFilteredCampaigns(campaigns.filter(campaign => {
                let shouldAddCampaign = true;
                const selectedFiltersEntries = Object.entries(selectedFilters);
                // eslint-disable-next-line no-restricted-syntax
                for (const [filter, shouldShow] of selectedFiltersEntries) {
                    if (!shouldShow === campaign[filter]) {
                        shouldAddCampaign = false;
                    }
                }
                return shouldAddCampaign;
            }));
        } else if (!archivedCampaigns) {
            const getCampaigns = async () => {
                const archivedCampaigns = (await getArchivedCampaigns({
                    archived: true,
                    company_name: localStorage.getItem('company-name'),
                })).data;
                setHasFetchedArchived(true);
                setIsLoading(false);
                if (archivedCampaigns && archivedCampaigns.length) {
                    setFilteredCampaigns(archivedCampaigns);
                } else {
                    setIsLoading(false);
                }
            };
            if (!hasFetchedArchived) {
                getCampaigns();
            }
        } else {
            setFilteredCampaigns(archivedCampaigns);
        }
    }, [selectedFilters, campaigns, showArchived, archivedCampaigns]);

    useEffect(() => {
        if (campaigns && campaigns.length) {
            setIsLoading(false);
        }
    }, [campaigns]);

    // const isSelected = (filter) => selectedFilters[filter];

    const handleOpenModal = (campaign) => {
        setCampaign(campaign);
        setShowModal(true);
    };

    const handleFilterClick = (filter) => {
        setSelectedFilters(selectedFilters => {
            const newSelectedFilters = { ...selectedFilters };
            newSelectedFilters[filter] = !newSelectedFilters[filter];
            return newSelectedFilters;
        });
    };

    const handleGoToArchived = () => {
        if (!archivedCampaigns) {
            setIsLoading(true);
        }
        setShowArchived(true);
    };

    return (
        <>
            <Container
                className="home-container m-3"
                fluid
            >
                {showArchived ?
                    <Row className="ml-0">
                        <Col className="text-left">
                            <p>
                                <BsArrow90DegLeft
                                    className="back-button pointer"
                                    size="35"
                                    color="#000"
                                    onClick={() => setShowArchived(false)}
                                />
                            </p>
                        </Col>
                    </Row> :

                    // UNARCHIVED CAMPAIGNS
                    <Row className="d-flex justify-content-lg-between px-2">
                        {/* ACTIVE-INACTIVE TOGGLE */}
                        <Col md={2} className="button-container m-0 p-0">
                            <form>
                                <label
                                    className="toggle-switch"
                                    htmlFor="activeInactive"
                                >
                                    <input
                                        type="checkbox"
                                        id="activeInactive"
                                        onClick={() => handleFilterClick(FILTERS.active)}
                                    />
                                    <div className="toggle-slider-active">
                                        <div>Active</div>
                                        <div>Inactive</div>
                                    </div>
                                </label>
                            </form>
                        </Col>

                        {/* LIVE-DEMO TOGGLE */}
                        <Col md={2} className="button-container m-0 p-0">
                            <form>
                                <label
                                    className="toggle-switch"
                                    htmlFor="demoLive"
                                >
                                    <input
                                        type="checkbox"
                                        id="demoLive"
                                        onClick={() => handleFilterClick(FILTERS.demo)}
                                    />
                                    <span className="toggle-slider-live">
                                        <div>Live</div>
                                        <div>Demo</div>
                                    </span>
                                </label>
                            </form>
                        </Col>

                        {/* VIEW ARCHIVED */}
                        <Col
                            md={2}
                            className="button-container px-0"
                        >
                            <button
                                className="archived-button"
                                id="archived_campaigns"
                                type="button"
                                onClick={() => handleGoToArchived()}
                            >
                                Archived
                                <FaFolderOpen size="25px" color="#000" style={{ marginTop: '-3px' }}/>
                            </button>
                            <UncontrolledTooltip target="archived_campaigns">
                                View all archived campaigns
                            </UncontrolledTooltip>
                        </Col>

                        {/* CREATE CAMPAIGN */}
                        <Col
                            className="button-container create-container pl-0 pr-5"
                        >
                            <button
                                className="create-button"
                                id="create_campaigns"
                                type="button"
                                onClick={() => history.push('/choose-template')}
                            >
                                Create
                                <BsPlusCircle size="30px" color="#FFF" style={{ margin: '-4px 0 0 10px' }}/>
                            </button>
                            <UncontrolledTooltip target="create_campaigns">
                                Create a new campaign
                            </UncontrolledTooltip>
                        </Col>
                    </Row>
                }

                {/* CAMPAIGN CARDS */}
                {isLoading ?
                    // LOADING PLACEHOLDER
                    <Container className="w-100 h-100 text-center">
                        <img src={loader} alt="Loading"/>
                    </Container> :

                    // LOADED CONTENT
                    <Row className="mx-auto my-2 wrapper">
                        {filteredCampaigns && !!filteredCampaigns.length ?
                            filteredCampaigns.filter(campaign => !campaign.template).map(campaign => (
                                <Col key={campaign.id}>
                                    <CampaignCard
                                        campaign={campaign}
                                        handleModal={handleOpenModal}
                                    />
                                </Col>
                            )) :

                            <>
                                {showArchived &&
                                    <Col className="text-center">
                                        <h3 className="heading-font">
                                            There are no archived campaigns
                                        </h3>
                                    </Col>
                                }
                            </>
                        }
                    </Row>
                }
            </Container>

            {/* POP-UP MODAL */}
            {showModal &&
                <ModalQrCode
                    campaignRef={campaign.ref}
                    firstButtonText="Go back to my campaigns"
                    handleFirstButton={() => setShowModal(false)}
                    headerText={campaign && campaign.name && campaign.name}
                    handleClose={() => setShowModal(false)}
                />
            }
        </>
    );
};

export default connect((state) => ({
    loggedUser: state.auth.loggedUser,
    language: state.language.selectedLanguage,
    campaigns: state.campaigns.all,
    archivedCampaigns: state.campaigns.archived,
    user: state.user.currentUser,
}), { getCampaigns, changeScreen, resetAnalytics, getArchivedCampaigns })(Home);
