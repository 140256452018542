import Axios from 'axios';

/*
const testData =
{
        fileSent: 'https://marketingweek.imgix.net/content/uploads/2014/10/Coca_Cola.jpg',
        ImageRecTestOptionChoice: 'Curr3ntText',
};
*/
export function sendFrameToGoogleVision(data, callback) {
    const packet =
    {
        fileSent: data,
        ImageRecTestOptionChoice: 'Curr3ntText',
    };

    Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/imgrec', packet)
        .then(response => {
            callback(response);
        })
        .catch(err => {
            throw err;
        });
}