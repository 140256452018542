import React, { useEffect, useState } from 'react';
import ChatbotLayout from './ChatbotLayout';
import salesDemoIcon from '../../assets/images/salesdemo-logo.png';
import salesDemoAvatar from '../../assets/images/Brand_Chat_Logo.png';
import MessageModule from './MessageModule';
import Typing from './Typing';
import { MODULE_TYPES } from '../Modules/constants';

const ChatbotSalesDemo = () => {
    const [showTyping, setShowTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [campaign, setCampaign] = useState({});
    const [showCelebration, setShowCelebration] = useState(false);

    const testCampaign = {
        color: '#0e1a5b',
        logo: salesDemoIcon,
        avatar: salesDemoAvatar,
    };

    const message1 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Hi 👋',
            showAvatar: true,
        },
    };

    const message2 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Were you expecting a website?',
        },
    };

    const message3 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Instructions to download an app?',
        },
    };

    const message4 = {
        type: MODULE_TYPES.Image,
        props: {
            url: 'https://media.giphy.com/media/xT0xeuOy2Fcl9vDGiA/200w_d.gif',
        },
    };

    const message5 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Not here.',
        },
    };

    const message6 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Meet BrandChat. Where Media Meets People.',
            showAvatar: true,
            showCelebration: true,
        },
    };

    const message7 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'BrandChat is a dynamic chat platform built for marketers that want to activate their audience',
        },
    };

    const message8 = {
        type: MODULE_TYPES.Text,
        props: {
            text: '...with a quick and simple enhanced experience.',
        },
    };

    const message9 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'No microsites. No apps required.',
        },
    };

    const message10 = {
        type: MODULE_TYPES.Image,
        props: {
            url: 'https://media.giphy.com/media/7vASvnl2wdmgYYBMcs/giphy.gif',
        },
    };

    const message11 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'With a simple Click, Tap, or Scan your media instantly becomes smarter and more interactive.',
            showAvatar: true,
        },
    };

    const message12 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'BrandChat delivers the right content and the right call-to-actions at the right time.',
            showAvatar: true,
        },
    };

    const message13 = {
        type: MODULE_TYPES.Text,
        props: {
            text: "Feedback, Offers, Video, Product Locators, and BUY NOW directly in the palm of people's hands",
            showAvatar: true,
        },
    };

    const message14 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Stay Up To Date and Receive Exclusive Offers via text',
            showAvatar: true,
        },
    };

    const message15 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Would you like to share your phone number?',
            showAvatar: true,
        },
    };

    const message16 = {
        type: MODULE_TYPES.QuickReply,
        props: {
            quick_replies: [{
                title: 'Sure!',
                payload: 'salesDemo_Sure',
            },
            {
                title: 'Maybe Later',
                payload: 'salesDemo_MaybeLater',
            }],
            showAvatar: false,
            showCelebration: false,
        },
    };

    const getCampaign = () => {
        // TODO Fetch data here
        setCampaign(testCampaign);
    };

    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };

    const renderMessage = (message) => <MessageModule
        key={JSON.stringify(message)}
        setMessages={setMessages}
        message={message}
        setShowCelebration={message.props.showCelebration && setShowCelebration}
        avatar={message.props.showAvatar && campaign.avatar}
    />;

    const populateBubbles = () => {
        setTimeout(() => {
            setShowTyping(true);
            addMessage(message1);
        }, 4000);

        setTimeout(() => {
            addMessage(message2);
        }, 5000);

        setTimeout(() => {
            addMessage(message3);
        }, 6200);

        setTimeout(() => {
            setShowTyping(false);
            addMessage(message4);
        }, 7400);

        setTimeout(() => {
            addMessage(message5);
        }, 9800);

        setTimeout(() => {
            setShowTyping(true);
            addMessage(message6);
        }, 12000);

        setTimeout(() => {
            addMessage(message7);
        }, 16600);

        setTimeout(() => {
            addMessage(message8);
        }, 20100);

        setTimeout(() => {
            addMessage(message9);
        }, 22300);

        setTimeout(() => {
            addMessage(message10);
        }, 25000);

        setTimeout(() => {
            addMessage(message11);
        }, 27200);

        setTimeout(() => {
            addMessage(message12);
        }, 29600);

        setTimeout(() => {
            addMessage(message13);
        }, 31400);

        setTimeout(() => {
            addMessage(message14);
        }, 33500);

        setTimeout(() => {
            addMessage(message15);
        }, 35000);

        setTimeout(() => {
            setShowTyping(false);
            addMessage(message16);
        }, 37500);
    };

    useEffect(() => {
        setShowTyping(true);
        getCampaign();
        populateBubbles();
    }, []);

    return (
        <ChatbotLayout campaign={campaign} showCelebration={showCelebration}>
            {messages.map((message) => renderMessage(message))}
            {showTyping && <Typing avatar={campaign.avatar}/>}
        </ChatbotLayout>
    );
};

export default ChatbotSalesDemo;