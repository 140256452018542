import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, FormGroup, Input, InputGroupAddon, InputGroupText, InputGroup, Container, Row, Col, Spinner } from 'reactstrap';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import localization from '../../helpers/localizationHelper';
import { checkIfEmailIsValid } from '../../helpers/regexHelpers';
import loginHelper from '../../helpers/loginHelper';
import { login } from '../../redux/auth/actions';
import { getRoles } from '../../redux/user/actions';
import { bigLogoPNG as logo } from '../../assets/img';

const Login = ({ login, loginStarted }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [roles] = useState([]);
    const [showPassword, setShowPassword] = useState(false);

    const isFormValid = () => email.length > 0 && checkIfEmailIsValid(email) && password.length > 0;
    const onEmailChange = event => setEmail(event.target.value);
    const onPasswordChange = event => setPassword(event.target.value);

    const handleLoginSubmit = event => {
        event.preventDefault();
        event.stopPropagation();
        if (isFormValid()) {
            loginHelper(login, { email, password }, roles)
                .catch((err) => (setErrorMessage(err.data.message)));
        } else {
            setErrorMessage('Please provide valid login credentials.');
        }
    };

    const handleEnterPress = event => {
        if (event.key === 'Enter') {
            handleLoginSubmit(event);
        }
    };

    // background color was #911f98
    // button color was #ff0073

    return (
        <Container
            fluid
            className="login-container h-100 w-100 justify-content-center"
            style={{ backgroundColor: '#f0f1f2' }}
        >
            <Row className="h-100 align-items-center">
                <Col>
                    <Row className="text-center pb-5">
                        <Col lg={{ offset: 4, size: 4 }}>
                            <img
                                className="w-100 img-fluid"
                                src={logo}
                                alt="Brandchat"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 4, size: 4 }}>
                            <FormGroup>
                                <div className="text-left input-label">Username</div>
                                <Input
                                    required
                                    autoFocus
                                    type="email"
                                    name="email"
                                    id="email"
                                    onChange={(e) => onEmailChange(e)}
                                    onKeyPress={(e) => handleEnterPress(e)}
                                    value={email}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{ offset: 4, size: 4 }}>
                            <FormGroup>
                                <div className="text-left input-label">Password</div>
                                <InputGroup>
                                    <Input
                                        required
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        id="password"
                                        onChange={(e) => onPasswordChange(e)}
                                        onKeyPress={(e) => handleEnterPress(e)}
                                        value={password}
                                    />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText
                                            style={{ backgroundColor: '#FFFFFF', borderRadius: 0 }}
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <IoMdEye/> : <IoMdEyeOff/>}
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="text-center p-2 error-text" id="loginError">
                        <Col>
                            <h5>{errorMessage || <br/>}</h5>
                        </Col>
                    </Row>
                    <Row className="login">
                        <Col lg={{ offset: 5, size: 2 }}>
                            <Button
                                style={{ backgroundColor: '#000' }}
                                className="btn-lg btn-block btn-primary-custom mt-5 text-center"
                                id="loginBtn"
                                onClick={handleLoginSubmit}
                            >
                                <Row>
                                    <Col sm={{ offset: 4, size: 4 }} className="p-0">
                                        {localization.login.loginButton}
                                    </Col>
                                    <Col className="p-0 d-flex my-auto align-items-start">
                                        {loginStarted && <Spinner className="spinner"/>}
                                    </Col>
                                </Row>
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default connect((state) => ({
    loggedUser: state.auth.loggedUser,
    loginStarted: state.auth.started,
    language: state.language.selectedLanguage,
}), { login, getRoles })(Login);
