import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import { TINY_MCE_API_KEY } from '../../../config';
import {
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ModuleAnimations,
    ModuleStyles,
} from '../SharedConfigurations';
import { checkSharedMessageError } from './errorHelper';

const Text = ({
    text,
    textColor,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule,
}) => {
    const [isFirstTime, setIsFirstTime] = useState(true);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.Text.animation,
                        textColor: moduleStyles.Text.textColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                Text: {
                    ...moduleStyles.Text,
                    animation,
                    textColor,
                },
            }));
        }
    }, [animation, textColor]);

    const initialTextValue = '<p>Type text here...</p>';
    const initTinyMCE = {
        height: 150,
        menubar: 'edit view format tools table tc',
        plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
        ],
        toolbar:
            `undo redo |
            bold italic underline strikethrough |
            fontselect fontsizeselect formatselect |
            alignleft aligncenter alignright alignjustify |
            outdent indent |
            numlist bullist checklist |
            forecolor backcolor casechange permanentpen formatpainter removeformat |
            pagebreak | charmap emoticons |
            fullscreen preview |
            link |
            a11ycheck ltr rtl |
            showcomments addcomment`,
        content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700');
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }
        `,
        font_formats: `
            Abel=abel,sans-serif;
            Arial=arial,helvetica,sans-serif;
            Arial Black=arial black,gadget,avant garde;
            Comic Sans MS=comic sans ms,comic sans ms5,cursive;
            Courier New=courier new,courier;
            Crimson Text=crimson text,serif;
            Georgia=georgia, serif;
            Helvetica=helvetica,sans-serif;
            Impact=impact,charcoal,sans-serif;
            Kaisei Tokumin=kaisei tokumin,serif;
            Lucida Console=lucida console,monaco,monospace;
            Lucida Sans Unicode=lucida sans unicode,lucida grande,sans-serif;
            Open Sans=open sans,sans-serif;
            Open Sans Condensed=open sans condensed,sans-serif;
            Raleway=raleway,serif;
            Roboto=roboto,sans-serif;
            Tahoma=tahoma,geneva,sans-serif;
            Terminal=terminal,monospace;
            Times New Roman=times new roman,times,serif;
            Trebuchet MS=trebuchet ms,sans-serif;
            Verdana=verdana,geneva,sans-serif;
        `,
    };

    const handleTextEditorChange = (content) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    text: content,
                    error: (!isFirstTime && text === '') || checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
        setIsFirstTime(false);
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('Text');
        } else {
            clearDefaultConfigs('Text');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                Text: {
                    ...moduleStyles.Text,
                    animation,
                    textColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="text-module-configuration">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* TEXT INPUT */}
            <Row className="shared-module-background mt-3">
                <Col className="m-0 p-0">
                    <div className="shared-heading">
                        Text Bubble
                    </div>
                    <Editor
                        apiKey={TINY_MCE_API_KEY}
                        init={initTinyMCE}
                        initialValue={initialTextValue}
                        value={text}
                        onEditorChange={handleTextEditorChange}
                        outputFormat="html"
                    />
                </Col>
            </Row>

            {/* COLOR PICKER */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <ColorPicker
                        heading="Text Bubble Color"
                        colorProp="textColor"
                        parentColor={textColor}
                        setSelectedMessage={setSelectedMessage}
                        moduleStyles={moduleStyles}
                        setModuleStyles={setModuleStyles}
                    />
                </Col>
                <Col>
                    <GlobalStylesToggle
                        moduleType="Text"
                        heading="Set as default Text module color"
                        isDefaultConfig={isDefaultConfig}
                        handleDefaultToggle={handleDefaultToggle}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default Text;