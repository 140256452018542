import React from 'react';
import { Col, Row } from 'reactstrap';
import { FaThLarge } from 'react-icons/fa';
import {
    MdLocationOn,
    MdMail,
    MdMessage,
    MdPhoto,
    MdQuestionAnswer,
    MdPlayCircleFilled,
    MdStars,
    MdTextFields,
    MdTextsms,
    MdViewCarousel,
} from 'react-icons/all';
import { RiQrCodeFill } from 'react-icons/ri';
import { MODULE_TYPES } from '../Modules/constants';

const ModuleSelection = ({ selectNewModule }) => {
    const checkIfAdmin = (module) => {
        const admin = localStorage.getItem('role') === 'admin';
        const restrictedModules = [MODULE_TYPES.SKUx];
        if (admin || (!admin && !restrictedModules.includes(module.type))) {
            return module;
        }
    };

    const moduleIconStyles = {
        size: '40px',
        style: { color: '#756682' },
    };

    const modules = [
        {
            header: 'Buttons',
            type: MODULE_TYPES.QuickReply,
            icon: <MdQuestionAnswer {...moduleIconStyles}/>,
        },
        {
            header: 'Simple Buttons',
            type: MODULE_TYPES.ButtonReply,
            icon: <MdQuestionAnswer {...moduleIconStyles}/>,
        },
        {
            header: 'Carousel',
            type: MODULE_TYPES.ImageCarousel,
            icon: <MdViewCarousel {...moduleIconStyles}/>,
        },
        {
            header: 'Collage',
            type: MODULE_TYPES.Collage,
            icon: <FaThLarge size="37px" color="#756682"/>,
        },
        {
            header: 'Email',
            type: MODULE_TYPES.EmailSubmissionForm,
            icon: <MdMail {...moduleIconStyles}/>,
        },
        {
            header: 'Image',
            type: MODULE_TYPES.Image,
            icon: <MdPhoto {...moduleIconStyles}/>,
        },
        {
            header: 'Input Field',
            type: MODULE_TYPES.InputField,
            icon: <MdTextFields {...moduleIconStyles}/>,
        },
        {
            header: 'Location',
            type: MODULE_TYPES.LocationButton,
            icon: <MdLocationOn {...moduleIconStyles}/>,
        },
        {
            header: 'Rating',
            type: MODULE_TYPES.Rating,
            icon: <MdStars {...moduleIconStyles}/>,
        },
        {
            header: 'SMS',
            type: MODULE_TYPES.PhoneNumberField,
            icon: <MdTextsms {...moduleIconStyles}/>,
        },
        {
            header: 'SKUx',
            type: MODULE_TYPES.SKUx,
            icon: <RiQrCodeFill {...moduleIconStyles}/>,
        },
        {
            header: 'Text',
            type: MODULE_TYPES.Text,
            icon: <MdMessage {...moduleIconStyles}/>,
        },
        {
            header: 'Video',
            type: MODULE_TYPES.Video,
            icon: <MdPlayCircleFilled {...moduleIconStyles}/>,
        },
    ];

    return (
        <Row>
            {modules.filter(module => checkIfAdmin(module)).map(module => (
                <Col
                    lg={3}
                    md={4}
                    sm={6}
                    className="text-center pointer my-3 p-0"
                    key={module.header}
                    onClick={() => selectNewModule(module.type)}
                >
                    {module.icon}
                    <h6 className="pt-2 shared-heading text-center">
                        {module.header}
                    </h6>
                </Col>
            ))}
        </Row>
    );
};

export default ModuleSelection;
