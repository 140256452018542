import React, { useEffect, useState } from 'react';
import ChatbotLayout from './ChatbotLayout';
import salesDemoIcon from '../../assets/images/salesdemo-logo.png';
import salesDemoAvatar from '../../assets/images/Brand_Chat_Logo.png';
import MessageModule from './MessageModule';
import Typing from './Typing';
import { MODULE_TYPES } from '../Modules/constants';

const ChatbotSalesDemo = () => {
    const [showTyping, setShowTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [campaign, setCampaign] = useState({});
    const [showCelebration, setShowCelebration] = useState(false);

    const testCampaign = {
        color: '#fcba03',
        logo: salesDemoIcon,
        avatar: salesDemoAvatar,
    };

    const message1 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Hi 👋',
            showAvatar: true,
        },
    };

    const message2 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'This is the BrandChat Feature Demo / New Feature Debugger',
        },
    };

    const message3 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Select the Choices Below to Test / Debug various Modules',
        },
    };

    const message4 = {
        type: MODULE_TYPES.QuickReply,
        props: {
            quick_replies: [{
                title: 'Text Module',
                payload: 'textModuleDemo',
            },
            {
                title: 'Image Module',
                payload: 'imageModuleDemo',
            },
            {
                title: 'Link Module',
                payload: 'linkModuleDemo',
            },
            {
                title: 'Celebration Demo',
                payload: 'celebrationDemo',
            },
            {
                title: 'SMS Module',
                payload: 'phoneModuleDemo',
            },
            {
                title: 'Image Carousel',
                payload: 'imageCarouselDemo',
            },

            ],
            showAvatar: true,
        },
    };

    const getCampaign = () => {
        // TODO Fetch data here
        setCampaign(testCampaign);
    };

    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };
    console.log('messages', messages);

    const renderMessage = (message) => <MessageModule
        key={JSON.stringify(message)}
        setMessages={setMessages}
        message={message}
        setShowCelebration={message.props.showCelebration && setShowCelebration}
        avatar={message.props.showAvatar && campaign.avatar}
    />;

    const populateBubbles = () => {
        setTimeout(() => {
            setShowTyping(true);
            addMessage(message1);
        }, 4000);

        setTimeout(() => {
            addMessage(message2);
        }, 5000);

        setTimeout(() => {
            addMessage(message3);
        }, 6200);

        setTimeout(() => {
            setShowTyping(false);
            addMessage(message4);
        }, 7400);
    };

    useEffect(() => {
        setShowTyping(true);
        getCampaign();
        populateBubbles();
    }, []);

    return (
        <ChatbotLayout
            campaign={campaign}
            showCelebration={showCelebration}
        >
            {messages.map((message) => renderMessage(message))}
            {showTyping && <Typing avatar={campaign.avatar}/>}
        </ChatbotLayout>
    );
};

export default ChatbotSalesDemo;