import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Badge } from 'reactstrap';
import Axios from 'axios';
import { sendEmailSubscription } from '../../services/emailSubmission';

const EmailSubmissionForm = ({
    campaign,
    emailMessage,
    campaignName,
    showPreview,
    backgroundColor,
    emailFieldColor,
    emailTextColor,
    buttonBackgroundColor,
    buttonTextColor,
    callBack = null,
    containerClassName = 'emailModule',
    textClassName = '',
    emailFieldValue = 'Enter Email',
    answered = false,
    formStatus = 'Sending...',
    defaultEmail = '...',
    userId,
    moduleIdentifier,
}) => {
    const [isAnswered, setAnswered] = useState(answered);
    const [status, setStatus] = useState(formStatus);
    const [emailAddressSent, setEmailAddressSent] = useState(defaultEmail);
    const { handleSubmit, register, errors } = useForm();

    const logUserClick = (emailAddress) => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campaignURL = window.location.href;

        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campaignURL;
        userDataObject['Email Destination'] = emailAddressSent || 'No Email Destination found';
        userDataObject['Email Reciever'] = emailAddress || 'No Email Destination found';
        userDataObject.TypeOfClick = 'Email Submission';
        userDataObject.ModuleIdentifier = moduleIdentifier || 'No Identifier';
        userAnalytics.push(userDataObject);
        // console.log('userData\n', JSON.stringify(userInput.userData));

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!:', response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const onSubmit = values => {
        const data = {
            subscriptionType: 'email',
            destinationEmail: values.email,
            source: campaignName,
            textToUser: emailMessage,
        };

        setEmailAddressSent(values.email);
        // Insights Logging
        if (!campaign.demo && !showPreview) {
            logUserClick(values.email);
        }

        sendEmailSubscription(data).then(() => {
            setStatus(`Sent Email to: ${values.email}`);
        });

        if (callBack != null) { callBack(values.email); }

        setAnswered(true);
    };

    return (
        <div
            className={`chat-bubble bold w-100 ${containerClassName}`}
            style={{ backgroundColor }}
        >
            {!isAnswered ?
                <div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <input
                            className="col-8 border"
                            name="email"
                            defaultValue={emailFieldValue}
                            ref={register({
                                required: 'Required',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'invalid email address',
                                },
                            })}
                            style={{
                                backgroundColor: emailFieldColor,
                                borderColor: emailFieldColor,
                                color: emailTextColor,
                            }}
                        />

                        <Button
                            className={`answer-button col-4 ${answered ? 'answered' : 'unanswered'}`}
                            type="submit"
                            style={{
                                minHeight: '35px',
                                backgroundColor: buttonBackgroundColor,
                                borderColor: buttonBackgroundColor,
                                color: buttonTextColor,
                            }}
                        >
                            Submit
                        </Button>
                    </form>

                    {errors.email &&
                        <h6>
                            <Badge color="danger">
                                {errors.email.message}
                            </Badge>
                        </h6>
                    }
                </div> :

                <div className={`chat-bubble bold ${containerClassName}`}>
                    <p className={`m-0 ${textClassName}`}>
                        {status}
                    </p>
                </div>
            }
        </div>
    );
};

export default EmailSubmissionForm;