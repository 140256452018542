import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    Container,
    CardImg,
    Row,
    Col,
    Input,
    Form,
    FormGroup,
    FormFeedback,
} from 'reactstrap';
import { Image } from 'react-bootstrap';
import {
    BsCheck,
    BsX,
    BsPencil,
} from 'react-icons/bs';
import { toastr } from 'react-redux-toastr';
import { getCurrentUserData, editCurrentUser } from '../../redux/user/actions';
import { changeScreen } from '../../redux/shared/actions';
import { checkIfEmailIsValid, checkIfNameIsValid, checkIfNumberIsValid, checkIfUrlIsValid } from '../../helpers/regexHelpers';
import ImageUpload from './ImageUpload';
import initialProfilePicture from '../../assets/images/profilePhoto.jpg';
import logo from '../../assets/img/logo-2x.png';
import localization from '../../helpers/localizationHelper';
import { getCampaigns } from '../../redux/campaign/actions';

const UserInfo = ({
    editCurrentUser,
    changeScreen,
    currentUser,
    getCampaigns,
    campaigns,
}) => {
    const emptyUserModel = {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        role: '',
        profilePicture: '',
        phoneNumber: '',
        companyName: '',
        companyURL: '',
    };
    const [user, setUser] = useState(emptyUserModel);
    const [editMode, setEditMode] = useState(false);
    const [openImageUploadModal, setOpenImageUploadModal] = useState(false);

    useEffect(() => {
        changeScreen('userInfo');

        if (!campaigns.length) {
            // getCampaigns({ archived: false });
            getCampaigns({
                archived: false,
                role: localStorage.getItem('role'),
                company_name: localStorage.getItem('company-name'),
            });
        }
    }, []);

    const loadProfilePicture = () => {
        if (user.profile_picture === undefined || user.profile_picture === '') {
            return initialProfilePicture;
        }
        return user.profile_picture;
    };

    const editModeOff = () => {
        setEditMode(false);
    };

    const onInputChange = (e) => {
        setUser({
            ...user,
            [e.target.id]: e.target.value,
        });
    };

    const toggleImageUploadModal = () => {
        setOpenImageUploadModal(!openImageUploadModal);
    };

    const inputValidation = (field) => {
        if (editMode) {
            switch (field) {
                case 'firstName':
                    return (user.firstName.length > 30 || user.firstName.length < 2) ?
                        false : checkIfNameIsValid(user.firstName);
                case 'lastName':
                    return (user.lastName.length > 30 || user.lastName.length < 2) ?
                        false : checkIfNameIsValid(user.lastName);
                case 'email':
                    return user.email.length === 0 ? false : checkIfEmailIsValid(user.email);
                case 'role':
                    return true;
                case 'phoneNumber':
                    return user.phoneNumber.length === 0 ? false : checkIfNumberIsValid(user.phoneNumber);
                case 'companyName':
                    return !(user.companyName.length > 100 || user.companyName.length < 2);
                case 'companyURL':
                    return user.companyURL.length === 0 ? false : checkIfUrlIsValid(user.companyURL);
                default:
                    return null;
            }
        }
        return null;
    };

    const isFormValid = () => !!(inputValidation('firstName') && inputValidation('lastName') &&
        inputValidation('email') && inputValidation('phoneNumber') &&
        inputValidation('companyName') && inputValidation('companyURL'));

    const handleSaveUserEdits = async () => {
        if (!isFormValid()) {
            toastr.error('Please check the input errors');
            return;
        }
        try {
            await editCurrentUser(user);
            setEditMode(false);
            toastr.success(localization.userInfo.successMessage);
        } catch (err) {
            console.log({ err });
            toastr.error(localization.userInfo.errorMessage);
            throw err;
        }
    };

    useEffect(() => {
        setUser({
            id: currentUser.id,
            firstName: currentUser.first_name,
            lastName: currentUser.last_name,
            email: currentUser.email,
            role: currentUser.role,
            phoneNumber: currentUser.phone_number,
            companyName: currentUser.company_name,
            companyURL: currentUser.company_url,
            profile_picture: currentUser.profile_picture,
        });
    }, [currentUser]);

    // console.log({
    //     campaigns,
    //     role: localStorage.getItem('role'),
    //     company_name: localStorage.getItem('company-name'),
    // });

    return (
        <Form className="user-information">
            <Container>
                <Card
                    style={{ width: '850px', marginBottom: '100px' }}
                >
                    <CardBody>
                        <div className="shared-heading">
                            Profile
                        </div>

                        {/* PROFILE PICTURE */}
                        <Row>
                            <Col>
                                <CardImg
                                    className="rounded-circle mb-3"
                                    src={loadProfilePicture()}
                                />
                            </Col>
                            <Col
                                className="text-center"
                                sm={{ size: 6, offset: 3 }}
                                style={{
                                    position: 'absolute',
                                    top: '200px',
                                    right: '120px',
                                }}
                            >
                                <BsPencil
                                    id="editProfilePicture"
                                    onClick={() => toggleImageUploadModal()}
                                    className="pointer"
                                    size="50px"
                                    color="#000"
                                    style={{
                                        padding: '15px',
                                        backgroundColor: 'white',
                                        borderRadius: '50%',
                                        boxShadow: '0px 4px 4px #D5D5D5',
                                    }}
                                />
                            </Col>
                        </Row>

                        {/* EDIT BUTTON */}
                        {editMode ?
                            // CHECK AND CLOSE
                            <Row>
                                <Col
                                    sm={{ size: 1, offset: 10 }}
                                    className="text-right"
                                >
                                    <BsCheck
                                        onClick={() => handleSaveUserEdits()}
                                        className="pointer"
                                        size="25px"
                                        color="#000"
                                    />
                                </Col>
                                <Col
                                    sm={1}
                                    className="pl-0"
                                >
                                    <BsX
                                        onClick={() => editModeOff()}
                                        className="pointer"
                                        size="25px"
                                        color="#000"
                                    />
                                </Col>
                            </Row> :

                            // EDIT
                            <Row className="text-right">
                                <Col
                                    sm={{ size: 2, offset: 10 }}
                                    className="edit-icon"
                                >
                                    <BsPencil
                                        onClick={() => setEditMode(true)}
                                        className="pointer"
                                        size="25px"
                                        color="#000"
                                    />
                                </Col>
                            </Row>
                        }

                        {/* DATA FIELDS */}
                        <Row className="mt-3 px-5">
                            <Col className="m-0 pl-3 pr-5">
                                {/* FIRST NAME */}
                                <Row>
                                    <Col className="text-left">
                                        <div className="shared-subheading">
                                            First Name
                                        </div>
                                        <FormGroup>
                                            <Input
                                                className="data-input"
                                                margin="dense"
                                                id="firstName"
                                                type="text"
                                                required
                                                disabled={!editMode}
                                                value={user.firstName}
                                                onChange={e => onInputChange(e)}
                                                valid={inputValidation('firstName')}
                                                invalid={inputValidation('firstName') !== null && !inputValidation('firstName')}
                                            />
                                            {editMode &&
                                                <FormFeedback valid/>
                                            }
                                            {editMode &&
                                                <FormFeedback
                                                    valid={false}
                                                    id="firstNameErrorMessage"
                                                >
                                                    {(user.firstName.length < 2 || user.firstName.length > 30) ?
                                                        `${localization.userInfo.requiredField} and must have 3-30 characters. ` :
                                                        localization.userInfo.invalidName}
                                                </FormFeedback>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* EMAIL */}
                                <Row>
                                    <Col className="text-left">
                                        <div className="shared-subheading">
                                            Email
                                        </div>
                                        <FormGroup>
                                            <Input
                                                className="data-input"
                                                margin="dense"
                                                id="email"
                                                type="email"
                                                required
                                                disabled={!editMode}
                                                value={user.email}
                                                onChange={(e) => onInputChange(e)}
                                                valid={inputValidation('email')}
                                                invalid={inputValidation('email') !== null && !inputValidation('email')}
                                            />
                                            {editMode &&
                                                <FormFeedback valid/>
                                            }
                                            {editMode &&
                                                <FormFeedback
                                                    valid={false}
                                                    id="emailErrorMessage"
                                                >
                                                    {user.email.length === 0 ?
                                                        localization.userInfo.requiredField :
                                                        localization.userInfo.invalidEmail}
                                                </FormFeedback>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* COMPANY */}
                                <Row>
                                    <Col className="text-left">
                                        <div className="shared-subheading">
                                            Company
                                        </div>
                                        <FormGroup>
                                            <Input
                                                className="data-input"
                                                type="text"
                                                id="companyName"
                                                value={user.companyName}
                                                disabled={!editMode}
                                                required
                                                valid={inputValidation('companyName')}
                                                invalid={inputValidation('companyName') !== null &&
                                                    !inputValidation('companyName')}
                                                onChange={(e) => onInputChange(e)}
                                            />
                                            {editMode &&
                                                <FormFeedback
                                                    valid={false}
                                                    id="companyNameError"
                                                >
                                                    {(!user.companyName || user.companyName.length < 2 ||
                                                        user.companyName.length > 100) &&
                                                        `${localization.userInfo.requiredField} and must have 2-100 characters.`
                                                    }
                                                </FormFeedback>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="m-0 pl-5 pr-3">
                                {/* LAST NAME */}
                                <Row>
                                    <Col className="text-left">
                                        <div className="shared-subheading">
                                            Last Name
                                        </div>
                                        <FormGroup>
                                            <Input
                                                className="data-input"
                                                margin="dense"
                                                id="lastName"
                                                type="text"
                                                required
                                                disabled={!editMode}
                                                value={user.lastName}
                                                onChange={(e) => onInputChange(e)}
                                                valid={inputValidation('lastName')}
                                                invalid={inputValidation('lastName') !== null && !inputValidation('lastName')}
                                            />
                                            {editMode &&
                                                <FormFeedback valid/>
                                            }
                                            {editMode &&
                                                <FormFeedback
                                                    valid={false}
                                                    id="lastNameErrorMessage"
                                                >
                                                    {(user.lastName.length < 2 || user.lastName.length > 30) ?
                                                        `${localization.userInfo.requiredField} and must have 3-30 characters. ` :
                                                        localization.userInfo.invalidName}
                                                </FormFeedback>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* PHONE NUMBER */}
                                <Row>
                                    <Col className="text-left">
                                        <div className="shared-subheading">
                                            Phone Number
                                        </div>
                                        <FormGroup>
                                            <Input
                                                className="data-input"
                                                type="text"
                                                id="phoneNumber"
                                                value={user.phoneNumber}
                                                disabled={!editMode}
                                                required
                                                valid={inputValidation('phoneNumber')}
                                                invalid={inputValidation('phoneNumber') !== null && !inputValidation('phoneNumber')}
                                                onChange={(e) => onInputChange(e)}
                                            />
                                            {editMode &&
                                                <FormFeedback
                                                    valid={false}
                                                    id="phoneNumberError"
                                                >
                                                    {(!user.phoneNumber || !(checkIfNumberIsValid(user.phoneNumber))) &&
                                                        `${localization.userInfo.requiredField} and only accepts numbers`
                                                    }
                                                </FormFeedback>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* URL */}
                                <Row>
                                    <Col className="text-left">
                                        <div className="shared-subheading">
                                            Company URL
                                        </div>
                                        <FormGroup>
                                            <Input
                                                className="data-input"
                                                type="text"
                                                id="companyURL"
                                                value={user.companyURL}
                                                disabled={!editMode}
                                                required
                                                valid={inputValidation('companyURL')}
                                                invalid={inputValidation('companyURL') !== null &&
                                                    !inputValidation('companyURL')}
                                                onChange={(e) => onInputChange(e)}
                                            />
                                            {editMode &&
                                                <FormFeedback
                                                    valid={false}
                                                    id="companyURLError"
                                                >
                                                    {user.companyURL.length === 0 ?
                                                        localization.userInfo.requiredField :
                                                        'Please enter valid url.'
                                                    }
                                                </FormFeedback>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        {/* CAMPAIGNS */}
                        <Row className="mt-3 px-5">
                            <div className="shared-subheading">
                                Campaigns
                            </div>
                        </Row>

                        <Row className="my-3 px-5 pb-5">
                            {campaigns.filter(campaign => !campaign.template).map(campaign => (
                                <Col
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    className="m-0 p-0"
                                >
                                    <Card
                                        className="m-2"
                                        style={{ border: '0', boxShadow: '0 0 0' }}
                                    >
                                        <CardBody className="p-1 text-center">
                                            <Image
                                                src={campaign.logo ? campaign.logo : logo}
                                                alt={campaign.name}
                                                height="100%"
                                                width="70%"
                                            />
                                            <h6 className="shared-heading">
                                                {campaign.name}
                                            </h6>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>

                        {/* IMAGE UPLOAD POP-UP */}
                        <Row>
                            <Col>
                                {openImageUploadModal &&
                                    <ImageUpload
                                        isOpen={openImageUploadModal}
                                        toggle={toggleImageUploadModal}
                                    />
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </Form>
    );
};

export default connect((state) => ({
    language: state.language.selectedLanguage,
    currentUser: state.user.currentUser,
    campaigns: state.campaigns.all,
}),
{ getCurrentUserData, editCurrentUser, changeScreen, getCampaigns })(UserInfo);

