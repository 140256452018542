import React, { useState } from 'react';
import { Row, Col, Modal as ReactStrapModal, ModalBody } from 'reactstrap';
import { MdClose } from 'react-icons/all';
import { Button, Image } from 'react-bootstrap';
import QuickActionModal from './QuickActionModal';
import { QUICK_ACTION_TYPE } from './constants';
import Video from './Video';
import cointreauLogo from '../../assets/images/cointreau-logo.png';

const Shop = ({ url }) => {
    const [showModal, setShowModal] = useState(true);
    const [showQuickAction, setShowQuickAction] = useState(false);
    const [selectedType, setSelectedType] = useState(null);

    return (
        <div className="shop-container">
            <ReactStrapModal
                className="shop-modal"
                isOpen={showModal}
                size="lg"
                backdrop={false}
                toggle={() => setShowModal(!showModal)}
            >
                <ModalBody className="p-0 shop-modal flex-column">
                    <MdClose
                        className="back-arrow"
                        size={22}
                        onClick={() => setShowModal(!showModal)}
                    />
                    <Row className="m-0 h-100">
                        <Col className="p-0">
                            <Row>
                                <Col className="text-center m-auto">
                                    {cointreauLogo && <Image alt="Campaign icon" src={cointreauLogo} className="logo"/>}
                                </Col>
                            </Row>
                            <Row className="video-row m-0">
                                <Col xs="12 pr-0 pl-0">
                                    <Video url="https://s3.us-east-2.amazonaws.com/brandchat.ai/MakeMargarita.mp4"/>
                                </Col>
                            </Row>
                            <Row className="m-0 pt-3 content-list">
                                <Col className="p-1">
                                    <Row>
                                        <Col xs={{ size: 10, offset: 1 }}>
                                            <h5 className="text-bold font-header">THE HOLIDAY MARGARITA</h5>
                                        </Col>
                                    </Row>
                                    <Row className="ingredients-list pt-2">
                                        <Col xs={{ size: 10, offset: 1 }}>
                                            <h5 className="pb-2 text-bold">Ingredients:</h5>
                                        </Col>
                                        <Col xs={{ size: 10, offset: 1 }} className="pb-2 item">
                                            <h6>1 oz Contreau</h6>
                                        </Col>
                                        <hr/>
                                        <Col xs={{ size: 10, offset: 1 }} className="border-line pb-2 pt-2 item">
                                            <h6>1 oz Casamigos Blanco</h6>
                                        </Col>
                                        <Col xs={{ size: 10, offset: 1 }} className="pt-2 item">
                                            <h6>1/2 Realime Juice</h6>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="actions-row m-0 w-100">
                                <Col xs="auto pr-0 pl-0">
                                    <Button onClick={() => {
                                        setShowQuickAction(true);
                                        setSelectedType(QUICK_ACTION_TYPE.SAVE_RECIPE);
                                    }}
                                    >
                                        <p>Save recipe</p>
                                    </Button>
                                </Col>
                                <Col xs="auto pr-0">
                                    <Button onClick={() => {
                                        setShowQuickAction(true);
                                        setSelectedType(QUICK_ACTION_TYPE.ADD_TO_SHOPPING_LIST);
                                    }}
                                    >
                                        <p>Add to shopping list</p>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
            </ReactStrapModal>
            {showQuickAction && <QuickActionModal setShowQuickActionModal={setShowQuickAction} url={url} type={selectedType}/>}
        </div>
    );
};

export default Shop;