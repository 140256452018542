import { loginSuccess, loginFail, logoutSuccess, registerSuccess, registerFail, loginStarted } from './actionCreators';
import {
    loginUser,
    registerUser,
    registerUserWithoutPasswordWithProviderService,
    registerUserWithProviderService,
    socialLoginService,
} from '../../services/user';
import { history } from '../../routes';

export function login(userData) {
    return function (dispatch) {
        dispatch(loginStarted());
        return loginUser(userData)
            .then(response => {
                const loggedUser = {
                    email: userData.email,
                };
                dispatch(loginSuccess(loggedUser));
                return response;
            })
            .catch(err => {
                dispatch(loginFail());
                throw err;
            });
    };
}

export function socialLogin(data, provider) {
    return function (dispatch) {
        return socialLoginService(data, provider)
            .then(response => {
                const loggedUser = {
                    email: response.data.email,
                };
                dispatch(loginSuccess(loggedUser));
                return response;
            })
            .catch(err => {
                dispatch(loginFail());
                throw err;
            });
    };
}

export function registerWithoutPasswordWithProvider(data, provider) {
    return function (dispatch) {
        return registerUserWithoutPasswordWithProviderService(data, provider)
            .then(response => {
                const loggedUser = {
                    email: response.data.email,
                };
                dispatch(loginSuccess(loggedUser));
                return response;
            })
            .catch(err => {
                dispatch(loginFail());
                throw err;
            });
    };
}

export function registerUserWithProvider(data, provider) {
    return function (dispatch) {
        return registerUserWithProviderService(data, provider)
            .then(response => {
                const loggedUser = {
                    email: response.data.email,
                };
                dispatch(loginSuccess(loggedUser));
                return response;
            })
            .catch(err => {
                dispatch(loginFail());
                throw err;
            });
    };
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('logged-user');
    localStorage.removeItem('x-access-token');
    history.push('/');
    dispatch(logoutSuccess());
    window.location.reload();
};

export function register(userData) {
    return function (dispatch) {
        return registerUser(userData)
            .then(response => {
                dispatch(registerSuccess(response));
                return response;
            })
            .catch(err => {
                dispatch(registerFail());
                throw err;
            });
    };
}
