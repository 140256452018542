import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import configureStore from './redux/configureStore';
import Routes from './routes';
import './assets/scss/style.scss';
import './assets/flags-icons/famfamfam-flags.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import localization from './helpers/localizationHelper';

const store = configureStore();

if (window.Cypress) {
    window.__store__ = store;
}

if (localStorage.getItem('language')) {
    localization.setLanguage(localStorage.getItem('language'));
}

ReactDOM.render(
    <Provider store={store}>
        <>
            <Routes/>
            <ReduxToastr
                transitionIn="fadeIn"
                transitionOut="fadeOut"
            />
        </>
    </Provider>,
    document.getElementById('root'),
);
