/* eslint-env browser */
import React, { useState, useEffect } from 'react';
import { sendFrameToGoogleVision } from '../../services/googleVision';

// const Tesseract = useScript('https://unpkg.com/tesseract.js@v2.0.0-beta.1/dist/tesseract.min.js');

const GoogleVisionDemo = () => {
    // TEST URL / Text Data
    const url1 = 'https://dev.brandchat.me/b0ajo0ehy';// window.open("https://www.google.com", "_blank");
    const url2 = 'https://dev.brandchat.me/mZkyrqNWi';
    const phrase1 = 'Chewy';
    const phrase2 = 'Goose';

    const addMessage = (m, bold) => {
        let msg = `<p>${m}</p>`;
        if (bold) {
            msg = `<p class="bold">${m}</p>`;
        }
        const messages = document.getElementById('messages');
        if (messages) {
            messages.innerHTML += msg;
            messages.scrollTop = messages.scrollHeight;
        }
    };

    const doOCR = async () => {
        const video = document.getElementById('poem-video');
        if (video) {
            const c = document.createElement('canvas');
            c.width = 640;
            c.height = 360;
            c.getContext('2d').drawImage(video, 0, 0, 640, 360);
            // const start = new Date();
            // const { data: { text } } = await scheduler.addJob('recognize', c);
            await sendFrameToGoogleVision(c.toDataURL('image/png'), (response) => {
                // the average response is
                if (response.data && response.data.messages && response.data.messages.length > 1) {
                    // console.log(`Found: ${response.data.messages[1].text}`);

                    // const end = new Date();
                    // addMessage(`[${start.getMinutes()}:${start.getSeconds()}
                    // - ${end.getMinutes()}:${end.getSeconds()}], ${(end - start) / 1000} s`);

                    const { text } = response.data.messages[1];
                    // console.log(`text: ${text}`);
                    // const filteredText = Array.from(text.matchAll(/\d/g)).join("")
                    addMessage(text);

                    if (text.includes(phrase1)) {
                        window.location = url1;
                    } else if (text.includes(phrase2)) {
                        window.location = url2;
                    }
                }
            });
        }
    };

    (async () => {
        // video.style.visibility = 'hidden';  //put back
        addMessage('Loading...');
        addMessage('Now you can play the video. :)');
        // video.style.visibility = 'visible'; //put back
        setInterval(doOCR, 2000);
    })();

    const [video] = useState(React.createRef());

    const videoError = (error) => {
        console.log('error', error);
    };

    const handleVideo = (stream) => {
        video.current.srcObject = stream;
    };

    const handleCanPlay = () => {
        video.current.play();
    };

    useEffect(() => {
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;

        // Newer media setup
        if (navigator.mediaDevices) {
            navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(stream => {
                video.current.srcObject = stream;
            }).catch(console.log);
        } else if (navigator.getUserMedia) {
            navigator.getUserMedia({ video: { facingMode: 'environment' } }, handleVideo, videoError);
        } else {
            console.log('Error getting camera');
        }
    });

    return (
        <div id="root">
            <video id="poem-video" crossOrigin="anonymous" ref={video} onCanPlay={handleCanPlay} autoPlay playsInline muted/>
            <div id="sep"/>
            <div id="messages"/>
        </div>
    );
};

export default GoogleVisionDemo;