import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    Row,
    Input,
    FormGroup,
    Label,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import DatePicker from 'react-date-picker';
import _get from 'lodash.get';
import {
    FaArrowAltCircleUp,
    FaPlayCircle,
    FaPlusCircle,
    FaStopCircle,
    FaTrashAlt,
    FaThLarge,
} from 'react-icons/fa';
import {
    MdLocationOn,
    MdMail,
    MdMessage,
    MdPhoto,
    MdPlayCircleFilled,
    MdStars,
    MdTextFields,
    MdTextsms,
    MdViewCarousel,
    MdSmartphone,
    MdQuestionAnswer,
} from 'react-icons/md';
import { BsCaretDownFill } from 'react-icons/bs';
import { toastr } from 'react-redux-toastr';
import ChatbotLayout from '../Chatbot/ChatbotLayout';
import ChatbotCore from '../Chatbot/ChatbotCore';
import MessageModule from '../Chatbot/MessageModule';
import { MODULE_TYPES } from '../Modules/constants';
import { history } from '../../routes';
import imageModulePlaceholder from '../../assets/images/image-module-placeholder.png';
import ModuleConfiguration from './ModuleConfiguration';
import ModuleSelection from './ModuleSelection';
import SharedMessageConfiguration from './SharedMessageConfiguration';
import { getCampaignModulesConfiguration, updateCampaignConfiguration, uploadCampaignFile } from '../../services/campaign';
import { changeScreen } from '../../redux/shared/actions';
import { editModuleStyles } from '../../redux/campaign/actions';
import { lightenColor } from '../../helpers/colorHelper';
import Modal from '../../components/Modal';
import ModalQrCode from '../../components/ModalQRCode';
import { getBase64GUID } from '../../helpers/base64';
import { generateKeyword } from '../../helpers/nodeBranchingHelper';
import { findConfigurationByKeyword } from '../../helpers/configurationHelper';
import { defaultModuleStyles } from '../../helpers/customizationsHelper';
import { defaultCarouselCard } from '../../helpers/moduleConfigurationsHelper';
import { checkIfErrorExist } from './ModuleConfigurations/errorHelper';
import { NODE_BRANCH_CONFIGURATION_STATUS } from '../../config';
import TabBar from '../CreateCampaign/TabBar';
import 'react-datepicker/dist/react-datepicker.css';

const ChatbotConfiguration = ({ location, changeScreen, editModuleStyles }) => {
    const [campaign, setCampaign] = useState({});
    const [showModalQR, setShowModalQR] = useState(false);
    const [goBackModal, setGoBackModal] = useState(false);
    const [isSaved, setIsSaved] = useState(true);
    const [savesCounter, setSavesCounter] = useState(0);
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorSaveCampaign, setErrorSaveCampaign] = useState(false);
    const [hoverMessage, setHoverMessage] = useState({ index: 0, isHover: false });
    const [hoverNewModule, setHoverNewModule] = useState(false);
    const [moduleStyles, setModuleStyles] = useState({});

    const [selectedConfigTriggerIndex, setSelectedConfigTriggerIndex] = useState(0);
    const [isFirstConfiguration, setIsFirstConfiguration] = useState(true);
    const [configurationTriggerOrder, setConfigurationTriggerOrder] = useState([0]);
    const [configurationTriggers, setConfigurationTriggers] = useState([{
        keywords: [],
        configuration: [],
    }]);
    const [selectedMessage, setSelectedMessage] = useState({});
    useEffect(() => {
        // Sets configuration triggers that are displayed in phone preview screen.
        if (selectedMessage && selectedMessage.message) {
            setConfigurationTriggers(configurationTriggers => {
                const newConfigurationTriggers = [...configurationTriggers];
                const newConfiguration = [...newConfigurationTriggers[selectedConfigTriggerIndex].configuration];
                newConfiguration[selectedMessage.index] = selectedMessage.message;
                newConfigurationTriggers[selectedConfigTriggerIndex].configuration = newConfiguration;
                return newConfigurationTriggers;
            });
        }
    }, [selectedMessage]);

    // Preview state variables
    const [playPreview, setPlayPreview] = useState(false);
    const [showCelebration, setShowCelebration] = useState(false);
    const [celebration, setCelebration] = useState('');
    const [wallpaperImage, setWallpaperImage] = useState('');
    const [wallpaperSize, setWallpaperSize] = useState('');
    const [wallpaperRepeat, setWallpaperRepeat] = useState('');
    const [isCardConfiguration, setIsCardConfiguration] = useState(false);
    const [mobilePreview, setMobilePreview] = useState(location.state.horizontal_layout ?
        { type: 'Custom', height: '450px', width: '650px' } :
        { type: 'Mobile', height: '650px', width: '360px' });
    const [tempMobileHeight, setTempMobileHeight] = useState(location.state.horizontal_layout ? 450 : 650);
    const [tempMobileWidth, setTempMobileWidth] = useState(location.state.horizontal_layout ? 650 : 350);
    const [mobilePreviewDropdownOpen, setMobilePreviewDropdownOpen] = useState(false);
    const toggleMobilePreviewDropdown = () => setMobilePreviewDropdownOpen(!mobilePreviewDropdownOpen);

    // Preview chatbot
    const [previewAgeGate, setPreviewAgeGate] = useState(campaign.age_gate_required || null);
    const [ageGateValue, onChangeAgeGate] = useState(new Date());
    const [previewLandingPage, setPreviewLandingPage] = useState(campaign.landing_page || null);
    const [isNarrowConfigScreen, setNarrowConfigScreen] = useState(false);
    const [containerBorderRadii, setContainerBorderRadii] = useState({ wallpaper: '5px 0 0 5px', chatbot: '5px' });

    // Shared module dropdown
    const [moduleSelectionDropdownOpen, setModuleSelectionDropdownOpen] = useState(false);
    const [editChat, setEditChat] = useState(true);

    const toggleModuleSelectionDropdown = () => setModuleSelectionDropdownOpen(!moduleSelectionDropdownOpen);

    const handleEditChat = () => {
        setEditChat(true);
        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];
            const configurationTriggerIndex = 0;
            setSelectedConfigTriggerIndex(configurationTriggerIndex);
            setConfigurationTriggerOrder(order => [...order, configurationTriggerIndex]);
            return newConfigurationTriggers;
        });
        setSelectedMessage({ message: configurationTriggers[0].configuration[0], index: 0 });
    };

    const handleEditCard = () => {
        setEditChat(false);
        const keyword = campaign.card_id;
        const cardIndex = configurationTriggers.findIndex(({ keywords }) => keywords[0].includes(keyword));

        // this causes the card pop-up chat moduels to get displayed
        // if cardIndex is not found (cardIndex === -1) because the camapign loaded was created before
        // the card pop-up feature was created, it uses dummy data.
        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];

            // If the keyword doesn't exist in the configurationTriggers, add a new configuration trigger for it with
            // an empty configuration, otherwise set the found configuration to the selected one.
            if (cardIndex === -1) {
                setSelectedConfigTriggerIndex(newConfigurationTriggers.length);
                newConfigurationTriggers.push({
                    keywords: [campaign.card_id],
                    configuration: [{
                        type: 'Text',
                        props: {
                            text: 'Welcome!',
                            textColor: '#FFFFFF',
                            showAvatar: true,
                            conversationStart: true,
                            card: true,
                        },
                    }],
                });
                setConfigurationTriggerOrder(order => [...order, newConfigurationTriggers.length - 1]);
            } else {
                setSelectedConfigTriggerIndex(cardIndex);
                setConfigurationTriggerOrder(order => [...order, cardIndex]);
            }

            return newConfigurationTriggers;
        });

        // sets the selected messages to be the first card pop-up module.
        // if cardIndex is not found (cardIndex === -1) because the camapign loaded was created before
        // the card pop-up feature was created, it uses dummy data.
        if (cardIndex === -1) {
            setSelectedMessage({
                message: {
                    type: 'Text',
                    props: {
                        text: 'Welcome!',
                        textColor: '#FFFFFF',
                        showAvatar: true,
                        conversationStart: true,
                        card: true,
                    },
                },
                index: 0,
            });
        } else {
            setSelectedMessage({
                message: configurationTriggers[cardIndex].configuration[0],
                index: 0,
            });
        }
    };

    useEffect(() => {
        if (savesCounter > 3) {
            setIsSaved(false);
        }

        if (selectedMessage && Object.keys(selectedMessage).length) {
            const { props } = configurationTriggers[selectedConfigTriggerIndex].configuration[selectedMessage.index];
            if (props.horizontalImageUrl) {
                setWallpaperImage(props.horizontalImageUrl);
                setWallpaperSize(props.horizontalBgSize);
                setWallpaperRepeat(props.horizontalBgRepeat);
            }
        }

        setIsCardConfiguration(configurationTriggers[selectedConfigTriggerIndex].keywords[0] &&
            configurationTriggers[selectedConfigTriggerIndex].keywords[0].includes(campaign.card_id));
        // isCardConfiguration = configurationTriggers[selectedConfigTriggerIndex].keywords[0] === campaign.card_id;
        // isCardConfiguration = configurationTriggers[selectedConfigTriggerIndex].keywords[0] &&
        //     configurationTriggers[selectedConfigTriggerIndex].keywords[0].includes(campaign.card_id);
        setIsFirstConfiguration(selectedConfigTriggerIndex === 0 || isCardConfiguration);
        setSavesCounter(counter => counter + 1);
    }, [configurationTriggers]);

    useEffect(() => {
        // This redirects to the first module once 'Configure' is loaded.
        // If no previously configured module exists, the page will stay on
        // the module selection screen rendered from this index.js module.
        changeScreen('configureCampaign');
        if (location.state) {
            let campaign = location.state;
            if (Object.keys(campaign).length && campaign.styles) {
                campaign = {
                    ...campaign,
                    styles: JSON.parse(campaign.styles),
                };
            }
            if (Object.keys(campaign).length && campaign.module_styles) {
                campaign = {
                    ...campaign,
                    module_styles: JSON.parse(campaign.module_styles),
                };
                const tempModuleStyles = { ...campaign.module_styles };
                Object.keys(defaultModuleStyles).forEach(moduleStyle => {
                    if (!Object.keys(campaign.module_styles).includes(moduleStyle)) {
                        tempModuleStyles[moduleStyle] = defaultModuleStyles[moduleStyle];
                    }
                });
                setModuleStyles(tempModuleStyles);
            }
            setCampaign(campaign);
            setWallpaperImage(campaign.horizontal_image);
            setWallpaperSize(campaign.styles.horizontalBgSize);
            setWallpaperRepeat(campaign.styles.horizontalBgRepeat);
            const getCampaignConfigurationTrigger = async () => {
                const configurationTriggers = (await getCampaignModulesConfiguration(campaign.id)).data;

                if (configurationTriggers && configurationTriggers[0] && configurationTriggers[0].configuration) {
                    setConfigurationTriggers(configurationTriggers);
                    setSelectedMessage({
                        message: configurationTriggers[0].configuration[0],
                        index: 0,
                    });
                }
            };

            getCampaignConfigurationTrigger()
                .then(() => { setIsSaved(true); setSavesCounter(0); });
        } else {
            console.error('Could not fetch campaign');
        }
    }, []);

    const {
        id,
        name,
        avatar,
        styles,
        card,
        bg_image: outerBgImage,
        interior_bg_image: interiorBgImage,
        horizontal_layout: horizontalLayout,
    } = campaign;

    useEffect(() => {
        if (!playPreview) {
            setShowCelebration(false);
        } else {
            setPreviewAgeGate(campaign.age_gate_required);
            setPreviewLandingPage(campaign.landing_page);
        }
    }, [playPreview]);

    useEffect(() => {
        if (horizontalLayout && parseInt(mobilePreview.width, 10) < 500) {
            setNarrowConfigScreen(true);
        } else {
            setNarrowConfigScreen(false);
        }

        if (!horizontalLayout) {
            setContainerBorderRadii({ wallpaper: '5px 0 0 5px', chatbot: '5px' });
        } else if (horizontalLayout && isNarrowConfigScreen) {
            setContainerBorderRadii({ wallpaper: '5px 5px 0 0', chatbot: '0 0 5px 5px' });
        } else {
            setContainerBorderRadii({ wallpaper: '5px 0 0 5px', chatbot: '0 5px 5px 0' });
        }
    }, [mobilePreview]);

    // AGE GATE
    const calculateAge = (birthday) => {
        const ageDifMs = Date.now() - birthday;
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const ageGateCheck = (birthdaySubmitted) => {
        if (birthdaySubmitted == null) {
            // TODO: Use sub modal instead of alert
            // eslint-disable-next-line no-alert, no-undef
            alert('Please Enter Your Date of Birth Before Proceeding');
            return;
        }

        // done to prevent people born the day after from being counted too
        const birthdayFormatted = birthdaySubmitted.setHours(0, 0, 0, 0);
        const userAge = calculateAge(birthdayFormatted);
        if (birthdaySubmitted && userAge >= 21) {
            setPreviewAgeGate(null);
            setShowModalQR(false);
        } else {
            // eslint-disable-next-line no-alert, no-undef
            alert('Must Be 21 or Older To View');
        }
    };

    useEffect(() => {
        if (showCelebration && setShowCelebration) {
            setTimeout(setShowCelebration(false), 2000);
        }
    }, [showCelebration]);

    const configureNodeBranchingPath = (answer, reference) => {
        const keyword = generateKeyword(campaign.name, campaign.id, reference, answer);

        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];
            const configurationTriggerIndex = newConfigurationTriggers.findIndex(({ keywords }) => keywords.includes(keyword));

            // If the keyword doesn't exist in the configurationTriggers, add a new configuration trigger for it with
            // an empty configuration, otherwise set the found configuration to the selected one.
            if (configurationTriggerIndex === -1) {
                setSelectedConfigTriggerIndex(newConfigurationTriggers.length);
                newConfigurationTriggers.push({
                    keywords: [keyword],
                    configuration: [],
                });
                setConfigurationTriggerOrder(order => [...order, newConfigurationTriggers.length - 1]);
            } else {
                setSelectedConfigTriggerIndex(configurationTriggerIndex);
                setConfigurationTriggerOrder(order => [...order, configurationTriggerIndex]);
            }

            return newConfigurationTriggers;
        });
        setSelectedMessage(null);
    };

    const linkNodeBranches = (answer, answerToLinkTo, reference) => {
        const keywordToLinkTo = generateKeyword(campaign.name, campaign.id, reference, answerToLinkTo);
        const keyword = generateKeyword(campaign.name, campaign.id, reference, answer);

        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];
            const configurationTriggerIndex = newConfigurationTriggers.findIndex(({ keywords }) => keywords.includes(keywordToLinkTo));
            newConfigurationTriggers[configurationTriggerIndex].keywords.push(keyword);
            return newConfigurationTriggers;
        });
    };

    const updateKeyword = (reference, previousTitle, newTitle) => {
        const previousKeyword = generateKeyword(campaign.name, campaign.id, reference, previousTitle);
        const newKeyword = generateKeyword(campaign.name, campaign.id, reference, newTitle);

        setConfigurationTriggers(configurationTriggers => {
            let newConfigurationTriggers = [...configurationTriggers];

            newConfigurationTriggers = newConfigurationTriggers.map(configurationTrigger => {
                const newConfigurationTrigger = { ...configurationTrigger };

                newConfigurationTrigger.keywords = newConfigurationTrigger.keywords.map(keyword => {
                    if (keyword === previousKeyword) {
                        return newKeyword;
                    }
                    return keyword;
                });

                return newConfigurationTrigger;
            });

            return newConfigurationTriggers;
        });
    };

    const selectNewModule = (type, index) => {
        let moduleSpecificProps = {};

        switch (type) {
            case MODULE_TYPES.Collage: {
                moduleSpecificProps = {
                    node_branches: [{
                        imageUrl: imageModulePlaceholder,
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.EmailSubmissionForm: {
                moduleSpecificProps = { campaignName: campaign.name };
                break;
            }
            case MODULE_TYPES.Image: {
                moduleSpecificProps = {
                    // old prop: url, new prop: imageUrl
                    imageUrl: imageModulePlaceholder,
                    node_branches: [{
                        title: 'ImageModule',
                        content_type: 'text',
                    },
                    ],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.ImageCarousel: {
                moduleSpecificProps = {
                    campaignName: campaign.name,
                    carouselCards: defaultCarouselCard,
                    node_branches: [{
                        title: 'ImageCarousel',
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.InputField: {
                moduleSpecificProps = {
                    campaignName: campaign.name,
                    userInput: {
                        // values array has oen string per input field.
                        // The value is the default value until the user writes over it.
                        values: ['Input Field Name'],

                        // userData array will grow with one object for each user
                        // and each object contains a property corresponding to each input field
                        // the Modules/InputFields.js pushes to this array
                        userData: [],
                    },
                    node_branches: [{
                        title: '📝',
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.LinkModal: {
                moduleSpecificProps = {};
                break;
            }
            case MODULE_TYPES.LocationButton: {
                moduleSpecificProps = {
                    campaignName: campaign.name,
                    node_branches: [{
                        title: 'Location Button',
                        content_type: 'text',
                    },
                    ],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.PhoneNumberField: {
                moduleSpecificProps = {
                    campaignName: campaign.name,
                    node_branches: [{
                        title: 'Phone Number Field',
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.ButtonReply: {
                moduleSpecificProps = {
                    node_branches: [{
                        title: 'Add a title...',
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.QuickReply: {
                moduleSpecificProps = {
                    node_branches: [{
                        title: 'Add a title...',
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.Rating: {
                moduleSpecificProps = {
                    ratingIcon: '★',
                    node_branches: [{
                        title: 'rating_module',
                        content_type: 'text',
                    }],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.SKUx: {
                moduleSpecificProps = {
                    campaignName: campaign.name,
                    node_branches: [{
                        title: 'SKUx Button',
                        content_type: 'text',
                    },
                    ],
                    reference: getBase64GUID(),
                };
                break;
            }
            case MODULE_TYPES.Text: {
                moduleSpecificProps = { text: 'Add some text here...' };
                break;
            }
            case MODULE_TYPES.Video: {
                moduleSpecificProps = {};
                break;
            }
            default: {
                moduleSpecificProps = {};
            }
        }

        setSelectedMessage({
            message: {
                type,
                props: {
                    showTyping: _get(selectedMessage, 'message.props.showTyping', false),
                    showAvatar: _get(selectedMessage, 'message.props.showAvatar', false),
                    showCelebration: _get(selectedMessage, 'message.props.showCelebration', false),
                    ...moduleSpecificProps,
                },
            },
            index: typeof index !== 'undefined' ? index : configurationTriggers[selectedConfigTriggerIndex].configuration.length,
        });
    };

    const addNewEmptyModule = () => {
        setSelectedMessage(null);
    };

    const moduleNames = {
        Text: 'Text',
        Image: 'Image',
        Video: 'Video',
        ButtonReply: 'Simple Buttons',
        QuickReply: 'Buttons',
        ImageCarousel: 'Image Carousel',
        PhoneNumberField: 'SMS',
        Rating: 'Rating',
        EmailSubmissionForm: 'Email',
        LocationButton: 'Location',
        InputField: 'Input Field',
        Collage: 'Collage',
    };

    const deleteConfigurationForNodeBranch = (answer, reference) => {
        const keyword = generateKeyword(campaign.name, campaign.id, reference, answer);
        configurationTriggers.forEach((configurationTrigger, configurationTriggerIndex) => {
            if (configurationTrigger.keywords.length && configurationTrigger.keywords.includes(keyword)) {
                if (configurationTrigger.keywords.length === 1) {
                    const newConfigurationTriggers = [...configurationTriggers];
                    newConfigurationTriggers.splice(configurationTriggerIndex, 1);
                    setConfigurationTriggers(newConfigurationTriggers);
                } else {
                    const keywordIndex = configurationTrigger.keywords.indexOf(keyword);
                    configurationTrigger.keywords.splice(keywordIndex, 1);
                }
            }
        });
    };

    const createExternalUrlLink = (index, nodeBranch, externalURL) => {
        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];
            // Assumption that the keyword needed will always be at index 0 of keywords array. Might change!
            // const nodeBranchKeyword = newConfigurationTriggers[0].keywords[0];
            // const configurationTriggerIndex = newConfigurationTriggers.findIndex(({ keywords }) => keywords.includes(nodeBranchKeyword));
            const newConfiguration = newConfigurationTriggers[selectedConfigTriggerIndex];
            const { uid } = JSON.parse(nodeBranch.payload && nodeBranch.payload);
            let configIndex = 0;
            newConfiguration.configuration.forEach((obj, idx) => {
                if (obj.props.reference === uid) {
                    configIndex = idx;
                }
            });
            newConfiguration.configuration[configIndex].props.node_branches[index].externalURL = externalURL;
            return newConfigurationTriggers;
        });
    };

    const getConfigurationStatus = (nodeBranch) => {
        let child = NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION;
        if (!nodeBranch.payload) {
            return child;
        }
        const { campaignName, campaignID, uid, answer } = JSON.parse(nodeBranch.payload && nodeBranch.payload);
        const keyword = generateKeyword(campaignName, campaignID, uid, answer);
        const configurationArray = findConfigurationByKeyword(configurationTriggers, keyword);
        if (configurationArray.length) {
            child = NODE_BRANCH_CONFIGURATION_STATUS.HAS_CONFIGURATION;
            configurationArray.forEach(module => {
                if (module.type === MODULE_TYPES.ButtonReply || module.type === MODULE_TYPES.QuickReply) {
                    child = NODE_BRANCH_CONFIGURATION_STATUS.HAS_NODE_BRANCH_IN_CONFIGURATION;
                }
            });
        }
        return child;
    };

    const removeSelectedMessage = () => {
        if (selectedMessage.message.type === MODULE_TYPES.QuickReply) {
            const arrayNodeBranches = selectedMessage.message.props.node_branches;
            for (let i = 0; i < arrayNodeBranches.length; i++) {
                const status = getConfigurationStatus(arrayNodeBranches[i]);
                if (status !== NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION) {
                    toastr.error('Please delete each node branch first.');
                    return;
                }
            }
        }

        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];
            const newConfiguration = [...newConfigurationTriggers[selectedConfigTriggerIndex].configuration];

            // removes default global styles
            const module = newConfiguration[selectedMessage.index];
            if (module.props.isDefaultConfig) {
                setModuleStyles(moduleStyles => ({
                    ...moduleStyles,
                    [module.type]: { ...defaultModuleStyles[module.type] },
                }));
            }

            // Set the new selected message to be: 1. The message before 2. The message after 3. New module config
            if (newConfiguration[selectedMessage.index - 1]) {
                setSelectedMessage({
                    index: selectedMessage.index - 1,
                    message: newConfiguration[selectedMessage.index - 1],
                });
            } else if (newConfiguration[selectedMessage.index + 1]) {
                setSelectedMessage({
                    index: selectedMessage.index,
                    message: newConfiguration[selectedMessage.index + 1],
                });
            } else {
                setSelectedMessage(null);
            }

            // Remove the deleted message
            newConfiguration.splice(selectedMessage.index, 1);

            newConfigurationTriggers[selectedConfigTriggerIndex].configuration = newConfiguration;
            return newConfigurationTriggers;
        });
    };

    const restoreDefaultModuleStyles = (type) => {
        setModuleStyles(moduleStyles => ({
            ...moduleStyles,
            [type]: { ...defaultModuleStyles[type] },
        }));
    };

    const clearDefaultConfigs = (type) => {
        setConfigurationTriggers(configurationTriggers => {
            const newConfigurationTriggers = [...configurationTriggers];
            configurationTriggers.forEach(({ configuration }, i) => {
                configuration.forEach((module, j) => {
                    if (module.type === type) {
                        newConfigurationTriggers[i].configuration[j].props.isDefaultConfig = false;
                    }
                });
            });
            return configurationTriggers;
        });
    };

    const renderModuleConfiguration = () => {
        const handleMenuChoice = (type, index) => {
            selectNewModule(type, index);
            toggleModuleSelectionDropdown();
        };

        return (
            <>
                {selectedMessage && selectedMessage.message && selectedMessage.message.props &&
                    <>
                        {/* HEADING */}
                        <Row className="mb-3">
                            <Col
                                sm={9}
                                className="text-left"
                            >
                                <div className="shared-heading">
                                    Message Configuration
                                </div>
                            </Col>
                            <Col
                                sm={3}
                                className="text-left"
                                onClick={() => removeSelectedMessage()}
                            >
                                <FaTrashAlt size="22" className="pointer"/>
                            </Col>
                        </Row>

                        {/* SHOW TYPING/AVATAR/CELEBRATION */}
                        <SharedMessageConfiguration
                            selectedMessage={selectedMessage}
                            setSelectedMessage={setSelectedMessage}
                        />

                        {/* MODULE SELECTION MENU */}
                        <Row className="mt-2">
                            {selectedMessage.message &&
                                <Col className="mr-0 pr-0">
                                    <button
                                        type="button"
                                        className="shared-module-button"
                                        onClick={() => toggleModuleSelectionDropdown()}
                                    >
                                        <h6>
                                            Module: &nbsp;
                                            {moduleNames[selectedMessage.message.type]}
                                        </h6>
                                        <BsCaretDownFill
                                            size="11px"
                                            style={{ marginTop: '-4px' }}
                                        />
                                    </button>

                                    {moduleSelectionDropdownOpen &&
                                        <div className="shared-menu modules">
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.Text, selectedMessage.index)}
                                            >
                                                <MdMessage
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Text
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.Image, selectedMessage.index)}
                                            >
                                                <MdPhoto
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Image
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.Video, selectedMessage.index)}
                                            >
                                                <MdPlayCircleFilled
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Video
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.QuickReply, selectedMessage.index)}
                                            >
                                                <MdQuestionAnswer
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Buttons
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.ButtonReply, selectedMessage.index)}
                                            >
                                                <MdQuestionAnswer
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Simple Buttons
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.ImageCarousel, selectedMessage.index)}
                                            >
                                                <MdViewCarousel
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Carousel
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={
                                                    () => handleMenuChoice(MODULE_TYPES.PhoneNumberField, selectedMessage.index)
                                                }
                                            >
                                                <MdTextsms
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                SMS
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={
                                                    () => handleMenuChoice(MODULE_TYPES.EmailSubmissionForm, selectedMessage.index)
                                                }
                                            >
                                                <MdMail
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Email
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.LocationButton, selectedMessage.index)}
                                            >
                                                <MdLocationOn
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Location
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.InputField, selectedMessage.index)}
                                            >
                                                <MdTextFields
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Input Field
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.Collage, selectedMessage.index)}
                                            >
                                                <FaThLarge
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Collage
                                            </button>
                                            <button
                                                className="shared-item modules"
                                                type="submit"
                                                onClick={() => handleMenuChoice(MODULE_TYPES.Rating, selectedMessage.index)}
                                            >
                                                <MdStars
                                                    size="25px"
                                                    color="#756682"
                                                />
                                                Rating
                                            </button>
                                        </div>
                                    }
                                </Col>
                            }
                        </Row>

                        <ModuleConfiguration
                            campaign={campaign}
                            configureNodeBranchingPath={configureNodeBranchingPath}
                            createExternalUrlLink={createExternalUrlLink}
                            deleteConfigurationForNodeBranch={deleteConfigurationForNodeBranch}
                            getConfigurationStatus={getConfigurationStatus}
                            linkNodeBranches={linkNodeBranches}
                            selectedMessage={selectedMessage}
                            setSelectedMessage={setSelectedMessage}
                            updateKeyword={updateKeyword}
                            waterfallLayout={campaign.waterfall_layout}
                            moduleStyles={moduleStyles}
                            setModuleStyles={setModuleStyles}
                            clearDefaultConfigs={clearDefaultConfigs}
                            restoreDefaultModuleStyles={restoreDefaultModuleStyles}
                        />
                    </>
                }
            </>
        );
    };

    const saveModulesConfiguration = async () => {
        if (checkIfErrorExist(configurationTriggers, setConfigurationTriggers)) {
            toastr.error('Please check errors in your configuration');
            return;
        }

        try {
            setShowModalQR(true);
            setShowSpinner(true);
            for (let index = 0; index < configurationTriggers.length; index++) {
                const configurationTrigger = configurationTriggers[index];
                for (let moduleIndex = 0; moduleIndex < configurationTrigger.configuration.length; moduleIndex++) {
                    const moduleConfiguration = configurationTrigger.configuration[moduleIndex];

                    // uploads the module image file data for a given module to the S3 bucket and updates it's url prop to that url
                    // for example, the Image module's image url that is temporary assigned to a file prop
                    if (moduleConfiguration.props.imageFile) {
                        const formData = new FormData();
                        formData.append('file', moduleConfiguration.props.imageFile);
                        // eslint-disable-next-line no-await-in-loop
                        moduleConfiguration.props.imageUrl = (await uploadCampaignFile(formData)).data.location;
                        moduleConfiguration.props.imageFile = null;
                    }

                    // uploads the left side wallpaper image file data for a given module to the S3 bucket and
                    // updates it's url prop to that url
                    if (moduleConfiguration.props.horizontalImageFile) {
                        const formData = new FormData();
                        formData.append('file', moduleConfiguration.props.horizontalImageFile);
                        // eslint-disable-next-line no-await-in-loop
                        moduleConfiguration.props.horizontalImageUrl = (await uploadCampaignFile(formData)).data.location;
                        moduleConfiguration.props.horizontalImageFile = null;
                    }

                    // uploads the module video file data for a given module to the S3 bucket
                    // and updates it's url prop to that url
                    if (moduleConfiguration.props.file) {
                        const formData = new FormData();
                        formData.append('file', moduleConfiguration.props.file);
                        // eslint-disable-next-line no-await-in-loop
                        moduleConfiguration.props.url = (await uploadCampaignFile(formData)).data.location;
                        moduleConfiguration.props.file = null;
                    }

                    // special upload case for a given quick reply module to the S3 bucket
                    // and updates it's horizontalImageUrl prop to that url
                    if (moduleConfiguration.props.node_branches) {
                        const branches = moduleConfiguration.props.node_branches;
                        for (let branchIndex = 0; branchIndex < branches.length; branchIndex++) {
                            const nodeBranch = branches[branchIndex];
                            if (nodeBranch.horizontalImageFile) {
                                const formData = new FormData();
                                formData.append('file', nodeBranch.horizontalImageFile);
                                // eslint-disable-next-line no-await-in-loop
                                nodeBranch.horizontalImageUrl = (await uploadCampaignFile(formData)).data.location;
                                nodeBranch.horizontalImageFile = null;
                            }
                        }
                    }

                    // special upload case for a given collage module to the S3 bucket
                    // and updates it's horizontalImageUrl prop to that url
                    if (moduleConfiguration.props.node_branches) {
                        const branches = moduleConfiguration.props.node_branches;
                        for (let branchIndex = 0; branchIndex < branches.length; branchIndex++) {
                            const nodeBranch = branches[branchIndex];
                            if (nodeBranch.imageFile) {
                                const formData = new FormData();
                                formData.append('file', nodeBranch.imageFile);
                                // eslint-disable-next-line no-await-in-loop
                                nodeBranch.imageUrl = (await uploadCampaignFile(formData)).data.location;
                                nodeBranch.imageFile = null;
                            }
                        }
                    }

                    // special upload case for image carousel as that has image files nested one layer into an array
                    // that array being carouselCards[i].file carouselCards[i].src (not 'url' like with image module)
                    if (moduleConfiguration.props.carouselCards) {
                        const cards = moduleConfiguration.props.carouselCards;
                        for (let carouselCardIndex = 0; carouselCardIndex < cards.length; carouselCardIndex++) {
                            if (cards[carouselCardIndex].file) {
                                const formData = new FormData();
                                formData.append('file', cards[carouselCardIndex].file);
                                // eslint-disable-next-line no-await-in-loop
                                cards[carouselCardIndex].src = (await uploadCampaignFile(formData)).data.location;
                                cards[carouselCardIndex].file = null;
                            }
                        }
                    }

                    // special upload case for image carousel as that has image files nested one layer into an array
                    // that array being carouselCards[i].file carouselCards[i].src (not 'url' like with image module)
                    // if (moduleConfiguration.props.carouselCards) {
                    //     const cards = moduleConfiguration.props.carouselCards;
                    //     for (let carouselCardIndex = 0; carouselCardIndex < cards.length; carouselCardIndex++) {
                    //         if (cards[carouselCardIndex].file) {
                    //             const formData = new FormData();
                    //             formData.append('file', cards[carouselCardIndex].file);
                    //             // eslint-disable-next-line no-await-in-loop
                    //             cards[carouselCardIndex].src = (await uploadCampaignFile(formData)).data.location;
                    //             cards[carouselCardIndex].file = null;
                    //         }
                    //     }
                    // }
                }
            }
            await updateCampaignConfiguration(configurationTriggers, campaign);
            setShowSpinner(false);
        } catch (e) {
            setShowSpinner(false);
            setErrorSaveCampaign(true);
            console.error(e);
        }
    };

    const shouldSelectRow = (index) => ((campaign && selectedMessage && selectedMessage.index === index) ||
        (hoverMessage.index === index && hoverMessage.isHover));

    const goToPreviousConfig = () => {
        const previousTrigger = configurationTriggerOrder[configurationTriggerOrder.length - 2];
        const lastConfigurationIndex = configurationTriggers[previousTrigger].configuration.length - 1;

        setSelectedConfigTriggerIndex(previousTrigger);
        setSelectedMessage({
            index: lastConfigurationIndex,
            message: configurationTriggers[previousTrigger].configuration[lastConfigurationIndex],
        });

        setConfigurationTriggerOrder(configurationTriggerOrder => {
            const newConfigurationTriggerOrder = [...configurationTriggerOrder];
            newConfigurationTriggerOrder.pop();
            return newConfigurationTriggerOrder;
        });
    };

    const getBackgroundColor = (index, error) => {
        if (error) {
            return 'rgba(255, 51, 51, 0.3)';
        } if (shouldSelectRow(index)) {
            return lightenColor(styles.outerBgColor);
        } if (styles && styles.interiorBgColor) {
            return styles.interiorBgColor;
        }
        return '#FFF';
    };

    const handleDisplayModule = (message, index) => {
        if (message.props.horizontalImageUrl) {
            setWallpaperImage(message.props.horizontalImageUrl);
            setWallpaperSize(message.props.horizontalBgSize);
            setWallpaperRepeat(message.props.horizontalBgRepeat);
        } else {
            setWallpaperImage(campaign.horizontal_image);
            setWallpaperSize(styles.horizontalBgSize);
            setWallpaperRepeat(styles.horizontalBgRepeat);
        }
        setSelectedMessage({ message, index });
    };

    const handlePreviewButton = () => {
        setPlayPreview(!playPreview);
        setWallpaperImage(campaign.horizontal_image);
        setWallpaperSize(styles.horizontalBgSize);
        setWallpaperRepeat(styles.horizontalBgRepeat);
    };

    const confirmBackToSetup = () => {
        changeScreen('editCampaign');
        history.push('/campaign/edit', campaign);
    };

    const handleBackToSetup = () => {
        if (isSaved) {
            confirmBackToSetup();
        } else {
            setGoBackModal(true);
        }
    };

    const saveModuleStyles = async () => {
        try {
            // finds and sets global styles
            const moduleTypesWithGlobalStyles = ['colorPalette'];
            configurationTriggers.forEach(({ configuration }) => {
                configuration.forEach(module => {
                    if (module.props.isDefaultConfig) {
                        moduleTypesWithGlobalStyles.push(module.type);

                        Object.keys(defaultModuleStyles[module.type]).forEach(style => {
                            setModuleStyles(moduleStyles => ({
                                ...moduleStyles,
                                [module.type]: {
                                    ...moduleStyles[module.type],
                                    [style]: module.props[style],
                                },
                            }));
                        });
                    }
                });
            });

            // if a certain module type has no global styles, resets to defaults
            Object.keys(defaultModuleStyles).forEach(type => {
                if (moduleTypesWithGlobalStyles.includes(type)) {
                    return;
                }
                setModuleStyles(moduleStyles => ({
                    ...moduleStyles,
                    [type]: { ...defaultModuleStyles[type] },
                }));
            });

            let result = {};
            const formData = new FormData();
            formData.append('campaign', JSON.stringify({
                id,
                name,
                module_styles: JSON.stringify(moduleStyles),
            }));
            result = await editModuleStyles(formData);

            if (result && result.data && result.data.id) {
                setCampaign(campaign => ({
                    ...campaign,
                    module_styles: result.data.module_styles,
                }));
                setModuleStyles(JSON.parse(result.data.module_styles));
            }
        } catch (e) {
            setShowSpinner(false);
            setErrorSaveCampaign(true);
            console.error(e);
        }
    };

    const handleSaveChanges = () => {
        setIsSaved(true);
        saveModulesConfiguration().then(() => { saveModuleStyles(); });
    };

    return (
        <>
            <TabBar
                campaign={campaign}
                switchScreens={handleBackToSetup}
            />

            <Row className="chatbot-configuration-container justify-content-between">
                {/* OPTIONS */}
                <Col className="m-0 pl-0 pr-3">
                    {/* SCREEN SIZES */}
                    <Row className="screen-sizes mt-0 mb-3">
                        <Col className="pt-1">
                            <Dropdown
                                isOpen={mobilePreviewDropdownOpen}
                                toggle={toggleMobilePreviewDropdown}
                            >
                                <DropdownToggle
                                    caret
                                    outline
                                >
                                    <MdSmartphone
                                        size="20px"
                                        className="icon"
                                    />
                                    <span className="top-button-font">
                                        {`${mobilePreview.type}
                                        ${mobilePreview.width.slice(0, -2)} x
                                        ${mobilePreview.height.slice(0, -2)}`}
                                    </span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem>
                                        <Row
                                            className="m-2 p-1"
                                            onClick={() => setMobilePreview({
                                                type: 'Mobile', height: '650px', width: '360px',
                                            })}
                                        >
                                            <Col>
                                                Mobile - 360 x 650
                                            </Col>
                                        </Row>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <Row
                                            className="m-2 p-1"
                                            onClick={() => setMobilePreview({
                                                type: 'iPhone X', height: '812px', width: '375px',
                                            })}
                                        >
                                            <Col>
                                                iPhone X - 375 x 812
                                            </Col>
                                        </Row>
                                    </DropdownItem>

                                    <DropdownItem>
                                        <Row
                                            className="m-2 p-1"
                                            onClick={() => setMobilePreview({
                                                type: 'iPad', height: '1024px', width: '768px',
                                            })}
                                        >
                                            <Col>
                                                iPad - 768 x 1024
                                            </Col>
                                        </Row>
                                    </DropdownItem>

                                    <DropdownItem divider/>

                                    <DropdownItem
                                        toggle={false}
                                        onClick={() => setMobilePreview({
                                            type: 'Custom',
                                            height: `${tempMobileHeight}px`,
                                            width: `${tempMobileWidth}px`,
                                        })}
                                    >
                                        <Row className="m-2">
                                            <Col>
                                                <FormGroup>
                                                    <Label>
                                                        <span className="height-width-label">
                                                            Width in Points
                                                        </span>
                                                        <Input
                                                            className="my-2"
                                                            type="text"
                                                            name="mobileWidth"
                                                            id="mobileWidthInput"
                                                            value={tempMobileWidth}
                                                            onChange={(e) => setTempMobileWidth(e.target.value)}
                                                        />
                                                        <span className="height-width-label">
                                                            Height in Points
                                                        </span>
                                                        <Input
                                                            className="my-2"
                                                            type="text"
                                                            name="mobileHeight"
                                                            id="mobileWidthInput"
                                                            value={tempMobileHeight}
                                                            onChange={(e) => setTempMobileHeight(e.target.value)}
                                                        />
                                                    </Label>
                                                </FormGroup>
                                                Set Custom Screen Size
                                            </Col>
                                        </Row>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                        <Col className="d-flex justify-content-between pt-1">
                            {card &&
                                <>
                                    <button
                                        className="edit-button"
                                        type="button"
                                        onClick={() => handleEditChat()}
                                        style={{
                                            backgroundColor: editChat ? '#756682' : '#FFF',
                                            color: editChat ? '#FFF' : '#756682',
                                        }}
                                    >
                                        Edit Chat
                                    </button>
                                    <button
                                        className="edit-button"
                                        type="button"
                                        onClick={() => handleEditCard()}
                                        style={{
                                            backgroundColor: !editChat ? '#756682' : '#FFF',
                                            color: !editChat ? '#FFF' : '#756682',
                                        }}
                                    >
                                        Edit Card
                                    </button>
                                </>
                            }
                        </Col>
                    </Row>

                    {/* MODULE CONFIGURATIONS */}
                    <Row className="m-0 p-0">
                        <Col className="module-configurations p-4">
                            {/* MODULE SELECTION */}
                            {selectedMessage && configurationTriggers[selectedConfigTriggerIndex].configuration.length ?
                                renderModuleConfiguration() :
                                <ModuleSelection selectNewModule={selectNewModule}/>
                            }
                        </Col>
                    </Row>

                    {/* COMPLETE CONFIG BUTTONS */}
                    <Row className="mt-2 mb-0 mx-0 p-0">
                        <Col>
                            <Button
                                type="submit"
                                className="shared-create-config-button"
                                onClick={() => handleBackToSetup()}
                            >
                                Back to Setup
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="submit"
                                className="shared-create-config-button"
                                onClick={() => handleSaveChanges()}
                            >
                                Save Changes
                            </Button>
                        </Col>
                    </Row>
                </Col>

                {/* CHATBOT PREVIEW */}
                <Col
                    xl={7}
                    className="m-0 p-0"
                    style={{ height: mobilePreview.height }}
                >
                    {/* CHATBOT */}
                    <Row
                        className="h-100 mx-auto p-0"
                        style={{ width: mobilePreview.width }}
                    >
                        {playPreview ?
                            // PREVIEW
                            <Col className="h-100 m-0 p-0">
                                {/* PREVIEW AGE GATE */}
                                {previewAgeGate && playPreview &&
                                    <div
                                        style={{
                                            zIndex: '12',
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            backgroundColor: '#FFF',
                                        }}
                                    >
                                        <Row className="m-0 h-100">
                                            <Col className="p-0">
                                                <Row>
                                                    <Col
                                                        xs={{ size: 10, offset: 1 }}
                                                        className="text-center subTitleRow m-auto"
                                                    >
                                                        <h6>Date of Birth</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col
                                                        xs={{ size: 10, offset: 1 }}
                                                        className="text-center date-row m-auto"
                                                    >
                                                        <div>
                                                            <DatePicker
                                                                style={{
                                                                    margin: '10px',
                                                                    minWidth: '250px',
                                                                    width: '100%',
                                                                }}
                                                                onChange={onChangeAgeGate}
                                                                value={ageGateValue}
                                                                maxDate={new Date()}
                                                                required
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="verify-row w-100">
                                                    <Col className="text-center">
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                ageGateCheck(ageGateValue);
                                                            }}
                                                            style={{
                                                                marginTop: '16px',
                                                                marginLeft: '29px',
                                                                maxHeight: '42px',
                                                            }}
                                                        >
                                                            Verify
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                }

                                {/* PREVIEW LANDING PAGE */}
                                {previewLandingPage && playPreview &&
                                    <div
                                        style={{
                                            zIndex: '11',
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                        }}
                                    >
                                        <button
                                            type="submit"
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                border: '0',
                                                backgroundImage: `url(${campaign.landing_page_image})`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat',
                                            }}
                                            onClick={() => setPreviewLandingPage(null)}
                                        />
                                    </div>
                                }

                                <ChatbotLayout
                                    campaign={campaign}
                                    chatbotActive={false}
                                    showPreview
                                    previewWidth={mobilePreview.width}
                                    showCelebration={showCelebration}
                                    celebration={celebration}
                                    wallpaperImage={wallpaperImage}
                                    wallpaperSize={wallpaperSize}
                                    wallpaperRepeat={wallpaperRepeat}
                                >
                                    <ChatbotCore
                                        campaign={campaign}
                                        chatbotActive
                                        showPreview
                                        setShowCelebration={setShowCelebration}
                                        setCelebration={setCelebration}
                                        setWallpaperImage={setWallpaperImage}
                                        setWallpaperSize={setWallpaperSize}
                                        setWallpaperRepeat={setWallpaperRepeat}
                                        setWallpaperExternalLinkUrl={Function.prototype}
                                        enableScrollToBottom={false}
                                    />
                                </ChatbotLayout>
                            </Col> :

                            // CONFIGURABLE CHATBOT MODULES
                            <div className={isNarrowConfigScreen ? 'preview-container' : 'chatbot-container'}>
                                {horizontalLayout &&
                                    <Col
                                        className={`m-0 p-0
                                            ${isNarrowConfigScreen ? 'preview-wallpaper-container' : 'horizontal-wallpaper-container'}`}
                                        style={{
                                            borderRadius: containerBorderRadii.wallpaper,
                                            backgroundImage: `url(${wallpaperImage})`,
                                            backgroundColor: styles && styles.outerBgColor ? styles.outerBgColor : '#DDD',
                                            backgroundSize: wallpaperSize || '100% 100%',
                                            backgroundRepeat: wallpaperRepeat || 'repeat',
                                        }}
                                    />
                                }

                                {editChat ?
                                    <Col
                                        className={isNarrowConfigScreen ? 'preview-layout-container' : 'chatbot-layout-container'}
                                        style={{
                                            borderRadius: containerBorderRadii.chatbot,
                                            width: horizontalLayout && !isNarrowConfigScreen ? '50%' : '100%',
                                            backgroundImage: `url(${outerBgImage})`,
                                            backgroundColor: styles && styles.outerBgColor ? styles.outerBgColor : '#DDD',
                                            backgroundSize: styles && styles.outerBgSize ? styles.outerBgSize : '100% 100%',
                                            backgroundRepeat: styles && styles.outerBgRepeat ? styles.outerBgRepeat : 'repeat',
                                        }}
                                    >
                                        <Row
                                            className="interior-box"
                                            style={{
                                                borderRadius: styles && styles.interiorBorderRadius ?
                                                    `${styles.interiorBorderRadius}px` : '20px',
                                                backgroundImage: `url(${interiorBgImage})`,
                                                backgroundColor: styles && styles.interiorBgColor ? styles.interiorBgColor : '#FFF',
                                                backgroundSize: styles && styles.interiorBgSize ? styles.interiorBgSize : '100% 100%',
                                                backgroundRepeat: styles && styles.interiorBgRepeat ? styles.interiorBgRepeat : 'repeat',
                                                overflowX: 'hidden',
                                                overflowY: 'scroll',
                                                scrollbarWidth: 'thin',
                                                scrollbarColor: '#00000070 transparent',
                                            }}
                                        >
                                            <Col className="m-0 p-2">
                                                {/* GO TO PREV CONFIG */}
                                                {selectedConfigTriggerIndex > 0 &&
                                                    <Row className="pointer">
                                                        <Col
                                                            className="text-center pb-2 pt-2"
                                                            onClick={() => goToPreviousConfig()}
                                                        >
                                                            <FaArrowAltCircleUp size={28}/>
                                                        </Col>
                                                    </Row>
                                                }

                                                {/* DISPLAYS MODULES */}
                                                {configurationTriggers &&
                                                configurationTriggers.length &&
                                                configurationTriggers[selectedConfigTriggerIndex].configuration &&
                                                configurationTriggers[selectedConfigTriggerIndex].configuration.map((message, index) => (
                                                    <Row
                                                        className="message-row my-2"
                                                        key={`${JSON.stringify(message)}+${index}`}
                                                        onClick={() => handleDisplayModule(message, index)}
                                                        style={{ backgroundColor: getBackgroundColor(index, message.props.error) }}
                                                        onMouseEnter={() => setHoverMessage({ index, isHover: true })}
                                                        onMouseLeave={() => setHoverMessage({ index, isHover: false })}
                                                    >
                                                        <Col>
                                                            <MessageModule
                                                                key={JSON.stringify(message)}
                                                                moduleIndex={index}
                                                                message={message}
                                                                campaign={campaign}
                                                                avatar={message.props.showAvatar && avatar}
                                                                chatbotActive={false}
                                                                waterfallLayout={campaign.waterfall_layout}
                                                                checkForNodeBranches={Function.prototype}
                                                                handleNodeBranching={Function.prototype}
                                                                loadWallpaperImage={Function.prototype}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}

                                                {/* ADD MODULE */}
                                                <Row
                                                    className="pointer"
                                                    onClick={() => addNewEmptyModule()}
                                                    style={{
                                                        backgroundColor: hoverNewModule ?
                                                            lightenColor(styles && styles.outerBgColor) : '#FFF',
                                                    }}
                                                    onMouseEnter={() => setHoverNewModule(true)}
                                                    onMouseLeave={() => setHoverNewModule(false)}
                                                >
                                                    <Col className="text-center py-5">
                                                        <FaPlusCircle size="28px"/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col> :

                                    <Col
                                        className={isNarrowConfigScreen ? 'preview-layout-container' : 'chatbot-layout-container'}
                                        style={{
                                            borderRadius: containerBorderRadii.chatbot,
                                            backgroundImage: `url(${outerBgImage})`,
                                            backgroundColor: (styles && styles.outerBgColor) || '#DDD',
                                            backgroundSize: (styles && styles.outerBgSize) || '100% 100%',
                                            backgroundRepeat: (styles && styles.outerBgRepeat) || 'repeat',
                                        }}
                                    >
                                        <Row
                                            className="modal-card-config-preview"
                                            style={{ backgroundColor: (styles && styles.outerBgColor) || '#FFF' }}
                                        >
                                            <Col className="m-0 p-2">
                                                {/* GO TO PREV CONFIG */}
                                                {!isFirstConfiguration &&
                                                    <Row className="pointer">
                                                        <Col
                                                            className="text-center pb-2 pt-2"
                                                            onClick={() => goToPreviousConfig()}
                                                        >
                                                            <FaArrowAltCircleUp size={28}/>
                                                        </Col>
                                                    </Row>
                                                }

                                                {/* DISPLAYS MODULES */}
                                                {configurationTriggers &&
                                                configurationTriggers.length &&
                                                configurationTriggers[selectedConfigTriggerIndex].configuration &&
                                                configurationTriggers[selectedConfigTriggerIndex].configuration.map((message, index) => (
                                                    <Row
                                                        className="message-row my-2"
                                                        key={`${JSON.stringify(message)}+${index}`}
                                                        onClick={() => handleDisplayModule(message, index)}
                                                        style={{ backgroundColor: getBackgroundColor(index, message.props.error) }}
                                                        onMouseEnter={() => setHoverMessage({ index, isHover: true })}
                                                        onMouseLeave={() => setHoverMessage({ index, isHover: false })}
                                                    >
                                                        <Col>
                                                            <MessageModule
                                                                key={JSON.stringify(message)}
                                                                moduleIndex={index}
                                                                message={message}
                                                                campaign={campaign}
                                                                avatar={message.props.showAvatar && avatar}
                                                                chatbotActive={false}
                                                                waterfallLayout={campaign.waterfall_layout}
                                                                checkForNodeBranches={Function.prototype}
                                                                handleNodeBranching={Function.prototype}
                                                                loadWallpaperImage={Function.prototype}
                                                            />
                                                        </Col>
                                                    </Row>
                                                ))}

                                                {/* ADD MODULE */}
                                                <Row
                                                    className="pointer"
                                                    onClick={() => addNewEmptyModule()}
                                                    style={{
                                                        backgroundColor: hoverNewModule ?
                                                            lightenColor(styles && styles.outerBgColor) : '#FFF',
                                                    }}
                                                    onMouseEnter={() => setHoverNewModule(true)}
                                                    onMouseLeave={() => setHoverNewModule(false)}
                                                >
                                                    <Col className="text-center py-5">
                                                        <FaPlusCircle size="28px"/>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </div>
                        }
                    </Row>
                </Col>

                {/* PREVIEW BUTTON */}
                <Col xl={1}>
                    <div className="text-right">
                        {playPreview ?
                            <FaStopCircle
                                size="50px"
                                color="#000"
                                className="pointer"
                                onClick={() => handlePreviewButton()}
                            /> :

                            <FaPlayCircle
                                size="50px"
                                color="#000"
                                className="pointer"
                                onClick={() => handlePreviewButton()}
                            />
                        }
                    </div>
                </Col>
            </Row>

            {goBackModal &&
                <Modal
                    header="Confirm Discard Changes!"
                    description="Are you sure you want to go back without saving your changes?"
                    showConfirmButton
                    confirmButtonTitle="Discard Changes"
                    closeButtonTitle="Cancel"
                    handleConfirm={confirmBackToSetup}
                    handleClose={() => setGoBackModal(false)}
                    size="md"
                    showCloseButton
                    showCloseIcon={false}
                    handleError={error => console.error(error)}
                    headerClassName="modal-header"
                    footerClassName="modal-footer"
                    confirmButtonClassName="modal-delete-button"
                />
            }

            {showModalQR &&
                <ModalQrCode
                    campaignRef={campaign.ref}
                    exitText="Exit to Campaigns"
                    setupText="Back to Setup"
                    configureText="Continue Configuration"
                    handleExit={() => { history.push('/'); window.location.reload(); }}
                    handleSetup={confirmBackToSetup}
                    handleConfigure={() => setShowModalQR(false)}
                    headerText={`The campaign for ${campaign && campaign.name && campaign.name} has been configured!`}
                    showSpinner={showSpinner}
                    errorSaveCampaign={errorSaveCampaign}
                    handleError={() => setShowModalQR(false)}
                    handleClose={() => setShowModalQR(false)}
                />
            }
        </>
    );
};

export default connect(
    (state) => ({ language: state.language.selectedLanguage }),
    ({ changeScreen, editModuleStyles }),
)(ChatbotConfiguration);