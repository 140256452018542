import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import modules from '../Modules';
import Typing from './Typing';

const MessageModule = ({
    moduleIndex,
    message,
    campaign,
    userId,
    chatbotActive,
    setShowCelebration,
    setCelebration,
    checkForNodeBranches,
    setAnsweredIndex,
    handleNodeBranching,
    loadWallpaperImage,
    setMessageProp,
}) => {
    const {
        showTyping,
        typingDuration,
        withDelay,
        showAvatar,
        moduleHeading,
        hasBeenRendered,
        hasBeenClicked,
        showCelebration,
        celebration,
        animation,
        styles,
        isVirtualized,
    } = message.props;

    const { type: moduleType } = message;

    const [avatar, setAvatar] = useState(null);
    const [waterfallLayout, setWaterfallLayout] = useState(null);
    const [overflowXVisibility, setOverflowXVisibility] = useState('hidden');

    useEffect(() => {
        if (campaign) {
            setAvatar(campaign.avatar);
            setWaterfallLayout(campaign.waterfall_layout);
        }
    }, [campaign]);

    const paddingLeft = (!waterfallLayout && styles && styles.paddingLeft) || 3;
    const avatarDiameter = (campaign && campaign.styles && campaign.styles.avatarDiameter) || 35;
    const MessageComponent = message && moduleType ? modules[moduleType] : null;

    useEffect(() => {
        if (showCelebration && setShowCelebration && !hasBeenRendered && !hasBeenClicked) {
            setShowCelebration(true);
            setCelebration(celebration);
        }

        // so slide-in module animations look right because they slide in from the right
        setTimeout(() => { setOverflowXVisibility('visible'); }, animation === 'slide-in' ? 1000 : 0);
    }, []);

    const previewTimeouts = useRef([]);
    const delay = async (duration) => new Promise(resolve => previewTimeouts.current.push(setTimeout(resolve, duration)));
    const [isModuleDelayed, setIsModuleDelayed] = useState(false);
    useEffect(() => {
        async function setTypingDelay() {
            if ((withDelay || showTyping) && typingDuration) {
                setIsModuleDelayed(true);
                await delay(typingDuration);
                setIsModuleDelayed(false);
            }
        }
        setTypingDelay();

        return () => {
            previewTimeouts.current.forEach(previewTimeout => clearTimeout(previewTimeout));
            previewTimeouts.current = [];
        };
    }, []);

    let moduleMargin = '4% 0';
    if (moduleType === 'Text' && showAvatar) {
        moduleMargin = '4% 0 1px 0';
    } else if (moduleType === 'Text' && !showAvatar) {
        moduleMargin = '1px 0';
    }

    let messageModuleCssClass = '';
    if (chatbotActive && !hasBeenRendered) {
        const { buttonStyles, node_branches: nodeBranches } = message.props;
        switch (animation) {
            case 'drop-in':
                if (moduleType === 'QuickReply') {
                    if ((buttonStyles && buttonStyles.isStacked && nodeBranches.length > 4) ||
                        (buttonStyles && !buttonStyles.isStacked && nodeBranches.length > 6)) {
                        messageModuleCssClass = `slide-up ${animation} slow`;
                    } else if ((buttonStyles && buttonStyles.isStacked && nodeBranches.length > 2) ||
                        (buttonStyles && !buttonStyles.isStacked && nodeBranches.length > 4)) {
                        messageModuleCssClass = `slide-up ${animation} medium`;
                    } else {
                        messageModuleCssClass = `slide-up ${animation} fast`;
                    }
                } else if (moduleType === 'ImageCarousel') {
                    messageModuleCssClass = `slide-up ${animation} slow`;
                } else {
                    messageModuleCssClass = `slide-up ${animation} fast`;
                }
                break;
            case 'slide-in':
                messageModuleCssClass = `slide-up ${animation} fast`;
                break;
            case 'pop-in':
                messageModuleCssClass = `slide-up ${animation}`;
                break;
            case 'expand-in':
                messageModuleCssClass = `slide-up ${animation}`;
                break;
            case 'fade-in':
                messageModuleCssClass = `slide-up ${animation}`;
                break;
            default:
                messageModuleCssClass = 'slipe-up fade-in';
        }
    }

    return (
        <>
            {isModuleDelayed && chatbotActive ?
                <Typing
                    showTyping={showTyping}
                    avatar={avatar}
                    avatarDiameter={avatarDiameter}
                    paddingLeft={paddingLeft}
                /> :

                <Row style={{ margin: moduleMargin }}>
                    {/* AVATAR */}
                    {!isVirtualized &&
                        <Col
                            xs={2}
                            className={`d-flex justify-content-start m-0 px-1 ${waterfallLayout ? 'pt-3 pb-2' : 'py-0'}`}
                        >
                            {showAvatar && campaign && avatar &&
                                <Image
                                    className="avatar"
                                    src={avatar}
                                    roundedCircle
                                    style={{ height: `${avatarDiameter}px`, width: `${avatarDiameter}px` }}
                                />
                            }
                        </Col>
                    }

                    {/* HEADING */}
                    {waterfallLayout && moduleHeading &&
                        <Col
                            xs={10}
                            className="m-0 pt-4 pb-0 pl-0 pr-3 text-right"
                        >
                            {moduleHeading !== '' && <div dangerouslySetInnerHTML={{ __html: moduleHeading }}/>}
                        </Col>
                    }

                    {/* MESSAGE MODULE */}
                    <Col
                        xs={isVirtualized || waterfallLayout ? 12 : 10}
                        className={`message-container m-0 py-0 pr-3 pl-${paddingLeft}`}
                        style={{ overflowX: overflowXVisibility }}
                    >
                        <div className={messageModuleCssClass}>
                            <MessageComponent
                                campaign={campaign}
                                moduleIndex={moduleIndex}
                                chatbotActive={chatbotActive}
                                waterfallLayout={waterfallLayout}
                                checkForNodeBranches={checkForNodeBranches}
                                setAnsweredIndex={setAnsweredIndex}
                                handleNodeBranching={handleNodeBranching}
                                loadWallpaperImage={loadWallpaperImage}
                                setShowCelebration={setShowCelebration}
                                setMessageProp={setMessageProp}
                                setCelebration={setCelebration}
                                userId={userId}
                                {...message.props}
                            />
                        </div>
                    </Col>
                </Row>
            }
        </>
    );
};

export default MessageModule;