import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { BsImage, BsLink45Deg, BsXCircle } from 'react-icons/bs';
import { ImageSizing } from '.';
import { IMAGE_PLACEHOLDER_URL, VALID_IMAGE_TYPES } from '../../../config';
import imageModulePlaceholder from '../../../assets/images/image-module-placeholder.png';
import { checkIfImageUrlValid, getImageFileName } from '../../../helpers/regexHelpers';
import { checkSharedMessageError } from '../ModuleConfigurations/errorHelper';

const ImageUpload = ({
    wallpaper = true,
    heading = '',
    imageUrl,
    imageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    setSelectedMessage,
}) => {
    const [errorImageUpload, setErrorImageUpload] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [errorImageURL, setErrorImageURL] = useState(false);
    const [wallpaperExternalLinkURL, setWallpaperExternalLinkURL] = useState('');
    const [hasExternalLinkUrl, setHasExternalLinkUrl] = useState(false);
    const [hasImageUrl, setHasImageUrl] = useState(false);
    const [displayFileName, setDisplayFileName] = useState('');

    const selectedIconColor = '#000';
    const unselectedIconColor = '#BBB';

    useEffect(() => {
        if (imageUrl && imageUrl !== '' && imageUrl !== IMAGE_PLACEHOLDER_URL) {
            setHasImageUrl(true);
        } else {
            setHasImageUrl(false);
        }
    }, [imageUrl]);

    useEffect(() => {
        if (wallpaperExternalLinkUrl) {
            setHasExternalLinkUrl(true);
        }
    }, [wallpaperExternalLinkUrl]);

    useEffect(() => {
        if (imageFile && imageFile.name) {
            setDisplayFileName(`${imageFile.name}`);
        } else if (imageUrl) {
            const fileName = getImageFileName(imageUrl);
            setDisplayFileName(`${fileName}`);
        }
    }, [imageFile, imageUrl]);

    const handleImageChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        const imageFile = e.target.files[0];
        if (!VALID_IMAGE_TYPES.includes(imageFile.type)) {
            setErrorImageUpload(true);
        } else {
            setDisplayFileName(imageFile.name);
            setErrorImageUpload(false);
            setErrorImageURL(false);
            setImageURL('');
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        [wallpaper ? 'horizontalImageUrl' : 'imageUrl']: URL.createObjectURL(imageFile),
                        [wallpaper ? 'horizontalImageFile' : 'imageFile']: imageFile,
                        error: (() => {
                            // general error check, whether wallpaper or image module
                            if (checkSharedMessageError(selectedMessage.message.props) ||
                                !VALID_IMAGE_TYPES.includes(imageFile.type)) {
                                return true;
                            }

                            // if wallpaper upload, this returns the existing error boolean
                            if (!wallpaper && !imageFile) {
                                return true;
                            } if (!wallpaper && imageFile) {
                                return false;
                            }
                            return selectedMessage.message.props.error;
                        })(),
                        // error: !imageFile ||
                        //     checkSharedMessageError(selectedMessage.message.props) ||
                        //     !VALID_IMAGE_TYPES.includes(imageFile.type),
                    },
                },
            }));
            // resets value to initial state because if same picture is uploaded there is no state change
            e.target.value = '';
        }
    };

    const handleImageUrlChange = (e) => {
        const { value } = e.target;

        if (!checkIfImageUrlValid(value)) {
            setErrorImageURL(true);
        } else {
            setErrorImageURL(false);
        }
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    [wallpaper ? 'horizontalImageUrl' : 'imageUrl']: value || '',
                    [wallpaper ? 'horizontalImageFile' : 'imageFile']: null,
                    error: !value ||
                        checkSharedMessageError(selectedMessage.message.props) ||
                        !checkIfImageUrlValid(value),
                },
            },
        }));
        setImageURL(value);
    };

    const handleRemoveImage = () => {
        setDisplayFileName('');
        setErrorImageUpload(false);
        setErrorImageURL(false);
        setImageURL('');
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    [wallpaper ? 'horizontalImageUrl' : 'imageUrl']: wallpaper ? null : imageModulePlaceholder,
                    [wallpaper ? 'horizontalImageFile' : 'imageFile']: null,
                    error: (() => {
                        // general error check, whether wallpaper or image module
                        if (checkSharedMessageError(selectedMessage.message.props)) {
                            return true;
                        }

                        // if wallpaper upload, this returns the existing error boolean
                        if (!wallpaper && !imageFile) {
                            return true;
                        } if (!wallpaper && imageFile) {
                            return false;
                        }
                        return selectedMessage.message.props.error;
                    })(),
                    // error: selectedMessage.message.props.error ||
                    //     ((!wallpaper && !imageFile) ||
                    //     checkSharedMessageError(selectedMessage.message.props) ||
                    //     (!wallpaper && !VALID_IMAGE_TYPES.includes(imageFile.type))),
                },
            },
        }));
    };

    const RemoveImage = () => (
        <>
            <button
                className="shared-remove-file-button"
                type="submit"
                onClick={() => handleRemoveImage()}
            >
                <BsXCircle
                    size="15px"
                    style={{ marginTop: '-12px', color: '#F83667' }}
                />
            </button>
        </>
    );

    const handleExternalLinkUrlChange = (e) => {
        const { value } = e.target;

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    wallpaperExternalLinkUrl: value || '',
                },
            },
        }));
        setWallpaperExternalLinkURL(value);
    };

    const handleAddRemoveLink = () => {
        setHasExternalLinkUrl(!hasExternalLinkUrl);

        if (hasExternalLinkUrl) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        wallpaperExternalLinkUrl: null,
                    },
                },
            }));
            setWallpaperExternalLinkURL('');
        }
    };

    const setCoverValues = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    horizontalBgSize: `${value}% auto`,
                    horizontalBgRepeat: 'repeat',
                },
            },
        }));
    };

    const setButtonValues = (size, repeat) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    horizontalBgSize: size,
                    horizontalBgRepeat: repeat,
                },
            },
        }));
    };

    return (
        <Row className="shared-module-background">
            <Col className="m-0 p-0">
                <h6 className="shared-heading">
                    <BsImage
                        size="18px"
                        color="#000"
                        style={{ margin: '0 12px 0 5px' }}
                    />
                    {wallpaper ? 'Wallpaper Image' : heading}
                </h6>

                {/* UPLOAD BUTTON */}
                <FormGroup className="mb-0 pb-0">
                    <Label
                        className="shared-media-upload"
                        for={wallpaper ? 'horizontalImage' : 'image'}
                    >
                        <p>Upload or drag image here</p>
                    </Label>
                    <div
                        style={{
                            width: '100%',
                            height: '25px',
                            display: 'flex',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <div className="shared-heading">{displayFileName}</div>
                        {(imageFile || hasImageUrl) && <RemoveImage/>}
                    </div>
                    {/* {imageFile && imageFile.name && imageURL === '' &&
                        <p className="pt-2 description-font">{imageFile.name}</p>
                    } */}
                </FormGroup>

                {/* IMAGE URL TEXT BOX */}
                <FormGroup>
                    <Input
                        className="shared-input"
                        type="textarea"
                        placeholder="Or enter image URL"
                        value={imageUrl && imageUrl !== IMAGE_PLACEHOLDER_URL && imageURL === '' && !imageFile ?
                            imageUrl : imageURL}
                        onChange={handleImageUrlChange}
                    />
                    {errorImageURL &&
                        <p className="error-message">Please enter valid URL</p>
                    }
                </FormGroup>

                {/* ERROR MESSAGE */}
                <FormGroup>
                    <Input
                        type="file"
                        name={wallpaper ? 'horizontalImage' : 'image'}
                        id={wallpaper ? 'horizontalImage' : 'image'}
                        onChange={handleImageChange}
                    />
                    {errorImageUpload &&
                        <p className="error-message">
                            Please upload image in valid format (JPG, JPEG, PNG, GIF, BMP)
                        </p>
                    }
                </FormGroup>

                {/* EXTERNAL LINK */}
                {wallpaper &&
                    <FormGroup
                        check
                        className="shared-message-configuration mt-4 mx-0 p-0"
                    >
                        <Label
                            check
                            className="shared-message-label"
                        >
                            <Input
                                type="checkbox"
                                onChange={() => handleAddRemoveLink()}
                                className="pointer"
                            />
                            <BsLink45Deg
                                size="20px"
                                color={hasExternalLinkUrl ? selectedIconColor : unselectedIconColor}
                                style={{ margin: '0 2px 0 5px' }}
                            />
                            <div className="shared-message-checkbox-text">
                                {hasExternalLinkUrl ? 'Remove Link' : 'Add link on click'}
                            </div>
                        </Label>
                    </FormGroup>
                }

                {/* LINK URL TEXT BOX */}
                {hasExternalLinkUrl &&
                    <FormGroup>
                        <Input
                            className="shared-input"
                            type="textarea"
                            placeholder="Enter link URL"
                            value={wallpaperExternalLinkUrl && wallpaperExternalLinkURL === '' ?
                                wallpaperExternalLinkUrl : wallpaperExternalLinkURL}
                            onChange={handleExternalLinkUrlChange}
                        />
                    </FormGroup>
                }

                {/* WALLPAPER IMAGE SIZING */}
                {wallpaper &&
                    <ImageSizing
                        stylesBgSize={horizontalBgSize}
                        stylesBgRepeat={horizontalBgRepeat}
                        setCoverValues={setCoverValues}
                        setButtonValues={setButtonValues}
                    />
                }
            </Col>
        </Row>
    );
};

export default ImageUpload;