import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { BsCaretDownFill } from 'react-icons/bs';
import { checkSharedMessageError } from '../ModuleConfigurations/errorHelper';

const ModuleStyles = ({ styles, setSelectedMessage }) => {
    const [moduleStyles, setModuleStyles] = useState(styles || { paddingLeft: 0 });
    useEffect(() => {
        if (styles) {
            setModuleStyles(styles);
        }
    }, [styles]);

    const [stylesMenu, setStylesMenu] = useState(false);

    const handleSelectPadding = (choice) => {
        setStylesMenu(false);
        const newStyles = { ...styles };
        newStyles.paddingLeft = choice;
        setModuleStyles(newStyles);

        setSelectedMessage(selectedMessage => {
            const { props } = selectedMessage.message;
            props.styles = newStyles;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...props,
                        error: checkSharedMessageError(props),
                    },
                },
            });
        });
    };

    return (
        <Row className="shared-module-background">
            <Col className="m-0 p-0">
                <h5 className="shared-heading">
                    Select Spacing Between Module and Avatar
                </h5>
                <button
                    className="shared-module-button"
                    type="button"
                    onClick={() => setStylesMenu(!stylesMenu)}
                >
                    <h6>{moduleStyles.paddingLeft}</h6>
                    <BsCaretDownFill
                        size="11px"
                        style={{ marginTop: '-4px' }}
                    />
                </button>

                {stylesMenu &&
                    <div className="shared-menu animations">
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectPadding(0)}
                        >
                            0
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectPadding(1)}
                        >
                            1
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectPadding(2)}
                        >
                            2
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectPadding(3)}
                        >
                            3
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectPadding(4)}
                        >
                            4
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectPadding(5)}
                        >
                            5
                        </button>
                    </div>
                }
            </Col>
        </Row>
    );
};

export default ModuleStyles;