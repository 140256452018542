export const getRequestHeader = (withAccessToken = true) => (withAccessToken ? {
    headers: {
        'x-access-token': localStorage.getItem('x-access-token'),
        'Content-Type': 'application/json',
    },
} : {
    headers: {
        'Content-Type': 'application/json',
    },
});

export const getMultipartRequestHeader = (withAccessToken = true) => (withAccessToken ? {
    headers: {
        'x-access-token': localStorage.getItem('x-access-token'),
        'Content-type': 'multipart/form-data',
    },
} : {
    headers: {
        'Content-type': 'multipart/form-data',
    },
});
