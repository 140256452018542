import {
    getCampaignsForCurrentUser,
    getAnalyticsForCampaign,
    getEngagementsByGender,
    getEngagementsByCity,
    getEngagementsByOperatingSystem,
    getEngagementsByDeviceCategory,
    archiveCampaign,
    restoreCampaign,
    postCampaign,
    updateCampaign,
    updateModuleStyles,
    duplicateCampaign,
    permanentlyArchiveCampaign,
} from '../../services/campaign';
import {
    getCampaignAnalyticsSuccess,
    getEngagementsByCitySuccess,
    getEngagementsByGenderSuccess,
    getEngagementsByDeviceCategorySuccess,
    getCampaignSuccess,
    resetAnalyticsSuccess,
    archiveCampaignSuccess,
    restoreCampaignSuccess,
    getArchivedCampaignsSuccess,
    createCampaignSuccess,
    editCampaignSuccess,
    editModuleStylesSuccess,
    duplicateCampaignSuccess,
    deleteCampaignSuccess,
} from './actionCreators';

export function getCampaigns(filter) {
    return function (dispatch) {
        return getCampaignsForCurrentUser(filter)
            .then(response => {
                dispatch(getCampaignSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function getAnalytics(id) {
    return function (dispatch) {
        return getAnalyticsForCampaign(id)
            .then(response => {
                dispatch(getCampaignAnalyticsSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function fetchEngagementsByGender(id) {
    return function (dispatch) {
        return getEngagementsByGender(id)
            .then(response => {
                dispatch(getEngagementsByGenderSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function fetchEngagementsByCity(id) {
    return function (dispatch) {
        return getEngagementsByCity(id)
            .then(response => {
                dispatch(getEngagementsByCitySuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function fetchEngagementsByOperatingSystem(id) {
    return function (dispatch) {
        return getEngagementsByOperatingSystem(id)
            .then(response => {
                dispatch(getEngagementsByGenderSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function fetchEngagementsByDeviceCategory(id) {
    return function (dispatch) {
        return getEngagementsByDeviceCategory(id)
            .then(response => {
                dispatch(getEngagementsByDeviceCategorySuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function resetAnalytics() {
    return function (dispatch) {
        dispatch(resetAnalyticsSuccess());
    };
}

export function archiveSelectedCampaign(campaign) {
    return function (dispatch) {
        return archiveCampaign(campaign.id)
            .then((response) => {
                dispatch(archiveCampaignSuccess(campaign));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function restoreSelectedCampaign(campaign) {
    return function (dispatch) {
        return restoreCampaign(campaign.id)
            .then((response) => {
                dispatch(restoreCampaignSuccess(campaign));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function getArchivedCampaigns(filter) {
    return function (dispatch) {
        return getCampaignsForCurrentUser(filter)
            .then((response) => {
                dispatch(getArchivedCampaignsSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function createCampaign(campaign) {
    return function (dispatch) {
        return postCampaign(campaign)
            .then((response) => {
                dispatch(createCampaignSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function duplicateCampaignTemplate(campaign) {
    return function (dispatch) {
        return duplicateCampaign(campaign)
            .then((response) => {
                dispatch(duplicateCampaignSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function editCampaign(campaign) {
    return function (dispatch) {
        return updateCampaign(campaign)
            .then((response) => {
                dispatch(editCampaignSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function editModuleStyles(campaign) {
    return function (dispatch) {
        return updateModuleStyles(campaign)
            .then((response) => {
                dispatch(editModuleStylesSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function deleteCampaign(id) {
    return function (dispatch) {
        return permanentlyArchiveCampaign(id)
            .then((response) => {
                dispatch(deleteCampaignSuccess());
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}