import * as constants from './constants';
import initialState from '../initialState';

export default function reducer(state = initialState.user, action = {}) {
    switch (action.type) {
        case constants.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
            };
        case constants.GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.user,
            };
        case constants.GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUser: action.payload.user,
            };
        case constants.ADD_USER_SUCCESS:
            return {
                ...state,
                users: [...state.users, action.payload],
            };
        case constants.EDIT_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map((user) => {
                    if (user.id === action.payload.user.id) {
                        return {
                            ...user,
                            first_name: action.payload.user.first_name,
                            last_name: action.payload.user.last_name,
                            email: action.payload.user.email,
                            password: action.payload.user.password,
                            role: action.payload.user.role,
                            profile_picture: action.payload.user.profile_picture,
                        };
                    }
                    return user;
                }),
                currentUser: {
                    ...state.currentUser,
                    ...action.payload.user,
                },
            };
        case constants.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user._id !== action.payload.userId),
            };
        case constants.GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: action.payload.roles,
            };
        default:
            return state;
    }
}
