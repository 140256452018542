import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import { SketchPicker } from 'react-color';

const ColorPicker = ({
    heading, // optional
    colorProp, // optional
    parentColor = null, // optional
    handleParentColorChange = undefined, // optional
    setSelectedMessage,
    disableAlpha = false, // optional
    coverStyles = {}, // optional
    buttonPopoverStyles = {}, // optional
    moduleStyles,
    setModuleStyles,
}) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false); // For controlled ColorPicker open/close state managed in parent
    const [color, setColor] = useState(parentColor || '#DDDDDD');
    const [previousColor, setPreviousColor] = useState(parentColor || '#DDDDDD');

    const MAX_COLORS = 8;
    const [limitReached, setLimitReached] = useState(false);
    useEffect(() => {
        setLimitReached(moduleStyles && moduleStyles.colorPalette && moduleStyles.colorPalette.colors.length >= MAX_COLORS);
    }, [displayColorPicker]);

    const [swatchInFocus, setSwatchInFocus] = useState(-1);

    const swatchRefs = [];
    for (let i = 0; i < MAX_COLORS; i++) {
        swatchRefs.push(useRef(null));
    }

    useEffect(() => {
        if (swatchRefs[swatchInFocus] && swatchRefs[swatchInFocus].current) {
            swatchRefs[swatchInFocus].current.focus();
        }
    }, [swatchInFocus]);

    const handleOpenCloseColorPicker = (e) => {
        e.preventDefault();
        setDisplayColorPicker(!displayColorPicker);

        if (!displayColorPicker) {
            // ON OPEN COLOR PICKER
            setPreviousColor(color);
            setLimitReached(false);
        } else if (handleParentColorChange) {
            // ON CLOSE COLOR PICKER
            handleParentColorChange(previousColor);
        } else {
            // ON CLOSE COLOR PICKER
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        [colorProp]: previousColor,
                    },
                },
            }));
        }
        setPreviousColor(parentColor);
    };

    const handleColorChange = (colorPassed) => {
        setSwatchInFocus(-1);

        if (moduleStyles.colorPalette.colors.length > MAX_COLORS - 1) {
            setLimitReached(true);
        }

        if (handleParentColorChange) {
            handleParentColorChange(colorPassed);
        } else {
            const decimalToHex = alpha => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));

            let hexCode = `${colorPassed.hex}${decimalToHex(colorPassed.rgb.a)}`;
            const isHexCorrect = /^#[0-9A-F]{8}$/i.test(hexCode);
            if (!isHexCorrect) {
                hexCode = `${hexCode.toString().substring(0, 7)}00`;
            }
            setColor(hexCode);
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        [colorProp]: hexCode,
                    },
                },
            }));
        }
    };

    const handleColorSwatch = (color, index) => {
        setSwatchInFocus(index);

        if (handleParentColorChange) {
            handleParentColorChange(color);
        } else {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        [colorProp]: color,
                    },
                },
            }));
        }
    };

    const handleSaveColor = () => {
        if (!moduleStyles.colorPalette) {
            setSwatchInFocus(0);

            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                colorPalette: {
                    colors: [color],
                },
            }));
        } else if (moduleStyles.colorPalette.colors.length < MAX_COLORS) {
            setSwatchInFocus(moduleStyles.colorPalette.colors.length);

            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                colorPalette: {
                    ...moduleStyles.colorPalette,
                    colors: [...moduleStyles.colorPalette.colors, parentColor],
                },
            }));

            if (moduleStyles.colorPalette.colors.length >= MAX_COLORS - 1) {
                setLimitReached(true);
            }
        }
    };

    const handleDeleteColor = () => {
        if (swatchInFocus > -1) {
            const lowerHalf = moduleStyles.colorPalette.colors.slice(0, swatchInFocus);
            const upperHalf = moduleStyles.colorPalette.colors.slice(swatchInFocus + 1);
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                colorPalette: {
                    ...moduleStyles.colorPalette,
                    colors: [...lowerHalf, ...upperHalf],
                },
            }));
        }
        setSwatchInFocus(-1);
        setLimitReached(false);
    };

    return (
        <Row className="mt-1 mb-3 mx-0 p-0">
            <Col className="m-0 p-0">
                {heading &&
                    <div className="shared-subheading">
                        {heading}
                    </div>
                }

                <button
                    className="color-picker-button"
                    type="submit"
                    onClick={e => handleOpenCloseColorPicker(e)}
                >
                    <div
                        className="color-picker-swatch"
                        style={{ backgroundColor: parentColor || '#DDDDDD' }}
                    />
                    <div className="color-picker-label">
                        {parentColor || color}
                    </div>
                </button>

                {displayColorPicker &&
                    <div
                        className="color-picker-popover"
                        style={{ ...buttonPopoverStyles }}
                    >
                        <div
                            className="cover"
                            style={{ ...coverStyles }}
                        >
                            <div className="swatch-container">
                                {moduleStyles.colorPalette && moduleStyles.colorPalette.colors.map((color, index) => (
                                    <button
                                        className="swatch"
                                        type="button"
                                        ref={swatchRefs[index]}
                                        onClick={() => handleColorSwatch(color, index)}
                                        style={{ backgroundColor: color }}
                                    />
                                ))}
                            </div>

                            <div className="controls">
                                <div className="button-container">
                                    <button type="button" onClick={() => setDisplayColorPicker(false)}>
                                        Set As Color
                                    </button>
                                </div>
                                <div className="button-container">
                                    {!limitReached &&
                                        <button type="button" onClick={() => handleSaveColor()}>
                                            Save Color
                                        </button>
                                    }
                                </div>
                                <div className="button-container">
                                    {swatchInFocus > -1 &&
                                        <button type="button" onClick={() => handleDeleteColor()}>
                                            Delete Color
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>

                        <SketchPicker
                            color={parentColor || color}
                            onChange={handleParentColorChange || handleColorChange}
                            disableAlpha={disableAlpha}
                            presetColors={[]}
                        />
                    </div>
                }
            </Col>
        </Row>
    );
};

export default ColorPicker;