import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const MapQuest = ({ textColor = 'FFF', apiKey = process.env.REACT_APP_MAP_QUEST_API_KEY, mapHeight = 1000, mapWidth = 500,
    coordinates, mapZoom = 12, mapType = '', markerType = 'marker' }) => {
    const [status, setStatus] = useState('loading');
    const [mapData, setMapData] = useState({});

    const mapURL = `https://www.mapquestapi.com/staticmap/v5/map?locations=${coordinates
    }&zoom=${mapZoom}&type=${mapType}&size=${mapHeight},${mapWidth}@2x&defaultMarker=${markerType}&key=${apiKey}`;

    useEffect(() => {
        Axios.get(mapURL)
            .then(res => {
                setMapData(res.data);
                setStatus('ready');
                if (mapData === {}) {
                    setStatus('blank');
                }
            });
    }, []);

    // Uncomment To Debug
    /*
    useEffect(() => {
        console.log(`Status Change: ${status}`);
        if (status === 'ready') {
            console.log('Map is Loaded');
        } else if (status === 'blank') {
            console.log('No Map Loaded!');
        }
    }, [status]);
    */

    return (
        <div>
            {mapData && status === 'ready' ?
                <>
                    <div>
                        <a href={mapURL} rel="noopener noreferrer" target="_blank">
                            <img alt="mapquest map" className="w-100 image-module" src={mapURL}/>
                        </a>
                    </div>
                </> :
                <>
                    <div style={{ backgroundColor: textColor }} className="chat-bubble bold">
                        <div className="m-0 text-left">
                            Loading...
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
export default MapQuest;
