import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import auth from './auth/reducer';
import user from './user/reducer';
import language from './languages/reducer';
import campaigns from './campaign/reducer';
import shared from './shared/reducer';

const rootReducer = combineReducers({
    language,
    user,
    auth,
    campaigns,
    shared,
    toastr: toastrReducer,
});

export default rootReducer;