import React from 'react';
import { MdClose } from 'react-icons/md';
import { FaCookie } from 'react-icons/fa';

const CookieInfoModal = ({ title, text, onCloseEvent, children }) => (
    <div className="cookie-info-modal">
        <div className="cookie-info-modal-card">
            <div className="modal-header">
                <h5 className="modal-title">{title}</h5>
                <MdClose
                    className="close"
                    size={22}
                    onClick={() => onCloseEvent()}
                />
            </div>
            <div className="cookie-modal-icon">
                <FaCookie size="70px" color="#777"/>
            </div>
            <div className="modal-text">
                {text}
            </div>
            {children}
        </div>
    </div>
);

export default CookieInfoModal;