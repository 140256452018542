import React, { useEffect, useState } from 'react';
import ChatbotLayout from './ChatbotLayout';
import cointreauAvatar from '../../assets/images/cointreau-avatar.png';
import cointreauLogo from '../../assets/images/cointreau-logo.png';
import cointreauAgeGateLogo from '../../assets/images/cointreau-logo-circle.png';
import holidayMargarita from '../../assets/images/holiday-margarita.png';
import holidaySidecar from '../../assets/images/holiday-sidecar.png';
import winterSangria from '../../assets/images/winter-sangria.png';
import MessageModule from './MessageModule';
import Typing from './Typing';
import { MODULE_TYPES } from '../Modules/constants';

const ChatbotCointreau = () => {
    const [showTyping, setShowTyping] = useState(false);
    const [showChatTree, setShowChatTree] = useState(true);
    const [messages, setMessages] = useState([]);
    const [campaign, setCampaign] = useState({});

    const testCampaign = {
        color: '#E55F33',
        logo: cointreauLogo,
        avatar: cointreauAvatar,
        ageRestriction: false,
        minAgeRequired: 21,
        ageGateLogo: cointreauAgeGateLogo,
    };

    const message1 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Hi there, welcome to the Art of the mix by Cointreau!',
            showAvatar: true,
        },
    };
    const message2 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'We have 3 selected cocktails made for the season by master mixologist Dan Binrod. Let us know which ' +
                'one you would like to start with:',
            showAvatar: false,
        },
    };
    const message3 = {
        type: MODULE_TYPES.ListItemGroup,
        props: {
            listItems: [{
                title: 'THE HOLIDAY MARGARITA',
                image_url: holidayMargarita,
                onClickModule: {
                    type: MODULE_TYPES.Shop,
                    props: {
                        url: 'The holiday margarita',
                        video_url: 'https://s3.us-east-2.amazonaws.com/brandchat.ai/MakeMargarita.mp4',
                        nextModuleProps: {
                            url: 'sidecar.com',
                            video_url: 'https://s3.us-east-2.amazonaws.com/brandchat.ai/MakeMargarita.mp4',
                        },
                    },
                },
            }, {
                title: 'HOLIDAY SIDECAR',
                image_url: holidaySidecar,
                onClickModule: {
                    type: MODULE_TYPES.Shop,
                    props: {
                        url: 'Holiday sidecar',
                        video_url: 'https://s3.us-east-2.amazonaws.com/brandchat.ai/MakeMargarita.mp4',
                        nextModuleProps: {
                            url: 'winter.com',
                            video_url: 'winter.com',
                        },
                    },
                },
            }, {
                title: 'WINTER SANGRIA',
                image_url: winterSangria,
                onClickModule: {
                    type: MODULE_TYPES.Shop,
                    props: {
                        url: 'Winter sangria',
                        video_url: 'https://s3.us-east-2.amazonaws.com/brandchat.ai/MakeMargarita.mp4',
                    },
                },
            }],
        },
    };

    const getCampaign = () => {
        // TODO Fetch data here
        setCampaign(testCampaign);
    };

    const loadChatTree = () => {
        setShowChatTree(true);
    };

    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };

    const renderMessage = (message) => <MessageModule
        key={JSON.stringify(message)}
        setMessages={setMessages}
        message={message}
        avatar={message.props.showAvatar && campaign.avatar}
    />;

    const populateBubbles = () => {
        setTimeout(() => {
            setShowTyping(false);
            addMessage(message1);
        }, 5600);

        setTimeout(() => {
            setShowTyping(false);
            addMessage(message2);
        }, 6500);

        setTimeout(() => {
            setShowTyping(false);
            addMessage(message3);
        }, 6900);
    };

    useEffect(() => {
        setShowTyping(true);
        getCampaign();
    }, []);

    useEffect(() => {
        if (showChatTree) {
            populateBubbles();
        }
    }, [showChatTree]);

    return (
        <ChatbotLayout campaign={campaign} loadChatTree={loadChatTree}>
            {messages.map((message) => renderMessage(message))}
            {showTyping && <Typing avatar={campaign.avatar}/>}
        </ChatbotLayout>
    );
};

export default ChatbotCointreau;