/* eslint-disable camelcase */
import React, { useState } from 'react';
import Axios from 'axios';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const QuickReply = ({
    campaign,
    node_branches,
    moduleIndex,
    groupHeading,
    unansweredButtonColor,
    unansweredTextColor,
    unansweredIconColor,
    unansweredIconTextColor,
    unansweredBorderColor,
    answeredButtonColor,
    answeredTextColor,
    answeredIconColor,
    answeredIconTextColor,
    answeredBorderColor,
    buttonBorderRadius,
    replyBackgroundColor,
    replyBorderColor,
    isStacked,
    isFilled,
    isButtonGroup,
    hasButtonShadow,
    hasBackgroundShadow,
    handleNodeBranching,
    loadWallpaperImage,
    chatbotActive,
    showPreview,
    numberOfTimesRendered,
    hasBeenClicked,
    answeredIndex,
    setAnsweredIndex,
    userId,
}) => {
    const [hoverIndex, setHoverIndex] = useState(-1);

    const logUserClick = (nodeBranch, index) => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campaignURL = window.location.href;
        // console.log('testTitle\n', testTitle);

        // console.log('Campaign REF FOUND\n', campaign.name);
        // console.log('Campaign REF FOUND\n', campaign.ref);

        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campaignURL;
        userDataObject['Title Clicked'] = nodeBranch.title || 'No Title';
        userDataObject['ExternalLink Clicked'] = nodeBranch.externalURL || 'No Link';
        userDataObject.ModuleIdentifier = nodeBranch.moduleIdentifier || 'No Identifier';
        userDataObject['Image Clicked'] = nodeBranch.image_url || 'No Image URL';
        userDataObject['Button Index'] = index || 'Nil';
        userDataObject.TypeOfClick = nodeBranch.externalURL ? 'ButtonExternalLinkClick' : 'ButtonClick';

        userAnalytics.push(userDataObject);

        // console.log('Campaign Name\n', campaignName);
        // console.log('userData\n', JSON.stringify(userInput.userData));
        // console.log({ userDataObject });

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!:', response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleClick = (nodeBranch, index) => {
        if (!chatbotActive) { return; }

        if (!campaign.demo && !showPreview) {
            logUserClick(nodeBranch, index);
        }

        if (nodeBranch.externalURL) {
            window.open(nodeBranch.externalURL, 'Redirect');
            // if (nodeBranch.externalURL.includes('http://') || nodeBranch.externalURL.includes('https://')) {
            //     window.open(nodeBranch.externalURL, 'Quick Replies Redirect');
            // } else {
            //     window.open(`https://${nodeBranch.externalURL}`, 'Quick Replies Redirect');
            // }
        }

        if (nodeBranch.horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl: nodeBranch.horizontalImageUrl,
                horizontalBgSize: nodeBranch.horizontalBgSize,
                horizontalBgRepeat: nodeBranch.horizontalBgRepeat,
                wallpaperExternalLinkUrl: nodeBranch.externalURL,
            });
        }

        if (nodeBranch.payload && !hasBeenClicked) {
            handleNodeBranching(nodeBranch, moduleIndex);
            if (!nodeBranch.isTriggerModuleRemoved) {
                setAnsweredIndex(moduleIndex, index);
            }
        }
    };

    const unansweredButtonStyles = {
        borderColor: `${unansweredBorderColor}`,
        borderRadius: `${buttonBorderRadius}px`,
        backgroundColor: `${unansweredButtonColor}`,
        color: `${unansweredTextColor}`,
        boxShadow: hasButtonShadow ? '2px 2px 5px 0px rgba(0,0,0,0.2)' : 'none',
    };

    const unansweredIconStyles = {
        backgroundColor: `${unansweredIconColor}`,
        color: `${unansweredIconTextColor}`,
    };

    const answeredButtonStyles = {
        float: `${(numberOfTimesRendered > 1) ? 'right' : 'none'}`,
        borderColor: `${answeredBorderColor}`,
        borderRadius: `${buttonBorderRadius}px`,
        backgroundColor: `${answeredButtonColor}`,
        color: `${answeredTextColor}`,
        boxShadow: hasButtonShadow ? '2px 2px 5px 0px rgba(0,0,0,0.2)' : 'none',
    };

    const answeredIconStyles = {
        backgroundColor: `${answeredIconColor}`,
        color: `${answeredIconTextColor}`,
    };

    const notBeenClickedStyles = {
        border: `0.5px solid ${replyBorderColor}`,
        backgroundColor: replyBackgroundColor,
    };

    const hasBeenClickedStyles = {
        backgroundColor: 'transparent',
        justifyContent: 'flex-end',
    };

    const withIconStyles = {
        marginLeft: '11px',
        width: 'calc(100% - 27px)',
        justifyContent: 'start',
        fontFamily: '"Raleway", sans-serif',
        fontSize: '13.5px',
        fontWeight: '500',
    };

    const noIconStyles = {
        marginLeft: '0',
        width: '100%',
        justifyContent: 'center',
    };

    const QuickReplyButtons = () => {
        // ANSWERED BUTTON
        if (hasBeenClicked) {
            const nodeBranch = node_branches[answeredIndex];
            return (
                <div className={`quick-reply-buttons-container ${isFilled ? 'filled-width-button' : ''}`}>
                    <Button
                        className="quick-reply-button answered"
                        style={answeredButtonStyles}
                        onClick={() => Function.prototype()}
                    >
                        <div className="quick-reply-content">
                            {nodeBranch.icon &&
                                <div
                                    className="quick-reply-icon"
                                    style={answeredIconStyles}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: nodeBranch.icon }}/>
                                </div>
                            }
                            {nodeBranch.title &&
                                <div
                                    className="quick-reply-title"
                                    style={nodeBranch.icon ? withIconStyles : noIconStyles}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: nodeBranch.title }}/>
                                </div>
                            }
                        </div>
                    </Button>
                </div>
            );
        }

        // UNANSWERED BUTTONS
        return (
            <>
                {node_branches && node_branches.map((nodeBranch, index) => (
                    <div
                        className={`quick-reply-buttons-container ${isFilled ? 'filled-width-button' : ''}`}
                        key={`${nodeBranch.title}${nodeBranch.image_url}${index}`}
                        onMouseEnter={() => setHoverIndex(index)}
                        onMouseLeave={() => setHoverIndex(-1)}
                    >
                        <Button
                            className="quick-reply-button unanswered"
                            onClick={() => handleClick(nodeBranch, index)}
                            style={hoverIndex === index ? answeredButtonStyles : unansweredButtonStyles}
                        >
                            <div className="quick-reply-content">
                                {nodeBranch.icon &&
                                    <div
                                        className="quick-reply-icon"
                                        style={hoverIndex === index ? answeredIconStyles : unansweredIconStyles}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: nodeBranch.icon }}/>
                                    </div>
                                }
                                {nodeBranch.title &&
                                    <div
                                        className="quick-reply-title"
                                        style={nodeBranch.icon ? withIconStyles : noIconStyles}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: nodeBranch.title }}/>
                                    </div>
                                }
                            </div>
                        </Button>
                    </div>
                ))}
            </>
        );
    };

    const ButtonGroup = () => (
        <>
            {node_branches && node_branches.map((nodeBranch, index) => (
                <div
                    className={`quick-reply-buttons-container ${isFilled ? 'filled-width-button' : ''}`}
                    key={`${nodeBranch.title}${nodeBranch.image_url}${index}`}
                    onMouseEnter={() => !hasBeenClicked && setHoverIndex(index)}
                    onMouseLeave={() => !hasBeenClicked && setHoverIndex(-1)}
                >
                    <Button
                        className="quick-reply-button answered"
                        onClick={() => handleClick(nodeBranch, index)}
                        style={index === hoverIndex || index === answeredIndex ? answeredButtonStyles : unansweredButtonStyles}
                    >
                        <div className="quick-reply-content">
                            {nodeBranch.icon &&
                                <div
                                    className="quick-reply-icon"
                                    style={index === hoverIndex || index === answeredIndex ? answeredIconStyles : unansweredIconStyles}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: nodeBranch.icon }}/>
                                </div>
                            }
                            {nodeBranch.title &&
                                <div
                                    className="quick-reply-title"
                                    style={nodeBranch.icon ? withIconStyles : noIconStyles}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: nodeBranch.title }}/>
                                </div>
                            }
                        </div>
                    </Button>
                </div>
            ))}
        </>
    );

    return (
        <Row className="quick-reply-row px-3">
            <Col
                className={`quick-reply-background
                    ${hasBeenClicked && !isButtonGroup ? 'answered' : ''}
                    ${isStacked ? 'stacked-buttons' : ''}
                    ${!hasBeenClicked && hasBackgroundShadow ? 'box-shadow' : ''}
                `}
                style={hasBeenClicked && !isButtonGroup ? hasBeenClickedStyles : notBeenClickedStyles}
            >
                {groupHeading && <h6 style={{ color: unansweredTextColor }}>{groupHeading}</h6>}

                {isButtonGroup ? <ButtonGroup/> : <QuickReplyButtons/>}
            </Col>
        </Row>
    );
};

export default QuickReply;