import React, { useEffect, useState } from 'react';
import { ImageSizing } from '.';

const BackgroundImageSizing = ({
    bgSize,
    bgRepeat,
    campaignData,
    setCampaignStyles,
}) => {
    const [stylesBgSize, setStylesBgSize] = useState(null);
    const [stylesBgRepeat, setStylesBgRepeat] = useState(null);
    useEffect(() => {
        setStylesBgSize(campaignData.styles && campaignData.styles[bgSize]);
        setStylesBgRepeat(campaignData.styles && campaignData.styles[bgRepeat]);
    }, [campaignData]);

    const setCoverValues = (value) => {
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            [bgSize]: `${value}% auto`,
            [bgRepeat]: 'repeat',
        }));
    };

    const setButtonValues = (size, repeat) => {
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            [bgSize]: size,
            [bgRepeat]: repeat,
        }));
    };

    return (
        <>
            <ImageSizing
                stylesBgSize={stylesBgSize}
                stylesBgRepeat={stylesBgRepeat}
                setCoverValues={setCoverValues}
                setButtonValues={setButtonValues}
            />
        </>
    );
};

export default BackgroundImageSizing;