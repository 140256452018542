export const checkIfEmailIsValid = (email) => {
    // eslint-disable-next-line max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
};

export const checkIfNameIsValid = (name) => {
    const regex = /^[a-zA-Z]*$/;
    return regex.test(name);
};

export const checkIfUrlIsValid = (name) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return regex.test(name);
};

export const checkIfImageUrlValid = (url) => {
    const regex = /(http(s?):)([/|.|\w%|\s|-])*\.(?:jpg|jpeg|gif|png)/;
    return regex.test(url);
};

export const checkIfNumberIsValid = (number) => {
    const regex = /^(\(?\+?[0-9]*\)?)?[0-9_\- ()]*$/;
    return regex.test(number);
};

export const getImageFileName = (imageUrl) => {
    const regex = /\.\w{4}-/i; // ex: https://url.com/img/%3A58.539Z-example.png
    const result = regex.exec(imageUrl);
    if (result) {
        return imageUrl.slice(result.index + 6);
    }
    return '';
};

export const convertSpaceToUnderscore = (name) => name.replace(/ /g, '_').toLowerCase();
