import * as constants from './constants';

export function loginStarted() {
    return {
        type: constants.LOGIN_USER_STARTED,
    };
}

export function loginSuccess(loggedUser) {
    return {
        type: constants.LOGIN_USER_SUCCESS,
        payload: {
            loggedUser,
        },
    };
}

export function loginFail() {
    return {
        type: constants.LOGIN_USER_FAIL,
    };
}

export function logoutSuccess() {
    return {
        type: constants.LOGOUT_USER_SUCCESS,
        payload: {
            loggedUser: null,
        },
    };
}

export function registerSuccess(data) {
    return {
        type: constants.REGISTER_USER_SUCCESS,
        payload: {
            data,
        },
    };
}

export function registerFail() {
    return {
        type: constants.REGISTER_USER_FAIL,
    };
}
