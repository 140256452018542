import React from 'react';

const Text = ({
    text,
    textColor,
    containerClassName = '',
    textClassName = '',
    waterfallLayout,
}) => (
    <div
        className={`chat-bubble bold ${containerClassName} ${waterfallLayout ? 'w-100' : ''}`}
        style={{ backgroundColor: textColor }}
    >
        <div
            className={`m-0 text-left ${textClassName}`}
            dangerouslySetInnerHTML={{ __html: text }}
        />
    </div>
);

export default Text;
