import React from 'react';
import cloneDeep from 'lodash.clonedeep';
import { Col, Row } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { MODULE_TYPES } from './constants';
import Text from './Text';
import butterfingerCoupon from '../../assets/images/butterfingerCoupon.png';

const Template = ({ title, answers, answered, setMessages }) => {
    const message18 = {
        type: MODULE_TYPES.Modal,
        props: {
            title: 'Open your Walmart Coupon',
            header: '',
            image: butterfingerCoupon,
            showAvatar: true,
        },
    };

    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };

    const handleReply = (answer) => {
        console.log('API request:', answer);

        const replyMessage = {
            type: MODULE_TYPES.Template,
            props: {
                title,
                answers: [answer],
                answered: true,
            },
        };

        setMessages(messageHistory => {
            const messageHistoryReply = cloneDeep(messageHistory);
            messageHistoryReply[messageHistory.length - 1] = replyMessage;
            return messageHistoryReply;
        });

        addMessage(message18);
    };

    return (
        <>
            <Row>
                <Col>
                    <Text containerClassName="no-bottom-rounding" text={title}/>
                </Col>
            </Row>
            {answers && answers.map((answer, index) => (
                <Row
                    className={`template-answer ${index === answers.length - 1 ? 'last' : ''}`}
                    key={`${answer.title}${answer.image_url}`}
                >
                    <Col xs="auto" className="w-100">
                        <Button
                            className={`answer-button w-100 ${answered ? 'answered' : 'unanswered'}`}
                            onClick={() => !answered && handleReply(answer)}
                        >
                            <Row>
                                {answer.image_url &&
                                    <Col className="m-auto">
                                        <img
                                            alt={`quick-reply-${index}`}
                                            src={answer.image_url}
                                            className="answer-img"
                                        />
                                    </Col>
                                }
                                {answer.title &&
                                    <Col className="m-auto">
                                        <p className="m-0">{answer.title}</p>
                                    </Col>
                                }
                            </Row>
                        </Button>
                    </Col>
                </Row>
            ))}
        </>
    );
};

export default Template;