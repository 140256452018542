import Axios from 'axios';
import { API_URL } from '../../config';
import {
    getUserSuccess,
    getUsersSuccess,
    deleteUserSuccess,
    addUserSuccess,
    editUserSuccess,
    getCurrentUserDataSuccess,
    getRolesSuccess,
} from './actionCreators';
import {
    createUserByAdmin,
    currentUserEdit,
    deleteUserById, passwordUpdate,
    profilePictureUpdate,
    retrieveCurrentUserData,
    retrieveRoles,
    retrieveUserByEmail,
    retrieveUsers,
    userEdit,
} from '../../services/user';

export function inviteUsers(data) {
    return function () {
        const requestOptions = {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': localStorage.getItem('x-access-token'),
            },
        };

        return Axios.post(`${API_URL}/api/mail/send-registration-invitation`, JSON.stringify(data), requestOptions)
            .catch(err => {
                throw err;
            });
    };
}

export function getUserByEmail(email) {
    return function (dispatch) {
        return retrieveUserByEmail(email)
            .then((response) => {
                dispatch(getUserSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function getCurrentUserData() {
    return function (dispatch) {
        return retrieveCurrentUserData()
            .then((response) => {
                dispatch(getCurrentUserDataSuccess(response.data));
                return response;
            })
            .catch((err) => {
                throw err;
            });
    };
}

export function getUsers() {
    return function (dispatch) {
        return retrieveUsers()
            .then(response => {
                dispatch(getUsersSuccess(response.data));
                return response.data;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function deleteUser(userId) {
    return function (dispatch) {
        return deleteUserById(userId)
            .then(response => {
                dispatch(deleteUserSuccess(userId));
                return response;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function editUser(user) {
    return function (dispatch) {
        return userEdit(user)
            .then(response => {
                dispatch(editUserSuccess(response.data));
                return response;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function editCurrentUser(user) {
    return function (dispatch) {
        return currentUserEdit(user)
            .then(response => {
                dispatch(editUserSuccess(response.data));
                console.log({ response });
                return response;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function updateProfilePicture(profilePicture) {
    return function (dispatch) {
        return profilePictureUpdate(profilePicture)
            .then(response => {
                dispatch(editUserSuccess(response.data));
                return response;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function updatePassword(password) {
    return function (dispatch) {
        return passwordUpdate(password)
            .then(response => {
                dispatch(editUserSuccess(response.data));
                return response;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function createUser(user) {
    return function (dispatch) {
        return createUserByAdmin(user)
            .then(response => {
                dispatch(addUserSuccess(response.data));
                return response;
            })
            .catch(err => {
                throw err;
            });
    };
}

export function getRoles() {
    return function (dispatch) {
        return retrieveRoles()
            .then((response) => {
                dispatch(getRolesSuccess(response.data));
                return response.data;
            })
            .catch((err) => {
                throw err;
            });
    };
}
