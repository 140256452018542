import React from 'react';
import { Button } from 'reactstrap';
import { GrTree } from 'react-icons/gr';

const BranchingPath = ({
    answer,
    reference,
    configureNodeBranchingPath,
    id = null,
    disabled = false,
}) => (
    <>
        <Button
            className="btn-secondary-custom"
            onClick={() => configureNodeBranchingPath(answer, reference)}
            id={id}
            disabled={disabled}
        >
            <GrTree
                size="15px"
                style={{ margin: '0 15px 0 0' }}
            />
            Configure path...
        </Button>
    </>
);

export default BranchingPath;

