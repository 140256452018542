import * as constants from './constants';
import initialState from '../initialState';

export default function reducer(state = initialState.auth, action = {}) {
    switch (action.type) {
        case constants.LOGIN_USER_STARTED:
            return {
                ...state,
                started: true,
            };
        case constants.LOGIN_USER_SUCCESS:
            return {
                ...state,
                started: false,
                loggedUser: action.payload.loggedUser,
            };
        case constants.LOGIN_USER_FAIL:
            return {
                ...state,
                started: false,
            };
        case constants.LOGOUT_USER_SUCCESS:
            return {
                ...state,
                loggedUser: action.payload.loggedUser,
            };
        case constants.REGISTER_USER_SUCCESS:
            return {
                ...state,
                status: action.payload.data.status,
            };
        case constants.REGISTER_USER_FAIL:
            return state;
        default:
            return state;
    }
}
