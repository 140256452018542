import React from 'react';
import Axios from 'axios';
import { Button } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { detect } from 'detect-browser';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const ButtonReply = ({
    campaign,
    // eslint-disable-next-line camelcase
    node_branches: nodeBranches,
    moduleIndex,
    buttonStyles,
    unansweredButtonColor,
    unansweredTextColor,
    answeredButtonColor,
    answeredTextColor,
    replyBackgroundColor,
    replyBorderColor = '#DDDDDD',
    handleNodeBranching,
    loadWallpaperImage,
    isHistory,
    chatbotActive,
    showPreview,
    numberOfTimesRendered,
    hasBeenClicked,
    answeredIndex,
    setAnsweredIndex,
    userId,
}) => {
    const isSafari = detect().name === 'safari';
    const isStacked = buttonStyles ? buttonStyles.isStacked : false;
    const isFilled = buttonStyles ? buttonStyles.isFilled : false;

    const logUserClick = (nodeBranch, index) => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campaignURL = window.location.href;

        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campaignURL;
        userDataObject['Title Clicked'] = nodeBranch.title || 'No Title';
        userDataObject['ExternalLink Clicked'] = nodeBranch.externalURL || 'No Link';
        userDataObject.ModuleIdentifier = nodeBranch.moduleIdentifier || 'No Identifier';
        userDataObject['Image Clicked'] = nodeBranch.image_url || 'No Image URL';
        userDataObject['Button Index'] = index || 'Nil';
        userDataObject.TypeOfClick = nodeBranch.externalURL ? 'ButtonExternalLinkClick' : 'ButtonClick';

        userAnalytics.push(userDataObject);

        // console.log('Campaign Name\n', campaignName);
        // console.log('userData\n', JSON.stringify(userInput.userData));
        // console.log({ userDataObject });

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!:', response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleClick = (nodeBranch, index) => {
        if (!chatbotActive) { return; }

        if (!campaign.demo && !showPreview) {
            logUserClick(nodeBranch, index);
        }

        if (nodeBranch.externalURL) {
            window.open(nodeBranch.externalURL, 'Redirect');
            // if (nodeBranch.externalURL.includes('http://') || nodeBranch.externalURL.includes('https://')) {
            //     window.open(nodeBranch.externalURL, 'Quick Replies Redirect');
            // } else {
            //     window.open(`https://${nodeBranch.externalURL}`, 'Quick Replies Redirect');
            // }
        }

        if (nodeBranch.horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl: nodeBranch.horizontalImageUrl,
                horizontalBgSize: nodeBranch.horizontalBgSize,
                horizontalBgRepeat: nodeBranch.horizontalBgRepeat,
                wallpaperExternalLinkUrl: nodeBranch.externalURL,
            });
        }

        if (nodeBranch.payload && !hasBeenClicked) {
            handleNodeBranching(nodeBranch, moduleIndex);
            if (!nodeBranch.isTriggerModuleRemoved) {
                setAnsweredIndex(moduleIndex, index);
            }
        }
    };

    const getAnimationCssClass = (title) => {
        if (numberOfTimesRendered > 1) {
            return;
        }

        if (isHistory) {
            return 'float-right';
        }

        const titleLength = title.length;
        let resolvedCssClass;
        if (titleLength <= 4) {
            resolvedCssClass = 'short-animation';
        }

        // && titleLength < 25
        if (titleLength > 4) {
            resolvedCssClass = 'long-animation';
        }

        return isSafari ? `${resolvedCssClass}-safari` : resolvedCssClass;
    };

    const unansweredStyles = {
        backgroundColor: `${unansweredButtonColor}`,
        color: `${unansweredTextColor}`,
        borderColor: `${unansweredButtonColor}`,
        borderRadius: buttonStyles ? `${buttonStyles.borderRadius}px` : '0px',
    };

    const answeredStyles = {
        backgroundColor: `${answeredButtonColor}`,
        color: `${answeredTextColor}`,
        borderColor: `${answeredButtonColor}`,
        borderRadius: buttonStyles ? `${buttonStyles.borderRadius}px` : '0px',
        float: `${(numberOfTimesRendered > 1) ? 'right' : 'none'}`,
    };

    const notBeenClickedStyles = {
        border: `0.5px solid ${replyBorderColor}`,
        backgroundColor: replyBackgroundColor,
    };

    const hasBeenClickedStyles = {
        backgroundColor: 'transparent',
        justifyContent: 'flex-end',
    };

    function ButtonReplyButtons() {
        // ANSWERED BUTTON
        if (hasBeenClicked) {
            const nodeBranch = nodeBranches[answeredIndex];
            return (
                <Row className="m-0 p-0">
                    <Col
                        className={`quick-reply-col answered-col-width ${isFilled ? 'filled-width-button' : ''}`}
                        key={`${nodeBranch.title}${nodeBranch.image_url}${answeredIndex}`}
                    >
                        <Button
                            className={`quick-reply-button simple answered ${getAnimationCssClass(nodeBranch.title)}`}
                            style={answeredStyles}
                            onClick={() => Function.prototype()}
                        >
                            <Row>
                                {nodeBranch.image_url &&
                                    <Col className="m-auto">
                                        <img
                                            alt={`quick-reply-${answeredIndex}`}
                                            src={nodeBranch.image_url}
                                            className="quick-reply-img"
                                        />
                                    </Col>
                                }
                                {nodeBranch.title &&
                                    <Col className="m-auto">
                                        <p className="m-0">
                                            {nodeBranch.title}
                                        </p>
                                    </Col>
                                }
                            </Row>
                        </Button>
                    </Col>
                </Row>
            );
        }

        // UNANSWERED BUTTONS
        return (
            <>
                {nodeBranches && nodeBranches.map((nodeBranch, index) => (
                    <div
                        className={`quick-reply-buttons-container ${isFilled ? 'filled-width-button' : ''}`}
                        key={`${nodeBranch.title}${nodeBranch.image_url}${index}`}
                    >
                        <Button
                            className={`quick-reply-button simple unanswered
                                ${getAnimationCssClass(nodeBranch.title)}
                            `}
                            style={unansweredStyles}
                            onClick={() => handleClick(nodeBranch, index)}
                        >
                            <Row>
                                {nodeBranch.image_url &&
                                    <Col className="m-auto">
                                        <img
                                            alt={`quick-reply-${index}`}
                                            src={nodeBranch.image_url}
                                            className="quick-reply-img"
                                        />
                                    </Col>
                                }
                                {nodeBranch.title &&
                                    <Col className="m-auto">
                                        <p className="m-0">
                                            {nodeBranch.title}
                                        </p>
                                    </Col>
                                }
                            </Row>
                        </Button>
                    </div>
                ))}
            </>
        );
    }

    return (
        <Row className="quick-reply-row px-3">
            <Col
                className={`quick-reply-background ${isStacked ? 'stacked-buttons' : ''}`}
                style={hasBeenClicked ? hasBeenClickedStyles : notBeenClickedStyles}
            >
                <ButtonReplyButtons/>
            </Col>
        </Row>
    );
};

export default ButtonReply;