import React from 'react';
/* global navigator */

const ClipboardCopy = ({ text, onCopy, children }) => {
    const textToClipboard = async (text) => {
        if ('clipboard' in navigator) {
            return navigator.clipboard.writeText(text);
        }
        return document.execCommand('copy', true, text);
    };

    const handleCopyClick = () => {
        textToClipboard(text)
            .then(() => { onCopy(); })
            .catch((err) => { console.log(err); });
    };

    return (
        <button
            className="clipboard-copy-button"
            type="button"
            onClick={() => handleCopyClick()}
        >
            {children}
        </button>
    );
};

export default ClipboardCopy;