import React from 'react';
import { Col, Modal as ReactStrapModal, ModalBody, Row } from 'reactstrap';
import { FaFacebookMessenger } from 'react-icons/all';
import { Button, Image, Tab, Tabs } from 'react-bootstrap';
import closeIcon from '../../assets/images/close-icon.png';
import drizly from '../../assets/images/drizly-logo.png';
import amazonPrime from '../../assets/images/amazon-prime-logo.png';
import cointreauBottle from '../../assets/images/cointreau-bottle.jpeg';
import casamigosBottle from '../../assets/images/casamigos-bottle.jpg';
import reaLimeBottle from '../../assets/images/realime-bottle.jpg';
import { QUICK_ACTION_TYPE } from './constants';
import emailIcon from '../../assets/images/email-icon.png';
import messageIcon from '../../assets/images/message-icon.png';

const QuickActionModal = ({ url, type, setShowQuickActionModal }) => {
    const itemList = [
        {
            image: cointreauBottle,
            name: 'Cointreau',
            volume: '750ml',
            price: '$42.07',
        }, {
            image: casamigosBottle,
            name: 'Casamigos Blanco Tequila',
            volume: '750ml',
            price: '$39.99',
        }, {
            image: reaLimeBottle,
            name: 'ReaLime Lime Juice',
            volume: '8oz',
            price: '$9.99',
        },
    ];

    const ItemList = () => (
        <>
            {itemList && itemList.map(item => (
                <>
                    <Row className="item-list p-1 w-100 m-0 mt-1">
                        <Col>
                            <div className="h-100 d-flex align-items-center justify-content-center">
                                <Image src={item.image}/>
                            </div>
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <h6>{item.name}</h6>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h6>{item.volume}</h6>
                                </Col>
                            </Row>
                            <Row className="mt-1">
                                <Col>
                                    <h6>{item.price}</h6>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr className="m-1"/>
                </>
            ))}
            <Row className="mt-2 mb-1 pr-1">
                <Col className="w-100 text-center go-to-cart-col">
                    <Button>
                        <p className="m-0 p-1 go-to-cart">Go to cart</p>
                    </Button>
                </Col>
            </Row>
        </>
    );

    return (
        <div className="quick-action-modal-component">
            <ReactStrapModal
                isOpen
                size="lg"
                className="quick-action-modal-module"
                backdrop={false}
                toggle={() => setShowQuickActionModal(false)}
            >
                <div className="close-circle-container">
                    <Image
                        src={closeIcon}
                        className="close-circle"
                        onClick={() => setShowQuickActionModal(false)}
                    />
                </div>
                <ModalBody className="quick-action-body-modal-module">
                    {type === QUICK_ACTION_TYPE.SAVE_RECIPE &&
                        <>
                            <Row>
                                <Col xs={4}>
                                    <a href={`mailto:?subject=Here%20is%20a%20nice%20recipe&body=${url}`} target="_top">
                                        <Row>
                                            <Col className="text-center" xs={12}>
                                                <Image src={emailIcon} width="30px"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-center" xs={12}>
                                                <p className="m-0 content-title">Email</p>
                                            </Col>
                                        </Row>
                                    </a>
                                </Col>
                                <Col xs={4}>
                                    <a href="https://m.facebook.com/messages" target="_top">
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <FaFacebookMessenger color="#0079fa" className="messenger-image"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <p className="m-0 content-title">Messenger</p>
                                            </Col>
                                        </Row>
                                    </a>
                                </Col>
                                <Col xs={4}>
                                    <a href="sms:?&body=Recipe content">
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <Image src={messageIcon} width="30px"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="text-center">
                                                <p className="m-0 content-title">Message</p>
                                            </Col>
                                        </Row>
                                    </a>
                                </Col>
                            </Row>
                        </>
                    }
                    {type === QUICK_ACTION_TYPE.ADD_TO_SHOPPING_LIST &&
                        <Row className="shopping-list">
                            <Tabs defaultActiveKey="amazonPrime" className="w-100" id="uncontrolled-tab-example">
                                <Tab eventKey="amazonPrime" tabClassName="tab-img text-center" title={<Image src={amazonPrime}/>}>
                                    <ItemList/>
                                </Tab>
                                <Tab eventKey="drizly" tabClassName="tab-img text-center" title={<Image src={drizly}/>}>
                                    <ItemList/>
                                </Tab>
                            </Tabs>
                        </Row>
                    }
                </ModalBody>
            </ReactStrapModal>
        </div>
    );
};

export default QuickActionModal;