/* eslint-disable camelcase */
import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { FixedSizeList as List } from 'react-window';
import {
    Carousel,
    CarouselItem,
    CarouselControl,
    CarouselIndicators,
    CarouselCaption,
    Button,
} from 'reactstrap';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const defaultCards = [
    {
        src: IMAGE_PLACEHOLDER_URL,
        file: null,
        altText: 'Header Text #1',
        caption: 'Subtitle Text #1',
        imageLink: '',
        buttons: [
            {
                buttonID: 0,
                buttonText: 'ButtonText #1',
                buttonCommand: '',
                buttonType: 'link',
            },
        ],
    },
];

const ImageCarousel = ({
    campaign,
    carouselCards = defaultCards,
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    handleNodeBranching,
    loadWallpaperImage,
    moduleIndex,
    node_branches,
    answered = false,
    currentCard = 0,
    chatbotActive,
    hasBeenClicked,
    carouselBackgroundColor,
    buttonBackgroundColor,
    buttonTextColor,
    headerTextColor,
    subheaderTextColor,
    buttonBorderRadius,
    isFilled,
    headerTextAlignment,
    isVirtualized,
}) => {
    const [activeIndex, setActiveIndex] = useState(currentCard);
    const [animating, setAnimating] = useState(false);
    const [isAnswered, setAnswered] = useState(answered);
    const [moreThanOne, setMoreThanOne] = useState(false);
    const [selectedCard, setSelectedCard] = useState(0);
    const listRef = useRef();

    useEffect(() => {
        if (carouselCards.length > 1) {
            setMoreThanOne(true);
        }
    }, []);

    const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => setWindowInnerWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
    });

    // const [externalLinkURL, setExternalLinkURL] = useState('');
    // useEffect(() => {
    //     if (externalLink) {
    //         if (externalLink.includes('http://') || externalLink.includes('https://')) {
    //             setExternalLinkURL(externalLink);
    //             console.log({ externalLink });
    //         } else {
    //             setExternalLinkURL(`https://${externalLink}`);
    //             console.log({ externalLinkURL });
    //         }
    //     }
    // }, []);

    const { outerBgColor, interiorBgColor } = campaign.styles;
    let rootNode = null;

    const next = () => {
        if (animating) { return; }
        const nextIndex = activeIndex === carouselCards.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        if (animating) { return; }
        const previousIndex = activeIndex === 0 ? carouselCards.length - 1 : activeIndex - 1;
        setActiveIndex(previousIndex);
    };

    const goToIndex = (newIndex) => {
        if (animating) { return; }
        setActiveIndex(newIndex);
    };

    const isValidURL = (str) => {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    };

    const handleBranching = (nodeBranch) => {
        if (nodeBranch.horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl,
                horizontalBgSize,
                horizontalBgRepeat,
                wallpaperExternalLinkUrl,
            });
        }

        if (nodeBranch.payload && !hasBeenClicked) {
            handleNodeBranching(nodeBranch, moduleIndex);
        }
    };

    const handleButtonTap = (buttonLink, buttonType, branchingNode) => {
        if (!chatbotActive) {
            return;
        }

        let urlLink = buttonLink;
        // let urlLink = '';
        // if (buttonLink.includes('http://') || buttonLink.includes('https://')) {
        //     urlLink = buttonLink;
        //     console.log({ urlLinkIF: urlLink });
        // } else {
        //     urlLink = `https://${buttonLink}`;
        //     console.log({ urlLinkELSE: urlLink });
        // }

        if (!buttonLink || buttonLink === '' || !isValidURL(urlLink)) {
            handleBranching(branchingNode);
            setAnswered(true);
            return;
        }

        if (!urlLink.match(/^https?:\/\//i)) {
            urlLink = `http://${urlLink}`;
        }

        if (buttonType === 'link') { window.open(urlLink, '_blank'); }

        handleBranching(branchingNode);
        setAnswered(true);
    };

    useEffect(() => {
        goToIndex(activeIndex);
    }, [activeIndex]);

    // prevents null return on button branching; particularly useful for simple reuse of the module
    const checkForContinuedBranch = (chatBranch, branchStartingPoint) => {
        let branchPath;
        if (chatBranch) {
            branchPath = chatBranch[branchStartingPoint];
        } else {
            branchPath = [];
        }
        return branchPath;
    };

    const handleIndicatorClick = (index) => {
        listRef.current.scrollToItem(index, 'center');
        setSelectedCard(index);
    };

    // VIRTUALIZED LIST
    const Card = ({ index, data, style }) => {
        if (node_branches) {
            rootNode = [node_branches[0]];
        }

        const item = data[index];
        const buttonList = item.buttons.map((button) => (
            <Button
                className="image-carousel-button"
                key={`buttonKey_${button.buttonText}${item.length}`}
                onClick={() => handleButtonTap(
                    button.buttonCommand,
                    'link',
                    checkForContinuedBranch(node_branches, button.buttonID),
                )}
                style={{
                    marginLeft: headerTextAlignment === 'left' ? '10px' : 'auto',
                    marginRight: headerTextAlignment === 'right' ? '10px' : 'auto',
                    borderColor: buttonBackgroundColor,
                    borderRadius: isFilled ? '0px' : `${buttonBorderRadius}px`,
                    width: isFilled ? '100%' : 'auto',
                    backgroundColor: buttonBackgroundColor,
                    color: buttonTextColor,
                }}
            >
                {button.buttonText}
            </Button>
        ));

        return (
            <div
                className="list-card-container"
                // className={`list-card-container ${index === carouselCards.length - 1 ? 'last-card' : ''}`}
                style={style}
                key={`card_${index}`}
            >
                <div
                    className="list-card"
                    style={{ backgroundColor: carouselBackgroundColor }}
                >
                    {!isAnswered && item.imageLink ?
                        <img
                            className="image-carousel-img"
                            key={`imageKey_${carouselCards.length}`}
                            src={item.src}
                            alt={item.altText}
                            onClick={() => handleButtonTap(item.imageLink, 'link', rootNode)}
                            onKeyDown={() => handleButtonTap(item.imageLink, 'link', rootNode)}
                            role="presentation"
                            style={{ cursor: 'pointer' }}
                        /> :

                        <img
                            className="image-carousel-img"
                            key={`imageKeyAnswered_${carouselCards.length}`}
                            src={item.src}
                            alt={item.altText}
                            role="presentation"
                        />
                    }

                    <div
                        className="caption"
                        style={{ textAlign: headerTextAlignment }}
                    >
                        <h3 style={{ color: headerTextColor }}>
                            {item.altText}
                        </h3>
                        <p style={{ color: subheaderTextColor }}>
                            {item.caption}
                        </p>
                    </div>

                    {!hasBeenClicked &&
                        <div className="list-card-buttons">
                            {buttonList}
                        </div>
                    }
                </div>
            </div>
        );
    };

    const innerElementType = forwardRef(({ style, ...rest }, ref) => (
        <div
            ref={ref}
            style={{ ...style, width: `${parseFloat(style.width) + (chatbotActive ? 50 : 150)}px` }}
            {...rest}
        />
    ));

    if (isVirtualized) {
        let maxButtons = 1;
        carouselCards.forEach(card => { maxButtons = Math.max(maxButtons, card.buttons.length); });

        let width = windowInnerWidth / (campaign && campaign.horizontal_layout && windowInnerWidth > 500 ? 2.25 : 1.25);
        if (!chatbotActive) {
            if (campaign && campaign.horizontal_layout && windowInnerWidth < 900) {
                width = Math.min(300, 0.45 * windowInnerWidth);
            } else if (campaign && campaign.horizontal_layout) {
                width = Math.min(400, 0.45 * windowInnerWidth);
            } else {
                width = Math.min(1000, 0.9 * windowInnerWidth);
            }
        }

        return (
            <>
                <List
                    className="image-carousel-list"
                    layout="horizontal"
                    height={200 + 35 * maxButtons}
                    width={width}
                    itemSize={190}
                    itemCount={carouselCards.length}
                    itemData={carouselCards}
                    innerElementType={innerElementType}
                    ref={listRef}
                >
                    {Card}
                </List>
                <div className="image-carousel-indicators">
                    {carouselCards.map((card, index) => (
                        <button
                            type="button"
                            onClick={() => handleIndicatorClick(index)}
                            style={{ borderColor: index === selectedCard ? outerBgColor : interiorBgColor }}
                        />
                    ))}
                </div>
            </>
        );
    }

    // IMAGE CAROUSEL
    const slides = carouselCards.map((item, index) => {
        if (node_branches) {
            rootNode = [node_branches[0]];
        }

        const buttonList = item.buttons.map((button) => (
            <Button
                className="image-carousel-button"
                key={`buttonKey_${button.buttonText}${item.length}`}
                onClick={() => handleButtonTap(
                    button.buttonCommand,
                    'link',
                    checkForContinuedBranch(node_branches, button.buttonID),
                )}
                style={{
                    marginLeft: headerTextAlignment === 'left' ? '10px' : 'auto',
                    marginRight: headerTextAlignment === 'right' ? '10px' : 'auto',
                    borderColor: buttonBackgroundColor,
                    borderRadius: isFilled ? '0px' : `${buttonBorderRadius}px`,
                    width: isFilled ? '100%' : 'auto',
                    backgroundColor: buttonBackgroundColor,
                    color: buttonTextColor,
                }}
            >
                {button.buttonText}
            </Button>
        ));

        return (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={`carouselItemKey_${index}_${item.src}`}
            >
                {!isAnswered && item.imageLink ?
                    <img
                        className="image-carousel-img"
                        key={`imageKey_${carouselCards.length}`}
                        src={item.src}
                        alt={item.altText}
                        onClick={() => handleButtonTap(item.imageLink, 'link', rootNode)}
                        onKeyDown={() => handleButtonTap(item.imageLink, 'link', rootNode)}
                        role="presentation"
                        style={{ cursor: 'pointer' }}
                    /> :

                    <img
                        className="image-carousel-img"
                        key={`imageKeyAnswered_${carouselCards.length}`}
                        src={item.src}
                        alt={item.altText}
                        role="presentation"
                    />
                }

                <CarouselCaption
                    className="image-carousel-caption"
                    captionText={item.caption}
                    captionHeader={item.altText}
                />

                {!hasBeenClicked ?
                    <div
                        className="image-carousel-buttons"
                        key={`buttonsDiv_${carouselCards.length}`}
                    >
                        {buttonList}
                    </div> :

                    <div
                        className="image-carousel-buttons"
                        key={`buttonsDivAnswered_${carouselCards.length}`}
                    />
                }
            </CarouselItem>
        );
    });

    return (
        <div
            key={`image_carousel_${Math.random()}`}
            className={`carousel-container ${moreThanOne ? 'more-than-one' : 'only-one'}`}
            style={{ backgroundColor: carouselBackgroundColor }}
        >
            <style>
                {
                    `.image-carousel-caption {
                        height: ${moreThanOne ? '90px' : '60px'};
                        padding-top: ${moreThanOne ? '40px' : '20px'};
                        text-align: ${headerTextAlignment};
                    }

                    .image-carousel-caption h3 {
                        color: ${headerTextColor};
                    }

                    .image-carousel-caption p {
                        color: ${subheaderTextColor};
                    }`
                }
            </style>
            <Carousel
                className="image-carousel"
                key={`image_carousel_${Math.random()}`}
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                interval={false}
            >
                {moreThanOne &&
                    <CarouselIndicators
                        className="image-carousel-indicators"
                        items={carouselCards}
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                    />
                }
                {slides}
                <CarouselControl
                    className="image-carousel-previous"
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                />
                <CarouselControl
                    className="image-carousel-next"
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                />
            </Carousel>
        </div>
    );
};

export default ImageCarousel;