/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ChatbotLayout from './ChatbotLayout';
import ChatbotCore from './ChatbotCore';
// import Typing from './Typing';
import { getCampaignByRef } from '../../services/campaign';
import { CUSTOM_TOKEN } from '../../config';
import { checkForPreviewURL } from '../../helpers/previewUrlCheckHelper';
import { phoneShell } from '../../assets/images';

const Chatbot = ({ match }) => {
    const [campaign, setCampaign] = useState({});
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showCelebration, setShowCelebration] = useState(false);
    const [celebration, setCelebration] = useState('');
    const [wallpaperImage, setWallpaperImage] = useState('');
    const [wallpaperSize, setWallpaperSize] = useState('');
    const [wallpaperRepeat, setWallpaperRepeat] = useState('');
    const [wallpaperExternalLinkUrl, setWallpaperExternalLinkUrl] = useState('');
    const [isDemoURL, setDemoURL] = useState(false);

    useEffect(() => {
        const getCampaign = async () => {
            try {
                const campaignRef = match.params.ref;
                let { data: cmp } = await getCampaignByRef(campaignRef, CUSTOM_TOKEN);
                if (!cmp) {
                    throw new Error('No campaign found!');
                }
                if (cmp.styles) {
                    cmp = {
                        ...cmp,
                        styles: JSON.parse(cmp.styles),
                    };
                    setWallpaperImage(cmp.horizontal_image);
                    setWallpaperSize(cmp.styles.horizontalBgSize);
                    setWallpaperRepeat(cmp.styles.horizontalBgRepeat);
                }
                setCampaign(cmp);
                // setWallpaperImage(cmp.horizontal_image);
                // setWallpaperSize(cmp.styles.horizontalBgSize);
                // setWallpaperRepeat(cmp.styles.horizontalBgRepeat);
                setIsLoading(false);
            } catch (err) {
                console.log('ERROR:', err.message);
                setError(true);
            }
        };

        getCampaign();

        setDemoURL(checkForPreviewURL(window.location));
    }, []);

    useEffect(() => {
        if (showCelebration && setShowCelebration) {
            setTimeout(setShowCelebration(false), 2000);
        }
    }, [showCelebration]);

    const chatbotLayoutProps = {
        campaign,
        chatbotActive: true,
        showCelebration,
        celebration,
        setShowCelebration,
        wallpaperImage,
        wallpaperSize,
        wallpaperRepeat,
        wallpaperExternalLinkUrl,
        setWallpaperImage,
        setWallpaperSize,
        setWallpaperRepeat,
        setWallpaperExternalLinkUrl,
    };

    const chatbotCoreProps = {
        campaign,
        chatbotActive: true,
        setShowCelebration,
        setCelebration,
        setWallpaperImage,
        setWallpaperSize,
        setWallpaperRepeat,
        setWallpaperExternalLinkUrl,
    };

    return (
        <>
            {error ?
                <div
                    align="center"
                    className="h-100 qr-landing-page-container"
                >
                    <h3 className="pt-5">
                        Campaign not found
                    </h3>
                </div> :

                <>
                    {isDemoURL && !campaign.horizontal_layout ?
                        <div
                            className="chatbot-phone-shell"
                            style={{ backgroundImage: `url(${phoneShell})` }}
                        >
                            <ChatbotLayout {...chatbotLayoutProps}>
                                {!isLoading && <ChatbotCore {...chatbotCoreProps}/>}
                            </ChatbotLayout>
                        </div> :

                        <ChatbotLayout {...chatbotLayoutProps}>
                            {!isLoading && <ChatbotCore {...chatbotCoreProps}/>}
                        </ChatbotLayout>
                    }
                </>
            }
        </>
    );
};

export default withRouter(Chatbot);
