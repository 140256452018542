/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    Input,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip,
    Fade,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import {
    BsFillDashCircleFill,
    BsLink45Deg,
    BsThreeDotsVertical,
    BsTrashFill,
} from 'react-icons/bs';
import { IoMdAnalytics } from 'react-icons/io';
import { BiUnlink } from 'react-icons/bi';
import { GrTree } from 'react-icons/gr';
import {
    AddNewButton,
    ButtonWallpaper,
    ConfigurePathButton,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { generatePayload } from '../../../helpers/nodeBranchingHelper';
import { NODE_BRANCH_CONFIGURATION_STATUS } from '../../../config';

const ButtonReply = ({
    node_branches,
    buttonStyles = {
        borderRadius: 1,
        isStacked: false,
        isFilled: false,
    },
    unansweredButtonColor,
    unansweredTextColor,
    answeredButtonColor,
    answeredTextColor,
    replyBackgroundColor,
    replyBorderColor,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    reference,
    campaign,
    configureNodeBranchingPath,
    deleteConfigurationForNodeBranch,
    getConfigurationStatus,
    linkNodeBranches,
    createExternalUrlLink,
    updateKeyword,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [actionDropDown, setActionDropDown] = useState({
        open: false,
        index: -1,
    });
    const [tempExternalURL, setTempExternalURL] = useState('');
    useEffect(() => {
        setTempExternalURL(null);
    }, [actionDropDown]);

    const toggleActionDropDown = (index) => setActionDropDown(actionDropDown => ({
        index,
        open: !actionDropDown.open,
    }));

    useEffect(() => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    error: !node_branches.length,
                },
            },
        }));
    }, [node_branches]);

    // STYLE CHANGES
    const [isStacked, setIsStacked] = useState(buttonStyles.isStacked);
    const [isFilled, setIsFilled] = useState(buttonStyles.isFilled);
    const [isFirstTime, setIsFirstTime] = useState(true);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        animation: moduleStyles.ButtonReply.animation,
                        unansweredButtonColor: moduleStyles.ButtonReply.unansweredButtonColor,
                        unansweredTextColor: moduleStyles.ButtonReply.unansweredTextColor,
                        answeredButtonColor: moduleStyles.ButtonReply.answeredButtonColor,
                        answeredTextColor: moduleStyles.ButtonReply.answeredTextColor,
                        replyBackgroundColor: moduleStyles.ButtonReply.replyBackgroundColor,
                        replyBorderColor: moduleStyles.ButtonReply.replyBorderColor,
                        existingModule: true,
                    },
                },
            }));
        }
    }, []);

    const dependencyArray = [
        animation,
        unansweredButtonColor,
        unansweredTextColor,
        answeredButtonColor,
        answeredTextColor,
        replyBackgroundColor,
        replyBorderColor,
    ];
    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                ButtonReply: {
                    ...moduleStyles.ButtonReply,
                    animation,
                    unansweredButtonColor,
                    unansweredTextColor,
                    answeredButtonColor,
                    answeredTextColor,
                    replyBackgroundColor,
                    replyBorderColor,
                },
            }));
        }
    }, dependencyArray);

    const handleTitleChange = (e, index) => {
        const { value: title } = e.target;
        const previousTitle = node_branches[index].title;

        updateKeyword(reference, previousTitle, title);

        setSelectedMessage(selectedMessage => {
            let newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[index] = {
                ...newNodeBranches[index],
                title,
                payload: generatePayload(campaign, reference, title),
            };

            // Update linking data with new changes
            newNodeBranches = newNodeBranches.map(nodeBranch => {
                if (nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.title === previousTitle) {
                    return {
                        ...nodeBranch,
                        linkingData: {
                            nodeBranch: newNodeBranches[index],
                            index,
                        },
                    };
                }
                return nodeBranch;
            });

            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            };
        });
    };

    const titlePlaceholder = 'Add a title...';
    const removeTextOnFocus = (e, index) => {
        if (e.target.value === titlePlaceholder) {
            setSelectedMessage(selectedMessage => {
                const newNodeBranches = selectedMessage.message.props.node_branches;
                newNodeBranches[index].title = '';

                return ({
                    ...selectedMessage,
                    message: {
                        ...selectedMessage.message,
                        props: {
                            ...selectedMessage.message.props,
                            node_branches: newNodeBranches,
                        },
                    },
                });
            });
        }
    };

    const handleNewNodeBranch = () => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = selectedMessage.message.props.node_branches ? [...selectedMessage.message.props.node_branches] : [];
            newNodeBranches.push({ title: titlePlaceholder });

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    const deleteNodeBranchFromNodeBranches = (index) => {
        setSelectedMessage(selectedMessage => {
            let newNodeBranches = [...selectedMessage.message.props.node_branches];
            const nodeBranchToRemove = newNodeBranches[index];

            newNodeBranches.splice(index, 1);

            // Delete linking data from linked node branch
            newNodeBranches = newNodeBranches.map(nodeBranch => {
                if (nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.title === nodeBranchToRemove.title) {
                    return {
                        ...nodeBranch,
                        linkingData: null,
                    };
                }
                return nodeBranch;
            });

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    // BUTTON OPTIONS
    const handleStackedChange = () => {
        setIsStacked(!isStacked);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    buttonStyles: {
                        ...selectedMessage.message.props.buttonStyles,
                        isStacked: !isStacked,
                    },
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleFilledChange = () => {
        setIsFilled(!isFilled);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    buttonStyles: {
                        ...selectedMessage.message.props.buttonStyles,
                        isFilled: !isFilled,
                    },
                    error: !node_branches.length,
                },
            },
        }));
    };

    // BUTTON CONFIGURATIONS
    const handleLinkNodeBranch = (nodeBranch, nodeBranchIndex, nodeBranchToLinkTo, nodeBranchToLinkToIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];

            newNodeBranch = {
                ...newNodeBranch,
                linkingData: {
                    index: nodeBranchToLinkToIndex,
                    nodeBranch: nodeBranchToLinkTo,
                },
                isTriggerModuleRemoved: node_branches[nodeBranchToLinkToIndex].isTriggerModuleRemoved,
                isTruncatedModules: node_branches[nodeBranchToLinkToIndex].isTruncatedModules,
            };
            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });

        linkNodeBranches(nodeBranch.title, nodeBranchToLinkTo.title, reference);
    };

    const handleUnlinkNoeBranch = (nodeBranch, nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            const newNodeBranch = newNodeBranches[nodeBranchIndex];

            delete newNodeBranch.linkingData;

            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });

        deleteConfigurationForNodeBranch(nodeBranch.title, reference);
    };

    const handleDeleteConfigurationAndNodeBranch = (index, nodeBranch) => {
        deleteNodeBranchFromNodeBranches(index);
        deleteConfigurationForNodeBranch(nodeBranch.title, reference);
    };

    const handleDeleteOfNodeBranch = (nodeBranch, index) => {
        if (!nodeBranch.payload) {
            deleteNodeBranchFromNodeBranches(index);
            return;
        }
        const deleteStatus = getConfigurationStatus(nodeBranch);
        switch (deleteStatus) {
            case NODE_BRANCH_CONFIGURATION_STATUS.HAS_CONFIGURATION:
                toastr.confirm('Are you sure you want to delete this configured path?', {
                    onOk: () => handleDeleteConfigurationAndNodeBranch(index, nodeBranch),
                    closeOnToastrClick: true,
                });
                break;
            case NODE_BRANCH_CONFIGURATION_STATUS.HAS_NODE_BRANCH_IN_CONFIGURATION:
                toastr.error('You have node branches in this path. Please delete them first.');
                break;
            case NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION:
                deleteNodeBranchFromNodeBranches(index);
                break;
            default:
                break;
        }
    };

    const handleSetExternalLink = (nodeBranchIndex, nodeBranch, tempExternalURL) => {
        if (tempExternalURL) {
            setSelectedMessage(selectedMessage => {
                const newNodeBranches = [...selectedMessage.message.props.node_branches];
                let newNodeBranch = newNodeBranches[nodeBranchIndex];
                newNodeBranch = {
                    ...newNodeBranch,
                    externalURL: tempExternalURL,
                };
                newNodeBranches[nodeBranchIndex] = newNodeBranch;

                return ({
                    ...selectedMessage,
                    message: {
                        ...selectedMessage.message,
                        props: {
                            ...selectedMessage.message.props,
                            node_branches: newNodeBranches,
                        },
                    },
                });
            });

            createExternalUrlLink(nodeBranchIndex, nodeBranch, tempExternalURL);
            setTempExternalURL(null);
        }
    };

    const handleRemoveExternalLink = (nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];

            newNodeBranch = {
                ...newNodeBranch,
                externalURL: null,
            };

            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
        setTempExternalURL(null);
    };

    const handleExternalLink = (nodeBranchIndex, nodeBranch, tempExternalURL) => {
        if (!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '')) {
            handleSetExternalLink(nodeBranchIndex, nodeBranch, tempExternalURL);
        } else {
            handleRemoveExternalLink(nodeBranchIndex);
        }
    };

    const hasConfiguration = (nodeBranch) => {
        if (!nodeBranch.payload) {
            return false;
        }
        return getConfigurationStatus(nodeBranch) !== NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION;
    };

    const handleCornerRounding = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    buttonStyles: {
                        ...selectedMessage.message.props.buttonStyles,
                        borderRadius: value,
                    },
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleModuleIdentifier = (value, nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];
            newNodeBranch = {
                ...newNodeBranch,
                moduleIdentifier: value,
            };
            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('ButtonReply');
        } else {
            clearDefaultConfigs('ButtonReply');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                ButtonReply: {
                    ...moduleStyles.ButtonReply,
                    animation,
                    unansweredButtonColor,
                    unansweredTextColor,
                    answeredButtonColor,
                    answeredTextColor,
                    replyBackgroundColor,
                    replyBorderColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="quick-reply-module-configuration">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* BUTTON CONFIGURATIONS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {/* UNANSWERED */}
                    <Row className="mt-2">
                        <Col>
                            <h5 className="shared-heading">
                                Unanswered Buttons
                            </h5>
                        </Col>
                    </Row>

                    {/* UNANSWERED COLORS */}
                    <Row className="mt-0">
                        {/* BUTTON COLOR */}
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="unansweredButtonColor"
                                parentColor={unansweredButtonColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        {/* TEXT COLOR */}
                        <Col>
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="unansweredTextColor"
                                parentColor={unansweredTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* ANSWERED */}
                    <Row className="mt-5">
                        <Col>
                            <h5 className="shared-heading">
                                Answered Buttons
                            </h5>
                        </Col>
                    </Row>

                    {/* ANSWERED COLORS */}
                    <Row className="mt-0">
                        {/* BUTTON COLOR */}
                        <Col className="mb-3">
                            <ColorPicker
                                heading="Button Color"
                                colorProp="answeredButtonColor"
                                parentColor={answeredButtonColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        {/* TEXT COLOR */}
                        <Col className="mb-3">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="answeredTextColor"
                                parentColor={answeredTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* BACKGROUND */}
                    <Row className="mt-4">
                        <Col>
                            <h5 className="shared-heading">
                                Background
                            </h5>
                        </Col>
                    </Row>

                    {/* BACKGROUND COLOR */}
                    <Row className="mt-0">
                        <Col>
                            <ColorPicker
                                heading="Background Color"
                                colorProp="replyBackgroundColor"
                                parentColor={replyBackgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        <Col>
                            <ColorPicker
                                heading="Border Color"
                                colorProp="replyBorderColor"
                                parentColor={replyBorderColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* GLOBAL DEFAULT STYLES */}
                    <Row className="mt-4">
                        <Col>
                            <GlobalStylesToggle
                                moduleType="ButtonReply"
                                heading="Set as default button colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>

                    {/* BUTTON OPTIONS */}
                    <Row className="mt-5">
                        <Col>
                            <h6 className="shared-subheading">
                                Stacked
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isStacked"
                                type="checkbox"
                                checked={isStacked}
                                onChange={handleStackedChange}
                            />
                        </Col>

                        <Col>
                            <h6 className="shared-subheading">
                                <span style={isStacked ? {} : { color: 'darkgray' }}>
                                    Fill Width
                                </span>
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isFilled"
                                type="checkbox"
                                checked={isFilled}
                                disabled={!isStacked}
                                onChange={handleFilledChange}
                            />
                        </Col>
                    </Row>

                    {/* BUTTON ROUNDING */}
                    <Row className="mt-4">
                        <Col xl={6}>
                            <h6 className="shared-subheading">
                                Corner Rounding: &nbsp;
                                {buttonStyles.borderRadius}
                                px
                            </h6>
                            <RangeSlider
                                value={buttonStyles.borderRadius}
                                min={1}
                                max={20}
                                variant="secondary"
                                onChange={e => handleCornerRounding(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <Row className="shared-module-background">
                <Col>
                    {/* CONFIGURE BUTTONS */}
                    {node_branches && node_branches.map((nodeBranch, index) => (
                        <>
                            {/* OPTIONS & DROPDOWN */}
                            <Row className="mb-5 align-items-center">
                                {(nodeBranch.title || nodeBranch.title === '') &&
                                    <>
                                        {/* OPTIONS */}
                                        <Col className="m-0 p-0">
                                            {/* TITLE INPUT */}
                                            <FormGroup className="mb-1">
                                                <Input
                                                    type="text"
                                                    name="message"
                                                    id={`node_branch_${index}_message_input`}
                                                    value={nodeBranch.title || ''}
                                                    invalid={nodeBranch.title === ''}
                                                    onChange={(e) => handleTitleChange(e, index)}
                                                    onFocus={(e) => removeTextOnFocus(e, index)}
                                                />
                                            </FormGroup>

                                            {/* LINKED-TO DESCRIPTION */}
                                            {nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.title &&
                                                <Fade
                                                    in
                                                    className="font-italic m-0"
                                                    tag="p"
                                                >
                                                    {`Linked to ${nodeBranch.linkingData.nodeBranch.title}`}
                                                </Fade>
                                            }

                                            {/* CONFIGURE PATH BUTTON */}
                                            {(!nodeBranch.linkingData || !nodeBranch.linkingData.nodeBranch) &&
                                                <>
                                                    {(nodeBranch.title === '' || nodeBranch.title === titlePlaceholder) ?
                                                        <>
                                                            <ConfigurePathButton
                                                                answer={nodeBranch.title}
                                                                reference={reference}
                                                                configureNodeBranchingPath={configureNodeBranchingPath}
                                                                id="warningMessageTooltip"
                                                                disabled
                                                            />
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target="warningMessageTooltip"
                                                            >
                                                                Please add a title first
                                                            </UncontrolledTooltip>
                                                        </> :

                                                        <ConfigurePathButton
                                                            answer={nodeBranch.title}
                                                            reference={reference}
                                                            configureNodeBranchingPath={configureNodeBranchingPath}
                                                        />
                                                    }
                                                </>
                                            }
                                        </Col>

                                        {/* DROPDOWN */}
                                        <Col
                                            sm="auto"
                                            className="m-0 p-0"
                                        >
                                            <Dropdown
                                                // className="dropdown-list"
                                                isOpen={index === actionDropDown.index && actionDropDown.open}
                                                toggle={() => toggleActionDropDown(index)}
                                            >
                                                <DropdownToggle>
                                                    <BsThreeDotsVertical size="22" color="#000"/>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    {/* MODULE IDENTIFIER */}
                                                    <DropdownItem toggle={false}>
                                                        <Row className="py-1">
                                                            <Col>
                                                                <FormGroup className="mx-2">
                                                                    <div className="shared-heading pl-1">
                                                                        <IoMdAnalytics
                                                                            size="18px"
                                                                            style={{ marginRight: '10px', color: '#000' }}
                                                                        />
                                                                        Button-click ID for analytics (recommended)
                                                                    </div>
                                                                    <Input
                                                                        className="shared-input"
                                                                        type="input"
                                                                        id={`module_indentifier_input_${actionDropDown.index}`}
                                                                        placeholder="Enter a unique module ID"
                                                                        value={nodeBranch.moduleIdentifier}
                                                                        onChange={
                                                                            (e) => handleModuleIdentifier(e.target.value, actionDropDown.index)
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </DropdownItem>

                                                    <DropdownItem divider/>

                                                    {/* ADD EXTERNAL LINK */}
                                                    <DropdownItem toggle={false}>
                                                        <Row
                                                            className="m-1"
                                                            onClick={() => handleExternalLink(index, nodeBranch, tempExternalURL)}
                                                            // onClick={!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                            //     () => handleSetExternalLink(index, tempExternalURL) :
                                                            //     () => handleRemoveExternalLink(index)
                                                            // }
                                                        >
                                                            <Col sm="auto" className="pr-0">
                                                                {!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                                    <BsLink45Deg className="pointer" size="20"/> :
                                                                    <BiUnlink className="pointer" size="20"/>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <div
                                                                    className="shared-heading"
                                                                    style={{ paddingTop: '3px' }}
                                                                >
                                                                    {!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                                        'Set external link' :
                                                                        'Remove external link'
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup className="mx-2">
                                                                    <Input
                                                                        type="text"
                                                                        name="message"
                                                                        id={`node_branch_${index}_external_url_input`}
                                                                        value={tempExternalURL !== null ? tempExternalURL : nodeBranch.externalURL}
                                                                        onChange={(e) => setTempExternalURL(e.target.value)}
                                                                        onFocus={(e) => removeTextOnFocus(e, index)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {tempExternalURL !== null && tempExternalURL.trim() === '' &&
                                                            <Row>
                                                                <Col>
                                                                    <p style={{ textAlign: 'center', fontStyle: 'italic' }}>
                                                                        Please choose a valid web address
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </DropdownItem>

                                                    <DropdownItem divider/>

                                                    {/* LINK TO OTHER BUTTON */}
                                                    {nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch ?
                                                        // UNLINK
                                                        <DropdownItem>
                                                            <Row
                                                                className="m-1"
                                                                onClick={() => handleUnlinkNoeBranch(nodeBranch, index)}
                                                            >
                                                                <Col sm="auto" className="pr-0">
                                                                    <BsFillDashCircleFill size="18px" className="pointer"/>
                                                                </Col>
                                                                <Col>
                                                                    <p>
                                                                        {`Unlink from button: ${nodeBranch.linkingData.nodeBranch.title}`}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </DropdownItem> :

                                                        // LINK
                                                        <DropdownItem className="no-pointer">
                                                            {!hasConfiguration(nodeBranch) ?
                                                                // LINK
                                                                <>
                                                                    <Row className="m-1">
                                                                        <Col sm="auto" className="pr-0">
                                                                            <GrTree size="18"/>
                                                                        </Col>
                                                                        <Col>
                                                                            <p>
                                                                                Link to other button:
                                                                            </p>
                                                                        </Col>
                                                                    </Row>
                                                                    {node_branches && node_branches.map((linkingOption, linkingOptionIndex) => (
                                                                        <span key={`linking_option_${linkingOptionIndex}`}>
                                                                            {(index !== linkingOptionIndex && !linkingOption.linkingData && hasConfiguration(linkingOption)) &&
                                                                                <Row
                                                                                    className="quick-reply-option-row m-1 ml-4"
                                                                                    onClick={() => handleLinkNodeBranch(nodeBranch, index, linkingOption, linkingOptionIndex)}
                                                                                >
                                                                                    <Col className="p-1 pl-3">
                                                                                        <p className="m-0">
                                                                                            {linkingOption.title}
                                                                                        </p>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                        </span>
                                                                    ))}
                                                                </> :

                                                                // NOT LINKABLE
                                                                <Row className="m-1">
                                                                    <Col>
                                                                        <p className="font-italic text-wrap">
                                                                            This button is already configured.
                                                                        </p>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </DropdownItem>
                                                    }

                                                    <DropdownItem divider/>

                                                    {/* ADD WALLPAPER IMAGE */}
                                                    <DropdownItem toggle={false}>
                                                        <ButtonWallpaper
                                                            key={`bwp_${actionDropDown.index}_${nodeBranch.title}`}
                                                            buttonIndex={actionDropDown.index}
                                                            nodeBranch={nodeBranch}
                                                            setSelectedMessage={setSelectedMessage}
                                                        />
                                                    </DropdownItem>

                                                    <DropdownItem divider/>

                                                    {/* DELETE BUTTON */}
                                                    <DropdownItem>
                                                        <Row
                                                            className="m-1"
                                                            onClick={() => handleDeleteOfNodeBranch(nodeBranch, index)}
                                                        >
                                                            <Col className="pr-0" sm="auto">
                                                                <BsTrashFill
                                                                    className="pointer"
                                                                    size="20"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p>
                                                                    Delete button
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </>
                                }
                            </Row>

                            {/* EXTERNAL LINK SUBTEXT */}
                            <Row className="mb-1">
                                <Col>
                                    {nodeBranch.externalURL &&
                                        <Fade
                                            in
                                            tag="p"
                                            className="font-italic"
                                        >
                                            {`Externally linked to ${nodeBranch.externalURL}`}
                                        </Fade>
                                    }
                                </Col>
                            </Row>
                        </>
                    ))}

                    {/* NO BUTTON WARNING */}
                    {node_branches.length === 0 &&
                        <Row>
                            <Col>
                                <p className="text-danger">
                                    A button module must have at least one button.
                                </p>
                            </Col>
                        </Row>
                    }

                    <AddNewButton
                        handleClick={handleNewNodeBranch}
                        text="Add new button"
                    />
                </Col>
            </Row>

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default ButtonReply;