import { MODULE_TYPES } from '../../Modules/constants';
import { IMAGE_PLACEHOLDER_URL } from '../../../config';

export const checkSharedMessageError = (props) => props.showTyping && (!props.typingDuration || props.typingDuration === '0');

const imageHasPlaceholder = (module) => module.type === MODULE_TYPES.Image &&
    module.props.imageUrl === IMAGE_PLACEHOLDER_URL;

const collageHasPlaceholder = (module) => module.type === MODULE_TYPES.Collage && (!module.props.node_branches.length ||
    !!module.props.node_branches.find(nodeBranch => nodeBranch.imageUrl === IMAGE_PLACEHOLDER_URL));

const videoHasPlaceholder = (module) => module.type === MODULE_TYPES.Video && !module.props.url;

const nodeBranchHasBranches = (module) => module.type === MODULE_TYPES.QuickReply && module.props.node_branches.length;

const nodeBranchHasNoBranches = (module) => module.type === MODULE_TYPES.QuickReply && !module.props.node_branches.length;

const ratingHasNoIcon = (module) => module.type === MODULE_TYPES.Rating && !module.props.ratingIcon;

export const checkIfErrorExist = (configurationTriggers, setConfigurationTriggers) => {
    let errorExist = false;
    configurationTriggers.forEach((configurationTrigger, configurationTriggerIndex) => {
        if (configurationTrigger.configuration.length) {
            configurationTrigger.configuration.forEach((module, moduleIndex) => {
                if (nodeBranchHasBranches(module)) {
                    const nodeBranchesArray = module.props.node_branches;
                    nodeBranchesArray.forEach((nodeBranch) => {
                        if (!nodeBranch.title || nodeBranch.title === '' || nodeBranch.title === 'Add a title...') {
                            setConfigurationTriggers(configurationTriggers => {
                                const newConfigurationTriggers = [...configurationTriggers];
                                const newConfiguration = newConfigurationTriggers[configurationTriggerIndex].configuration;
                                const message = newConfiguration[moduleIndex];
                                message.props.error = true;
                                return newConfigurationTriggers;
                            });
                            errorExist = true;
                        }
                    });
                }
                if (imageHasPlaceholder(module) || videoHasPlaceholder(module) || nodeBranchHasNoBranches(module)) {
                    setConfigurationTriggers(configurationTriggers => {
                        const newConfigurationTriggers = [...configurationTriggers];
                        const newConfiguration = newConfigurationTriggers[configurationTriggerIndex].configuration;
                        const message = newConfiguration[moduleIndex];
                        message.props.error = true;
                        return newConfigurationTriggers;
                    });
                    errorExist = true;
                }
                if (module.props && module.props.error) {
                    errorExist = true;
                }

                if (collageHasPlaceholder(module)) {
                    errorExist = true;
                }

                if (ratingHasNoIcon(module)) {
                    errorExist = true;
                }
            });
        }
    });
    return errorExist;
};