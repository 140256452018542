/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Axios from 'axios';
import Cookies from 'js-cookie';
import {
    Label,
    Button,
    Modal as ReactStrapModal,
    ModalBody,
    Row,
    Col,
    Collapse,
    Card,
    CardBody,
} from 'reactstrap';
import Modal, { ModalProvider, BaseModalBackground } from 'styled-react-modal';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import styled from 'styled-components';
import DatePicker from 'react-date-picker';
import TagManager from 'react-gtm-module';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import MessageModule from '../../containers/Chatbot/MessageModule';
import CookieInfoModal from '../../containers/Chatbot/CookieInfoModal';
import { defaultCustomData } from '../../helpers/customizationsHelper';
import {
    checkForBranches,
    setAnswered,
    handleBranching,
    loadWallpaper,
    setMessageModuleProp,
} from '../../helpers/chatLogicHelpers';
import {
    FRONTEND_URL,
    WEBSOCKET_URL,
    GA_ID,
    GA_APP_WEB_ID,
    CAMPAIGN_NOT_ACTIVE_MESSAGE,
} from '../../config';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = Math.random() * 16 | 0;
        // eslint-disable-next-line no-bitwise, no-mixed-operators
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function getUserID() {
    let userId = Cookies.get('userId');
    if (!userId) {
        const days = 1;
        const d = new Date();
        Cookies.set('userId', uuidv4(), { expires: d.getTime() + (days * 24 * 60 * 60 * 1000) });
        userId = Cookies.get('userId');
    }
    return userId;
}

const eventId = uuidv4();
const eventTimestamp = new Date().getTime();

let awsWebSocket;
// let globalTypingSignal = false;

function setInitialCookiesConfig() {
    if (!Cookies.get('cookiesConfiguration')) {
        Cookies.set(
            'cookiesConfiguration',
            { configurationDone: false, trackingCookiesEnabled: true },
            { expires: new Date().getTime() + (10 * 365 * 24 * 60 * 60 * 1000) },
        );
    }
}

function getCookiesConfig() {
    let cookiesConfigFound = null;
    try {
        cookiesConfigFound = JSON.parse(Cookies.get('cookiesConfiguration'));
    } catch (err) {
        console.log(`error with cookiesConfigFound:${err}`);
        cookiesConfigFound = null;
    }
    return cookiesConfigFound;
}

setInitialCookiesConfig();

const ChatbotLogic = ({
    campaign,
    chatbotActive,
    showPreview,
    configurationTriggers,
    setShowCelebration,
    setCelebration,
    enableScrollToBottom = true,
    setWallpaperImage,
    setWallpaperSize,
    setWallpaperRepeat,
    setWallpaperExternalLinkUrl,
}) => {
    const CAMPAIGN_GA_ID = campaign.google_analytics_tag || '';
    const [hasCustomGoogleTag, setHasCustomGoogleTag] = useState(CAMPAIGN_GA_ID || false);
    const gaSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    const gaAppWebSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_APP_WEB_ID}`;
    const gaCampaignSrc = `https://www.googletagmanager.com/gtag/js?id=${CAMPAIGN_GA_ID}`;
    const [cookieConfigurationDone, setCookieConfigurationDone] = useState(false);
    const [enableTrackingCookies, setEnableTrackingCookies] = useState(false);

    const [messageModules, setMessageModules] = useState([]);
    const [cardMessageModules, setCardMessageModules] = useState([]);
    const [initialConfigurationLoaded, setInitialConfigurationLoaded] = useState(false);
    const [customCampaignData, setCustomCampaignData] = useState(defaultCustomData);

    const [showCardPopUp, setShowCardPopUp] = useState(false);
    const [showChatContent, setShowChatContent] = useState(true);

    const {
        styles,
        card,
        card_id: cardId,
        card_lock: cardLock,
    } = campaign;

    useEffect(() => {
        const customData = JSON.parse(campaign.custom_data);
        if (customData && customData.isCustomized) {
            setCustomCampaignData(customData);
        }

        if (card && !showPreview) {
            setShowChatContent(false);
            setTimeout(() => { setShowCardPopUp(true); }, 700);
        } else {
            setShowChatContent(true);
        }
    }, [campaign]);

    const [tmpEnableTrackingCookies, setTmpEnableTrackingCookies] = useState(false);
    const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);
    const [showCookieSettingsModal, setShowCookieSettingsModal] = useState(false);
    const [isOpenRequiredCookiePolicyModal, setIsOpenRequiredCookiePolicyModal] = useState(false);
    const [isOpenAnalyticCookiePolicyModal, setIsOpenAnalyticCookiePolicyModal] = useState(false);
    const [ageGateRequired, setAgeGateRequired] = useState(campaign.age_gate_required || null);
    const [landingPage, setLandingPage] = useState(campaign.landing_page || null);
    useEffect(() => {
        if (showPreview) {
            setAgeGateRequired(null);
            setLandingPage(null);
        }
    }, []);

    const [showAgeGateModal, setShowAgeGateModal] = useState(true);
    const [showLandingPageModal, setShowLandingPageModal] = useState(true);
    const [modalOpacity, setModalOpacity] = useState(0);
    const [ageGateValue, onChangeAgeGate] = useState(new Date());

    // const messageQueue = useRef([]);
    const messagesEndRef = useRef(null);
    const nodeBranchingRef = useRef(null);
    const whichNodeBranchingRef = useRef(0);
    const previewTimeouts = useRef([]);
    const previousTypingDuration = useRef('0');
    const campaignDependencyCounter = useRef(0);
    const userId = getUserID();

    // used to log the user ID and time for when a chat is entered
    const logUserEntered = (userID, campaignName) => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        let campName = campaignName;
        if (campaignName != null) { campName = campName.replace(/ /g, '_').toLowerCase(); }

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campURL = window.location.href;

        // userDataObject['Campaign Name'] = campaignName;
        console.log('Campaign Ref 2022:\n', campaign.ref);

        userDataObject['User ID'] = userID;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || campName || testTitle;
        userDataObject['Campaign URL'] = campURL;
        userDataObject.EnteredChat = true;

        userAnalytics.push(userDataObject);

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!, Campaign Entry Sent:', response.data);
            })
            .catch(error => {
                console.error('There was an error passing campaign entry data', error);
            });
    };

    // AGE GATE
    const calculateAge = (birthday) => {
        const ageDifMs = Date.now() - birthday;
        const ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    };

    const ageGateCheck = (birthdaySubmitted) => {
        if (birthdaySubmitted == null) {
            // TODO: Use sub modal instead of alert
            // eslint-disable-next-line no-alert, no-undef
            alert('Please Enter Your Date of Birth Before Proceeding');
            return;
        }

        // done to prevent people born the day after from being counted too
        const birthdayFormatted = birthdaySubmitted.setHours(0, 0, 0, 0);
        const userAge = calculateAge(birthdayFormatted);
        if (birthdaySubmitted && userAge >= 21) {
            setAgeGateRequired(null);
            setShowAgeGateModal(!showAgeGateModal);
        } else {
            // eslint-disable-next-line no-alert, no-undef
            alert('Must Be 21 or Older To View');
        }
    };

    // console.log("Campaign Data Sent:");
    // console.log({campaign});

    // const isTequilaChat = ((campaign.ref === 'MW8AxlhWq') ||
    //     (campaign.ref === 'z06Mx+1Ry') || (campaign.ref === 'drizly') || (campaign.ref === 'pj0Jo5CD$')); // production
    // const isTequilaChat = (campaign.ref === 'YfRAw8p3W'); // local

    const delay = async (duration) => new Promise(resolve => previewTimeouts.current.push(setTimeout(resolve, duration)));

    const tagManagerArgs = {
        gtmId: 'GTM-P5BWHX5',
        dataLayer: {
            campaignID: campaign.id,
            userId: getUserID(),
            event_id: eventId,
            event_timestamp: eventTimestamp,
        },
    };

    const processMessageModules = async (tempMessageModules) => {
        if (!tempMessageModules || !tempMessageModules.length) {
            return;
        }

        // if (globalTypingSignal) {
        //     messageQueue.current = messageQueue.current.concat(tempMessageModules);
        //     console.log('Add messages to queue:', tempMessageModules);
        //     return;
        // }

        for (let i = 0; i < tempMessageModules.length; i += 1) {
            const message = tempMessageModules[i];
            const { withDelay, showTyping, typingDuration } = message.props;
            if (chatbotActive) {
                if ((withDelay || showTyping) && typingDuration) {
                    // eslint-disable-next-line no-await-in-loop
                    await delay(previousTypingDuration.current);
                    previousTypingDuration.current = typingDuration;
                } else {
                    const slightDelay = Number(previousTypingDuration.current) + 300;
                    // eslint-disable-next-line no-await-in-loop
                    await delay(slightDelay.toString());
                    previousTypingDuration.current = '0';
                }
            }
            setMessageModules(messageModules => [...messageModules, message]);
        }
    };

    const onLoadDependencies = [
        configurationTriggers,
        ageGateRequired,
        landingPage,
        showCookiePolicyModal,
        showCookieSettingsModal,
        showCardPopUp,
    ];

    useEffect(() => {
        if (initialConfigurationLoaded) {
            return;
        }

        if (configurationTriggers && configurationTriggers[0] && showChatContent &&
            !ageGateRequired && !ageGateRequired &&
            !showCookiePolicyModal && !showCookieSettingsModal) {
            // && !showTequilaPolicyModal) {
            const tempMessageModules = [];
            configurationTriggers[0].configuration.forEach(msg => tempMessageModules.push(msg));
            processMessageModules(tempMessageModules);
            setInitialConfigurationLoaded(true);
        }

        const cardIndex = configurationTriggers.findIndex(({ keywords }) => keywords[0] && keywords[0].includes(cardId));
        if (configurationTriggers && configurationTriggers[cardIndex]) {
            const tempMessageModules = [];
            configurationTriggers[cardIndex].configuration.forEach(msg => tempMessageModules.push(msg));
            setCardMessageModules(tempMessageModules);
        }

        return () => {
            previewTimeouts.current.forEach(previewTimeout => clearTimeout(previewTimeout));
            previewTimeouts.current = [];
        };
    }, onLoadDependencies);

    useEffect(() => {
        if (enableScrollToBottom && messagesEndRef.current && window.self === window.top) {
            const scrollToRef = ref => {
                ref.current.scrollIntoView({ behavior: 'smooth' });
            };
            scrollToRef(messagesEndRef);
        }
    }, [messageModules]);

    const waitForConnection = (callback, interval) => {
        if (awsWebSocket.readyState === 1) {
            console.log('waitForConnection callback');
            callback();
        } else {
            setTimeout(() => {
                console.log('waitForConnection', interval);
                waitForConnection(callback, interval);
            }, interval);
        }
    };

    const updateUnfinishedCookieConfiguration = () => {
        const cookiesConfig = getCookiesConfig();
        if (cookiesConfig != null && !cookiesConfig.configurationDone) {
            Cookies.set(
                'cookiesConfiguration',
                { configurationDone: true, trackingCookiesEnabled: tmpEnableTrackingCookies },
                { expires: new Date().getTime() + (10 * 365 * 24 * 60 * 60 * 1000) },
            );
            setEnableTrackingCookies(tmpEnableTrackingCookies);
            TagManager.initialize(tagManagerArgs);
            setCookieConfigurationDone(true);
        }
    };

    const closeCookiePolicyModal = () => {
        updateUnfinishedCookieConfiguration();
        setShowCookiePolicyModal(!showCookiePolicyModal);
    };

    const closeCookieSettingsModal = () => {
        updateUnfinishedCookieConfiguration();
        setShowCookieSettingsModal(!showCookieSettingsModal);
    };

    const changeCookiePreferences = () => {
        console.log(showCookieSettingsModal);
        setShowCookiePolicyModal(false);
        setShowCookieSettingsModal(true);
    };

    useEffect(() => {
        if (campaign.avatar) {
            const faviconUpdate = async () => {
                // grab favicon element since it's in the <link rel='shortcut icon'> block
                const links = document.getElementsByTagName('link');
                const filtered = [];
                let i = links.length;
                // eslint-disable-next-line no-plusplus
                while (i--) {
                    // eslint-disable-next-line no-unused-expressions
                    links[i].rel === 'shortcut icon' && filtered.push(links[i]);
                }
                filtered[0].href = campaign.avatar;
                // alert(filtered[0].href);
            };
            faviconUpdate();
        }

        if (!campaign.demo && chatbotActive &&
            !FRONTEND_URL.includes('localhost') && !FRONTEND_URL.includes('127.0.0.1')) {
            // if (!campaign.demo && chatbotActive) {
            // eslint-disable-next-line no-undef
            awsWebSocket = new WebSocket(WEBSOCKET_URL);
            console.log('awsWebSocket', awsWebSocket);

            awsWebSocket.onopen = () => {
                console.log('connected');
            };

            awsWebSocket.onmessage = (evt) => {
                console.log('***ONMESSAGE evt\n', evt);
                // const messages = JSON.parse(evt.data);
                // const tempMessageModules = [];
                // messages.forEach(msg => tempMessageModules.push([msg]));
                // processMessageModules(tempMessageModules);
            };

            const cookiesConfig = getCookiesConfig();

            if (cookiesConfig != null && !cookiesConfig.configurationDone) {
                setShowCookiePolicyModal(true);
            }

            if (cookiesConfig != null) {
                setCookieConfigurationDone(cookiesConfig.configurationDone);
                setEnableTrackingCookies(cookiesConfig.trackingCookiesEnabled);
                setTmpEnableTrackingCookies(cookiesConfig.trackingCookiesEnabled);
            }

            // this being done to appease the linter
            if (CAMPAIGN_GA_ID) {
                setHasCustomGoogleTag(true);
            }

            return () => {
                awsWebSocket.close();
            };
        }
    }, []);

    useEffect(() => {
        // Invalid Campaign ID
        if (!campaign.id) {
            return;
        }

        // show Campaign Not Active if the campaign is over
        if (!campaign.active) {
            if (campaignDependencyCounter === 0) {
                processMessageModules([
                    // { type: 'Text', props: { showAvatar: true, showTyping: true, typingDuration: '1000' } },
                    CAMPAIGN_NOT_ACTIVE_MESSAGE,
                ]);
            }
            campaignDependencyCounter.current += 1;
            return;
        }

        const campaignId = campaign.id;
        const userId = getUserID();

        logUserEntered(userId, campaign.name);

        if (!campaign.demo && chatbotActive &&
            !FRONTEND_URL.includes('localhost') && !FRONTEND_URL.includes('127.0.0.1')) {
            waitForConnection(() => awsWebSocket.send(
                JSON.stringify({
                    action: 'onMessage',
                    campaignId,
                    userId,
                    eventId,
                    eventTimestamp,
                }),
            ), 1000);
        }
    }, [campaign]);

    const processNodeBranchingMessages = async ({
        setMessageModules,
        configurationSet,
        moduleIndex,
        isTriggerModuleRemoved = false,
        isTruncatedModules = false,
    }) => {
        if (!configurationSet) { return; }

        // disables typing and pop-in animations in previously rendered modules
        // below the clicked module that triggered node branching
        for (let i = moduleIndex; i < messageModules.length; i += 1) {
            setMessageModules(messageModules => {
                const newMessageModules = [...messageModules];

                // disables typing dots animations
                const { showTyping, typingDuration } = newMessageModules[i].props;
                if (showTyping && typingDuration) {
                    newMessageModules[i] = {
                        ...newMessageModules[i],
                        props: {
                            ...newMessageModules[i].props,
                            showTyping: false,
                            typingDuration: '0',
                        },
                    };
                }

                // disables pop-in animations
                const { hasBeenRendered } = messageModules[i].props;
                if (!hasBeenRendered) {
                    newMessageModules[i] = {
                        ...newMessageModules[i],
                        props: {
                            ...newMessageModules[i].props,
                            hasBeenRendered: true,
                        },
                    };
                    return newMessageModules;
                }
                return newMessageModules;
            });
        }

        // Remove message module that triggered node branching
        let numberOfModulesRemoved = 0;
        if (isTriggerModuleRemoved) {
            numberOfModulesRemoved = 1;
            setMessageModules(messageModules => [
                ...messageModules.slice(0, moduleIndex),
                ...messageModules.slice(moduleIndex + 1),
            ]);
        }

        // Terminates chat module sequence
        if (isTruncatedModules) {
            setMessageModules(messageModules => messageModules.splice(0, moduleIndex + configurationSet.length));
        }

        // Add node branching modules
        let combinedTypingDuration = 0;
        for (let i = 0; i < configurationSet.length; i += 1) {
            const message = configurationSet[i];

            whichNodeBranchingRef.current = moduleIndex + i + 1 - numberOfModulesRemoved;
            if (enableScrollToBottom && nodeBranchingRef.current && (i > 0)) {
                nodeBranchingRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }

            const { showTyping, typingDuration } = message.props;

            if (chatbotActive) {
                if (showTyping && typingDuration) {
                    // sets delay for typing dots animation
                    combinedTypingDuration += Number(typingDuration);
                    previousTypingDuration.current = typingDuration;
                    // eslint-disable-next-line no-await-in-loop
                    await delay(typingDuration);
                } else {
                    // this sets a delay before the module is rendered if no typing dots
                    combinedTypingDuration = Number(previousTypingDuration.current) + 300;
                    // eslint-disable-next-line no-await-in-loop
                    await delay(combinedTypingDuration.toString());
                    previousTypingDuration.current = '0';
                }
            }

            // const setMessages = messageModules.length ? setMessageModules : setCardMessageModules;
            // setMessages(messageModules => {
            setMessageModules(messageModules => {
                const newMessageModules = [...messageModules];

                if (!isTriggerModuleRemoved) {
                    const { numberOfTimesRendered } = newMessageModules[moduleIndex].props;
                    newMessageModules[moduleIndex] = {
                        ...newMessageModules[moduleIndex],
                        props: {
                            ...newMessageModules[moduleIndex].props,
                            numberOfTimesRendered: numberOfTimesRendered + 1,
                        },
                    };
                }

                const newModuleIndex = isTriggerModuleRemoved ? moduleIndex : (moduleIndex + 1);

                if (isTruncatedModules) {
                    return [...newMessageModules.slice(0, newModuleIndex + i), message];
                }

                return [
                    ...newMessageModules.slice(0, newModuleIndex + i),
                    message,
                    ...newMessageModules.slice(newModuleIndex + i),
                ];
            });
        }
    };

    const checkForNodeBranches = (nodeBranch) => checkForBranches(nodeBranch, configurationTriggers);

    const setAnsweredIndex = (moduleIndex, answeredIndex) => setAnswered({
        moduleIndex,
        answeredIndex,
        setMessageModules: messageModules.length ? setMessageModules : setCardMessageModules,
    });

    const handleNodeBranching = (nodeBranch, moduleIndex, replyMessage = null) => handleBranching({
        nodeBranch,
        moduleIndex,
        replyMessage,
        setMessageModules: messageModules.length ? setMessageModules : setCardMessageModules,
        processNodeBranchingMessages,
        chatbotActive,
        configurationTriggers,
    });

    const loadWallpaperImage = ({ horizontalImageUrl, horizontalBgSize, horizontalBgRepeat, wallpaperExternalLinkUrl }) => loadWallpaper(
        chatbotActive,
        horizontalImageUrl,
        horizontalBgSize,
        horizontalBgRepeat,
        wallpaperExternalLinkUrl,
        setWallpaperImage,
        setWallpaperSize,
        setWallpaperRepeat,
        setWallpaperExternalLinkUrl,
        campaign.horizontal_image,
    );

    const setMessageProp = (moduleIndex, prop, value) => setMessageModuleProp(setMessageModules, moduleIndex, prop, value);

    const StyledModal = Modal.styled`
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        background-image: url(${campaign.landing_page_image});
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        opacity: ${(props) => props.opacity};
        transition : all 0.3s ease-in-out;
    `;

    const toggleModal = () => {
        setModalOpacity(0);
        setShowLandingPageModal(!showLandingPageModal);
        setLandingPage(null);
    };

    const afterOpen = () => setTimeout(() => { setModalOpacity(1); }, 100);

    const beforeClose = () => (
        new Promise((resolve) => {
            setModalOpacity(0);
            setTimeout(resolve, 300);
        })
    );

    const FadingBackground = styled(BaseModalBackground)`
        opacity: ${(props) => props.opacity};
        transition: all 0.4s ease-in-out;
    `;

    const handleCloseCardPopUp = () => {
        if (!cardLock) {
            setShowChatContent(true);
            setShowCardPopUp(false);
        }
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {campaign && campaign.name && `${campaign.name}`}
                </title>
                <meta
                    name="robots"
                    content="noindex"
                />
            </Helmet>

            {cookieConfigurationDone && enableTrackingCookies &&
                <Helmet encodeSpecialCharacters>
                    {/* Global site tag (gtag.js) - Google Analytics (Legacy implementation / Dev) */}
                    <script async src={gaSrc}/>
                    <script>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('set', 'dimension1', '${eventId}');
                            gtag('set', 'dimension2', '${eventTimestamp}');
                            gtag('config', '${GA_ID}');
                        `}
                    </script>
                    {/* Global site tag (gtag.js) - Google Analytics  (Newer App + Web Implementation
                        DEV GA ID
                    Note: the id being G-JB39LPBB61 instead of UA-XXXXXXX-X is due to this being a new format of tracking that
                        GA is doing. */}
                    <script async src={gaAppWebSrc}/>
                    <script>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('set', 'user_properties', {
                                event_id: '${eventId}',
                                event_timestamp: '${eventTimestamp}'
                            });
                            gtag('config', '${GA_APP_WEB_ID}', {
                                'user_id': '${getUserID()}'
                            });
                        `}
                    </script>
                </Helmet>
            }

            {hasCustomGoogleTag &&
                <Helmet encodeSpecialCharacters>

                    {/* The Campaign's specific / User inputed Google Analytics Tag Imported in from User */}

                    <script async src={gaCampaignSrc}/>
                    <script>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('set', 'user_properties', {
                                event_id: '${eventId}',
                                event_timestamp: '${eventTimestamp}'
                            });
                            gtag('config', '${CAMPAIGN_GA_ID}', {
                                'user_id': '${getUserID()}'
                            });
                        `}
                    </script>
                </Helmet>
            }

            {ageGateRequired && chatbotActive &&
                <div className="shop-container p-0 m-0">
                    <ReactStrapModal
                        className="agegate-modal-component"
                        isOpen={showAgeGateModal}
                        size="lg"
                        backdrop={false}
                        toggle={() => setShowAgeGateModal(!showAgeGateModal)}
                    >
                        <div
                            className="shop-modal m-0 flex-column"
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#FFF',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <Row className="m-0 h-100">
                                <Col className="p-0">
                                    <Row>
                                        <Col
                                            xs={{ size: 10, offset: 1 }}
                                            className="text-center subTitleRow m-auto"
                                        >
                                            <h6>Date of Birth</h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            xs={{ size: 10, offset: 1 }}
                                            className="text-center date-row m-auto"
                                        >
                                            <div>
                                                <DatePicker
                                                    style={{
                                                        margin: '10px',
                                                        minWidth: '250px',
                                                        width: '100%',
                                                    }}
                                                    onChange={onChangeAgeGate}
                                                    value={ageGateValue}
                                                    maxDate={new Date()}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="verify-row w-100">
                                        <Col className="text-center">
                                            <Button
                                                onClick={() => ageGateCheck(ageGateValue)}
                                                style={{
                                                    marginTop: '16px',
                                                    marginLeft: '29px',
                                                    maxHeight: '42px',
                                                }}
                                            >
                                                Verify
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </ReactStrapModal>
                </div>
            }

            {landingPage && chatbotActive &&
                <ModalProvider backgroundComponent={FadingBackground}>
                    <StyledModal
                        isOpen={showLandingPageModal}
                        afterOpen={afterOpen}
                        beforeClose={beforeClose}
                        onBackgroundClick={toggleModal}
                        onEscapeKeydown={toggleModal}
                        opacity={modalOpacity}
                        backgroundProps={{ modalOpacity }}
                    >
                        <Button
                            className="h-100 w-100 border-0"
                            onClick={() => setLandingPage(null)}
                            style={{ background: 'transparent' }}
                        />
                    </StyledModal>
                </ModalProvider>
            }

            {/* CHATBOT MODULES */}
            {!landingPage && !ageGateRequired &&
            !showCookiePolicyModal && !showCookieSettingsModal && showChatContent &&
            messageModules.map((message, index) => (
                <Row
                    key={`${JSON.stringify(message)}_${index}`}
                    className="message-row m-0 p-0"
                >
                    <Col className="m-0 p-0">
                        <MessageModule
                            key={`${JSON.stringify(message)}_${index}`}
                            moduleIndex={index}
                            message={message}
                            campaign={campaign}
                            userId={userId}
                            chatbotActive={chatbotActive}
                            showPreview={showPreview}
                            setShowCelebration={setShowCelebration}
                            setCelebration={setCelebration}
                            checkForNodeBranches={checkForNodeBranches}
                            setAnsweredIndex={setAnsweredIndex}
                            handleNodeBranching={handleNodeBranching}
                            loadWallpaperImage={loadWallpaperImage}
                            setMessageProp={setMessageProp}
                        />
                        {whichNodeBranchingRef.current === index && <div ref={nodeBranchingRef}/>}
                    </Col>
                </Row>
            ))}

            <div ref={messagesEndRef}/>

            {showCookiePolicyModal && campaign.active &&
                <CookieInfoModal
                    onCloseEvent={closeCookiePolicyModal}
                    title={customCampaignData.siteTermsTitle}
                    text={customCampaignData.siteTermsText}
                >
                    <ModalBody>
                        {customCampaignData.siteTermsLinks.map(link => (
                            <Row className="my-2">
                                <Col>
                                    <a
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {link.text}
                                    </a>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col className="text-center m-0 p-0">
                                <button
                                    className="edit-button"
                                    type="button"
                                    onClick={() => changeCookiePreferences()}
                                >
                                    Edit
                                </button>
                            </Col>
                            <Col className="text-center m-0 p-0">
                                <Button
                                    className="accept-button"
                                    color="primary"
                                    onClick={() => closeCookiePolicyModal()}
                                >
                                    Accept
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </CookieInfoModal>
            }

            {showCookieSettingsModal &&
                <CookieInfoModal
                    onCloseEvent={closeCookieSettingsModal}
                    title="Cookie Settings"
                >
                    <ModalBody>
                        <Row className="pt-4">
                            <Col className="col-9">
                                <span className="mr-2">Strictly necessary</span>
                                {!isOpenRequiredCookiePolicyModal &&
                                    <IoIosArrowDown
                                        onClick={() => setIsOpenRequiredCookiePolicyModal(!isOpenRequiredCookiePolicyModal)}
                                    />
                                }
                                {isOpenRequiredCookiePolicyModal &&
                                    <IoIosArrowUp
                                        onClick={() => setIsOpenRequiredCookiePolicyModal(!isOpenRequiredCookiePolicyModal)}
                                    />
                                }
                            </Col>
                            <Col className="col-3">
                                <Label className="switch">
                                    <input type="checkbox" checked disabled/>
                                    <span className="slider round"/>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Collapse isOpen={isOpenRequiredCookiePolicyModal}>
                                    <Card>
                                        <CardBody>
                                            These cookies or other identifiers are used for activities that are strictly
                                            necessary to operate or deliver the service you requested from us and, therefore,
                                            do not require you to consent.
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-9">
                                <span className="mr-2">Analytics</span>
                                {!isOpenAnalyticCookiePolicyModal &&
                                    <IoIosArrowDown
                                        onClick={() => setIsOpenAnalyticCookiePolicyModal(!isOpenAnalyticCookiePolicyModal)}
                                    />
                                }
                                {isOpenAnalyticCookiePolicyModal &&
                                    <IoIosArrowUp
                                        onClick={() => setIsOpenAnalyticCookiePolicyModal(!isOpenAnalyticCookiePolicyModal)}
                                    />
                                }
                            </Col>
                            <Col className="col-3">
                                <Label className="switch">
                                    <input
                                        onClick={() => setTmpEnableTrackingCookies(!tmpEnableTrackingCookies)}
                                        type="checkbox"
                                        checked={tmpEnableTrackingCookies}
                                    />
                                    <span className="slider round"/>
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Collapse
                                    isOpen={isOpenAnalyticCookiePolicyModal}
                                >
                                    <Card>
                                        <CardBody>
                                            Analytics cookies are used to understand how visitors interact with the website.
                                            These cookies help provide information on metrics the number of visitors, bounce
                                            rate, traffic source, etc.
                                        </CardBody>
                                    </Card>
                                </Collapse>
                            </Col>
                        </Row>
                        <Row className="pt-5">
                            <Col className="text-center">
                                <Button
                                    className="accept-button"
                                    color="primary"
                                    onClick={() => closeCookieSettingsModal()}
                                >
                                    Save Settings
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </CookieInfoModal>
            }

            {/* CARD POP-UP */}
            {!showPreview &&
                <SlidingPane
                    className="modal-card-pane"
                    overlayClassName="modal-card-overlay"
                    closeIcon={null}
                    hideHeader
                    isOpen={showCardPopUp}
                    from="bottom"
                    width="100%"
                    onRequestClose={() => { setShowCardPopUp(false); setShowChatContent(true); }}
                >
                    <div className="modal-card-top-overlay">
                        <button
                            className="modal-card-overlay-button"
                            type="button"
                            onClick={() => handleCloseCardPopUp()}
                        />
                    </div>
                    <div
                        className="modal-card-body"
                        style={{
                            backgroundColor: (styles && styles.outerBgColor) || '#FFF',
                        }}
                    >
                        {cardMessageModules.map((message, index) => (
                            <Row
                                key={`${JSON.stringify(message)}_${index}`}
                                className="message-row m-0 p-0"
                            >
                                <Col className="m-0 py-0 px-2">
                                    <MessageModule
                                        key={`${JSON.stringify(message)}_${index}`}
                                        moduleIndex={index}
                                        message={message}
                                        campaign={campaign}
                                        chatbotActive={chatbotActive}
                                        setShowCelebration={setShowCelebration}
                                        setCelebration={setCelebration}
                                        checkForNodeBranches={checkForNodeBranches}
                                        setAnsweredIndex={setAnsweredIndex}
                                        handleNodeBranching={handleNodeBranching}
                                        loadWallpaperImage={loadWallpaperImage}
                                    />
                                </Col>
                            </Row>
                        ))}
                    </div>
                </SlidingPane>
            }
        </HelmetProvider>
    );
};

export default ChatbotLogic;