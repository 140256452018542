import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import { history } from '../../routes';
import { getArchivedCampaigns, getCampaigns, resetAnalytics } from '../../redux/campaign/actions';
import { changeScreen } from '../../redux/shared/actions';
import loader from '../../assets/img/loader-small.gif';
import TemplateCard from './TemplateCard';
import Modal from '../../components/Modal';
import { modalDescription } from '../../helpers/accountSettingshelper';

const Templates = ({
    campaigns,
    getCampaigns,
    changeScreen,
    resetAnalytics,
    loggedUser,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const customCampaign = { id: 1000000000, name: 'Custom Campaign' };
    const numberOfCampaigns = campaigns.filter(campaign => !campaign.template).length;
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (!campaigns.length) {
            getCampaigns({
                archived: false,
                role: localStorage.getItem('role'),
                company_name: localStorage.getItem('company-name'),
            });
        }

        resetAnalytics();
        if (loggedUser) {
            changeScreen('choose-template');
        } else {
            history.push('/login');
        }
    }, []);

    useEffect(() => {
        if (campaigns && campaigns.length) {
            setIsLoading(false);
        }
    }, [campaigns]);

    return (
        <>
            <Container
                className="templates-container m-3"
                fluid
            >
                <Row className="justify-content-center pt-5 pr-5">
                    <h1>Create a new campaign:</h1>
                </Row>

                {/* CAMPAIGN CARDS */}
                {isLoading ?
                    // LOADING PLACEHOLDER
                    <Row className="w-100 h-100 text-center">
                        <img src={loader} alt="Loading"/>
                    </Row> :

                    // LOADED CONTENT
                    <>
                        {/* CREATE NEW CAMPAIGN OR TEMPLATE */}
                        <Row className="justify-content-center">
                            <Col xl={2} key="custom_campaign_template">
                                <TemplateCard
                                    template={customCampaign}
                                    numberOfCampaigns={numberOfCampaigns}
                                    setShowModal={setShowModal}
                                />
                            </Col>
                        </Row>

                        {/* DUPLICATE FROM TEMPLATE */}
                        {/* <Row className="justify-content-center">
                            {campaigns && !!campaigns.length &&
                                campaigns.filter(campaign => (campaign.template && !campaign.archived)).map(campaign => (
                                    <Col key={`campaign_card_${campaign.id}`}>
                                        <TemplateCard
                                            template={campaign}
                                            numberOfCampaigns={numberOfCampaigns}
                                            setShowModal={setShowModal}
                                        />
                                    </Col>
                                ))
                            }
                        </Row> */}
                    </>
                }
            </Container>

            {showModal &&
                <Modal
                    header="Campaign Limit Reached"
                    description={modalDescription}
                    showConfirmButton={false}
                    closeButtonTitle="Close"
                    handleClose={() => setShowModal(false)}
                    size="md"
                    showCloseButton
                    showCloseIcon={false}
                    handleError={error => console.log(error)}
                    headerClassName="modal-header"
                    footerClassName="modal-footer"
                />
            }
        </>
    );
};

export default connect((state) => ({
    loggedUser: state.auth.loggedUser,
    language: state.language.selectedLanguage,
    campaigns: state.campaigns.all,
    archivedCampaigns: state.campaigns.archived,
    user: state.user.currentUser,
}), { getCampaigns, changeScreen, resetAnalytics, getArchivedCampaigns })(Templates);
