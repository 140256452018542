import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Button, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
// import Navbar from '../Shared/Navbar/Navbar';
import { getRoles } from '../../redux/user/actions';
import { register } from '../../redux/auth/actions';
import localization from '../../helpers/localizationHelper';
import { checkIfEmailIsValid, checkIfNameIsValid } from '../../helpers/regexHelpers';
import { checkIfEmailExists } from '../../services/user';
import { history } from '../../routes';
import { bigLogoPNG as logo } from '../../assets/img';

const Register = ({ getRoles, register, status }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [roles, setRoles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [validEmail, setValidEmail] = useState(null);
    const [validate, setValidate] = useState(false);

    useEffect(() => {
        getRoles().then((response) => {
            setRoles(response);
        }).catch(() => {});
    }, []);

    useEffect(() => {
        if (status === 200) {
            history.push('/confirm-email');
        }
    }, [status]);

    const emailValid = () => {
        if (checkIfEmailIsValid(email)) {
            if (!validEmail) {
                setValidEmail(true);
            }
            return true;
        }
        setErrorMessage(localization.register.invalidEmail);
        setValidEmail(false);
        return false;
    };

    const emailExists = () => {
        setValidate(true);
        if (emailValid()) {
            checkIfEmailExists(email)
                .then((response) => {
                    if (response) {
                        setErrorMessage(localization.register.emailTaken);
                        setValidEmail(false);
                        return;
                    }
                    setValidEmail(true);
                });
        }
    };

    const isFormValid = () => email.length > 0 &&
        emailValid &&
        password.length > 7 &&
        confirmPassword === password &&
        checkIfNameIsValid(firstName) &&
        checkIfNameIsValid(lastName);

    const handleSubmit = () => {
        if (isFormValid()) {
            register({
                firstName,
                lastName,
                email,
                role,
                password,
            });
        }
    };

    return (
        <Container fluid className="h-100 register-container">
            {/* <Navbar/> */}
            <Row className="h-100">
                <Container className="col-sm-5 mx-auto my-auto p-4">
                    <Row className="text-center pb-4">
                        <Col lg={{ offset: 4, size: 4 }}>
                            <img
                                className="w-100 img-fluid"
                                src={logo}
                                alt="Brandchat"
                            />
                        </Col>
                    </Row>

                    <Form>
                        <h1 className="text-center header mb-4">
                            {localization.register.createAccount}
                        </h1>

                        {/* FIRST NAME */}
                        <FormGroup>
                            <Input
                                onChange={(e) => setFirstName(e.target.value)}
                                valid={firstName.length === 0 ? null : checkIfNameIsValid(firstName)}
                                invalid={firstName.length === 0 ? null : !checkIfNameIsValid(firstName)}
                                type="text"
                                name="firstName"
                                id="firstName"
                                placeholder={localization.register.firstName}
                            />
                            <FormFeedback valid id="firstNameValid">
                                {localization.register.validFirstName}
                            </FormFeedback>
                            <FormFeedback invalid id="firstNameNotValid">
                                {localization.register.invalidFirstName}
                            </FormFeedback>
                        </FormGroup>

                        {/* LAST NAME */}
                        <FormGroup>
                            <Input
                                onChange={(e) => setLastName(e.target.value)}
                                valid={lastName.length === 0 ? null : checkIfNameIsValid(lastName)}
                                invalid={lastName.length === 0 ? null : !checkIfNameIsValid(lastName)}
                                type="text"
                                name="lastName"
                                id="lastName"
                                placeholder={localization.register.lastName}
                            />
                            <FormFeedback valid id="lastNameValid">
                                {localization.register.validLastName}
                            </FormFeedback>
                            <FormFeedback invalid id="lastNameNotValid">
                                {localization.register.invalidLastName}
                            </FormFeedback>
                        </FormGroup>

                        {/* EMAIL */}
                        <FormGroup>
                            <Input
                                onBlur={emailExists}
                                onChange={(e) => setEmail(e.target.value)}
                                valid={!validate || email.length === 0 ? null : validEmail}
                                invalid={!validate || email.length === 0 ? null : !validEmail}
                                type="email"
                                name="email"
                                id="email"
                                placeholder={localization.register.email}
                            />
                            <FormFeedback valid id="emailValid">
                                {localization.register.validEmail}
                            </FormFeedback>
                            <FormFeedback invalid id="emailNotValid">
                                {errorMessage}
                            </FormFeedback>
                        </FormGroup>

                        {/* PASSWORD */}
                        <FormGroup>
                            <Input
                                onChange={(e) => setPassword(e.target.value)}
                                valid={password.length === 0 ? null : password.length > 7}
                                invalid={password.length === 0 ? null : password.length < 8}
                                type="password"
                                name="password"
                                id="password"
                                placeholder={localization.register.password}
                            />
                            <FormFeedback valid id="passwordValid">
                                {localization.register.validPassword}
                            </FormFeedback>
                            <FormFeedback invalid id="passwordNotValid">
                                {localization.register.invalidPassword}
                            </FormFeedback>
                        </FormGroup>

                        {/* CONFIRM PASSWORD */}
                        <FormGroup>
                            <Input
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                valid={confirmPassword.length === 0 ? null : password === confirmPassword}
                                invalid={confirmPassword.length === 0 ? null : password !== confirmPassword}
                                type="password"
                                name="confirmPassword"
                                id="confirmPassword"
                                placeholder={localization.register.confirmPassword}
                            />
                            <FormFeedback valid id="confirmPasswordValid">
                                {localization.register.validConfirmPassword}
                            </FormFeedback>
                            <FormFeedback invalid id="confirmPasswordNotValid">
                                {localization.register.invalidConfirmPassword}
                            </FormFeedback>
                        </FormGroup>

                        {/* CHOOSE ROLE */}
                        <Container className="border mb-3">
                            <FormGroup tag="fieldset">
                                <legend className="text-center">
                                    {localization.register.chooseRole}
                                </legend>
                                <Col>
                                    <Input
                                        type="select"
                                        value={role}
                                        id="role"
                                        onChange={(e) => setRole(e.target.value)}
                                    >
                                        {Object.entries(roles).map(r => (
                                            <option key={r[0]} value={r[0]}>{r[1]}</option>
                                        ))}
                                    </Input>
                                </Col>
                            </FormGroup>
                        </Container>

                        {/* REGISTER BUTTON */}
                        <Container className="register">
                            <Button
                                className="btn-lg btn-block btn-primary-custom"
                                onClick={handleSubmit}
                                id="submitBtn"
                                disabled={!isFormValid()}
                            >
                                {localization.register.submit}
                            </Button>
                        </Container>
                    </Form>
                </Container>
            </Row>
        </Container>
    );
};

export default connect((state) => ({
    status: state.auth.status,
    language: state.language.selectedLanguage,
}), { getRoles, register })(Register);
