/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Fade,
    Button,
    ButtonGroup,
    ButtonToggle,
} from 'reactstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { AiFillMinusCircle, AiFillPlusCircle } from 'react-icons/ai';
import { MdViewCarousel } from 'react-icons/md';
import {
    BsImage,
    BsTextLeft,
    BsTextCenter,
    BsTextRight,
    BsViewList,
} from 'react-icons/bs';
import {
    ColorPicker,
    ConfigurePathButton,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { IMAGE_PLACEHOLDER_URL, VALID_IMAGE_TYPES } from '../../../config';
import { checkIfImageUrlValid, checkIfUrlIsValid } from '../../../helpers/regexHelpers';
import { checkSharedMessageError } from './errorHelper';

const Carousel = ({
    carouselCards = [],
    node_branches,
    selectedMessage,
    setSelectedMessage,
    reference,
    configureNodeBranchingPath,
    lastSavedIndex = 0,
    url,
    file,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    externalLink,
    waterfallLayout,
    moduleHeading,
    carouselBackgroundColor,
    buttonBackgroundColor,
    buttonTextColor,
    header,
    subHeader,
    headerTextColor,
    subheaderTextColor,
    headerTextAlignment,
    buttonBorderRadius,
    isFilled,
    isVirtualized,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [errorImageUpload, setErrorImageUpload] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [errorURL, setErrorURL] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [carouselButtonIndex, setCarouselButtonIndex] = useState(0);
    const [radioValue, setRadioValue] = useState('1');
    const [isFilledWidth, setIsFilledWidth] = useState(isFilled);
    const [alignment, setAlignment] = useState(headerTextAlignment || 'left');
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [displayFileName, setDisplayFileName] = useState('');
    const [virtualized, setVirtualized] = useState(isVirtualized);

    const buttonBranchID = node_branches.length;
    const defaultCarouselCard = {
        src: IMAGE_PLACEHOLDER_URL,
        altText: 'Header Text',
        caption: 'Subheader Text',
        imageLink: '',
        fileName: '',
        buttons: [
            {
                buttonID: buttonBranchID,
                buttonText: 'Button #1',
                buttonCommand: '',
                buttonType: 'link',
            },
        ],
    };
    const [currentCarouselCards, setCurrentCarouselCards] = useState(carouselCards || [defaultCarouselCard]);

    useEffect(() => {
        setCurrentCarouselCards(carouselCards);
    }, [selectedMessage]);

    useEffect(() => {
        if (currentCarouselCards.length) {
            setDisplayFileName(`${carouselCards[0].fileName}`);
        }
    }, [currentCarouselCards]);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.ImageCarousel.animation,
                        carouselBackgroundColor: moduleStyles.ImageCarousel.carouselBackgroundColor,
                        buttonBackgroundColor: moduleStyles.ImageCarousel.buttonBackgroundColor,
                        buttonBorderRadius: moduleStyles.ImageCarousel.buttonBorderRadius,
                        buttonTextColor: moduleStyles.ImageCarousel.buttonTextColor,
                        headerTextColor: moduleStyles.ImageCarousel.headerTextColor,
                        subheaderTextColor: moduleStyles.ImageCarousel.subheaderTextColor,
                        headerTextAlignment: moduleStyles.ImageCarousel.headerTextAlignment,
                        isFilled: moduleStyles.ImageCarousel.isFilled,
                        isVirtualized: moduleStyles.ImageCarousel.isVirtualized,
                    },
                },
            }));
        }
    }, []);

    const dependencyArray = [
        animation,
        carouselBackgroundColor,
        buttonBackgroundColor,
        buttonTextColor,
        buttonBorderRadius,
        headerTextColor,
        subheaderTextColor,
        headerTextAlignment,
        isFilled,
        isVirtualized,
    ];
    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                ImageCarousel: {
                    ...moduleStyles.ImageCarousel,
                    animation,
                    carouselBackgroundColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                    buttonBorderRadius,
                    headerTextColor,
                    subheaderTextColor,
                    headerTextAlignment,
                    isFilled,
                    isVirtualized,
                },
            }));
        }
    }, dependencyArray);

    const buttonIconStyles = { margin: '-3px 10px 0 -7px', color: '#000' };
    const unselectedIconColor = '#BBB';
    const selectedIconColor = '#000';

    // IMAGE FILE & URL
    const handleImageChange = (e) => {
        if (!e.target.files[0]) { return; }

        const file = e.target.files[0];
        if (!VALID_IMAGE_TYPES.includes(file.type)) {
            setErrorImageUpload(true);
        } else {
            setDisplayFileName(file.name);
            setErrorImageUpload(false);
            setErrorURL(false);
            const carousel = currentCarouselCards;
            carousel[carouselIndex].src = URL.createObjectURL(file);
            carousel[carouselIndex].file = file;
            carousel[carouselIndex].fileName = file.name;
            setCurrentCarouselCards(carousel);
            console.log({ selectedMessage });
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        carouselCards: carousel,
                        file,
                        error: !file ||
                            checkSharedMessageError(selectedMessage.message.props) ||
                            !VALID_IMAGE_TYPES.includes(file.type),
                    },
                },
            }));
            // reset value to initial state because if same picture uploaded there is no change of state
            e.target.value = '';
        }
    };

    const handleImageUrlChange = (e) => {
        const { value } = e.target;
        const carousel = currentCarouselCards;
        carousel[carouselIndex].src = value;
        setCurrentCarouselCards(carousel);

        if (!checkIfImageUrlValid(value)) {
            setErrorURL(true);
        } else {
            setErrorURL(false);
        }
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    carouselCards: carousel || '',
                    error: !value ||
                        checkSharedMessageError(selectedMessage.message.props) ||
                        !checkIfImageUrlValid(value),
                },
            },
        }));
        setImageURL(value);
    };

    const handleHeader = (e) => {
        e.preventDefault();
        const { value } = e.target;
        const carousel = currentCarouselCards;
        carousel[carouselIndex].altText = value;
        setCurrentCarouselCards(carousel);

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    header: value || '',
                    carouselCards: carousel || '',
                    error: checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const handleSubHeader = (e) => {
        const { value } = e.target;
        const carousel = currentCarouselCards;
        carousel[carouselIndex].caption = value;
        setCurrentCarouselCards(carousel);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    subHeader: value || '',
                    carouselCards: carousel || '',
                    error: checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const handleExternalLink = (e) => {
        const { value } = e.target;
        const carousel = currentCarouselCards;
        carousel[carouselIndex].imageLink = value;
        setCurrentCarouselCards(carousel);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    carouselCards: carousel || '',
                    externalLink: value || '',
                    error: checkSharedMessageError(selectedMessage.message.props) ||
                        (selectedMessage.message.props.file && !VALID_IMAGE_TYPES.includes(selectedMessage.message.props.file.type)) ||
                        (!selectedMessage.message.props.file && selectedMessage.message.props.url &&
                            !checkIfImageUrlValid(selectedMessage.message.props.url)) ||
                        (value && !checkIfUrlIsValid(value)),
                },
            },
        }));
    };

    // NODE BRANCHING
    const handleNewNodeBranch = () => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = selectedMessage.message.props.node_branches ? [...selectedMessage.message.props.node_branches] : [];
            newNodeBranches.push({ title: `Path #${node_branches.length + 1}` });

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    const handleNewButtons = () => {
        setSelectedMessage(selectedMessage => {
            setCarouselButtonIndex(carouselButtonIndex + 1);
            const carousel = currentCarouselCards;
            const newButtons = carousel[carouselIndex].buttons;
            handleNewNodeBranch();
            newButtons.push(
                {
                    buttonID: buttonBranchID,
                    buttonText: `Button #${newButtons.length + 1}`,
                    buttonCommand: '',
                    buttonType: 'link',
                },
            );
            setRadioValue(currentCarouselCards.length);
            setCurrentCarouselCards(carousel);

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        buttons: newButtons,
                        carouselCards: carousel || '',
                    },
                },
            });
        });
    };

    // TODO: change to the button in focus
    const handleRemoveButton = () => {
        setSelectedMessage(selectedMessage => {
            const carousel = currentCarouselCards;
            let currentButtons = carousel[carouselIndex].buttons;
            setCurrentCarouselCards(carousel);
            setRadioValue(carouselIndex);

            if (currentButtons.length === 1) {
                currentButtons = { ...defaultCarouselCard.buttons[0] };
                // currentButtons = currentButtons[defaultCarouselCard.buttons[0]];
            }

            if (currentButtons.length && currentButtons.length > 1) {
                currentButtons.pop();
            }

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        buttons: currentButtons,
                        carouselCards: carousel || '',
                    },
                },
            });
        });
    };

    const handleButtonText = (e, index) => {
        const { value } = e.target;
        const carousel = currentCarouselCards;
        carousel[carouselIndex].buttons[index].buttonText = value;
        setCurrentCarouselCards(carousel);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    buttons: carousel.buttons || '',
                    carouselCards: carousel || '',
                    error: checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const handleButtonLink = (e, index) => {
        const { value } = e.target;
        const carousel = currentCarouselCards;
        carousel[carouselIndex].buttons[index].buttonCommand = value;
        setCurrentCarouselCards(carousel);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    buttons: carousel.buttons || '',
                    carouselCards: carousel || '',
                    error: checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const handleNewCarouselPageChange = (index) => {
        setDisplayFileName(currentCarouselCards[index].fileName);
        setCarouselIndex(index);
        setRadioValue(index);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    header: currentCarouselCards[index].altText || 'Header Text',
                    subHeader: currentCarouselCards[index].caption || 'Subheader Text',
                    externalLink: currentCarouselCards[index].imageLink || '',
                    buttons: currentCarouselCards[index].buttons || '',
                    carouselCards: currentCarouselCards,
                    currentCard: index,
                    lastSavedIndex: index,
                },
            },
        }));
    };

    const handleNewCarousel = () => {
        setSelectedMessage(selectedMessage => {
            handleNewNodeBranch();
            setCarouselIndex(carouselIndex + 1);
            setCarouselButtonIndex(carouselButtonIndex + 1);
            const carousel = currentCarouselCards;
            carousel.splice(carouselIndex + 1, 0, defaultCarouselCard);
            handleNewCarouselPageChange(carouselIndex + 1);
            setRadioValue(carouselIndex + 2);
            setCurrentCarouselCards(carousel);

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        carouselCards: carousel,
                        moreThanOne: true,
                        currentCard: carousel.length - 1,
                        lastSavedIndex: carousel.length - 1,
                    },
                },
            });
        });
    };

    const handleRemoveCarousel = () => {
        setSelectedMessage(selectedMessage => {
            const carousel = currentCarouselCards;
            let isMoreThanOneCarousel = true;

            if (carousel.length === 1) {
                carousel[0] = defaultCarouselCard;
            }

            if (carousel.length > 1) {
                carousel.splice(carouselIndex, 1);
            }

            if (carousel.length <= 1) {
                isMoreThanOneCarousel = false;
            }

            handleNewCarouselPageChange(carousel.length - 1);
            setRadioValue(carousel.length);
            setCurrentCarouselCards(carousel);

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        carouselCards: carousel,
                        moreThanOne: isMoreThanOneCarousel,
                        currentCard: carousel.length - 1,
                    },
                },
            });
        });
    };

    useEffect(() => {
        setSelectedMessage(selectedMessage => {
            handleNewCarouselPageChange(lastSavedIndex || carouselCards.length - 1);
            let radioButtonValue;
            if (lastSavedIndex) { radioButtonValue = lastSavedIndex + 1; }
            setRadioValue(radioButtonValue || carouselCards.length);
            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                    },
                },
            });
        });
    }, []);

    useEffect(() => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    error: !node_branches.length,
                },
            },
        }));
    }, [node_branches]);

    // BUTTON OPTIONS
    const handleHeaderAlignment = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    headerTextAlignment: value,
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleAlignment = (e) => {
        const { value } = e.target;
        handleHeaderAlignment(value);
        setAlignment(value);
    };

    const handleFilledChange = () => {
        setIsFilledWidth(!isFilledWidth);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isFilled: !isFilledWidth,
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleCornerRounding = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    buttonBorderRadius: value,
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleCarouselCardLayout = (bool) => {
        setVirtualized(bool);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isVirtualized: bool,
                },
            },
        }));
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('ImageCarousel');
        } else {
            clearDefaultConfigs('ImageCarousel');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                ImageCarousel: {
                    ...moduleStyles.ImageCarousel,
                    animation,
                    carouselBackgroundColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                    buttonBorderRadius,
                    headerTextColor,
                    subheaderTextColor,
                    headerTextAlignment,
                    isFilled,
                    isVirtualized,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <Row className="image-carousel-module-configuration">
            <Col>
                {/* MODULE HEADING */}
                {waterfallLayout &&
                    <HeadingEditor
                        moduleHeading={moduleHeading}
                        setSelectedMessage={setSelectedMessage}
                        isFirstTime={isFirstTime}
                        setIsFirstTime={setIsFirstTime}
                    />
                }

                {/* STYLES */}
                {!waterfallLayout &&
                    <ModuleStyles
                        styles={styles}
                        setSelectedMessage={setSelectedMessage}
                    />
                }

                {/* ANIMATIONS */}
                <ModuleAnimations
                    animation={animation}
                    setSelectedMessage={setSelectedMessage}
                />

                {/* CAROUSEL LAYOUT */}
                <Row className="shared-module-background">
                    <Col className="m-0 p-0">
                        <div className="shared-heading">
                            Choose Carousel Card Layout
                        </div>
                        <FormGroup
                            className="m-0 p-0"
                            tag="fieldset"
                            row
                        >
                            <Col
                                sm={8}
                                className="radio-button-container m-0 p-0"
                            >
                                <FormGroup
                                    check
                                    className="mb-2 pl-1"
                                >
                                    <Label
                                        check
                                        className="d-flex justify-content-start"
                                    >
                                        <Input
                                            type="radio"
                                            name="radioCarouselView"
                                            onClick={() => handleCarouselCardLayout(false)}
                                        />
                                        <MdViewCarousel
                                            className="icon"
                                            size="20px"
                                            color={virtualized ? '#999' : '#000'}
                                        />
                                        <h6 style={{ color: virtualized ? '#999' : '#000' }}>
                                            Carousel View
                                        </h6>
                                    </Label>
                                </FormGroup>
                                <FormGroup
                                    check
                                    className="mb-2 pl-1"
                                >
                                    <Label
                                        check
                                        className="d-flex justify-content-start"
                                    >
                                        <Input
                                            type="radio"
                                            name="radioVirtualizedList"
                                            onClick={() => handleCarouselCardLayout(true)}
                                        />
                                        <BsViewList
                                            className="icon rotated"
                                            size="18px"
                                            style={{
                                                marginLeft: '2px',
                                                color: virtualized ? '#000' : '#999',
                                            }}
                                        />
                                        <h6 style={{ color: virtualized ? '#000' : '#999' }}>
                                            Virtualized List
                                        </h6>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>

                {/* CARD NUMBER */}
                <ButtonGroup>
                    {currentCarouselCards && currentCarouselCards.map((carousel, index) => (
                        <ButtonToggle
                            key={`${carousel.buttons.buttonText}_${index}`}
                            type="radio"
                            color="secondary"
                            outline={index !== carouselIndex}
                            name="radio"
                            value={index + 1}
                            className="mb-1"
                            checked={radioValue === index + 1}
                            onChange={e => setRadioValue(e.currentTarget.value)}
                            onClick={() => handleNewCarouselPageChange(index)}
                        >
                            {index + 1}
                        </ButtonToggle>
                    ))}
                </ButtonGroup>

                {/* ADD/REMOVE CAROUSEL CARD */}
                <Row className="mt-3 mb-4 pl-2">
                    <Col>
                        <Button
                            className="btn-secondary-custom"
                            onClick={() => handleNewCarousel()}
                        >
                            <AiFillPlusCircle size="23" style={buttonIconStyles}/>
                            Add card
                        </Button>
                    </Col>
                    <Col>
                        <Button
                            className="btn-secondary-custom"
                            onClick={() => handleRemoveCarousel()}
                        >
                            <AiFillMinusCircle size="23" style={buttonIconStyles}/>
                            Remove card
                        </Button>
                    </Col>
                </Row>

                {/* CARD IMAGE */}
                <Row className="shared-module-background">
                    <Col className="m-0 p-0">
                        <h6 className="shared-heading">
                            <BsImage
                                size="18px"
                                color="#000"
                                style={{ margin: '0px 12px 0 5px' }}
                            />
                            Carousel Card Image
                        </h6>

                        {/* UPLOAD BUTTON */}
                        <FormGroup className="m-0 p-0">
                            <Label
                                for="image"
                                className="shared-media-upload"
                            >
                                <p>Upload or drag card image here</p>
                            </Label>
                            <p className="pt-2 description-font">{displayFileName}</p>
                        </FormGroup>

                        <div className="shared-media-info">or</div>

                        {/* TEXT BOX */}
                        <FormGroup>
                            <Input
                                className="shared-input"
                                type="textarea"
                                placeholder="Enter image URL"
                                value={url && url !== IMAGE_PLACEHOLDER_URL && imageURL === '' && !file ? url : imageURL}
                                onChange={handleImageUrlChange}
                            />
                            {errorURL &&
                                <p className="error-message">Please enter valid URL</p>
                            }
                        </FormGroup>

                        {/* TAP LINK */}
                        <FormGroup>
                            <Input
                                className="shared-input"
                                type="input"
                                placeholder="Carousel tap link (optional)"
                                onChange={handleExternalLink}
                                value={externalLink || ''}
                                style={{ borderRadius: '6px' }}
                            />
                        </FormGroup>

                        {/* ERROR MESSAGE */}
                        <FormGroup>
                            <Input
                                type="file"
                                name="image"
                                id="image"
                                onChange={handleImageChange}
                            />
                            {errorImageUpload &&
                                <p className="error-message">
                                    Please upload image in valid format (JPG, JPEG, PNG, GIF, BMP)
                                </p>
                            }
                        </FormGroup>
                    </Col>
                </Row>

                {/* OPTIONS & COLOR */}
                <Row className="shared-module-background">
                    <Col className="m-0 p-0">
                        {/* HEADER TEXT */}
                        <Row className="mt-2">
                            <Col>
                                <ColorPicker
                                    heading="Header Color"
                                    colorProp="headerTextColor"
                                    parentColor={headerTextColor}
                                    setSelectedMessage={setSelectedMessage}
                                    moduleStyles={moduleStyles}
                                    setModuleStyles={setModuleStyles}
                                />
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ColorPicker
                                    heading="Subheader Color"
                                    colorProp="subheaderTextColor"
                                    parentColor={subheaderTextColor}
                                    setSelectedMessage={setSelectedMessage}
                                    moduleStyles={moduleStyles}
                                    setModuleStyles={setModuleStyles}
                                    buttonPopoverStyles={{ left: '-95px' }}
                                />
                            </Col>
                        </Row>

                        {/* BUTTON BACKGROUND & TEXT */}
                        <Row className="mt-5">
                            <Col>
                                <ColorPicker
                                    heading="Button Color"
                                    colorProp="buttonBackgroundColor"
                                    parentColor={buttonBackgroundColor}
                                    setSelectedMessage={setSelectedMessage}
                                    moduleStyles={moduleStyles}
                                    setModuleStyles={setModuleStyles}
                                />
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ColorPicker
                                    heading="Button Text Color"
                                    colorProp="buttonTextColor"
                                    parentColor={buttonTextColor}
                                    setSelectedMessage={setSelectedMessage}
                                    moduleStyles={moduleStyles}
                                    setModuleStyles={setModuleStyles}
                                    buttonPopoverStyles={{ left: '-95px' }}
                                />
                            </Col>
                        </Row>

                        {/* HEADER OPTIONS */}
                        <Row className="mt-5">
                            <Col>
                                <h6 className="shared-subheading">
                                    Align Items
                                </h6>
                                <FormGroup className="carousel-header-alignment">
                                    <Label
                                        className="carousel-align-icon"
                                        check
                                    >
                                        <BsTextLeft
                                            size="21px"
                                            color={alignment === 'left' ? selectedIconColor : unselectedIconColor}
                                        />
                                        <Input
                                            type="checkbox"
                                            name="headerTextAlignLeft"
                                            value="left"
                                            variant="outline-secondary"
                                            onChange={e => handleAlignment(e)}
                                        />
                                    </Label>

                                    <Label
                                        className="carousel-align-icon"
                                        check
                                    >
                                        <BsTextCenter
                                            size="21px"
                                            color={alignment === 'center' ? selectedIconColor : unselectedIconColor}
                                        />
                                        <Input
                                            type="checkbox"
                                            name="headerTextAlignCenter"
                                            value="center"
                                            variant="outline-secondary"
                                            onChange={e => handleAlignment(e)}
                                        />
                                    </Label>

                                    <Label
                                        className="carousel-align-icon"
                                        check
                                    >
                                        <BsTextRight
                                            size="21px"
                                            color={alignment === 'right' ? selectedIconColor : unselectedIconColor}
                                        />
                                        <Input
                                            type="checkbox"
                                            name="headerTextAlignRight"
                                            value="right"
                                            variant="outline-secondary"
                                            onChange={e => handleAlignment(e)}
                                        />
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>

                        {/* BUTTON OPTIONS */}
                        <Row className="mt-5">
                            <Col>
                                <h6 className="shared-subheading">
                                    Fill Width
                                </h6>
                                <input
                                    className="config-checkbox"
                                    name="isFilledWidth"
                                    type="checkbox"
                                    checked={isFilledWidth}
                                    onChange={handleFilledChange}
                                />
                            </Col>

                            <Col>
                                <h6 className="shared-subheading">
                                    Corner Rounding: &nbsp;
                                    {buttonBorderRadius}
                                    px
                                </h6>
                                <RangeSlider
                                    value={buttonBorderRadius}
                                    min={1}
                                    max={20}
                                    disabled={isFilledWidth ? 'disabled' : null}
                                    variant="secondary"
                                    onChange={e => handleCornerRounding(e.target.value)}
                                />
                            </Col>
                        </Row>

                        {/* CAROUSEL BACKGROUND */}
                        <Row className="mt-5">
                            <Col>
                                <ColorPicker
                                    heading="Background Color"
                                    colorProp="carouselBackgroundColor"
                                    parentColor={carouselBackgroundColor}
                                    setSelectedMessage={setSelectedMessage}
                                    moduleStyles={moduleStyles}
                                    setModuleStyles={setModuleStyles}
                                />
                            </Col>
                            <Col>
                                <GlobalStylesToggle
                                    moduleType="ImageCarousel"
                                    heading="Set as default image carousel colors"
                                    isDefaultConfig={isDefaultConfig}
                                    handleDefaultToggle={handleDefaultToggle}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* HEADERS */}
                <Row className="shared-module-background">
                    <Col className="m-0 p-0">
                        <div className="shared-heading">
                            Headers
                        </div>

                        <FormGroup>
                            <Input
                                className="shared-input"
                                type="input"
                                onChange={handleHeader}
                                value={header || ''}
                                style={{ borderRadius: '6px' }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <Input
                                className="shared-input"
                                type="input"
                                onChange={handleSubHeader}
                                value={subHeader || ''}
                                style={{ borderRadius: '6px' }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                {/* BUTTON DETAILS */}
                <Row className="shared-module-background">
                    <Col className="m-0 p-0">
                        {currentCarouselCards && currentCarouselCards[carouselIndex].buttons.map((button, index) => (
                            <Row
                                className="mb-5 mx-0 p-0"
                                key={`button_container_${carouselIndex}_${index}`}
                            >
                                <Col className="m-0 p-0">
                                    {/* BUTTON TEXT */}
                                    {(currentCarouselCards || currentCarouselCards === '') &&
                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    key={`buttonText_${carouselIndex}_${index}`}
                                                >
                                                    <div className="shared-heading">
                                                        {`Button #${index + 1} Text:`}
                                                    </div>
                                                    <Input
                                                        className="shared-input"
                                                        type="input"
                                                        value={currentCarouselCards[carouselIndex].buttons[index].buttonText || ''}
                                                        onChange={(e) => handleButtonText(e, index)}
                                                        style={{ borderRadius: '6px' }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }

                                    {/* BUTTON LINK */}
                                    {(currentCarouselCards || currentCarouselCards === '') &&
                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    key={`buttonLink_${carouselIndex}_${index}`}
                                                >
                                                    <div className="shared-heading">
                                                        {`Button # ${index + 1} Link:`}
                                                    </div>
                                                    <Input
                                                        className="shared-input"
                                                        type="input"
                                                        placeholder="Enter external URL"
                                                        value={currentCarouselCards[carouselIndex].buttons[index].buttonCommand || ''}
                                                        onChange={(e) => handleButtonLink(e, index)}
                                                        style={{ borderRadius: '6px' }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }

                                    {/* CONFIGURE PATH & DROPDOWN */}
                                    {(node_branches[button.buttonID]) &&
                                        <Row>
                                            {/* CONFIGURE PATH */}
                                            <Col>
                                                {node_branches[button.buttonID].linkingData &&
                                                node_branches[button.buttonID].linkingData.nodeBranch &&
                                                node_branches[button.buttonID].linkingData.nodeBranch.title &&
                                                    <div
                                                        key={`node_branch_${carouselIndex}_${index}`}
                                                    >
                                                        <Fade
                                                            in
                                                            tag="p"
                                                            className="m-0 p-0"
                                                        >
                                                            {`Linked to ${node_branches[index].linkingData.nodeBranch.title}`}
                                                        </Fade>
                                                    </div>
                                                }
                                                {(!node_branches[button.buttonID].linkingData || !node_branches[button.buttonID].linkingData.nodeBranch) &&
                                                    <>
                                                        <ConfigurePathButton
                                                            answer={node_branches[button.buttonID].title}
                                                            reference={reference}
                                                            configureNodeBranchingPath={configureNodeBranchingPath}
                                                        />
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    }

                                    {/* NO BUTTON WARNING */}
                                    {node_branches.length === 0 &&
                                        <p className="text-danger">
                                            A button module must have at least one button.
                                        </p>
                                    }
                                </Col>
                            </Row>
                        ))}

                        <Row className="mt-5">
                            <Col>
                                <Button
                                    className="btn-secondary-custom"
                                    onClick={handleNewButtons}
                                >
                                    <AiFillPlusCircle size="23" style={buttonIconStyles}/>
                                    Add button
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className="btn-secondary-custom"
                                    onClick={() => handleRemoveButton()}
                                    id="Popover1"
                                    type="button"
                                >
                                    <AiFillMinusCircle size="23" style={buttonIconStyles}/>
                                    Remove button
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* CARD NUMBER */}
                <Row>
                    <Col>
                        <ButtonGroup>
                            {currentCarouselCards && currentCarouselCards.map((carousel, index) => (
                                <ButtonToggle
                                    key={`${carousel.buttons.buttonText}_${index}`}
                                    type="radio"
                                    color="secondary"
                                    outline={index !== carouselIndex}
                                    name="radio"
                                    value={index + 1}
                                    className="mb-1"
                                    checked={radioValue === index + 1}
                                    onChange={e => setRadioValue(e.currentTarget.value)}
                                    onClick={() => handleNewCarouselPageChange(index)}
                                >
                                    {index + 1}
                                </ButtonToggle>
                            ))}
                        </ButtonGroup>
                        <div className="shared-heading ml-1">
                            Card Number
                        </div>
                    </Col>
                </Row>

                {/* BRANCHING CHOICES */}
                <NodeBranchingOptions
                    nodeBranches={node_branches}
                    setSelectedMessage={setSelectedMessage}
                />

                {/* UPLOAD LEFT WALLPAPER HORIZONTAL LAYOUT */}
                <ImageUpload
                    wallpaper
                    imageUrl={horizontalImageUrl}
                    imageFile={horizontalImageFile}
                    horizontalBgSize={horizontalBgSize}
                    horizontalBgRepeat={horizontalBgRepeat}
                    wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                    setSelectedMessage={setSelectedMessage}
                />
            </Col>
        </Row>
    );
};

export default Carousel;
