/* eslint-disable camelcase */
import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import Axios from 'axios';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const Rating = ({
    campaign,
    moduleIndex,
    userId,
    starCount,
    starColor,
    emptyStarColor,
    unselectedBgColor,
    selectedBgColor,
    rating,
    ratingIcon,
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    handleNodeBranching,
    loadWallpaperImage,
    setMessageProp,
    node_branches,
    setShowCelebration,
    setCelebration,
    chatbotActive,
    hasBeenClicked,
    moduleIdentifier,
}) => {
    const handleBranching = () => {
        if (horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl,
                horizontalBgSize,
                horizontalBgRepeat,
                wallpaperExternalLinkUrl,
            });
        }

        if (node_branches[0] && !hasBeenClicked) {
            handleNodeBranching(node_branches[0], moduleIndex);
        }
    };

    const handleSubmitUserInput = (rating) => {
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();
        const campURL = window.location.href;
        const userAnalytics = [];
        const userDataObject = {};
        userDataObject.Rating = rating;
        userDataObject.ModuleIdentifier = moduleIdentifier || 'No Identifier';
        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref || testTitle;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campURL;
        userAnalytics.push(userDataObject);

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log(`Success!: ${response.data}`);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        console.log({ userAnalytics });
    };

    const onAnswer = (rating) => {
        if (!chatbotActive) { return; }

        handleBranching();

        setCelebration({ name: 'Hearts', type: 'hearts' });
        setShowCelebration(true);
        setMessageProp(moduleIndex, 'rating', rating);
        handleSubmitUserInput(rating);
    };

    const unselectedStyles = {
        border: '1px solid #DDDDDD',
        backgroundColor: unselectedBgColor,
    };

    const selectedStyles = {
        backgroundColor: selectedBgColor,
    };

    const iconStyles = {
        fontSize: '35px',
        letterSpacing: '10px',
    };

    return (
        <div className="review-container">
            <div
                className="ratings-box"
                style={hasBeenClicked ? selectedStyles : unselectedStyles}
            >
                <StarRatingComponent
                    name="rating"
                    starCount={starCount}
                    onStarClick={(rating) => !hasBeenClicked && onAnswer(rating)}
                    renderStarIcon={() => <span style={iconStyles}>{ratingIcon}</span>}
                    starColor={starColor}
                    emptyStarColor={emptyStarColor}
                    value={rating}
                />
            </div>
        </div>
    );
};

export default Rating;