import { findConfigurationByKeyword } from './configurationHelper';
import { converNodeBranchToUnderscore } from './nodeBranchingHelper';

const getNextConfigurationSet = (nodeBranchPayload, configurationTriggers) => {
    const { campaignID, campaignName, uid, answer } = JSON.parse(nodeBranchPayload);
    const keyword = converNodeBranchToUnderscore(`${campaignName}_${campaignID}_${uid}_${answer}`);
    return findConfigurationByKeyword(configurationTriggers, keyword);
};

export const checkForBranches = (nodeBranch, configurationTriggers) => {
    const configuredNodeBranches = getNextConfigurationSet(nodeBranch.payload, configurationTriggers);
    if (configuredNodeBranches.length) {
        return true;
    }
    return false;
};

export const setAnswered = ({ moduleIndex, answeredIndex, setMessageModules }) => {
    //  keeps state of answered index for Quick Reply buttons
    setMessageModules(messageModules => {
        const newMessageModules = [...messageModules];
        newMessageModules[moduleIndex] = {
            ...newMessageModules[moduleIndex],
            props: {
                ...newMessageModules[moduleIndex].props,
                answeredIndex,
            },
        };
        return newMessageModules;
    });
};

export const handleBranching = ({
    nodeBranch,
    moduleIndex,
    replyMessage = null,
    setMessageModules,
    processNodeBranchingMessages,
    chatbotActive,
    configurationTriggers,
}) => {
    if (!chatbotActive) { return; }

    // disables subsequent clicking of module
    setMessageModules(messageModules => {
        const newMessageModules = [...messageModules];
        newMessageModules[moduleIndex] = {
            ...newMessageModules[moduleIndex],
            props: {
                ...newMessageModules[moduleIndex].props,
                hasBeenClicked: true,
            },
        };
        return newMessageModules;
    });

    let nextConfigurationSet;
    if (replyMessage) {
        nextConfigurationSet = [replyMessage, ...getNextConfigurationSet(nodeBranch.payload, configurationTriggers)];
    } else {
        nextConfigurationSet = getNextConfigurationSet(nodeBranch.payload, configurationTriggers);
    }

    processNodeBranchingMessages({
        setMessageModules,
        configurationSet: nextConfigurationSet,
        moduleIndex,
        isTriggerModuleRemoved: nodeBranch.isTriggerModuleRemoved,
        isTruncatedModules: nodeBranch.isTruncatedModules,
    });
};

export const loadWallpaper = (
    chatbotActive,
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    setWallpaperImage,
    setWallpaperSize,
    setWallpaperRepeat,
    setWallpaperExternalLinkUrl,
    campaignHorizontalImage,
) => {
    if (!chatbotActive) { return; }

    if (horizontalImageUrl) {
        setWallpaperImage(horizontalImageUrl);
        setWallpaperSize(horizontalBgSize);
        setWallpaperRepeat(horizontalBgRepeat);
    } else {
        setWallpaperImage(campaignHorizontalImage);
    }

    setWallpaperExternalLinkUrl(wallpaperExternalLinkUrl);
};

export const setMessageModuleProp = (setMessageModules, moduleIndex, prop, value) => {
    setMessageModules(messageModules => {
        const newMessageModules = [...messageModules];
        newMessageModules[moduleIndex] = {
            ...newMessageModules[moduleIndex],
            props: {
                ...newMessageModules[moduleIndex].props,
                [prop]: value,
            },
        };
        return newMessageModules;
    });
};