import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { BsImage, BsXCircle } from 'react-icons/bs';
import { IMAGE_PLACEHOLDER_URL, VALID_IMAGE_TYPES } from '../../../config';
import imageModulePlaceholder from '../../../assets/images/image-module-placeholder.png';
import { checkIfImageUrlValid, getImageFileName } from '../../../helpers/regexHelpers';
import { generatePayload } from '../../../helpers/nodeBranchingHelper';
import { checkSharedMessageError } from '../ModuleConfigurations/errorHelper';

const ImageUploadButton = ({
    campaign,
    reference,
    branchIndex,
    imageUrl,
    imageFile,
    setSelectedMessage,
}) => {
    const [errorImageUpload, setErrorImageUpload] = useState(false);
    const [imageURL, setImageURL] = useState('');
    const [errorImageURL, setErrorImageURL] = useState(false);
    const [hasImageUrl, setHasImageUrl] = useState(false);
    const [displayFileName, setDisplayFileName] = useState('');

    useEffect(() => {
        if (imageUrl && imageUrl !== '' && imageUrl !== IMAGE_PLACEHOLDER_URL) {
            setHasImageUrl(true);
        } else {
            setHasImageUrl(false);
        }
    }, [imageUrl]);

    useEffect(() => {
        if (imageFile && imageFile.name) {
            setDisplayFileName(`${imageFile.name}`);
        } else if (imageUrl) {
            const fileName = getImageFileName(imageUrl);
            setDisplayFileName(`${fileName}`);
        }
    }, [imageFile, imageUrl]);

    const handleImageChange = (e) => {
        if (!e.target.files[0]) {
            return;
        }

        const imageFile = e.target.files[0];
        if (!VALID_IMAGE_TYPES.includes(imageFile.type)) {
            setErrorImageUpload(true);
        } else {
            setDisplayFileName(imageFile.name);
            setErrorImageUpload(false);
            setErrorImageURL(false);
            setImageURL('');
            setSelectedMessage(selectedMessage => {
                const newNodeBranches = [...selectedMessage.message.props.node_branches];
                newNodeBranches[branchIndex] = {
                    ...newNodeBranches[branchIndex],
                    imageUrl: URL.createObjectURL(imageFile),
                    imageFile,
                    payload: generatePayload(campaign, reference, URL.createObjectURL(imageFile)),
                };

                return {
                    ...selectedMessage,
                    message: {
                        ...selectedMessage.message,
                        props: {
                            ...selectedMessage.message.props,
                            node_branches: newNodeBranches,
                            error: !imageFile || checkSharedMessageError(selectedMessage.message.props) ||
                                !VALID_IMAGE_TYPES.includes(imageFile.type),
                        },
                    },
                };
            });
            // resets value to initial state because if same picture is uploaded there is no state change
            e.target.value = '';
        }
    };

    const handleImageUrlChange = (e) => {
        const { value } = e.target;

        if (!checkIfImageUrlValid(value)) {
            setErrorImageURL(true);
        } else {
            setErrorImageURL(false);
        }
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[branchIndex] = {
                ...newNodeBranches[branchIndex],
                imageUrl: value || '',
                imageFile: null,
            };
            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                        error: !value ||
                            checkSharedMessageError(selectedMessage.message.props) ||
                            !checkIfImageUrlValid(value),
                    },
                },
            };
        });
        setImageURL(value);
    };

    const handleRemoveImage = (index) => {
        setDisplayFileName('');
        setErrorImageUpload(false);
        setErrorImageURL(false);
        setImageURL('');
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[index] = {
                ...newNodeBranches[index],
                imageUrl: imageModulePlaceholder,
                imageFile: null,
            };
            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                        error: selectedMessage.message.props.error ||
                            (!imageFile ||
                            checkSharedMessageError(selectedMessage.message.props) ||
                            !VALID_IMAGE_TYPES.includes(imageFile.type)),
                    },
                },
            };
        });
    };

    const RemoveImage = () => (
        <>
            <button
                className="shared-remove-file-button"
                type="submit"
                onClick={() => handleRemoveImage()}
            >
                <BsXCircle
                    size="15px"
                    style={{ color: '#F83667' }}
                />
            </button>
        </>
    );

    return (
        <Row>
            <Col>
                {/* UPLOAD BUTTON */}
                <FormGroup className="mb-0 pb-0">
                    <Label
                        className="shared-media-upload"
                        // for="image"
                        for={`image_${branchIndex}`}
                    >
                        <BsImage
                            size="18px"
                            color="#000"
                            style={{ margin: '0 12px 0 5px' }}
                        />
                        <p>Upload or drag image here</p>
                    </Label>
                    <div
                        style={{
                            width: '100%',
                            height: '25px',
                            display: 'flex',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <p className="pt-2 description-font">{displayFileName}</p>
                        {(imageFile || hasImageUrl) && <RemoveImage/>}
                    </div>
                </FormGroup>

                <div className="shared-media-info">or</div>

                {/* IMAGE URL TEXT BOX */}
                <FormGroup>
                    <Input
                        className="shared-input"
                        type="textarea"
                        placeholder="Enter image URL"
                        value={imageUrl && imageUrl !== IMAGE_PLACEHOLDER_URL && imageURL === '' && !imageFile ?
                            imageUrl : imageURL}
                        onChange={handleImageUrlChange}
                    />
                    {errorImageURL &&
                        <p className="error-message">Please enter valid URL</p>
                    }
                </FormGroup>

                {/* ERROR MESSAGE */}
                <FormGroup>
                    <Input
                        type="file"
                        // name="image"
                        // id="image"
                        name={`image_${branchIndex}`}
                        id={`image_${branchIndex}`}
                        onChange={handleImageChange}
                    />
                    {errorImageUpload &&
                        <p className="error-message">
                            Please upload image in valid format (JPG, JPEG, PNG, GIF, BMP)
                        </p>
                    }
                </FormGroup>
            </Col>
        </Row>
    );
};

export default ImageUploadButton;