import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Layouts
import SidebarLayout from './layouts/SidebarLayout';

// Containers
import PrivateRoute from './containers/PrivateRoute/PrivateRoute';
import Login from './containers/Login/Login';
import Home from './containers/Home';
import Templates from './containers/Templates';
import CreateCampaign from './containers/CreateCampaign';
import Insights from './containers/Insights/Insights';
import ChatbotButterfinger from './containers/Chatbot/chatbotButterfinger';
import ChatbotMarketing from './containers/Chatbot/chatbotMarketing';
import Chatbot from './containers/Chatbot';
import ChatbotCointreau from './containers/Chatbot/chatbotCointreau';
import ChatbotSalesDemo from './containers/Chatbot/chatbotSalesDemo';
import ChatbotFeatureDemo from './containers/Chatbot/chatbotFeatureDemo';
import GoogleVisionDemo from './containers/Chatbot/googleVisionDemo';
import ChatbotConfiguration from './containers/ChatbotConfiguration';

// Components
import NotFound from './components/NotFound/NotFound';
import QRLandingPage from './components/Chat/QRLandingPage';
import Register from './components/Register/Register';
import Unauthorized from './components/Unauthorized/Unauthorized';
import UserInfo from './components/UserInfo/UserInfo';

export const history = createBrowserHistory();

// --

//  TODO: expand on this for client url whitelabeling
// console.log(window.location.toString());

// console.log(`history.location.pathname: ${history.location.pathname}`);

// TODO: Remove this when the tequila campaign is over
if (window.location.toString().includes('dev.brandchat.me') && window.location.toString().includes('MW8AxlhW')) {
    window.location.href = 'https://app.brandchat.me/M6y8FqC8D';
}

// when back button brings users to the login page in the history
// TODO: do a more correct way of preventing /login from being accessed

// virtual tequila
if (window.location.toString().includes('virtualtequila') && window.location.toString().includes('login')) {
    if (history.location.pathname === '/z06Mx+1Ry' || history.location.pathname === '/drizly') {
        window.location.href = 'https://virtualtequila/drizly';
    } else {
        window.location.href = 'https://virtualtequila/MW8AxlhWq';
    }
}

// AZ Milk Mash Potato Magic
if (window.location.toString().includes('azmilkmashedpotatomagic') && window.location.toString().includes('login')) {
    if (history.location.pathname === '/lb+hzaIjf') {
        window.location.href = 'https://azmilkmashedpotatomagic.com/lb+hzaIjf';
    } else {
        window.location.href = 'https://azmilkmashedpotatomagic.com/lb+hzaIjf';
    }
}

// ResMed
if (window.location.toString().includes('exploreresmed') && window.location.toString().includes('login')) {
    if (history.location.pathname === '/x2KWWCBk6') {
        window.location.href = 'https://exploreresmed.live/x2KWWCBk6';
    } else {
        window.location.href = 'https://exploreresmed.live/x2KWWCBk6';
    }
}

// Virtual Tequila
if (window.location.toString().includes('virtualtequila') && window.location.toString().includes('drizly')) {
    // do nothing, stay on drizly
    // history.replace('/z06Mx+1Ry');
} else if (window.location.toString().includes('virtualtequila') && window.location.toString().includes('z06Mx+1Ry')) {
    history.replace('/drizly');
} else if (window.location.toString().includes('virtualtequila')) {
    history.replace('/MW8AxlhWq');
}

// AZ Milk Mash Potato Magic
if (window.location.toString().includes('azmilkmashedpotatomagic')) {
    history.replace('/lb+hzaIjf');
}

// ResMed
if (window.location.toString().includes('exploreresmed')) {
    history.replace('/x2KWWCBk6');
}

if (window.Cypress) {
    window.tgHistory = history;
}

const Routes = () => (
    <Router history={history}>
        <Switch>
            <Route exact path="/chat" component={QRLandingPage}/>
            <Route exact path="/chatbot-butterfinger" component={ChatbotButterfinger}/>
            <Route exact path="/chatbot-cointreau" component={ChatbotCointreau}/>
            <Route exact path="/chatbot-salesdemo" component={ChatbotSalesDemo}/>
            <Route exact path="/chatbot-featuresdemo" component={ChatbotFeatureDemo}/>
            <Route exact path="/chatbot-marketingdemo" component={ChatbotMarketing}/>
            <Route exact path="/ScannerDemo" component={GoogleVisionDemo}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/register" component={Register}/>
            <Route exact path="/unauthorized" component={Unauthorized}/>
            <PrivateRoute exact path="/" component={Home} layout={SidebarLayout}/>
            <PrivateRoute exact path="/user-info" component={UserInfo} layout={SidebarLayout}/>
            <PrivateRoute exact path="/insights" component={Insights} layout={SidebarLayout}/>
            <PrivateRoute exact path="/choose-template" component={Templates} layout={SidebarLayout}/>
            <PrivateRoute exact path="/duplicate-template" component={CreateCampaign} layout={SidebarLayout}/>
            <PrivateRoute exact path="/create-campaign" component={CreateCampaign} layout={SidebarLayout}/>
            <PrivateRoute exact path="/campaign/edit" component={CreateCampaign} layout={SidebarLayout}/>
            <PrivateRoute exact path="/campaign/chatbot-configuration" component={ChatbotConfiguration} layout={SidebarLayout}/>
            <Route exact path="/:ref" component={Chatbot}/>
            <Route exact path="/curr3nt-preview/:ref" component={Chatbot}/>
            <Route component={NotFound}/>
        </Switch>
    </Router>
);

export default Routes;