import React from 'react';
import { connect } from 'react-redux';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Image } from 'react-bootstrap';
import { history } from '../../../routes';

// Icons
import {
    curr3ntLogo,
    selectedGraph,
    selectedGrid,
    selectedUser,
    unselectedGraph,
    unselectedGrid,
    unselectedUser,
} from '../../../assets/images';

const SidebarItems = ({ screen }) => {
    const handleCampaignSelected = () => {
        const { pathname } = window.location;
        if (pathname === '/campaign/edit' || pathname === '/campaign/chatbot-configuration') {
            history.push('/');
        }
    };

    const handleCampaignUnselected = () => {
        history.push('/');
    };

    return (
        <div className="sidebar">
            <div className="mt-4 mb-5 text-center">
                <img
                    src={curr3ntLogo}
                    alt="Brandchat"
                    className="header h-25"
                    height="40"
                    width="50"
                />
            </div>

            <ListGroup className="list-group list-group-flush mt-5">

                {/* screen prop is the current route path. The selected icon
                will display based on what path the broswer is at.
                Clicking on an icon WON'T select it. */}
                {screen === 'home' ?

                    // CAMPAIGN SELECTED
                    <ListGroupItem
                        className="sidebar-btn list-group-item-action list-item-selected"
                        onClick={() => handleCampaignSelected()}
                    >
                        <Image
                            src={selectedGrid}
                            width="30px"
                            height="30px"
                            style={{ margin: '4px' }}
                        />
                    </ListGroupItem> :

                    // CAMPAIGN UNSELECTED
                    <ListGroupItem
                        className="sidebar-btn list-group-item-action list-item-unselected"
                        onClick={() => handleCampaignUnselected()}
                    >
                        <Image
                            src={unselectedGrid}
                            width="30px"
                            height="30px"
                            style={{ margin: '4px' }}
                        />
                    </ListGroupItem>
                }

                {screen === 'insights' ?

                    // INSIGHTS SELECTED
                    <ListGroupItem
                        title="Insights"
                        className="list-group-item-action list-item-selected"
                        id="dynamicForm"
                    >
                        <Image
                            src={selectedGraph}
                            width="30px"
                            height="30px"
                        />
                    </ListGroupItem> :

                    // INSIGHTS UNSELECTED
                    <ListGroupItem
                        title="Insights"
                        className="list-group-item list-item-unselected"
                        style={{ cursor: 'default' }}
                        id="dynamicForm"
                    >
                        <Image
                            src={unselectedGraph}
                            width="30px"
                            height="30px"
                        />
                    </ListGroupItem>
                }

                {/* USER */}
                {screen === 'userInfo' ?

                    // USER SELECTED
                    <ListGroupItem
                        title="Account Profile"
                        className="list-group-item-action list-item-selected"
                        id="logout"
                    >
                        <Image
                            src={selectedUser}
                            width="30px"
                            height="30px"
                        />
                    </ListGroupItem> :

                    // USER UNSELECTED
                    <ListGroupItem
                        title="Account Profile"
                        className="list-group-item-action list-item-unselected"
                        id="logout"
                        onClick={() => history.push('/user-info')}
                    >
                        <Image
                            src={unselectedUser}
                            width="30px"
                            height="30px"
                        />
                    </ListGroupItem>
                }
            </ListGroup>
        </div>
    );
};

export default connect((state) => ({
    language: state.language.selectedLanguage,
    screen: state.shared.screen,
}), null)(SidebarItems);
