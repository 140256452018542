import jwtDecode from 'jwt-decode';
import authorization from './authorization';

export default function checkPermission(route) {
    const token = localStorage.getItem('x-access-token');
    if (!token) {
        return false;
    }
    const decodedToken = jwtDecode(token);
    const userPermissions = authorization[decodedToken.role].routes;
    return userPermissions.indexOf(route) > -1;
}
