module.exports = {
    user: {
        routes: [
            '/',
            '/calendar',
            '/invitation',
            '/user-info',
            '/dynamic-form',
            '/choose-template',
            '/duplicate-template',
            '/create-campaign',
            '/insights',
            '/campaign/edit',
            '/campaign/chatbot-configuration',
        ],
    },
    admin: {
        routes: [
            '/',
            '/calendar',
            '/invitation',
            '/user-info',
            '/admin',
            '/dynamic-form',
            '/campaign/modulesConfiguration',
            '/campaign/chatbot-configuration',
            '/choose-template',
            '/duplicate-template',
            '/create-campaign',
            '/insights',
            '/campaign/edit',
            '/campaign/chatbot-configuration',
        ],
    },
};