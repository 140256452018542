import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Row, Col, Container } from 'reactstrap';
import Navbar from '../components/Shared/Navbar/Navbar';
import 'rc-drawer/assets/index.css';
import SidebarItems from '../components/Shared/SidebarItems/SidebarItems';

const SidebarLayout = (props) => {
    const [open, setOpen] = useState(false);
    const { component } = props;

    return (
        <div className="sidebar-layout-container d-flex">
            <Container
                fluid
                className="m-0 p-0"
            >
                <Row className="min-vh-100 m-0 p-0">
                    <Col className="d-flex justify-content-start m-0 p-0">
                        <div className="sidebar-col text-center shadow">
                            <SidebarItems/>
                        </div>
                        <div className="body-container">
                            {/* TOP NAVBAR */}
                            <Row>
                                <Col className="position-relative w-100">
                                    <Navbar
                                        handleDrawerOpen={() => setOpen(!open)}
                                        open={open}
                                    />
                                </Col>
                            </Row>

                            {/* BOTTOM CAMPAIGN CARDS */}
                            <Row className="overflow-auto component-container">
                                <Col>
                                    {component}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default SidebarLayout;