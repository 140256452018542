import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Image } from 'react-bootstrap';
import { BsArrowCounterclockwise } from 'react-icons/bs';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import Reward from 'react-rewards';
import AgeGateModal from '../Modules/AgeGate';
import { checkForPreviewURL } from '../../helpers/previewUrlCheckHelper';

const ChatbotLayout = ({
    campaign,
    children,
    showPreview,
    previewWidth,
    loadChatTree,
    showCelebration,
    celebration,
    wallpaperImage,
    wallpaperSize,
    wallpaperRepeat,
    wallpaperExternalLinkUrl,
}) => {
    const [showChatTree, setShowChatTree] = useState(!campaign.ageRestriction);
    const [celebrationVisible, setCelebrationVisible] = useState(false);
    const [isPreviewURL, setPreviewURL] = useState(false);
    const [container, setContainer] = useState('chatbot-container');
    const [wallpaperContainer, setWallpaperContainer] = useState('chatbot-wallpaper-container');
    const [layoutContainer, setLayoutContainer] = useState('chatbot-layout-container');

    const {
        styles,
        logo,
        ageGateLogo,
        bg_image: outerBgImage,
        interior_bg_image: interiorBgImage,
        restart_image: restartImage,
        horizontal_layout: horizontalLayout,
        restart_capable: restartCapable,
    } = campaign;

    let reward = useRef();
    const rewardConfig = {
        confetti: {
            lifetime: 200,
            angle: 90,
            decay: 0.91,
            spread: 85,
            startVelocity: 55,
            elementCount: 256,
            elementSize: 8,
            colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
        },

        smileys: {
            lifetime: 300,
            angle: 90,
            decay: 0.91,
            spread: 360,
            startVelocity: 55,
            elementCount: 256,
            elementSize: 35,
            emoji: ['😃', '😄', '😆', '😊'],
        },

        hearts: {
            lifetime: 300,
            angle: 90,
            decay: 0.91,
            spread: 360,
            startVelocity: 55,
            elementCount: 256,
            elementSize: 35,
            emoji: ['💗', '💕', '❤️', '💜'],
        },

        custom: {
            lifetime: 300,
            angle: 90,
            decay: 0.91,
            spread: 360,
            startVelocity: 55,
            elementCount: 256,
            elementSize: 35,
            emoji: celebration && celebration.emoji,
        },
    };

    useEffect(() => {
        setPreviewURL(checkForPreviewURL(window.location));
    }, []);

    useEffect(() => {
        if (showPreview && horizontalLayout && parseInt(previewWidth, 10) < 500) {
            setContainer('preview-container');
            setWallpaperContainer('preview-wallpaper-container');
            setLayoutContainer('preview-layout-container');
        } else if (horizontalLayout) {
            setContainer('horizontal-container');
            setWallpaperContainer('horizontal-wallpaper-container');
            setLayoutContainer('horizontal-layout-container');
        } else {
            setContainer('chatbot-container');
            setLayoutContainer('chatbot-layout-container');
        }
    }, [previewWidth, campaign]);

    const closeAgeGate = () => {
        setShowChatTree(true);
        loadChatTree();
    };

    useEffect(() => {
        if (showCelebration) {
            setCelebrationVisible(true);
            setTimeout(() => setCelebrationVisible(false), 3000);

            const fetchSomeData = () => {
                reward.rewardMe();
                reward.punishMe();
            };
            fetchSomeData();
        }
    }, [showCelebration]);

    let outerBorderRadius = '0';
    if (isPreviewURL && !horizontalLayout) {
        outerBorderRadius = '3px 3px 41px 41px';
    } else if (showPreview && horizontalLayout) {
        outerBorderRadius = '0 5px 5px 0';
    } else if (showPreview && !horizontalLayout) {
        outerBorderRadius = '5px';
    }

    const WallpaperBackground = () => (
        <div
            className="wallpaper-image"
            alt="left side wallpaper"
            style={{
                backgroundImage: `url(${wallpaperImage})`,
                backgroundSize: wallpaperSize || '100% 100%',
                backgroundRepeat: wallpaperRepeat || 'repeat',
            }}
        />
    );

    const handleRestart = () => {
        if (!showPreview) {
            window.location.reload();
        }
    };

    return (
        <div
            className={container}
            style={{ borderRadius: showPreview ? '5px' : '0' }}
        >
            {horizontalLayout &&
                <div
                    className={wallpaperContainer}
                    style={{ backgroundColor: (styles && styles.outerBgColor) || '#DDD' }}
                >
                    {wallpaperImage && wallpaperExternalLinkUrl &&
                        <a
                            className="wallpaper-link"
                            href={wallpaperExternalLinkUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <WallpaperBackground/>
                        </a>
                    }

                    {wallpaperImage && !wallpaperExternalLinkUrl && <WallpaperBackground/>}
                </div>
            }

            <ReactCSSTransitionGroup
                transitionName="chatbot-animation"
                transitionAppear
                transitionAppearTimeout={1}
                transitionEnter={false}
                transitionLeave={false}
                style={{
                    width: horizontalLayout ? '50%' : '100%',
                    backgroundColor: (styles && styles.outerBgColor) || 'transparent',
                    borderRadius: outerBorderRadius,
                }}
            >
                <Container
                    fluid
                    className={layoutContainer}
                    key="chatbot_layout_container"
                    style={{
                        backgroundImage: `url(${outerBgImage})`,
                        backgroundColor: (styles && styles.outerBgColor) || 'transparent',
                        backgroundSize: (styles && styles.outerBgSize) || '100% 100%',
                        backgroundRepeat: (styles && styles.outerBgRepeat) || 'repeat',
                        borderRadius: outerBorderRadius,
                    }}
                >
                    {showChatTree &&
                        <Row
                            className="interior-box"
                            style={{
                                borderRadius: styles && styles.interiorBorderRadius ? `${styles.interiorBorderRadius}px` : '20px',
                                backgroundImage: `url(${interiorBgImage})`,
                                backgroundColor: (styles && styles.interiorBgColor) || 'transparent',
                                backgroundSize: (styles && styles.interiorBgSize) || '100% 100%',
                                backgroundRepeat: (styles && styles.interiorBgRepeat) || 'repeat',
                            }}
                        >
                            <Col className="h-100 px-0">
                                {styles && !styles.bottomLogo && logo &&
                                    <Row className="logo-area">
                                        <Col className="h-100">
                                            <Image
                                                className="logo"
                                                alt="Campaign icon"
                                                src={logo}
                                                style={{ maxHeight: `${styles && styles.logoHeight}px` }}
                                            />
                                        </Col>
                                    </Row>
                                }

                                {/* MESSAGE MODULES */}
                                <Row
                                    className="msg-area"
                                    style={{ height: (styles && !styles.bottomLogo && logo) ? '85%' : '100%' }}
                                >
                                    <Col className="m-0 p-0">
                                        {children}
                                        <div className="mb-5"/>
                                    </Col>

                                    {restartCapable &&
                                        <Col className={horizontalLayout ? 'restart-horizontal' : 'restart'}>
                                            {restartImage ?
                                                <button
                                                    className="restart-chat-button"
                                                    type="submit"
                                                    onClick={() => handleRestart()}
                                                >
                                                    <Image
                                                        className="restart-image"
                                                        src={restartImage}
                                                        style={{ height: styles && styles.restartImageHeight ?
                                                            `${styles.restartImageHeight}px` : '35px' }}
                                                    />
                                                </button> :

                                                <BsArrowCounterclockwise
                                                    className="restart-icon"
                                                    size="40px"
                                                    onClick={() => handleRestart()}
                                                    style={{
                                                        backgroundColor: (styles && styles.restartButtonBgColor) || '#FFF',
                                                        color: (styles && styles.restartButtonTextColor) || '#000',
                                                    }}
                                                />
                                            }
                                        </Col>
                                    }

                                    {styles && styles.bottomLogo && logo &&
                                        <Col className="floating-logo-area">
                                            <Image
                                                className="floating-logo"
                                                alt="Campaign icon"
                                                src={logo}
                                                style={{ maxHeight: `${styles && styles.logoHeight}px` }}
                                            />
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>
                    }

                    {!showChatTree &&
                        <AgeGateModal
                            logo={ageGateLogo}
                            className="ageGateModal-component"
                            closeAgeGate={closeAgeGate}
                        />
                    }

                    <div className={`confetti-row ${celebrationVisible ? 'show-confetti' : ''}`}>
                        <Reward
                            ref={ref => { reward = ref; }}
                            type={celebration && celebration.type === 'confetti' ? 'confetti' : 'emoji'}
                            config={celebration && rewardConfig[celebration.type]}
                        >
                            <div/>
                        </Reward>
                    </div>
                </Container>
            </ReactCSSTransitionGroup>
        </div>
    );
};

export default ChatbotLayout;