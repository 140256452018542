import React from 'react';
import { Row, Col, FormGroup, Input } from 'reactstrap';
import { IoMdAnalytics } from 'react-icons/io';

const ModuleIdentifier = ({ reference, moduleIdentifier, setSelectedMessage }) => {
    const handleChange = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    moduleIdentifier: value,
                },
            },
        }));
    };

    return (
        <Row className="shared-module-background">
            <Col className="m-0 p-0">
                <FormGroup>
                    <div className="shared-heading">
                        <IoMdAnalytics
                            size="18px"
                            style={{ margin: '0 10px 0 3px', color: '#000' }}
                        />
                        Module-click ID for analytics (recommended)
                    </div>
                    <Input
                        className="shared-input"
                        type="input"
                        id={`module_indentifier_input_${reference}`}
                        placeholder="Enter a unique module name"
                        value={moduleIdentifier}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </FormGroup>
            </Col>
        </Row>
    );
};
export default ModuleIdentifier;
