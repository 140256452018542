/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Col,
    CustomInput,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import { BsXCircle, BsImage, BsDash, BsPlus } from 'react-icons/bs';
import { IoIosAddCircleOutline } from 'react-icons/io';
import DatePicker from 'react-datepicker';
import { toastr } from 'react-redux-toastr';
import RangeSlider from 'react-bootstrap-range-slider';
import { getCampaignModulesConfiguration, updateCampaignConfiguration } from '../../services/campaign';
import {
    BackgroundImageSizing,
    CollapsibleSection,
    ColorPicker,
    CustomizationField,
    HintBox,
    ImageUrlInput,
} from '../ChatbotConfiguration/SharedConfigurations';
import ChatbotLayout from '../Chatbot/ChatbotLayout';
import ChatbotCore from '../Chatbot/ChatbotCore';
import MessageModule from '../Chatbot/MessageModule';
import { history } from '../../routes';
import campaignAvatarPlaceholder from '../../assets/images/campaign-avatar-placeholder.png';
import campaignLogoPlaceholder from '../../assets/images/campaign-logo-placeholder.png';
import { ONE_MONTH_LENGTH, VALID_IMAGE_TYPES } from '../../config';
import { changeScreen } from '../../redux/shared/actions';
import ModalQrCode from '../../components/ModalQRCode';
import ModalUTM from '../../components/ModalUTM';
import Modal from '../../components/Modal';
import { getBase64GUID } from '../../helpers/base64';
import { convertSpaceToUnderscore, getImageFileName } from '../../helpers/regexHelpers';
import { defaultCustomData, defaultCampaignStyles, defaultModuleStyles } from '../../helpers/customizationsHelper';
import { campaignLimitForUser, modalDescription } from '../../helpers/accountSettingshelper';
import { MODULE_TYPES } from '../Modules/constants';
import TabBar from './TabBar';
import {
    getCampaigns,
    createCampaign,
    editCampaign,
    duplicateCampaignTemplate,
    deleteCampaign,
} from '../../redux/campaign/actions';
import 'react-datepicker/dist/react-datepicker.css';

const CreateCampaign = ({
    location,
    campaigns,
    getCampaigns,
    createCampaign,
    editCampaign,
    duplicateCampaignTemplate,
    deleteCampaign,
}) => {
    // CAMPAIGN DATA
    const editCampaignData = location.state || {};
    const isEdit = !!location.state;
    const isDuplicate = location.pathname === '/duplicate-template';
    const [name, setName] = useState((editCampaignData && editCampaignData.name) || '');
    const [customData, setCustomData] = useState(editCampaignData && editCampaignData.custom_data ?
        editCampaignData.custom_data : JSON.stringify({ isCustomized: false }));
    const [customCampaignData, setCustomCampaignData] = useState(defaultCustomData);
    useEffect(() => {
        if (JSON.parse(customData).isCustomized) {
            setCustomCampaignData(JSON.parse(customData));
        }
    }, [editCampaignData]);

    // CAMPAIGN OPTIONS
    const [startDate, setStartDate] = useState(editCampaignData && editCampaignData.start_date ?
        new Date(editCampaignData.start_date) : new Date());
    const [endDate, setEndDate] = useState(editCampaignData && editCampaignData.end_date ?
        new Date(editCampaignData.end_date) : new Date(Date.now() + ONE_MONTH_LENGTH));
    const [active, setActive] = useState((editCampaignData && editCampaignData.active) || true);
    const [demo, setDemo] = useState((editCampaignData && editCampaignData.demo) || false);
    const [ageGateRequired, setAgeGateRequired] = useState((editCampaignData && editCampaignData.age_gate_required) || false);
    const [landingPageRequired, setLandingPageRequired] = useState((editCampaignData && editCampaignData.landing_page) || false);
    const [horizontalLayout, setHorizontalLayout] = useState((editCampaignData && editCampaignData.horizontal_layout) || false);

    const companyName = (editCampaignData && editCampaignData.company_name) || '';
    const [waterfallLayout, setWaterfallLayout] = useState((editCampaignData && editCampaignData.waterfall_layout) || false);
    const [isCustomized, setCustomized] = useState(editCampaignData && editCampaignData.custom_data ?
        JSON.parse(editCampaignData.custom_data).isCustomized : false);
    const [addScriptsHeight, setAddScriptsHeight] = useState('0');
    const [addFieldsHeight, setAddFieldsHeight] = useState('0');
    // eslint-disable-next-line no-unused-vars
    const [isTemplate, setTemplate] = useState((editCampaignData && editCampaignData.template) || false);
    const [card, setCard] = useState((editCampaignData && editCampaignData.card) || false);
    const [cardLock, setCardLock] = useState((editCampaignData && editCampaignData.card_lock) || false);
    const [restartCapable, setRestartCapable] = useState((editCampaignData && editCampaignData.restart_capable) || false);
    const [googleAnalyticsTag, setGoogleAnalyticsTag] = useState((editCampaignData && editCampaignData.google_analytics_tag) || null);

    // STYLES
    const parseStyles = (defaultStyles, styles) => {
        let parsedStyles = defaultStyles;
        if (editCampaignData && editCampaignData[styles]) {
            if (typeof editCampaignData[styles] === 'string') {
                parsedStyles = JSON.parse(editCampaignData[styles]);
            } else if (typeof editCampaignData[styles] === 'object') {
                parsedStyles = editCampaignData[styles];
            }
        }
        return parsedStyles;
    };
    const [campaignStyles, setCampaignStyles] = useState(editCampaignData && editCampaignData.styles ?
        parseStyles(defaultCampaignStyles, 'styles') : defaultCampaignStyles);
    const [moduleStyles, setModuleStyles] = useState(editCampaignData && editCampaignData.module_styles ?
        parseStyles(defaultModuleStyles, 'module_styles') : defaultModuleStyles);

    const [avatar, setAvatar] = useState(editCampaignData && editCampaignData.avatar);
    const [avatarBase64, setAvatarBase64] = useState((editCampaignData && editCampaignData.avatar) || campaignAvatarPlaceholder);
    const [avatarSliderValue, setAvatarSliderValue] = useState((editCampaignData && editCampaignData.styles &&
        editCampaignData.styles.avatarDiameter) || 25);

    const [logo, setLogo] = useState(editCampaignData && editCampaignData.logo);
    const [logoBase64, setLogoBase64] = useState((editCampaignData && editCampaignData.logo) || campaignLogoPlaceholder);
    const [logoSliderValue, setLogoSliderValue] = useState((editCampaignData && editCampaignData.styles &&
        editCampaignData.styles.logoHeight) || 50);
    const [bottomLogo, setBottomLogo] = useState((editCampaignData && editCampaignData.styles &&
        editCampaignData.styles.bottomLogo) || false);

    const [restartImage, setRestartImage] = useState(editCampaignData && editCampaignData.restart_image);
    const [restartImageBase64, setRestartImageBase64] = useState(editCampaignData && editCampaignData.restart_image);
    const [restartSliderValue, setRestartSliderValue] = useState((editCampaignData && editCampaignData.styles &&
        editCampaignData.styles.restartImageHeight) || 50);

    const [outerBgImage, setOuterBgImage] = useState(editCampaignData && editCampaignData.bg_image);
    const [outerBgImageBase64, setOuterBgImageBase64] = useState((editCampaignData && editCampaignData.bg_image) || ' ');

    const [interiorBgImage, setInteriorBgImage] = useState(editCampaignData && editCampaignData.interior_bg_image);
    const [interiorBgImageBase64, setInteriorBgImageBase64] = useState((editCampaignData && editCampaignData.interior_bg_image) || ' ');
    const [interiorRadiusSliderValue, setInteriorRadiusSliderValue] = useState((editCampaignData && editCampaignData.styles &&
        editCampaignData.styles.interiorBorderRadius) || 20);

    const [landingPageImageBase64, setLandingPageImageBase64] = useState((editCampaignData && editCampaignData.landing_page_image) || ' ');
    const [landingPageImage, setLandingPageImage] = useState(editCampaignData && editCampaignData.landing_page_image);
    const [horizontalImageBase64, setHorizontalImageBase64] = useState((editCampaignData && editCampaignData.horizontal_image) || null);
    const [horizontalImage, setHorizontalImage] = useState(editCampaignData && editCampaignData.horizontal_image);

    const [showQRModal, setShowQRModal] = useState(false);
    const [errorImageUpload, setErrorImageUpload] = useState(false);
    const keyWords = (editCampaignData && editCampaignData.key_words) || [];
    const [currentCampaign, setCurrentCampaign] = useState(editCampaignData);
    const [showSpinner, setShowSpinner] = useState(false);
    const [errorSaveCampaign, setErrorSaveCampaign] = useState(false);
    const [chatbotConfiguration, setChatbotConfiguration] = useState(null);
    const [missingImages, setMissingImages] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [showCelebration, setShowCelebration] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [celebration, setCelebration] = useState('');

    const [showUtmModal, setShowUtmModal] = useState(false);
    const [utmSource, setUtmSource] = useState('');
    const [utmMedium, setUtmMedium] = useState('');
    const [utmCampaign, setUtmCampaign] = useState('');
    const [utmTerm, setUtmTerm] = useState('');
    const [utmContent, setUtmContent] = useState('');
    const [UTM, setUTM] = useState('');
    const [isAdditionalUtmParameters, setAdditionalUtmParameters] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showCampaignLimitModal, setShowCampaignLimitModal] = useState(false);

    const chevronIconStyles = {
        size: '18px',
        style: { margin: '1px 0 0 10px', color: '#000' },
    };

    const cardId = (editCampaignData && editCampaignData.card_id) || getBase64GUID();

    useEffect(() => {
        if (isEdit) {
            changeScreen('editCampaign');
            const getCampaignConfiguration = async () => {
                const campaignConfiguration = (await getCampaignModulesConfiguration(editCampaignData.id)).data;
                if (campaignConfiguration && campaignConfiguration[0] && campaignConfiguration[0].configuration) {
                    setChatbotConfiguration(campaignConfiguration[0].configuration);
                }
            };
            getCampaignConfiguration();
        } else {
            changeScreen('createCampaign');
        }
    }, []);

    useEffect(() => {
        if (!campaigns.length) {
            getCampaigns({
                archived: false,
                company_name: localStorage.getItem('company-name'),
            });
        }
    }, []);

    const admin = localStorage.getItem('role') === 'admin';
    const adminCreate = admin && isEdit && !isDuplicate;
    const userCreate = !admin && !isTemplate && isEdit;
    const numberOfCampaigns = campaigns.filter(campaign => !campaign.template).length;
    const reader = new FileReader();
    const isFormValid = () => name.length > 3;
    const accountPermissions = JSON.parse(localStorage.getItem('account-permissions'));

    const getCampaignConfigurationTrigger = async (result) => {
        const configurationTriggers = (await getCampaignModulesConfiguration(result.data.id)).data;
        if (configurationTriggers && configurationTriggers[0] && configurationTriggers[0].configuration) {
            await updateCampaignConfiguration(configurationTriggers, result.data);
        }
    };

    const handleCampaignSubmit = async (e) => {
        e.preventDefault();
        if (!isFormValid()) {
            toastr.error('Please check the input errors in your campaign');
            return;
        }
        if (!isEdit && !avatar) {
            setMissingImages(true);
            return;
        }
        setMissingImages(false);
        try {
            let result = {};
            const formData = new FormData();
            setShowQRModal(true);
            setShowSpinner(true);

            // EDIT CAMPAIGN
            if (isEdit && !isDuplicate) {
                formData.append('campaign', JSON.stringify({
                    id: editCampaignData && editCampaignData.id,
                    name,
                    active,
                    demo,
                    card,
                    card_id: cardId,
                    card_lock: cardLock,
                    company_name: companyName,
                    custom_data: customData,
                    restart_capable: restartCapable,
                    google_analytics_tag: googleAnalyticsTag,
                    age_gate_required: ageGateRequired || false,
                    landing_page: landingPageRequired || false,
                    horizontal_layout: horizontalLayout || false,
                    waterfall_layout: waterfallLayout || false,
                    template: isTemplate || false,
                    start_date: startDate,
                    end_date: endDate,
                    avatar: avatar ? avatar.toString() : null,
                    logo: logo ? logo.toString() : null,
                    bg_image: outerBgImage ? outerBgImage.toString() : null,
                    interior_bg_image: interiorBgImage ? interiorBgImage.toString() : null,
                    landing_page_image: landingPageImage ? landingPageImage.toString() : null,
                    horizontal_image: horizontalImage ? horizontalImage.toString() : null,
                    restart_image: restartImage ? restartImage.toString() : null,
                    ref: editCampaignData.ref,
                    styles: JSON.stringify(campaignStyles),
                    module_styles: JSON.stringify(moduleStyles),
                }));
                formData.append('avatar', avatar);
                if (logo) {
                    formData.append('logo', logo);
                } else {
                    formData.delete('logo');
                }
                if (outerBgImage) {
                    formData.append('bg_image', outerBgImage);
                } else {
                    formData.delete('bg_image');
                }
                if (interiorBgImage) {
                    formData.append('interior_bg_image', interiorBgImage);
                } else {
                    formData.delete('interior_bg_image');
                }
                if (landingPageImage) {
                    formData.append('landing_page_image', landingPageImage);
                } else {
                    formData.delete('landing_page_image');
                }
                if (horizontalImage) {
                    formData.append('horizontal_image', horizontalImage);
                } else {
                    formData.delete('horizontal_image');
                }
                if (restartImage) {
                    formData.append('restart_image', restartImage);
                } else {
                    formData.delete('restart_image');
                }
                result = await editCampaign(formData);

            // DUPLICATE
            } if (isEdit && isDuplicate) {
                formData.append('campaign', JSON.stringify({
                    id: editCampaignData && editCampaignData.id,
                    name,
                    active,
                    demo,
                    card,
                    card_id: cardId,
                    card_lock: cardLock,
                    company_name: localStorage.getItem('company-name'),
                    custom_data: customData,
                    restart_capable: restartCapable,
                    google_analytics_tag: googleAnalyticsTag,
                    age_gate_required: ageGateRequired || false,
                    landing_page: landingPageRequired || false,
                    horizontal_layout: horizontalLayout || false,
                    waterfall_layout: waterfallLayout || false,
                    template: false,
                    key_words: keyWords && keyWords.length ? keyWords : [],
                    ref: convertSpaceToUnderscore(name),
                    avatar: avatar ? avatar.toString() : null,
                    logo: logo ? logo.toString() : null,
                    bg_image: outerBgImage ? outerBgImage.toString() : null,
                    interior_bg_image: interiorBgImage ? interiorBgImage.toString() : null,
                    landing_page_image: landingPageImage ? landingPageImage.toString() : null,
                    horizontal_image: horizontalImage ? horizontalImage.toString() : null,
                    restart_image: restartImage ? restartImage.toString() : null,
                    start_date: startDate,
                    end_date: endDate,
                    styles: JSON.stringify(campaignStyles),
                    module_styles: JSON.stringify(moduleStyles),
                }));
                formData.append('avatar', avatar);
                if (logo) { formData.append('logo', logo); }
                if (outerBgImage) { formData.append('bg_image', outerBgImage); }
                if (interiorBgImage) { formData.append('interior_bg_image', interiorBgImage); }
                if (landingPageImage) { formData.append('landing_page_image', landingPageImage); }
                if (horizontalImage) { formData.append('horizontal_image', horizontalImage); }
                if (restartImage) { formData.append('restart_image', restartImage); }
                result = await duplicateCampaignTemplate(formData);

            // CREATE CAMPAIGN
            } if (!isEdit) {
                formData.append('campaign', JSON.stringify({
                    name,
                    active,
                    demo,
                    card,
                    card_id: getBase64GUID(),
                    card_lock: cardLock,
                    company_name: localStorage.getItem('company-name'),
                    custom_data: customData,
                    restart_capable: restartCapable,
                    google_analytics_tag: googleAnalyticsTag,
                    age_gate_required: ageGateRequired || false,
                    landing_page: landingPageRequired || false,
                    horizontal_layout: horizontalLayout || false,
                    waterfall_layout: waterfallLayout || false,
                    template: isTemplate,
                    key_words: keyWords && keyWords.length ? keyWords : [],
                    ref: convertSpaceToUnderscore(name),
                    avatar: avatar ? avatar.toString() : null,
                    logo: logo ? logo.toString() : null,
                    bg_image: outerBgImage ? outerBgImage.toString() : null,
                    interior_bg_image: interiorBgImage ? interiorBgImage.toString() : null,
                    landing_page_image: landingPageImage ? landingPageImage.toString() : null,
                    horizontal_image: horizontalImage ? horizontalImage.toString() : null,
                    restart_image: restartImage ? restartImage.toString() : null,
                    start_date: startDate,
                    end_date: endDate,
                    styles: JSON.stringify(campaignStyles),
                    module_styles: JSON.stringify(defaultModuleStyles),
                }));
                formData.append('avatar', avatar);
                if (logo) { formData.append('logo', logo); }
                if (outerBgImage) { formData.append('bg_image', outerBgImage); }
                if (interiorBgImage) { formData.append('interior_bg_image', interiorBgImage); }
                if (landingPageImage) { formData.append('landing_page_image', landingPageImage); }
                if (horizontalImage) { formData.append('horizontal_image', horizontalImage); }
                if (restartImage) { formData.append('restart_image', restartImage); }
                result = await createCampaign(formData);
            }

            if (result && result.data && result.data.ref) {
                setShowSpinner(false);
                setCurrentCampaign(result.data);

                // After the campaign is duplicated in the DB, this updates the duplicated
                // configuration because there is a bug that is fixed by reprocessing the data
                // into a string. Otherwise, the duplication doesn't work properly without the
                // user saving the configuration in the Configure screen.
                getCampaignConfigurationTrigger(result);
            }
        } catch (e) {
            setShowSpinner(false);
            setErrorSaveCampaign(true);
            console.log(e);
        }
    };

    const campaign = {
        id: editCampaignData.id,
        name,
        demo,
        card,
        cardLock,
        card_id: cardId,
        active: editCampaignData.active,
        companyName,
        avatar: avatarBase64,
        logo: logoBase64,
        bg_image: outerBgImageBase64,
        interior_bg_image: interiorBgImageBase64,
        landing_page_image: landingPageImageBase64,
        horizontal_image: horizontalImageBase64,
        restart_image: restartImageBase64,
        horizontal_layout: horizontalLayout,
        waterfall_layout: waterfallLayout,
        custom_data: customData,
        template: isTemplate,
        styles: campaignStyles,
        module_styles: moduleStyles,
        restart_capable: restartCapable,
        google_analytics_tag: googleAnalyticsTag,
    };

    const handleAgeGateToggle = () => {
        setAgeGateRequired(!ageGateRequired);
        if (!landingPageRequired) { setLandingPageRequired(true); }
    };

    const handleLandingPageToggle = () => {
        setLandingPageRequired(!landingPageRequired);
        if (landingPageRequired && ageGateRequired) {
            setAgeGateRequired(false);
        }
    };

    const removeBGImage = () => {
        setOuterBgImageBase64('');
        setOuterBgImage('');
    };

    const removeInteriorImage = () => {
        setInteriorBgImageBase64('');
        setInteriorBgImage('');
    };

    const removeLandingPageImage = () => {
        setLandingPageImageBase64('');
        setLandingPageImage('');
    };

    const removeHorizontalImage = () => {
        setHorizontalImageBase64('');
        setHorizontalImage('');
    };

    const removeLogoImage = () => {
        setLogo('');
        setLogoBase64('');
    };

    const removeRestartImage = () => {
        setRestartImage('');
        setRestartImageBase64('');
    };

    const handleImageChange = (e, setImageBase64, setImage) => {
        if (!e.target.files[0]) {
            return;
        }

        const file = e.target.files[0];
        if (!VALID_IMAGE_TYPES.includes(file.type)) {
            setErrorImageUpload(true);
            return;
        }

        reader.readAsDataURL(file);
        reader.onload = () => {
            setErrorImageUpload(false);
            setImageBase64(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };

        setImage(file);
    };

    const handleCampaignNameChange = (e) => {
        setName(e.target.value);
    };

    // COLOR PICKER HANDLERS
    const getHexCode = (color) => {
        const checkIfHex = (color) => /^#[0-9A-F]{6,8}$/i.test(color);
        const isColorHex = checkIfHex(color);
        if (isColorHex) {
            return color;
        }

        const decimalToHex = (alpha: number) => (alpha === 0 ? '00' : Math.round(255 * alpha).toString(16));
        let hexCode = `${color.hex}${decimalToHex(color.rgb.a)}`;
        const isHexCorrect = checkIfHex(hexCode);
        if (!isHexCorrect) {
            hexCode = `${hexCode.toString().substring(0, 7)}00`;
        }
        return hexCode;
    };

    const handleOuterBackgroundColorChange = (color) => {
        const hexCode = getHexCode(color);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            outerBgColor: hexCode,
        }));
    };

    const handleInteriorBackgroundColorChange = (color) => {
        const hexCode = getHexCode(color);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            interiorBgColor: hexCode,
        }));
    };

    const handleRestartButtonBgColorChange = (color) => {
        const hexCode = getHexCode(color);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            restartButtonBgColor: hexCode,
        }));
    };

    const handleRestartButtonTextColorChange = (color) => {
        const hexCode = getHexCode(color);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            restartButtonTextColor: hexCode,
        }));
    };

    const getFileTitle = (image) => (image && image.name) || getImageFileName(image);

    useEffect(() => {
        if (!isEdit && avatar) {
            setMissingImages(false);
        }
    }, [avatar]);

    const UploadImage = () => (
        <>
            <BsImage
                size="15px"
                color="#756682"
                style={{ marginRight: '7px' }}
            />
            Upload
        </>
    );

    const RemoveImage = ({ removeImage }) => (
        <>
            <button
                className="shared-remove-file-button"
                type="submit"
                onClick={() => removeImage()}
                // style={{ border: '0', backgroundColor: 'transparent' }}
            >
                <BsXCircle
                    size="15px"
                    style={{ marginBottom: '7px', color: '#F83667' }}
                />
            </button>
        </>
    );

    const handleChangeField = (e, name) => {
        e.preventDefault();
        const { value } = e.target;

        setCustomCampaignData(customCampaignData => ({
            ...customCampaignData,
            isCustomized: true,
            [name]: value,
        }));

        setCustomData(JSON.stringify({
            ...customCampaignData,
            isCustomized: true,
            [name]: value,
        }));
    };

    const handleChangeLinkFields = (e, index, name) => {
        e.preventDefault();
        const { value } = e.target;

        setCustomCampaignData(customCampaignData => {
            const newSiteTermsLinks = [...customCampaignData.siteTermsLinks];
            newSiteTermsLinks[index] = {
                ...newSiteTermsLinks[index],
                [name]: value,
            };

            setCustomData(JSON.stringify({
                ...customCampaignData,
                isCustomized: true,
                siteTermsLinks: newSiteTermsLinks,
            }));

            return {
                ...customCampaignData,
                isCustomized: true,
                siteTermsLinks: newSiteTermsLinks,
            };
        });
    };

    const handleDeleteLinkFields = (e, index) => {
        e.preventDefault();

        setCustomCampaignData(customCampaignData => {
            const newSiteTermsLinks = [];
            for (let i = 0; i < customCampaignData.siteTermsLinks.length; i++) {
                if (i !== index) {
                    newSiteTermsLinks.push(customCampaignData.siteTermsLinks[i]);
                }
            }

            setCustomData(JSON.stringify({
                ...customCampaignData,
                isCustomized: true,
                siteTermsLinks: newSiteTermsLinks,
            }));

            return {
                ...customCampaignData,
                isCustomized: true,
                siteTermsLinks: newSiteTermsLinks,
            };
        });
    };

    const handleNewLinkField = (e) => {
        e.preventDefault();

        setCustomCampaignData(customCampaignData => {
            const newSiteTermsLinks = [...customCampaignData.siteTermsLinks];
            newSiteTermsLinks.push({ label: '', url: '' });

            setCustomData(JSON.stringify({
                ...customCampaignData,
                isCustomized: true,
                siteTermsLinks: newSiteTermsLinks,
            }));

            return {
                ...customCampaignData,
                isCustomized: true,
                siteTermsLinks: newSiteTermsLinks,
            };
        });
    };

    // const handleSaveAsTemplate = () => {
    //     setTemplate(true);
    // };

    const handleDuplicateCampaign = async () => {
        if (admin || numberOfCampaigns < campaignLimitForUser) {
            if (!isFormValid()) {
                toastr.error('Please check the input errors in your campaign');
                return;
            }
            if (!isEdit && !avatar) {
                setMissingImages(true);
                return;
            }

            setMissingImages(false);
            try {
                let result = {};
                const formData = new FormData();
                setShowQRModal(true);
                setShowSpinner(true);

                formData.append('campaign', JSON.stringify({
                    id: editCampaignData && editCampaignData.id,
                    name,
                    active,
                    demo,
                    card,
                    card_id: cardId,
                    card_lock: cardLock,
                    company_name: companyName,
                    custom_data: customData,
                    restart_capable: restartCapable,
                    google_analytics_tag: googleAnalyticsTag,
                    age_gate_required: ageGateRequired || false,
                    landing_page: landingPageRequired || false,
                    horizontal_layout: horizontalLayout || false,
                    waterfall_layout: waterfallLayout || false,
                    template: false,
                    key_words: keyWords && keyWords.length ? keyWords : [],
                    ref: convertSpaceToUnderscore(name),
                    avatar: avatar ? avatar.toString() : null,
                    logo: logo ? logo.toString() : null,
                    bg_image: outerBgImage ? outerBgImage.toString() : null,
                    interior_bg_image: interiorBgImage ? interiorBgImage.toString() : null,
                    landing_page_image: landingPageImage ? landingPageImage.toString() : null,
                    horizontal_image: horizontalImage ? horizontalImage.toString() : null,
                    restart_image: restartImage ? restartImage.toString() : null,
                    start_date: startDate,
                    end_date: endDate,
                    styles: JSON.stringify(campaignStyles),
                    module_styles: JSON.stringify(moduleStyles),
                }));
                formData.append('avatar', avatar);
                if (logo) { formData.append('logo', logo); }
                if (outerBgImage) { formData.append('bg_image', outerBgImage); }
                if (interiorBgImage) { formData.append('interior_bg_image', interiorBgImage); }
                if (landingPageImage) { formData.append('landing_page_image', landingPageImage); }
                if (horizontalImage) { formData.append('horizontal_image', horizontalImage); }
                if (restartImage) { formData.append('restart_image', restartImage); }
                result = await duplicateCampaignTemplate(formData);

                if (result && result.data && result.data.ref) {
                    setShowSpinner(false);
                    setCurrentCampaign(result.data);

                    // After the campaign is duplicated in the DB, this updates the duplicated
                    // configuration because there is a bug that is fixed by reprocessing the data
                    // into a string. Otherwise, the duplication doesn't work properly without the
                    // user saving the configuration in the Configure screen.
                    getCampaignConfigurationTrigger(result);
                }
            } catch {
                setShowSpinner(false);
                setErrorSaveCampaign(true);
                console.log('error');
            }
        } else {
            setShowCampaignLimitModal(true);
        }
    };

    const handleDiscardChanges = async () => {
        history.push('/');
    };

    const handleDeleteCampaign = async () => {
        setShowDeleteModal(true);
    };

    const confirmDeleteCampaign = async () => {
        try {
            const result = await deleteCampaign(campaign.id);
            if (result && result.data) {
                history.push('/');
            }
        } catch {
            console.log('error');
        }
    };

    const handleExit = () => {
        history.push('/');
    };

    const handleConfigure = () => {
        history.push('/campaign/chatbot-configuration', currentCampaign);
    };

    const handleLogoSlider = (value) => {
        setLogoSliderValue(value);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            logoHeight: value.toString(),
        }));
    };

    const handleRestartSlider = (value) => {
        setRestartSliderValue(value);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            restartImageHeight: value.toString(),
        }));
    };

    const handleAvatarSlider = (value) => {
        setAvatarSliderValue(value);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            avatarDiameter: value.toString(),
        }));
    };

    const handleInteriorRadiusSlider = (value) => {
        setInteriorRadiusSliderValue(value);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            interiorBorderRadius: value.toString(),
        }));
    };

    const handleBottomLogo = (value) => {
        setBottomLogo(value);
        setCampaignStyles(campaignStyles => ({
            ...campaignStyles,
            bottomLogo: value,
        }));
    };

    const handleCreateUTM = (e) => {
        e.preventDefault();
        setShowUtmModal(true);
        const domain = 'https://app.curr3nt.io';
        const URL = `${domain}/${location.state.ref}/`;
        setUTM(`${URL}?utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`);
        if (utmTerm && isAdditionalUtmParameters) {
            setUTM(UTM => `${UTM}&utm_term=${utmTerm}`);
        }
        if (utmContent && isAdditionalUtmParameters) {
            setUTM(UTM => `${UTM}&utm_content=${utmContent}`);
        }
    };

    const hasPermissionFor = (...args) => {
        if (admin) { return true; }

        if (accountPermissions) {
            for (let i = 0; i < args.length; i++) {
                const arg = args[i];
                if (accountPermissions[arg]) {
                    return true;
                }
            }
        }
        return false;
    };

    const handleGoogleAnalyticsChange = (e) => {
        setGoogleAnalyticsTag(e.target.value);
    };

    const tips = {
        campaignName: 'The name of your campaign (e.g. Holiday campaign product X).',
        dates: 'Start and end date that the campaign is running for.',
        activeInactive: 'Turn campaign on or off.',
        liveDemo: 'Live on or off for data collection and cookies.',
        waterfallLayout: 'Specific use cases; learn more in the user manual.',
        horizontalLayout: 'Horizontal format to be primarly used on desktop but can also be vertical.',
        ageGate: 'Specific use cases, contact us to learn how to activate and add terms and conditions related to it.',
        landingPage: 'A fullscreen image at the start of the campaign.',
        avatar: 'This is your icon in the campaign; learn more in the user manual.',
        logo: 'This is your logo in the campaign; learn more in the user manual.',
        outerBgImage: 'Add an image to the main background color in the campaign.',
        interiorBgImage: 'Add an image to the background behind the text and images in the campaign.',
        outerBgColor: 'Main background color in the campaign.',
        interiorBgColor: 'Color of the background behind the text, images in the campaign.',
        restartButton: 'Add a restart button to let users start over from the top in the campaign.',
        cardPopUp: 'Specific use cases; learn more in the user manual.',
        cardOnly: 'Will prevent visitor access to the camapign.',
        siteTerms: 'Terms & conditions for the unit that can be customized and linked if needed.',
        utm: 'Add campaign parameters to measure Custom Campaigns in Google Analytics etc.',
        googleAnalytics: 'Add your Google Analytics tag, example: UA-XXXXX-Y',
    };

    return (
        <>
            <TabBar
                campaign={currentCampaign}
                switchScreens={handleCampaignSubmit}
            />

            <Row className="create-campaign-container">
                {/* CAMPAIGN OPTIONS */}
                <Col
                    md={5}
                    className="m-0 pl-0 pr-5"
                >
                    <Form onSubmit={handleCampaignSubmit}>
                        {/* OPTIONS */}
                        <Row>
                            <Col className="campaign-options">
                                {/* NAME INPUT */}
                                <Row className="form-row shared-module-background">
                                    <Col>
                                        <FormGroup className="m-0 p-0">
                                            <Label htmlFor="campaignName">
                                                <div className="option-heading">
                                                    {isTemplate ? 'Template Name' : 'Campaign Name'}
                                                    <HintBox tip={tips.campaignName}/>
                                                </div>
                                            </Label>
                                            <Input
                                                className="shared-input"
                                                valid={name && name.length > 3}
                                                invalid={name && name.length < 4}
                                                type="text"
                                                name="campaignName"
                                                value={name}
                                                id="campaignName"
                                                onChange={handleCampaignNameChange}
                                                required
                                            />
                                            <FormFeedback invalid="true">
                                                Name should be at least 4 characters long
                                            </FormFeedback>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* DATES */}
                                {hasPermissionFor('dates') &&
                                    <Row className="form-row shared-module-background">
                                        <Col className="react-datepicker-wrapper">
                                            <Label>
                                                <div
                                                    className="option-heading"
                                                    style={{ color: hasPermissionFor('dates') ? '#000' : '#CCC' }}
                                                >
                                                    Start Date
                                                    <HintBox tip={tips.dates}/>
                                                </div>
                                            </Label>
                                            <DatePicker
                                                showPopperArrow={false}
                                                selected={startDate}
                                                onChange={date => setStartDate(date)}
                                                calendarClassName="react-datepicker"
                                                required
                                            />
                                        </Col>
                                        <Col className="react-datepicker-wrapper">
                                            <Label>
                                                <div
                                                    className="option-heading"
                                                    style={{ color: hasPermissionFor('dates') ? '#000' : '#CCC' }}
                                                >
                                                    End Date
                                                </div>
                                            </Label>
                                            <DatePicker
                                                showPopperArrow={false}
                                                selected={endDate}
                                                onChange={date => setEndDate(date)}
                                                calendarClassName="react-datepicker"
                                                required
                                            />
                                        </Col>
                                    </Row>
                                }

                                {/* ACTIVE/INACTIVE - DEMO/LIVE */}
                                {hasPermissionFor('activeInactive', 'demoLive') &&
                                    <Row className="form-row shared-module-background">
                                        {/* ACTIVE/INACTIVE */}
                                        <Col>
                                            <FormGroup className="m-0 p-0">
                                                <Label>
                                                    <div
                                                        className="option-heading"
                                                        style={{ color: hasPermissionFor('activeInactive') ? '#000' : '#DDD' }}
                                                    >
                                                        Active
                                                        <HintBox tip={tips.activeInactive}/>
                                                    </div>
                                                </Label>
                                                <CustomInput
                                                    type="switch"
                                                    id="idSwitch"
                                                    name="idSwitch"
                                                    onChange={() => setActive(!active)}
                                                    label=""
                                                    checked={active}
                                                    disabled={!hasPermissionFor('activeInactive')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* LIVE/DEMO */}
                                        <Col>
                                            <FormGroup className="m-0 p-0">
                                                <Label>
                                                    <div
                                                        className="option-heading"
                                                        style={{ color: hasPermissionFor('demoLive') ? '#000' : '#DDD' }}
                                                    >
                                                        Live
                                                        <HintBox tip={tips.liveDemo}/>
                                                    </div>
                                                </Label>
                                                <CustomInput
                                                    type="switch"
                                                    id="demoSwitch"
                                                    name="demoSwitch"
                                                    onChange={() => setDemo(!demo)}
                                                    label=""
                                                    checked={!demo}
                                                    disabled={!hasPermissionFor('demoLive')}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }

                                {/* LAYOUTS - LEFT WALLPAPER UPLOAD */}
                                {hasPermissionFor('waterfallLayout', 'horizontalLayout') &&
                                    <Row className="form-row shared-module-background">
                                        {/* WATERFALL LAYOUT */}
                                        <Col>
                                            <FormGroup className="m-0 p-0">
                                                <Label>
                                                    <div
                                                        className="option-heading d-block"
                                                        style={{ color: hasPermissionFor('waterfallLayout') ? '#000' : '#DDD' }}
                                                    >
                                                        Waterfall Layout
                                                        <HintBox tip={tips.waterfallLayout}/>
                                                    </div>
                                                </Label>
                                                <CustomInput
                                                    type="switch"
                                                    id="waterfallLayoutSwitch"
                                                    name="waterfallLayoutSwitch"
                                                    onChange={() => setWaterfallLayout(!waterfallLayout)}
                                                    label=""
                                                    checked={waterfallLayout}
                                                    disabled={!hasPermissionFor('waterfallLayout')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* HORIZONTAL LAYOUT */}
                                        <Col>
                                            <FormGroup className="m-0 p-0">
                                                <Label>
                                                    <div
                                                        className="option-heading d-block"
                                                        style={{ color: hasPermissionFor('horizontalLayout') ? '#000' : '#DDD' }}
                                                    >
                                                        Horizontal Layout
                                                        <HintBox tip={tips.horizontalLayout}/>
                                                    </div>
                                                </Label>
                                                <CustomInput
                                                    type="switch"
                                                    id="horizontalLayoutSwitch"
                                                    name="horizontalLayoutSwitch"
                                                    onChange={() => setHorizontalLayout(!horizontalLayout)}
                                                    label=""
                                                    checked={horizontalLayout}
                                                    disabled={!hasPermissionFor('horizontalLayout')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* LEFT WALLPAPER */}
                                        {horizontalLayout &&
                                            <Col
                                                md={4}
                                                className="p-0"
                                            >
                                                <FormGroup className="mt-2 p-0">
                                                    <div className="option-heading">
                                                        Left Wallpaper
                                                    </div>
                                                    <Label
                                                        for="horizontalImage"
                                                        className="btn-secondary-custom px-2"
                                                    >
                                                        <UploadImage/>
                                                    </Label>
                                                    {horizontalImage &&
                                                        <>
                                                            <p className="m-0 pt-2 description-font">
                                                                {getFileTitle(horizontalImage)}
                                                            </p>
                                                            <RemoveImage removeImage={removeHorizontalImage}/>
                                                        </>
                                                    }
                                                    <Input
                                                        type="file"
                                                        name="horizontalImage"
                                                        id="horizontalImage"
                                                        onChange={e => handleImageChange(e, setHorizontalImageBase64, setHorizontalImage)}
                                                    />
                                                </FormGroup>

                                                {horizontalImage &&
                                                    <BackgroundImageSizing
                                                        bgSize="horizontalBgSize"
                                                        bgRepeat="horizontalBgRepeat"
                                                        campaignData={campaign}
                                                        setCampaignStyles={setCampaignStyles}
                                                    />
                                                }
                                            </Col>
                                        }
                                    </Row>
                                }

                                {/* AGE GATE - LANDING PAGE */}
                                {hasPermissionFor('ageGate', 'landingPage') &&
                                    <Row className="form-row shared-module-background">
                                        {/* AGE GATE */}
                                        <Col>
                                            <FormGroup className="m-0 p-0">
                                                <Label>
                                                    <div
                                                        className="option-heading"
                                                        style={{ color: hasPermissionFor('ageGate') ? '#000' : '#DDD' }}
                                                    >
                                                        Age Gate
                                                        <HintBox tip={tips.ageGate}/>
                                                    </div>
                                                </Label>
                                                <CustomInput
                                                    type="switch"
                                                    id="ageGateSwitch"
                                                    name="ageGateSwitch"
                                                    onChange={() => handleAgeGateToggle()}
                                                    label=""
                                                    checked={ageGateRequired}
                                                    disabled={!hasPermissionFor('ageGate')}
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* LANDING PAGE */}
                                        <Col>
                                            <FormGroup className="m-0 p-0">
                                                <Label>
                                                    <div
                                                        className="option-heading"
                                                        style={{ color: hasPermissionFor('landingPage') ? '#000' : '#DDD' }}
                                                    >
                                                        Landing Page
                                                        <HintBox tip={tips.landingPage}/>
                                                    </div>
                                                </Label>
                                                <CustomInput
                                                    type="switch"
                                                    id="landingPageSwitch"
                                                    name="landingPageSwitch"
                                                    onChange={() => handleLandingPageToggle()}
                                                    label=""
                                                    disabled={!hasPermissionFor('landingPage')}
                                                    checked={landingPageRequired}
                                                />
                                            </FormGroup>
                                        </Col>

                                        {/* LANDING PAGE */}
                                        {landingPageRequired &&
                                            <Col
                                                md={4}
                                                className="p-0"
                                            >
                                                <FormGroup className="m-0 p-0">
                                                    <Label>
                                                        <div className="option-heading">
                                                            Landing Image
                                                        </div>
                                                    </Label>
                                                    <Row>
                                                        <Col>
                                                            <Label
                                                                for="campaignLandingPageImage"
                                                                className="btn-secondary-custom px-2"
                                                            >
                                                                <UploadImage/>
                                                            </Label>
                                                            {landingPageImage &&
                                                                <>
                                                                    <p className="m-0 pt-2 description-font">
                                                                        {getFileTitle(landingPageImage)}
                                                                    </p>
                                                                    <RemoveImage removeImage={removeLandingPageImage}/>
                                                                </>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Input
                                                        type="file"
                                                        name="landingPageImage"
                                                        id="campaignLandingPageImage"
                                                        onChange={e => handleImageChange(e, setLandingPageImageBase64, setLandingPageImage)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        }
                                    </Row>
                                }

                                {/* AVATAR & LOGO UPLOADS */}
                                <Row className="form-row shared-module-background">
                                    {/* AVATAR */}
                                    <Col className="pr-4">
                                        <FormGroup className="mt-2 p-0">
                                            <div className="option-heading">
                                                Avatar *required
                                                <HintBox tip={tips.avatar}/>
                                            </div>
                                            <Label
                                                for="campaignAvatar"
                                                className="btn-secondary-custom px-2"
                                            >
                                                <UploadImage/>
                                            </Label>
                                        </FormGroup>

                                        {avatar &&
                                            <p className="description-font pt-2">
                                                {getFileTitle(avatar)}
                                            </p>
                                        }

                                        <ImageUrlInput
                                            image={avatar}
                                            setImage={setAvatar}
                                        />

                                        <Input
                                            type="file"
                                            name="campaignAvatar"
                                            id="campaignAvatar"
                                            onChange={e => handleImageChange(e, setAvatarBase64, setAvatar)}
                                        />
                                        {avatar &&
                                            <Row>
                                                <Col>
                                                    <RangeSlider
                                                        value={avatarSliderValue}
                                                        min={25}
                                                        max={50}
                                                        variant="secondary"
                                                        onChange={e => handleAvatarSlider(e.target.value)}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                    </Col>

                                    {/* LOGO */}
                                    <Col className="pr-4">
                                        <FormGroup className="mt-2 p-0">
                                            <div className="option-heading">
                                                Logo
                                                <HintBox tip={tips.logo}/>
                                            </div>
                                            <Label
                                                for="campaignLogo"
                                                className="btn-secondary-custom px-2"
                                            >
                                                <UploadImage/>
                                            </Label>
                                            {logo &&
                                                <>
                                                    <p className="pt-2 description-font">
                                                        {getFileTitle(logo)}
                                                    </p>
                                                    <RemoveImage removeImage={removeLogoImage}/>
                                                </>
                                            }

                                            <ImageUrlInput
                                                image={logo}
                                                setImage={setLogo}
                                            />

                                            <Input
                                                type="file"
                                                name="campaignLogo"
                                                id="campaignLogo"
                                                onChange={e => handleImageChange(e, setLogoBase64, setLogo)}
                                            />
                                            {logo &&
                                                <>
                                                    <Row>
                                                        <Col>
                                                            <RangeSlider
                                                                value={logoSliderValue}
                                                                min={25}
                                                                max={150}
                                                                variant="secondary"
                                                                onChange={e => handleLogoSlider(e.target.value)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup className="m-0 p-0">
                                                                <Label>
                                                                    <div className="option-heading">
                                                                        Logo on bottom
                                                                    </div>
                                                                </Label>
                                                                <CustomInput
                                                                    type="switch"
                                                                    id="bottomLogoSwitch"
                                                                    name="bottomLogoSwitch"
                                                                    onChange={() => handleBottomLogo(!bottomLogo)}
                                                                    label=""
                                                                    checked={bottomLogo}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </>
                                            }
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* NO IMAGE WARNINGS */}
                                {(missingImages || errorImageUpload) &&
                                    <Row className="form-row">
                                        <Col>
                                            {missingImages &&
                                                <p className="error-message">Please upload campaign images</p>
                                            }
                                            {errorImageUpload &&
                                                <p className="error-message">Please upload image in valid format (JPG, JPEG, PNG, GIF, BMP)</p>
                                            }
                                        </Col>
                                    </Row>
                                }

                                {/* OUTER & INTERIOR BG UPLOADS */}
                                <Row className="form-row shared-module-background">
                                    {/* OUTER BACKGROUND */}
                                    <Col className="pl-1">
                                        <Row>
                                            <Col>
                                                <FormGroup className="mt-2 p-0">
                                                    <div className="option-heading">
                                                        Outer Backgound
                                                        <HintBox tip={tips.outerBgImage}/>
                                                    </div>
                                                    <Label
                                                        for="outerBgImage"
                                                        className="btn-secondary-custom px-2"
                                                    >
                                                        <UploadImage/>
                                                    </Label>
                                                    {outerBgImage &&
                                                        <>
                                                            <p className="m-0 pt-2 description-font">
                                                                {getFileTitle(outerBgImage)}
                                                            </p>
                                                            <RemoveImage removeImage={removeBGImage}/>
                                                        </>
                                                    }

                                                    <ImageUrlInput
                                                        image={outerBgImage}
                                                        setImage={setOuterBgImage}
                                                    />

                                                    <Input
                                                        type="file"
                                                        name="outerBgImage"
                                                        id="outerBgImage"
                                                        onChange={e => handleImageChange(e, setOuterBgImageBase64, setOuterBgImage)}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {outerBgImage &&
                                            <BackgroundImageSizing
                                                bgSize="outerBgSize"
                                                bgRepeat="outerBgRepeat"
                                                campaignData={campaign}
                                                setCampaignStyles={setCampaignStyles}
                                            />
                                        }
                                    </Col>

                                    {/* INTERIOR BACKGROUND */}
                                    <Col className="p-0">
                                        <FormGroup className="mt-2 p-0">
                                            <div className="option-heading">
                                                Interior Background
                                                <HintBox tip={tips.interiorBgImage}/>
                                            </div>
                                            <Label
                                                for="interiorBGImage"
                                                className="btn-secondary-custom px-2"
                                            >
                                                <UploadImage/>
                                            </Label>
                                            {interiorBgImage &&
                                                <>
                                                    <p className="m-0 pt-2 description-font">
                                                        {getFileTitle(interiorBgImage)}
                                                    </p>
                                                    <RemoveImage removeImage={removeInteriorImage}/>
                                                </>
                                            }

                                            <ImageUrlInput
                                                image={interiorBgImage}
                                                setImage={setInteriorBgImage}
                                            />

                                            <Input
                                                type="file"
                                                name="interiorBgImage"
                                                id="interiorBGImage"
                                                onInput={e => handleImageChange(e, setInteriorBgImageBase64, setInteriorBgImage)}
                                            />
                                        </FormGroup>

                                        {interiorBgImage &&
                                            <BackgroundImageSizing
                                                bgSize="interiorBgSize"
                                                bgRepeat="interiorBgRepeat"
                                                campaignData={campaign}
                                                setCampaignStyles={setCampaignStyles}
                                            />
                                        }
                                    </Col>
                                </Row>

                                {/* COLOR PICKERS */}
                                <Row className="form-row shared-module-background">
                                    <Col>
                                        {/* OUTER & INTERIOR COLOR */}
                                        <Row className="mt-1">
                                            <Col>
                                                <ColorPicker
                                                    heading={
                                                        <>
                                                            Outer Color
                                                            <HintBox tip={tips.outerBgColor}/>
                                                        </>
                                                    }
                                                    parentColor={campaignStyles && campaignStyles.outerBgColor}
                                                    handleParentColorChange={handleOuterBackgroundColorChange}
                                                    moduleStyles={moduleStyles}
                                                    setModuleStyles={setModuleStyles}
                                                    coverStyles={{ top: '260px' }}
                                                />
                                            </Col>
                                            <Col>
                                                <ColorPicker
                                                    heading={
                                                        <>
                                                            Interior Color
                                                            <HintBox tip={tips.interiorBgColor}/>
                                                        </>
                                                    }
                                                    parentColor={campaignStyles && campaignStyles.interiorBgColor}
                                                    handleParentColorChange={handleInteriorBackgroundColorChange}
                                                    moduleStyles={moduleStyles}
                                                    setModuleStyles={setModuleStyles}
                                                    coverStyles={{ top: '260px' }}
                                                />
                                            </Col>
                                        </Row>

                                        {/* INTERIOR BORDER RADIUS */}
                                        <Row className="mt-4 d-flex justify-content-end">
                                            <Col xs={6}>
                                                <div className="option-heading">
                                                    Interior Corner Rounding
                                                </div>
                                                <RangeSlider
                                                    value={interiorRadiusSliderValue}
                                                    min={0}
                                                    max={100}
                                                    variant="secondary"
                                                    onChange={e => handleInteriorRadiusSlider(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                {/* OPTIONS TOGGLE */}
                                <Row className="m-0 py-0 px-2">
                                    <Col className="m-0 p-0">
                                        <FormGroup>
                                            <Label>
                                                <div className="option-heading">
                                                    {isCustomized ? 'Hide Options' : 'More Options...'}
                                                </div>
                                            </Label>
                                            <CustomInput
                                                type="switch"
                                                id="customnDataSwitch"
                                                name="customDataSwitch"
                                                onChange={() => setCustomized(!isCustomized)}
                                                label=""
                                                checked={isCustomized}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* CUSTOMIZATIONS */}
                                {isCustomized &&
                                    <>
                                        {/* RESTART BUTTON */}
                                        <Row className="form-row shared-module-background">
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <FormGroup className="m-0 p-0">
                                                            <Label>
                                                                <div className="option-heading">
                                                                    Restart Button
                                                                    <HintBox tip={tips.restartButton}/>
                                                                </div>
                                                            </Label>
                                                            <CustomInput
                                                                type="switch"
                                                                id="restartCapable"
                                                                name="restartCapable"
                                                                onChange={() => { setRestartCapable(!restartCapable); }}
                                                                label=""
                                                                checked={restartCapable}
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                    {restartCapable &&
                                                        <Col className="pl-1">
                                                            <FormGroup className="mt-2 p-0">
                                                                <div className="option-heading">
                                                                    Button Image *optional
                                                                </div>
                                                                <Label
                                                                    for="restartImage"
                                                                    className="btn-secondary-custom px-2"
                                                                >
                                                                    <UploadImage/>
                                                                </Label>
                                                                {restartImage &&
                                                                    <>
                                                                        <p className="pt-2 description-font">
                                                                            {getFileTitle(restartImage)}
                                                                        </p>
                                                                        <RemoveImage removeImage={removeRestartImage}/>
                                                                    </>
                                                                }
                                                                <Input
                                                                    type="file"
                                                                    name="restartImage"
                                                                    id="restartImage"
                                                                    onChange={e => handleImageChange(e, setRestartImageBase64, setRestartImage)}
                                                                />
                                                                {restartImage &&
                                                                    <Row>
                                                                        <Col>
                                                                            <RangeSlider
                                                                                value={restartSliderValue}
                                                                                min={25}
                                                                                max={150}
                                                                                variant="secondary"
                                                                                onChange={e => handleRestartSlider(e.target.value)}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                    }
                                                </Row>

                                                {/* RESTART COLORS - IMAGE UPLOAD */}
                                                {restartCapable &&
                                                    <Row className="mt-4">
                                                        <Col>
                                                            <ColorPicker
                                                                heading="Button Color"
                                                                parentColor={campaignStyles && campaignStyles.restartButtonBgColor}
                                                                handleParentColorChange={handleRestartButtonBgColorChange}
                                                                moduleStyles={moduleStyles}
                                                                setModuleStyles={setModuleStyles}
                                                                coverStyles={{ top: '255px' }}
                                                                buttonPopoverStyles={{ top: '34px' }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <ColorPicker
                                                                heading="Arrow Color"
                                                                parentColor={campaignStyles && campaignStyles.restartButtonTextColor}
                                                                handleParentColorChange={handleRestartButtonTextColorChange}
                                                                moduleStyles={moduleStyles}
                                                                setModuleStyles={setModuleStyles}
                                                                coverStyles={{ top: '255px' }}
                                                                buttonPopoverStyles={{ top: '34px', left: '-94px' }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                }
                                            </Col>
                                        </Row>

                                        {/* CARD */}
                                        {hasPermissionFor('card') &&
                                            <Row className="form-row shared-module-background">
                                                <Col>
                                                    <FormGroup className="m-0 p-0">
                                                        <Label>
                                                            <div
                                                                className="option-heading"
                                                                style={{ color: hasPermissionFor('card') ? '#000' : '#DDD' }}
                                                            >
                                                                Card Pop-up
                                                                <HintBox tip={tips.cardPopUp}/>
                                                            </div>
                                                        </Label>
                                                        <CustomInput
                                                            type="switch"
                                                            id="card"
                                                            name="card"
                                                            onChange={() => { setCard(!card); setCardLock(false); }}
                                                            label=""
                                                            checked={card}
                                                            disabled={!hasPermissionFor('card')}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {card &&
                                                    <Col>
                                                        <FormGroup className="m-0 p-0">
                                                            <Label>
                                                                <div className="option-heading">
                                                                    Card Only, Disable Chat
                                                                    <HintBox tip={tips.cardOnly}/>
                                                                </div>
                                                            </Label>
                                                            <CustomInput
                                                                type="switch"
                                                                id="cardLock"
                                                                name="cardLock"
                                                                onChange={() => setCardLock(!cardLock)}
                                                                label=""
                                                                checked={cardLock}
                                                                disabled={!hasPermissionFor('card')}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                }
                                            </Row>
                                        }

                                        {/* SITE TERMS & COOKIES POP-UP */}
                                        {hasPermissionFor('cookiePopup') &&
                                            <Row className="form-row shared-module-background pb-3">
                                                <Col className="pt-2">
                                                    <div className="option-heading">
                                                        Site Terms & Cookies Pop-up
                                                        <HintBox tip={tips.siteTerms}/>
                                                    </div>

                                                    {/* TITLE */}
                                                    <CustomizationField
                                                        name="siteTermsTitle"
                                                        heading="Title"
                                                        cssClass="short-height"
                                                        value={customCampaignData.siteTermsTitle}
                                                        handleChangeField={e => { handleChangeField(e, 'siteTermsTitle'); }}
                                                    />

                                                    {customCampaignData.siteTermsLinks.map((link, index) => (
                                                        <>
                                                            <Row className="mt-5">
                                                                <Col>
                                                                    <div className="option-heading">
                                                                        {`Link #${index + 1}`}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            {/* LINK TEXT */}
                                                            <CustomizationField
                                                                name="siteTermsLinkText"
                                                                heading="Text"
                                                                cssClass="short-height"
                                                                value={link.text}
                                                                handleChangeField={e => { handleChangeLinkFields(e, index, 'text'); }}
                                                                handleDeleteField={handleDeleteLinkFields}
                                                                index={index}
                                                            />

                                                            {/* LINK URL */}
                                                            <CustomizationField
                                                                name="siteTermsLinkUrl"
                                                                heading="URL"
                                                                cssClass="short-height"
                                                                value={link.url}
                                                                handleChangeField={e => { handleChangeLinkFields(e, index, 'url'); }}
                                                            />
                                                        </>
                                                    ))}

                                                    {/* ADD NEW FIELD */}
                                                    <Row className="mt-4 mb-5 mx-0 p-0">
                                                        <Col className="m-0 p-0">
                                                            <button
                                                                className="btn-secondary-custom"
                                                                type="submit"
                                                                onClick={e => handleNewLinkField(e)}
                                                            >
                                                                <IoIosAddCircleOutline
                                                                    size="18"
                                                                    color="#756682"
                                                                    style={{ margin: '-2px 10px 0 0' }}
                                                                />
                                                                Add new link
                                                            </button>
                                                        </Col>
                                                    </Row>

                                                    {/* TEXT */}
                                                    <CustomizationField
                                                        name="siteTermsText"
                                                        heading="Text"
                                                        value={customCampaignData.siteTermsText}
                                                        handleChangeField={e => { handleChangeField(e, 'siteTermsText'); }}
                                                    />
                                                </Col>
                                            </Row>
                                        }

                                        {/* UTM */}
                                        <Row className="form-row shared-module-background">
                                            <Col className="pt-2">
                                                <div className="option-heading">
                                                    UTM
                                                    <HintBox tip={tips.utm}/>
                                                </div>
                                                <FormGroup className="m-0 p-0">
                                                    <Label htmlFor="utmSource">
                                                        <div className="option-heading">
                                                            Campaign Source*
                                                            {' '}
                                                            <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                The referrer (e.g.
                                                            </span>
                                                            {' '}
                                                            <span style={{ fontSize: '10px' }}>
                                                                <strong>Google, newsletter</strong>
                                                            </span>
                                                            <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                )
                                                            </span>
                                                        </div>
                                                    </Label>
                                                    <Input
                                                        className="shared-input"
                                                        type="text"
                                                        name="utmSource"
                                                        id="utmSource"
                                                        value={utmSource}
                                                        onChange={(e) => setUtmSource(e.target.value)}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mt-3 p-0">
                                                    <Label htmlFor="utmMedium">
                                                        <div className="option-heading">
                                                            Campaign Medium*
                                                            {' '}
                                                            <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                Marketing medium (e.g.
                                                            </span>
                                                            {' '}
                                                            <span style={{ fontSize: '10px' }}>
                                                                <strong>cpc, banner, email</strong>
                                                            </span>
                                                            <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                )
                                                            </span>
                                                        </div>
                                                    </Label>
                                                    <Input
                                                        className="shared-input"
                                                        type="text"
                                                        name="utmMedium"
                                                        id="utmMedium"
                                                        value={utmMedium}
                                                        onChange={(e) => setUtmMedium(e.target.value)}
                                                    />
                                                </FormGroup>

                                                <FormGroup className="mt-2 p-0">
                                                    <Label htmlFor="utmCampaign">
                                                        <div className="option-heading">
                                                            Campaign Name*
                                                            {' '}
                                                            <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                Product, promo code, or slogan (e.g.
                                                            </span>
                                                            {' '}
                                                            <span style={{ fontSize: '10px' }}>
                                                                <strong>brand name</strong>
                                                            </span>
                                                            <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                )
                                                            </span>
                                                        </div>
                                                    </Label>
                                                    <Input
                                                        className="shared-input"
                                                        type="text"
                                                        name="utmCampaign"
                                                        id="utmCampaign"
                                                        value={utmCampaign}
                                                        onChange={(e) => setUtmCampaign(e.target.value)}
                                                    />
                                                </FormGroup>

                                                <div className="option-heading collapsible mt-4">
                                                    Additional Parameters
                                                    {isAdditionalUtmParameters ?
                                                        <BsDash
                                                            onClick={() => setAdditionalUtmParameters(false)}
                                                            {...chevronIconStyles}
                                                        /> :
                                                        <BsPlus
                                                            onClick={() => setAdditionalUtmParameters(true)}
                                                            {...chevronIconStyles}
                                                        />
                                                    }
                                                </div>

                                                {isAdditionalUtmParameters &&
                                                    <>
                                                        <FormGroup className="mt-2 p-0">
                                                            <Label htmlFor="utmTerm">
                                                                <div className="option-heading">
                                                                    Campaign Term*
                                                                    {' '}
                                                                    <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                        Identify the paid keywords
                                                                    </span>
                                                                </div>
                                                            </Label>
                                                            <Input
                                                                className="shared-input"
                                                                type="text"
                                                                name="utmTerm"
                                                                id="utmTerm"
                                                                value={utmTerm}
                                                                onChange={(e) => setUtmTerm(e.target.value)}
                                                            />
                                                        </FormGroup>

                                                        <FormGroup className="mt-2 p-0">
                                                            <Label htmlFor="utmContent">
                                                                <div className="option-heading">
                                                                    Campaign Content*
                                                                    {' '}
                                                                    <span style={{ color: 'gray', fontSize: '10px' }}>
                                                                        Use to differentiate ads
                                                                    </span>
                                                                </div>
                                                            </Label>
                                                            <Input
                                                                className="shared-input"
                                                                type="text"
                                                                name="utmContent"
                                                                id="utmContent"
                                                                value={utmContent}
                                                                onChange={(e) => setUtmContent(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </>
                                                }

                                                <div className="w-100 d-flex justify-content-end">
                                                    <button
                                                        className="btn-secondary-custom mt-2 px-2"
                                                        type="submit"
                                                        onClick={(e) => handleCreateUTM(e)}
                                                    >
                                                        Create UTM
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* ADD ANALYTICS TAG */}
                                        {hasPermissionFor('addScripts') &&
                                            <Row className="form-row shared-module-background">
                                                <Col>
                                                    <CollapsibleSection
                                                        name="googleAnalyticsTag"
                                                        heading={
                                                            <>
                                                                Add Google Analytics / Tag ID
                                                                <HintBox tip={tips.googleAnalytics}/>
                                                            </>
                                                        }
                                                        height={addScriptsHeight}
                                                        setHeight={setAddScriptsHeight}
                                                        buttonText="Save"
                                                        hasPermission={hasPermissionFor('addScripts')}
                                                    >
                                                        <Input
                                                            className="shared-input"
                                                            valid={googleAnalyticsTag && googleAnalyticsTag.length > 3}
                                                            invalid={googleAnalyticsTag && googleAnalyticsTag.length < 1}
                                                            type="textarea"
                                                            name="googleAnalyticsTag"
                                                            value={googleAnalyticsTag}
                                                            id="googleAnalyticsTag"
                                                            onChange={handleGoogleAnalyticsChange}
                                                            disabled={!hasPermissionFor('addScripts')}
                                                            style={{
                                                                height: addScriptsHeight,
                                                                transition: 'all 0.3s ease-out',
                                                            }}
                                                        />
                                                    </CollapsibleSection>
                                                </Col>
                                            </Row>
                                        }

                                        {/* ADD SCRIPTS */}
                                        {hasPermissionFor('addScripts') &&
                                            <Row className="form-row shared-module-background">
                                                <Col>
                                                    <CollapsibleSection
                                                        name="addScripts"
                                                        heading="Add Scripts"
                                                        height={addScriptsHeight}
                                                        setHeight={setAddScriptsHeight}
                                                        buttonText="Save Scripts"
                                                        hasPermission={hasPermissionFor('addScripts')}
                                                    >
                                                        <Input
                                                            className="shared-input"
                                                            valid={name && name.length > 3}
                                                            type="textarea"
                                                            name="addScripts"
                                                            id="addScripts"
                                                            disabled={!hasPermissionFor('addScripts')}
                                                            style={{
                                                                height: addScriptsHeight,
                                                                transition: 'all 0.3s ease-out',
                                                            }}
                                                        />
                                                    </CollapsibleSection>
                                                </Col>
                                            </Row>
                                        }

                                        {/* ADD FIELDS */}
                                        {hasPermissionFor('addFields') &&
                                            <Row className="form-row shared-module-background">
                                                <Col>
                                                    <CollapsibleSection
                                                        name="addFields"
                                                        heading="Add Fields"
                                                        height={addFieldsHeight}
                                                        setHeight={setAddFieldsHeight}
                                                        buttonText="Save Fields"
                                                        hasPermission={hasPermissionFor('addFields')}
                                                    >
                                                        <Input
                                                            className="shared-input"
                                                            type="textarea"
                                                            name="addFields"
                                                            id="addFields"
                                                            disabled={!hasPermissionFor('addFields')}
                                                            style={{
                                                                height: addFieldsHeight,
                                                                transition: 'all 0.3s ease-out',
                                                            }}
                                                        />
                                                    </CollapsibleSection>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>

                        {/* EDIT/CREATE CAMPAIGN BUTTONS */}
                        <Row className="form-row small-row d-flex justify-content-between p-0">
                            {isEdit && !isDuplicate ?
                                <>
                                    {/* SAVE EXISITING CAMPAIGN OR TEMPLATE */}
                                    <Col>
                                        <Button
                                            className="shared-create-config-button"
                                            type="submit"
                                        >
                                            Save and Configure
                                        </Button>
                                    </Col>

                                    {/* DUPLICATE CAMPAIGN */}
                                    <Col>
                                        <Button
                                            className="shared-create-config-button"
                                            type="button"
                                            onClick={() => handleDuplicateCampaign()}
                                        >
                                            Duplicate Campaign
                                        </Button>
                                    </Col>
                                </> :

                                <>
                                    {/* CREATE NEW OR DUPLICATE CAMPAIGN */}
                                    <Col>
                                        <Button
                                            className="shared-create-config-button blue"
                                            type="submit"
                                        >
                                            {isDuplicate ? 'Create Duplicate' : 'Create Campaign'}
                                        </Button>
                                    </Col>

                                    {/* CREATE NEW TEMPLATE */}
                                    {/* {admin && !isDuplicate &&
                                        <Col>
                                            <Button
                                                className="shared-create-config-button"
                                                type="submit"
                                                onClick={() => handleSaveAsTemplate()}
                                            >
                                                Create Template
                                            </Button>
                                        </Col>
                                    } */}
                                </>
                            }

                            {/* DISRCARD CHANGES */}
                            <Col className="d-flex justify-content-end">
                                <Button
                                    className="shared-create-config-button gray"
                                    type="button"
                                    onClick={() => handleDiscardChanges()}
                                >
                                    {isEdit ? 'Discard Changes' : 'Discard Draft'}
                                </Button>
                            </Col>
                        </Row>

                        {/* DELETE BUTTON */}
                        {(adminCreate || userCreate) &&
                            <Row className="form-row small-row p-0">
                                <Col className="d-flex justify-content-end">
                                    <Button
                                        className="shared-create-config-button delete"
                                        type="button"
                                        onClick={() => handleDeleteCampaign()}
                                    >
                                        {isTemplate ? 'Delete Template' : 'Delete Campaign'}
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Form>
                </Col>

                {/* CHATBOT PREVIEW */}
                <Col
                    md={7}
                    className="m-0 p-0"
                    style={{ height: campaign.horizontal_layout ? '450px' : '600px' }}
                >
                    <Row
                        className="h-100 mx-auto p-0"
                        style={{ width: campaign.horizontal_layout ? '650px' : '350px' }}
                    >
                        <Col className="h-100 m-0 p-0">
                            <ChatbotLayout
                                campaign={campaign}
                                chatbotActive={false}
                                showPreview
                                wallpaperImage={horizontalImageBase64}
                                wallpaperSize={campaignStyles.horizontalBgSize}
                                wallpaperRepeat={campaignStyles.horizontalBgRepeat}
                            >
                                {!isEdit && !chatbotConfiguration ?
                                    // DEFAULT MESSAGE MODULE
                                    <MessageModule
                                        message={{
                                            type: MODULE_TYPES.Text,
                                            props: {
                                                text: name ? `Welcome to ${name}!` : 'Welcome!',
                                                showAvatar: true,
                                            },
                                        }}
                                        campaign={campaign}
                                    /> :

                                    <ChatbotCore
                                        campaign={campaign}
                                        chatbotActive={false}
                                        showPreview
                                        setShowCelebration={setShowCelebration}
                                        setCelebration={setCelebration}
                                        setWallpaperExternalLinkUrl={Function.prototype}
                                        enableScrollToBottom={false}
                                    />
                                }
                            </ChatbotLayout>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {showQRModal &&
                <ModalQrCode
                    campaignRef={currentCampaign.ref}
                    exitText="Exit to Campaigns"
                    configureText="Configure"
                    handleExit={handleExit}
                    handleConfigure={handleConfigure}
                    headerText={
                        `The ${currentCampaign.name && currentCampaign.name} ${isTemplate ? 'template' : 'campaign'} has been
                        ${isEdit && !isDuplicate ? ' edited' : ' created'}.`
                    }
                    showSpinner={showSpinner}
                    errorSaveCampaign={errorSaveCampaign}
                    handleError={() => setShowQRModal(false)}
                    handleClose={() => setShowQRModal(false)}
                />
            }

            {showUtmModal &&
                <ModalUTM
                    UTM={UTM}
                    handleClose={() => setShowUtmModal(false)}
                />
            }

            {showDeleteModal &&
                <Modal
                    header="Confirm Delete!"
                    description={`Are you sure you want to delete this ${editCampaignData.name} ${isTemplate ? 'template' : 'campaign'}?`}
                    showConfirmButton
                    confirmButtonTitle="Delete"
                    closeButtonTitle="Cancel"
                    handleConfirm={confirmDeleteCampaign}
                    handleClose={() => setShowDeleteModal(false)}
                    size="md"
                    showCloseButton
                    showCloseIcon={false}
                    handleError={error => console.log(error)}
                    headerClassName="modal-header"
                    footerClassName="modal-footer"
                    confirmButtonClassName="modal-delete-button"
                />
            }

            {showCampaignLimitModal &&
                <Modal
                    header="Campaign Limit Reached"
                    description={modalDescription}
                    showConfirmButton={false}
                    closeButtonTitle="Close"
                    handleClose={() => setShowCampaignLimitModal(false)}
                    size="md"
                    showCloseButton
                    showCloseIcon={false}
                    handleError={error => console.log(error)}
                    headerClassName="modal-header"
                    footerClassName="modal-footer"
                />
            }
        </>
    );
};

export default connect(
    (state) => ({ language: state.language.selectedLanguage, campaigns: state.campaigns.all }),
    { changeScreen, createCampaign, editCampaign, duplicateCampaignTemplate, deleteCampaign, getCampaigns },
)(CreateCampaign);
