import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

const CollapsibleSection = ({
    name,
    heading,
    height,
    setHeight,
    buttonText,
    children,
    hasPermission,
}) => {
    const handleExpandTextareaSize = (e) => {
        e.preventDefault();
        if (hasPermission) {
            if (height === '0') {
                setHeight('350px');
            } else {
                setHeight('0');
            }
        }
    };

    const iconStyles = {
        size: '18px',
        style: {
            margin: '4px 3px 0 0',
            color: hasPermission ? '#000' : '#CCC',
        },
    };

    return (
        <FormGroup>
            <Label
                className="w-100"
                htmlFor={name}
            >
                <div className="option-heading collapsible justify-content-between">
                    <div style={{ color: hasPermission ? '#000' : '#CCC' }}>
                        {heading}
                    </div>
                    {height === '0' ?
                        <BsChevronDown
                            onClick={(e) => handleExpandTextareaSize(e)}
                            {...iconStyles}
                        /> :
                        <BsChevronUp
                            onClick={(e) => handleExpandTextareaSize(e)}
                            {...iconStyles}
                        />
                    }
                </div>
            </Label>
            {children}
            {/* <FormFeedback invalid="true">
                Script should be at least 1 character long
            </FormFeedback> */}
            <div className="w-100 d-flex justify-content-end">
                <button
                    className="btn-secondary-custom mt-2 px-2"
                    type="button"
                    style={{
                        borderColor: hasPermission ? '#000' : '#CCC',
                        color: hasPermission ? '#000' : '#CCC',
                        cursor: hasPermission ? 'pointer' : 'default',
                    }}
                >
                    {buttonText}
                </button>
            </div>
        </FormGroup>
    );
};

export default CollapsibleSection;