import React from 'react';
import { BsQuestionCircle } from 'react-icons/bs';

const HintBox = ({ tip }) => (
    <BsQuestionCircle
        className="hint-box"
        size="12px"
        title={tip}
    />
);

export default HintBox;
