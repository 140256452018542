/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unknown-property */
/* Imports */
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { CUSTOM_TOKEN, CHAT_URL, GA_ID, GA_APP_WEB_ID, FB_PIXEL_ID } from '../../config';
import { getCampaignByRef } from '../../services/campaign';

const logo = require('../../assets/img/logo-2x.png');

const QRLandingPage = () => {
    const [campaign, setCampaign] = useState();
    const [error, setError] = useState(false);
    const gaSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    const gaAppWebSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_APP_WEB_ID}`;

    useEffect(() => {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const campaignRef = params.get('campaign');

        getCampaignByRef(campaignRef, CUSTOM_TOKEN)
            .then(({ data: cmp }) => {
                if (!cmp) {
                    setError(true);
                    return;
                }
                setCampaign(cmp);
            })
            .catch(() => {
                setError(true);
            });
    }, []);

    return (
        <HelmetProvider>
            <div align="center" className="h-100 qr-landing-page-container">
                <Helmet>
                    <title>{campaign && `${campaign.name} Chat`}</title>
                    <meta name="robots" content="noindex"/>
                </Helmet>
                <img src={logo} alt="BrandChat" className="logo"/>
                {!campaign && !error &&
                    <h3>
                        ...Loading
                    </h3>
                }
                {campaign && campaign.active &&
                    <div>
                        <Helmet encodeSpecialCharacters>
                            <script>
                                {`
                                    if (${campaign.active}) {
                                        function uuidv4() {
                                            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                                            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
                                            )
                                        }
                                    
                                        var event_id = uuidv4();
                                        var event_timestamp = new Date().getTime();
                                    }

                                    function Redirect() {
                                        window.location = "${CHAT_URL}?ref=${campaign.ref}|"
                                            + event_id + "|" + event_timestamp;
                                    }
                                `}
                            </script>
                            {/* Global site tag (gtag.js) - Google Analytics (Legacy implementation / Dev) */}
                            <script
                                async
                                // src="https://www.googletagmanager.com/gtag/js?id="
                                src={gaSrc}
                                onLoad="setTimeout(Redirect(), 500);"
                            />
                            <script>
                                {`
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag() { dataLayer.push(arguments); }
                                    gtag('js', new Date());
                                    gtag('set', 'dimension1', event_id);
                                    gtag('set', 'dimension2', event_timestamp);
                                    gtag('config', '${GA_ID}');
                                `}
                            </script>
                            {/* Global site tag (gtag.js) - Google Analytics  (Newer App + Web Implementation
                                DEV GA ID

                            Note: the id being G-JB39LPBB61 instead of UA-XXXXXXX-X is due to this being a new format of tracking that
                                GA is doing.

                            Global site tag (gtag.js) - Google Analytics */}
                            <script async src={gaAppWebSrc}/>
                            <script>
                                {`
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag() { dataLayer.push(arguments); }
                                    gtag('js', new Date());
                                    gtag('set', 'user_properties', {
                                        event_id: event_id,
                                        event_timestamp: event_timestamp
                                    });
                                    gtag('config', '${GA_APP_WEB_ID}');
                                `}
                            </script>
                            {/* Facebook Pixel Code

                            Dev FB Pixel */}
                            <script>
                                {`
                                    !function (f, b, e, v, n, t, s) {
                                    if (f.fbq) return; n = f.fbq = function () {
                                        n.callMethod ?
                                        n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                                    };
                                    if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
                                    n.queue = []; t = b.createElement(e); t.async = !0;
                                    t.src = v; s = b.getElementsByTagName(e)[0];
                                    s.parentNode.insertBefore(t, s)
                                    }(window, document, 'script',
                                    'https://connect.facebook.net/en_US/fbevents.js');
                                    fbq('init', '${FB_PIXEL_ID}');
                                    fbq('track', 'PageView');
                                `}
                            </script>
                            <noscript>
                                {`
                                    <img alt="" height="1" width="1" style="display:none"
                                        src="https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1" />
                                `}
                            </noscript>
                        </Helmet>
                        <h3>{`${campaign.name} Campaign`}</h3>
                    </div>
                }
                {campaign && !campaign.active &&
                    <h3>
                        Campaign is not active
                    </h3>
                }
                {error &&
                    <h3>
                        Campaign not found
                    </h3>
                }
            </div>
        </HelmetProvider>
    );
};

export default QRLandingPage;