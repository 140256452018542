/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import Axios from 'axios';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const InputField = ({
    // campaignName,
    campaign,
    userInput,
    moduleIndex,
    node_branches,
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    handleNodeBranching,
    loadWallpaperImage,
    inputFieldColor,
    inputTextColor,
    submitButtonText = 'Submit',
    buttonBackgroundColor,
    buttonTextColor,
    chatbotActive,
    hasBeenClicked,
    userId,
}) => {
    const [userData, setUserData] = useState(['']);
    const [selectedItems, setSelectedItems] = useState(['']);
    useEffect(() => {
        const emptyValues = new Array(userInput.values.length);
        setSelectedItems(emptyValues);
    }, []);

    const [actionDropDown, setActionDropDown] = useState({
        open: false,
        index: -1,
    });

    const [sliderValue, setSliderValue] = useState(0);

    const handleBranching = (nodeBranch) => {
        if (horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl,
                horizontalBgSize,
                horizontalBgRepeat,
                wallpaperExternalLinkUrl,
            });
        }

        if (nodeBranch.payload && !hasBeenClicked) {
            handleNodeBranching(nodeBranch, moduleIndex);
        }
    };

    /*
        userInput = {
            values array has one string per input field.
            The value is the default value until the user writes over it.
            values: [
                'value',
                'value',
                'value',
                ...
            ],
            userData array will grow with one object for each user
            and each object contains a property corresponding to each input field
            the Modules/InputFields.js pushes to this array
            userData: [
                {
                    value_0: 'aaa',
                    value_1: 'bbb',
                    ...,
                },
                {
                    value_0: 'xxx',
                    value_1: 'yyy',
                    ...,
                },
                ...
            ],
        }
    */

    const handleChangeInputValue = (value, index) => {
        const newUserData = [...userData];
        newUserData[index] = value;
        setUserData(newUserData);
    };

    const handleClickMenuItem = (item, index) => {
        handleChangeInputValue(item, index);
        setSelectedItems(selectedItems => {
            const newSelectedItems = [...selectedItems];
            newSelectedItems[index] = item;
            return newSelectedItems;
        });
    };

    const handleSlider = (value, index) => {
        setSliderValue(value);
        handleChangeInputValue(value, index);
    };

    const handleSubmitUserInput = () => {
        const userDataObject = {};

        userInput.values.forEach((value, index) => {
            if (value.includes('__DROPDOWN__,') || value.includes('__SLIDER__,')) {
                userDataObject[`${value.split(', ')[1]}`] = userData[index];
                return userDataObject;
            }
            userDataObject[`${value}`] = userData[index];
            // return userDataObject;
        });

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();
        const campURL = window.location.href;

        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campURL;
        userInput.userData.push(userDataObject);

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userInput.userData)
            .then(response => {
                console.log(`Success!: ${response.data}`);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });

        console.log({ userInput });
    };

    const handleInputField = (e) => {
        e.preventDefault();
        if (chatbotActive) {
            handleSubmitUserInput();
            handleBranching(node_branches[0]);
        }
    };

    const toggleActionDropDown = (index) => setActionDropDown(actionDropDown => ({
        index,
        open: !actionDropDown.open,
    }));

    return (
        <div className="input-field-container">
            <Form
                onSubmit={e => handleInputField(e)}
            >
                {/* FIELDS */}
                {userInput.values.map((value, index) => {
                    if (value.includes('__DROPDOWN__,')) {
                        return (
                            <Dropdown
                                key={`dropdown_${value}_${index}`}
                                className="dropdown-list"
                                isOpen={index === actionDropDown.index && actionDropDown.open}
                                toggle={() => toggleActionDropDown(index)}
                            >
                                <DropdownToggle
                                    caret
                                    style={{
                                        backgroundColor: inputFieldColor,
                                        color: inputTextColor,
                                    }}
                                >
                                    {selectedItems[index] || value.split(', ')[1]}
                                    {' '}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {value.split(', ').slice(2).map((item, idx) => (
                                        <DropdownItem
                                            key={`dropdownitem_${item}_${idx}`}
                                            className="pl-2"
                                            onClick={() => handleClickMenuItem(item, index)}
                                        >
                                            {item}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        );
                    } if (value.includes('__SLIDER__,')) {
                        const items = value.split(', ');
                        return (
                            <div
                                className="slider-container"
                                key={`slider_${value}_${index}`}
                            >
                                <div className="slider-label">
                                    <p style={{ margin: '10px 0 0 5px', color: inputTextColor }}>
                                        {`${items[1]} ${sliderValue}`}
                                    </p>
                                </div>
                                <RangeSlider
                                    value={sliderValue}
                                    min={parseInt(items[2], 10) || 0}
                                    max={parseInt(items[3], 10) || 100}
                                    variant="secondary"
                                    onChange={e => handleSlider(e.target.value, index)}
                                />
                                <div className="slider-details">
                                    <p style={{ color: inputTextColor }}>{items[2]}</p>
                                    <p style={{ color: inputTextColor }}>{items[3]}</p>
                                </div>
                            </div>
                        );
                    }
                    return (
                        <Input
                            className="input-field w-100 mb-1"
                            type="text"
                            key={`input_field_${value + index}}`}
                            placeholder={value}
                            style={{
                                width: '95%',
                                backgroundColor: inputFieldColor,
                                color: inputTextColor,
                            }}
                            onChange={e => handleChangeInputValue(e.target.value, index)}
                            required
                        />
                    );
                })}

                {/* SUBMIT */}
                <Button
                    className="input-field-button"
                    type="submit"
                    style={{
                        backgroundColor: buttonBackgroundColor,
                        color: buttonTextColor,
                    }}
                >
                    {submitButtonText}
                </Button>
            </Form>
        </div>
    );
};

export default InputField;