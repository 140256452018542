import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import ReactQuill from 'react-quill';
import {
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ModuleAnimations,
    ModuleStyles,
} from '../SharedConfigurations';
import { checkSharedMessageError } from './errorHelper';

import 'react-quill/dist/quill.snow.css';

const EmailSubmissionForm = ({
    emailMessage = '<p>Reply to <b>this email</b> to confirm your subscription!</p><p>(See..how easy was that?)<p>',
    backgroundColor,
    emailFieldColor,
    emailTextColor,
    buttonBackgroundColor,
    buttonTextColor,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const emptyValue = '<p><br></p>';
    const placeholderValue = '<p>Add some text here...</p>';
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [textToSend, setTextToSend] = useState(emailMessage);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.EmailSubmissionForm.animation,
                        backgroundColor: moduleStyles.EmailSubmissionForm.backgroundColor,
                        emailFieldColor: moduleStyles.EmailSubmissionForm.emailFieldColor,
                        emailTextColor: moduleStyles.EmailSubmissionForm.emailTextColor,
                        buttonBackgroundColor: moduleStyles.EmailSubmissionForm.buttonBackgroundColor,
                        buttonTextColor: moduleStyles.EmailSubmissionForm.buttonTextColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                EmailSubmissionForm: {
                    ...moduleStyles.EmailSubmissionForm,
                    animation,
                    backgroundColor,
                    emailFieldColor,
                    emailTextColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                },
            }));
        }
    }, [animation, backgroundColor, emailFieldColor, emailTextColor, buttonBackgroundColor, buttonTextColor]);

    const handleEmailChange = (emailMessage) => {
        setTextToSend(emailMessage);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    emailMessage,
                    error: (!isFirstTime && emailMessage === emptyValue) || checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const quillFormats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet',
        'link', 'background', 'color', 'image', 'video',
    ];

    const quillModules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', { background: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc',
                '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
                '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000',
                '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700',
                '#002966', '#3d1466'] }, { color: ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00',
                '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666',
                '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100',
                '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }], ['image'], ['video'],
            ['clean'],
        ],
    };

    const removeTextOnFocus = () => {
        if (emailMessage === placeholderValue) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        emailMessage: '',
                    },
                },
            }));
            setIsFirstTime(false);
        }
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('EmailSubmissionForm');
        } else {
            clearDefaultConfigs('EmailSubmissionForm');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                EmailSubmissionForm: {
                    ...moduleStyles.EmailSubmissionForm,
                    animation,
                    backgroundColor,
                    emailFieldColor,
                    emailTextColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="options-container my-3">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* INPUT FIELD */}
            <Row className="shared-module-background text-input-field">
                <Col className="m-0 p-0">
                    <h6 className="shared-heading">
                        Email To User:
                    </h6>
                    <ReactQuill
                        value={textToSend}
                        onFocus={removeTextOnFocus}
                        onChange={handleEmailChange}
                        formats={quillFormats}
                        modules={quillModules}
                    />
                </Col>
            </Row>

            {/* COLOR PICKERS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <Row>
                        <Col>
                            <ColorPicker
                                heading="Email Field Color"
                                colorProp="emailFieldColor"
                                parentColor={emailFieldColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Email Text Color"
                                colorProp="emailTextColor"
                                parentColor={emailTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="buttonBackgroundColor"
                                parentColor={buttonBackgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="buttonTextColor"
                                parentColor={buttonTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <ColorPicker
                                heading="Background Color"
                                colorProp="backgroundColor"
                                parentColor={backgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col>
                            <GlobalStylesToggle
                                moduleType="EmailSubmissionForm"
                                heading="Set as default email form colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

export default EmailSubmissionForm;