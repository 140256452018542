import React from 'react';
import ReactPlayer from 'react-player';
import Image from './Image';
import videoPlaceholder from '../../assets/images/video-module-placeholder.png';

const Video = ({ moduleIndex, url }) => (
    <>
        {url ?
            <div className="video-module-container">
                <ReactPlayer
                    url={url}
                    width="100%"
                    height="100%"
                    className="player"
                    playing
                    controls
                />
            </div> :
            <Image
                moduleIndex={moduleIndex}
                imageUrl={videoPlaceholder}
            />
        }
    </>
);

export default Video;