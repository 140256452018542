/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { IoIosAddCircleOutline, IoIosRemoveCircleOutline } from 'react-icons/all';
import {
    BranchingPath,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { inputFieldDropdownPlaceholder, sliderPlaceholder } from '../../../helpers/moduleConfigurationsHelper';

const InputField = ({
    setSelectedMessage,
    configureNodeBranchingPath,
    reference,
    userInput,
    inputFieldColor,
    inputTextColor,
    buttonBackgroundColor,
    buttonTextColor,
    submitButtonText,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    moduleHeading,
    waterfallLayout,
    node_branches,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [fieldValues, setFieldValues] = useState(null);
    useEffect(() => {
        setFieldValues(userInput.values);
    }, [userInput]);

    const [isFirstTime, setIsFirstTime] = useState(true);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.InputField.animation,
                        inputFieldColor: moduleStyles.InputField.inputFieldColor,
                        inputTextColor: moduleStyles.InputField.inputTextColor,
                        buttonBackgroundColor: moduleStyles.InputField.buttonBackgroundColor,
                        buttonTextColor: moduleStyles.InputField.buttonTextColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                InputField: {
                    ...moduleStyles.InputField,
                    animation,
                    inputFieldColor,
                    inputTextColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                },
            }));
        }
    }, [animation, inputFieldColor, inputTextColor, buttonBackgroundColor, buttonTextColor]);

    // USER INPUT DATA STRUCTURE
    /*
        userInput = {
            values array has one string per input field.
            The value is the default value until the user writes over it.
            values: [
                'value',
                'value',
                'value',
                ...
            ],
            userData array will grow with one object for each user submission
            and each object contains a property corresponding to each input field
            the Modules/InputFields.js pushes to this array
            userData: [
                {
                    value_0: 'aaa',
                    value_1: 'bbb',
                    ...,
                },
                {
                    value_0: 'xxx',
                    value_1: 'yyy',
                    ...,
                },
                ...
            ],
        }
    */

    const handleChangeSubmitButtonTextInput = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    submitButtonText: value,
                },
            },
        }));
    };

    const fieldNamePlaceholder = 'Input Field Name';

    const handleInputValueChange = (value, index) => {
        const newFieldValues = [...fieldValues];
        newFieldValues[index] = value;
        setFieldValues(newFieldValues);
    };

    const handleDropdownHeadingChange = (headingValue, index) => {
        const items = [...fieldValues][index].split(', ');
        const newValue = [items[0], headingValue, ...items.slice(2)].join(', ');
        handleInputValueChange(newValue, index);
    };

    const handleDropdownOptionsChange = (optionsValue, index) => {
        const items = [...fieldValues][index].split(', ');
        const newValue = [items[0], items[1], optionsValue].join(', ');
        handleInputValueChange(newValue, index);
    };

    const handleSliderLabelChange = (labelValue, index) => {
        const items = [...fieldValues][index].split(', ');
        const newValue = [items[0], labelValue, items[2], items[3]].join(', ');
        handleInputValueChange(newValue, index);
    };

    const handleSliderMinChange = (minValue, index) => {
        const items = [...fieldValues][index].split(', ');
        const newValue = [items[0], items[1], minValue, items[3]].join(', ');
        handleInputValueChange(newValue, index);
    };

    const handleSliderMaxChange = (maxValue, index) => {
        const items = [...fieldValues][index].split(', ');
        const newValue = [items[0], items[1], items[2], maxValue].join(', ');
        handleInputValueChange(newValue, index);
    };

    const handleSetFieldValues = (index) => {
        setSelectedMessage(selectedMessage => {
            const newValues = [...selectedMessage.message.props.userInput.values];
            newValues[index] = fieldValues[index];

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        userInput: {
                            ...selectedMessage.message.props.userInput,
                            values: newValues,
                        },
                    },
                },
            });
        });
    };

    // ADD/DELETE FIELDS HANDLERS
    const handleAddNewField = (placeholderValue) => {
        setSelectedMessage(selectedMessage => {
            const newValues = [...selectedMessage.message.props.userInput.values];
            newValues.push(placeholderValue);

            // const numberOfFields = Object.keys(fieldValues).length;
            // setFieldValues(fieldValues => ({
            //     ...fieldValues,
            //     [`field_${numberOfFields}`]: placeholderValue,
            // }));

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        userInput: {
                            ...selectedMessage.message.props.userInput,
                            values: newValues,
                        },
                    },
                },
            });
        });
    };

    const handleDeleteField = (index) => {
        setFieldValues(fieldValues => {
            const newFieldValues = [...fieldValues];
            newFieldValues.splice(index, 1);
            return newFieldValues;
        });

        setSelectedMessage(selectedMessage => {
            const truncatedValues = [...selectedMessage.message.props.userInput.values];
            truncatedValues.splice(index, 1);

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        userInput: {
                            ...selectedMessage.message.props.userInput,
                            values: truncatedValues,
                        },
                    },
                },
            });
        });
    };

    const Controls = ({ index }) => (
        <>
            {/* SET VALUE */}
            <Col
                xl={3}
                className="m-0 py-0 px-2"
            >
                <Button
                    className="btn-secondary-custom"
                    onClick={() => handleSetFieldValues(index)}
                >
                    Set
                </Button>
            </Col>

            {/* DELETE */}
            <Col
                xl={2}
                className="m-0 p-0"
            >
                <Button
                    className="btn-secondary-custom"
                    onClick={() => handleDeleteField(index)}
                >
                    <IoIosRemoveCircleOutline
                        size="18"
                        color="#756682"
                        style={{ marginTop: '-2px' }}
                    />
                </Button>
            </Col>
        </>
    );

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('InputField');
        } else {
            clearDefaultConfigs('InputField');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                InputField: {
                    ...moduleStyles.InputField,
                    animation,
                    inputFieldColor,
                    inputTextColor,
                    buttonBackgroundColor,
                    buttonTextColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="input-field-module-configuration mt-3 mb-5">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* INPUT FIELDS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <h6 className="shared-subheading">
                        Form Fields
                    </h6>

                    {/* INPUT FIELDS */}
                    {userInput.values && userInput.values.map((value, index) => {
                        if (value && value.includes('__DROPDOWN__,')) {
                            return (
                                <Row
                                    className="my-2 mx-0 p-0"
                                    key={`dropdown_config_${value}_${index}`}
                                >
                                    <Col
                                        xl={7}
                                        className="m-0 p-0"
                                    >
                                        <FormGroup>
                                            <Input
                                                className="shared-input"
                                                type="text"
                                                name={`dropdown_heading_${index}`}
                                                id={`dropdown_heading_${index}`}
                                                defaultValue={fieldValues && fieldValues[index] && fieldValues[index].split(', ')[1]}
                                                onChange={e => handleDropdownHeadingChange(e.target.value, index)}
                                            />
                                            <Input
                                                className="shared-input my-1"
                                                type="text"
                                                name={`dropdown_options_${index}`}
                                                id={`dropdown_options_${index}`}
                                                defaultValue={fieldValues && fieldValues[index] &&
                                                    fieldValues[index].split(', ').slice(2).join(', ')
                                                }
                                                onChange={e => handleDropdownOptionsChange(e.target.value, index)}
                                            />
                                            <h6 className="shared-subheading font-italic ml-2">
                                                Enter options separated by comma
                                            </h6>
                                        </FormGroup>
                                    </Col>

                                    <Controls index={index}/>
                                </Row>
                            );
                        } if (value && value.includes('__SLIDER__,')) {
                            return (
                                <Row
                                    className="my-2 mx-0 p-0"
                                    key={`slider_config_${value}_${index}`}
                                >
                                    <Col
                                        xl={7}
                                        className="m-0 p-0"
                                    >
                                        <FormGroup>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        className="shared-input"
                                                        type="text"
                                                        name={`input_field_slider${index}`}
                                                        id={`input_field_slider${index}`}
                                                        defaultValue={
                                                            fieldValues && fieldValues[index] && fieldValues[index].split(', ')[1]
                                                        }
                                                        onChange={e => handleSliderLabelChange(e.target.value, index)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-1">
                                                <Col>
                                                    <Input
                                                        className="shared-input"
                                                        type="text"
                                                        name={`slider_min_${index}`}
                                                        id={`slider_min_${index}`}
                                                        defaultValue={
                                                            fieldValues && fieldValues[index] && fieldValues[index].split(', ')[2]
                                                        }
                                                        onChange={e => handleSliderMinChange(e.target.value, index)}
                                                    />
                                                    <h6 className="shared-subheading mt-1 pl-1">
                                                        Min value
                                                    </h6>
                                                </Col>
                                                <Col>
                                                    <Input
                                                        className="shared-input"
                                                        type="text"
                                                        name={`slider_max_${index}`}
                                                        id={`slider_max_${index}`}
                                                        defaultValue={
                                                            fieldValues && fieldValues[index] && fieldValues[index].split(', ')[3]
                                                        }
                                                        onChange={e => handleSliderMaxChange(e.target.value, index)}
                                                    />
                                                    <h6 className="shared-subheading mt-1 pl-1">
                                                        Max value
                                                    </h6>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>

                                    <Controls index={index}/>
                                </Row>
                            );
                        }
                        return (
                            <Row
                                className="my-2 mx-0 p-0"
                                key={`input_field_config_${value}_${index}`}
                            >
                                <Col
                                    xl={7}
                                    className="m-0 p-0"
                                >
                                    <FormGroup>
                                        <Input
                                            className="shared-input"
                                            type="text"
                                            name={`input_field_${index}`}
                                            id={`input_field_${index}`}
                                            defaultValue={value}
                                            onChange={e => handleInputValueChange(e.target.value, index)}
                                        />
                                    </FormGroup>
                                </Col>

                                <Controls index={index}/>
                            </Row>
                        );
                    })}

                    {/* ADD INPUT FIELD */}
                    <Row>
                        <Col>
                            <button
                                className="btn-secondary-custom add-field"
                                type="button"
                                onClick={() => handleAddNewField(fieldNamePlaceholder)}
                            >
                                <IoIosAddCircleOutline className="add-icon" size="18"/>
                                Add field
                            </button>
                        </Col>
                    </Row>

                    {/* ADD DROPDOWN */}
                    <Row className="mt-3">
                        <Col>
                            <button
                                className="btn-secondary-custom add-field"
                                type="button"
                                onClick={() => handleAddNewField(inputFieldDropdownPlaceholder)}
                            >
                                <IoIosAddCircleOutline className="add-icon" size="18"/>
                                Add dropdown
                            </button>
                        </Col>
                    </Row>

                    {/* ADD SLIDER */}
                    <Row className="mt-3">
                        <Col>
                            <button
                                className="btn-secondary-custom add-field"
                                type="button"
                                onClick={() => handleAddNewField(sliderPlaceholder)}
                            >
                                <IoIosAddCircleOutline className="add-icon" size="18"/>
                                Add Slider
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* BUTTON TEXT */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <div className="shared-heading">
                        Submit Button Text
                    </div>
                    <FormGroup>
                        <Input
                            className="shared-input"
                            type="text"
                            name="submitButtonTextInputField"
                            id={`Button_Text_Input_Field_${submitButtonText}`}
                            placeholder="Enter button label"
                            value={submitButtonText}
                            onChange={e => handleChangeSubmitButtonTextInput(e)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            {/* BUTTON CONFIGS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {/* COLOR PICKERS */}
                    <Row>
                        <Col>
                            <ColorPicker
                                heading="Input Field Color"
                                colorProp="inputFieldColor"
                                parentColor={inputFieldColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Input Text Color"
                                colorProp="inputTextColor"
                                parentColor={inputTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="buttonBackgroundColor"
                                parentColor={buttonBackgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="buttonTextColor"
                                parentColor={buttonTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* GLOBAL DEFAULT STYLES */}
                    <Row className="mt-4">
                        <Col>
                            <GlobalStylesToggle
                                moduleType="InputField"
                                heading="Set as default Input Field module colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <BranchingPath
                nodeBranches={node_branches}
                reference={reference}
                configureNodeBranchingPath={configureNodeBranchingPath}
            />

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />

            {/* UPLOAD LEFT WALLPAPER HORIZONTAL LAYOUT */}
            <ImageUpload
                wallpaper
                imageUrl={horizontalImageUrl}
                imageFile={horizontalImageFile}
                horizontalBgSize={horizontalBgSize}
                horizontalBgRepeat={horizontalBgRepeat}
                wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default InputField;