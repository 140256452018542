import * as constants from './constants';
import initialState from '../initialState';

export default function reducer(state = initialState.campaigns, action = {}) {
    const { payload, type } = action;

    switch (type) {
        case constants.GET_CAMPAIGN_SUCCESS:
            return {
                ...state,
                all: payload.campaigns,
            };
        case constants.GET_CAMPAIGN_FAIL:
            return {
                ...state,
            };
        case constants.GET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: payload.analytics,
            };
        case constants.RESET_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: {},
            };
        case constants.GET_ENGAGEMENTS_BY_GENDER_SUCCESS:
            return {
                ...state,
                genderAnalytics: payload.genderAnalytics,
            };
        case constants.GET_ENGAGEMENTS_BY_CITY_SUCCESS:
            return {
                ...state,
                analytics: payload.analytics,
            };
        case constants.GET_ENGAGEMENTS_BY_OPERATING_SYSTEM_SUCCESS:
            return {
                ...state,
                analytics: payload.analytics,
            };
        case constants.GET_ENGAGEMENTS_BY_DEVICE_CATEGORY_SUCCESS:
            return {
                ...state,
                analytics: payload.analytics,
            };
        case constants.ARCHIVE_CAMPAIGN_SUCCESS: {
            const archivedCampaign = {
                ...payload.campaign,
                archived: true,
                active: false,
            };
            const stateArchived = Object.is(state.archived, null) || typeof state.archived !== 'object' ?
                [] : [...state.archived];
            return {
                ...state,
                all: state.all.filter(campaign => campaign.id !== archivedCampaign.id),
                archived: [...stateArchived, archivedCampaign],
            };
        }
        case constants.RESTORE_CAMPAIGN_SUCCESS: {
            const restoredCampaign = {
                ...payload.campaign,
                archived: false,
            };
            return {
                ...state,
                archived: state.archived.filter(campaign => campaign.id !== restoredCampaign.id),
                all: [...state.all, restoredCampaign],
            };
        }
        case constants.GET_ARCHIVED_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                archived: payload.campaigns,
            };
        case constants.CREATE_CAMPAIGN_SUCCESS: {
            const isArchived = payload.campaign.archived;
            let campaignsArray = {};
            if (isArchived) {
                campaignsArray = { archived: [...state.archived, payload.campaign] };
            } else {
                campaignsArray = { all: [...state.all, payload.campaign] };
            }
            return {
                ...state,
                ...campaignsArray,
            };
        }
        case constants.EDIT_CAMPAIGN_SUCCESS:
            return {
                ...state,
                all: state.all.map((campaign) => {
                    if (campaign.id === payload.campaign.id) {
                        return payload.campaign;
                    }
                    return campaign;
                }),
            };
        case constants.EDIT_MODULE_STYLES_SUCCESS:
            return {
                ...state,
                all: state.all.map((campaign) => {
                    if (campaign.id === payload.campaign.id) {
                        return payload.campaign;
                    }
                    return campaign;
                }),
            };
        default:
            return state;
    }
}
