/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
    Col,
    FormGroup,
    Input,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import RangeSlider from 'react-bootstrap-range-slider';
import { Editor } from '@tinymce/tinymce-react';
import 'bootstrap/dist/css/bootstrap.css';
import {
    BsFillDashCircleFill,
    BsLink45Deg,
    BsThreeDotsVertical,
    BsTrashFill,
} from 'react-icons/bs';
import { IoMdAnalytics } from 'react-icons/io';
import { BiUnlink } from 'react-icons/bi';
import { GrTree } from 'react-icons/gr';
import {
    AddNewButton,
    ButtonWallpaper,
    ConfigurePathButton,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { generatePayload } from '../../../helpers/nodeBranchingHelper';
import { checkSharedMessageError } from './errorHelper';
import { NODE_BRANCH_CONFIGURATION_STATUS, TINY_MCE_API_KEY } from '../../../config';

const QuickReply = ({
    node_branches,
    groupHeading,
    unansweredButtonColor,
    unansweredTextColor,
    unansweredIconColor,
    unansweredIconTextColor,
    unansweredBorderColor,
    answeredButtonColor,
    answeredTextColor,
    answeredIconColor,
    answeredIconTextColor,
    answeredBorderColor,
    buttonBorderRadius,
    replyBackgroundColor,
    replyBorderColor,
    isStacked,
    isFilled,
    isButtonGroup,
    hasButtonShadow,
    hasBackgroundShadow,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    reference,
    campaign,
    configureNodeBranchingPath,
    deleteConfigurationForNodeBranch,
    getConfigurationStatus,
    linkNodeBranches,
    createExternalUrlLink,
    updateKeyword,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [actionDropDown, setActionDropDown] = useState({ open: false, index: -1 });
    const [tempExternalURL, setTempExternalURL] = useState('');
    useEffect(() => {
        setTempExternalURL(null);
    }, [actionDropDown]);

    useEffect(() => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    error: !node_branches.length,
                },
            },
        }));
    }, [node_branches]);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.QuickReply.animation,
                        unansweredButtonColor: moduleStyles.QuickReply.unansweredButtonColor,
                        unansweredTextColor: moduleStyles.QuickReply.unansweredTextColor,
                        unansweredIconColor: moduleStyles.QuickReply.unansweredIconColor,
                        unansweredIconTextColor: moduleStyles.QuickReply.unansweredIconTextColor,
                        unansweredBorderColor: moduleStyles.QuickReply.unansweredBorderColor,
                        answeredButtonColor: moduleStyles.QuickReply.answeredButtonColor,
                        answeredTextColor: moduleStyles.QuickReply.answeredTextColor,
                        answeredIconColor: moduleStyles.QuickReply.answeredIconColor,
                        answeredIconTextColor: moduleStyles.QuickReply.answeredIconTextColor,
                        answeredBorderColor: moduleStyles.QuickReply.answeredBorderColor,
                        buttonBorderRadius: moduleStyles.QuickReply.buttonBorderRadius,
                        replyBackgroundColor: moduleStyles.QuickReply.replyBackgroundColor,
                        replyBorderColor: moduleStyles.QuickReply.replyBorderColor,
                        isStacked: moduleStyles.QuickReply.isStacked,
                        isFilled: moduleStyles.QuickReply.isFilled,
                        hasButtonShadow: moduleStyles.QuickReply.hasButtonShadow,
                        hasBackgroundShadow: moduleStyles.QuickReply.hasBackgroundShadow,
                    },
                },
            }));
        }
    }, []);

    const dependencyArray = [
        unansweredButtonColor,
        unansweredTextColor,
        unansweredIconColor,
        unansweredIconTextColor,
        unansweredBorderColor,
        answeredButtonColor,
        answeredTextColor,
        answeredIconColor,
        answeredIconTextColor,
        answeredBorderColor,
        buttonBorderRadius,
        replyBackgroundColor,
        replyBorderColor,
        isStacked,
        isFilled,
        hasButtonShadow,
        hasBackgroundShadow,
    ];
    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                QuickReply: {
                    ...moduleStyles.QuickReply,
                    animation,
                    unansweredButtonColor,
                    unansweredTextColor,
                    unansweredIconColor,
                    unansweredIconTextColor,
                    unansweredBorderColor,
                    answeredButtonColor,
                    answeredTextColor,
                    answeredIconColor,
                    answeredIconTextColor,
                    answeredBorderColor,
                    buttonBorderRadius,
                    replyBackgroundColor,
                    replyBorderColor,
                    isStacked,
                    isFilled,
                    hasButtonShadow,
                    hasBackgroundShadow,
                },
            }));
        }
    }, dependencyArray);

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('QuickReply');
        } else {
            clearDefaultConfigs('QuickReply');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                QuickReply: {
                    ...moduleStyles.QuickReply,
                    animation,
                    unansweredButtonColor,
                    unansweredTextColor,
                    unansweredIconColor,
                    unansweredIconTextColor,
                    unansweredBorderColor,
                    answeredButtonColor,
                    answeredTextColor,
                    answeredIconColor,
                    answeredIconTextColor,
                    answeredBorderColor,
                    buttonBorderRadius,
                    replyBackgroundColor,
                    replyBorderColor,
                    isStacked,
                    isFilled,
                    hasButtonShadow,
                    hasBackgroundShadow,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    const toggleActionDropDown = (index) => setActionDropDown(actionDropDown => ({
        index,
        open: !actionDropDown.open,
    }));

    const handleEditorChange = (value, index, prop) => {
        const previousValue = node_branches[index][prop];

        if (prop === 'title') {
            updateKeyword(reference, previousValue, value);
        }

        setSelectedMessage(selectedMessage => {
            let newNodeBranches = [...selectedMessage.message.props.node_branches];
            newNodeBranches[index] = {
                ...newNodeBranches[index],
                [prop]: value,
                error: (!isFirstTime && previousValue === '') ||
                    checkSharedMessageError(selectedMessage.message.props.node_branches[index]),
            };
            setIsFirstTime(false);

            if (prop === 'title') {
                newNodeBranches[index] = {
                    ...newNodeBranches[index],
                    payload: generatePayload(campaign, reference, value),
                };
            }

            // Update linking data with new changes
            newNodeBranches = newNodeBranches.map(nodeBranch => {
                if (nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch &&
                    nodeBranch.linkingData.nodeBranch[prop] === previousValue) {
                    return {
                        ...nodeBranch,
                        linkingData: {
                            nodeBranch: newNodeBranches[index],
                            index,
                        },
                    };
                }
                return nodeBranch;
            });

            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            };
        });
    };

    const titlePlaceholder = 'Add a title...';

    // BUTTON OPTIONS
    const handleButtonOptions = (option) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    [option]: !selectedMessage.message.props[option],
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handlePropChange = (value, prop) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    [prop]: value,
                    error: !node_branches.length,
                },
            },
        }));
    };

    // BUTTON CONFIGURATIONS
    const handleNewNodeBranch = () => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = selectedMessage.message.props.node_branches ? [...selectedMessage.message.props.node_branches] : [];
            newNodeBranches.push({ title: titlePlaceholder });

            return {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            };
        });
    };

    const deleteNodeBranchFromNodeBranches = (index) => {
        setSelectedMessage(selectedMessage => {
            let newNodeBranches = [...selectedMessage.message.props.node_branches];
            const nodeBranchToRemove = newNodeBranches[index];

            newNodeBranches.splice(index, 1);

            // Delete linking data from linked node branch
            newNodeBranches = newNodeBranches.map(nodeBranch => {
                if (nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.title === nodeBranchToRemove.title) {
                    return {
                        ...nodeBranch,
                        linkingData: null,
                    };
                }
                return nodeBranch;
            });

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    const handleLinkNodeBranch = (nodeBranch, nodeBranchIndex, nodeBranchToLinkTo, nodeBranchToLinkToIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];

            newNodeBranch = {
                ...newNodeBranch,
                linkingData: {
                    index: nodeBranchToLinkToIndex,
                    nodeBranch: nodeBranchToLinkTo,
                },
                isTriggerModuleRemoved: node_branches[nodeBranchToLinkToIndex].isTriggerModuleRemoved,
                isTruncatedModules: node_branches[nodeBranchToLinkToIndex].isTruncatedModules,
            };
            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });

        linkNodeBranches(nodeBranch.title, nodeBranchToLinkTo.title, reference);
    };

    const handleUnlinkNoeBranch = (nodeBranch, nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            const newNodeBranch = newNodeBranches[nodeBranchIndex];

            delete newNodeBranch.linkingData;

            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });

        deleteConfigurationForNodeBranch(nodeBranch.title, reference);
    };

    const handleDeleteConfigurationAndNodeBranch = (index, nodeBranch) => {
        deleteNodeBranchFromNodeBranches(index);
        deleteConfigurationForNodeBranch(nodeBranch.title, reference);
    };

    const handleDeleteOfNodeBranch = (nodeBranch, index) => {
        if (!nodeBranch.payload) {
            deleteNodeBranchFromNodeBranches(index);
            return;
        }
        const deleteStatus = getConfigurationStatus(nodeBranch);
        switch (deleteStatus) {
            case NODE_BRANCH_CONFIGURATION_STATUS.HAS_CONFIGURATION:
                toastr.confirm('Are you sure you want to delete this configured path?', {
                    onOk: () => handleDeleteConfigurationAndNodeBranch(index, nodeBranch),
                    closeOnToastrClick: true,
                });
                break;
            case NODE_BRANCH_CONFIGURATION_STATUS.HAS_NODE_BRANCH_IN_CONFIGURATION:
                toastr.error('You have node branches in this path. Please delete them first.');
                break;
            case NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION:
                deleteNodeBranchFromNodeBranches(index);
                break;
            default:
                break;
        }
    };

    const handleSetExternalLink = (nodeBranchIndex, nodeBranch, tempExternalURL) => {
        if (tempExternalURL) {
            setSelectedMessage(selectedMessage => {
                const newNodeBranches = [...selectedMessage.message.props.node_branches];
                let newNodeBranch = newNodeBranches[nodeBranchIndex];
                newNodeBranch = {
                    ...newNodeBranch,
                    externalURL: tempExternalURL,
                };
                newNodeBranches[nodeBranchIndex] = newNodeBranch;

                return ({
                    ...selectedMessage,
                    message: {
                        ...selectedMessage.message,
                        props: {
                            ...selectedMessage.message.props,
                            node_branches: newNodeBranches,
                        },
                    },
                });
            });

            createExternalUrlLink(nodeBranchIndex, nodeBranch, tempExternalURL);
            setTempExternalURL(null);
        }
    };

    const handleRemoveExternalLink = (nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];

            newNodeBranch = {
                ...newNodeBranch,
                externalURL: null,
            };

            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
        setTempExternalURL(null);
    };

    const handleExternalLink = (nodeBranchIndex, nodeBranch, tempExternalURL) => {
        if (!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '')) {
            handleSetExternalLink(nodeBranchIndex, nodeBranch, tempExternalURL);
        } else {
            handleRemoveExternalLink(nodeBranchIndex);
        }
    };

    const hasConfiguration = (nodeBranch) => {
        if (!nodeBranch.payload) {
            return false;
        }
        return getConfigurationStatus(nodeBranch) !== NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION;
    };

    const handleModuleIdentifier = (value, nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];
            newNodeBranch = {
                ...newNodeBranch,
                moduleIdentifier: value,
            };
            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    // const initialTitleValue = '<p>Add a title...</p>';
    const initTinyMCEHeading_Icon = {
        menubar: 'edit view format tc',
        placeholder: 'Add icon text...',
        inline: true,
        plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
        ],
        toolbar:
            `undo redo |
            bold italic underline strikethrough |
            fontselect fontsizeselect formatselect |
            alignleft aligncenter alignright alignjustify`,
        content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700');
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }
        `,
        font_formats: `
            Abel=abel,sans-serif;
            Arial=arial,helvetica,sans-serif;
            Arial Black=arial black,gadget,avant garde;
            Comic Sans MS=comic sans ms,comic sans ms5,cursive;
            Courier New=courier new,courier;
            Crimson Text=crimson text,serif;
            Georgia=georgia, serif;
            Helvetica=helvetica,sans-serif;
            Impact=impact,charcoal,sans-serif;
            Kaisei Tokumin=kaisei tokumin,serif;
            Lucida Console=lucida console,monaco,monospace;
            Lucida Sans Unicode=lucida sans unicode,lucida grande,sans-serif;
            Open Sans=open sans,sans-serif;
            Open Sans Condensed=open sans condensed,sans-serif;
            Raleway=raleway,serif;
            Roboto=roboto,sans-serif;
            Tahoma=tahoma,geneva,sans-serif;
            Terminal=terminal,monospace;
            Times New Roman=times new roman,times,serif;
            Trebuchet MS=trebuchet ms,sans-serif;
            Verdana=verdana,geneva,sans-serif;
        `,
    };

    const initTinyMCEHeading_Title = {
        menubar: 'edit view format tc',
        inline: true,
        placeholder: titlePlaceholder,
        plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
        ],
        toolbar:
            `undo redo |
            bold italic underline strikethrough |
            fontselect fontsizeselect formatselect |
            alignleft aligncenter alignright alignjustify`,
        content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700');
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }
        `,
        font_formats: `
            Abel=abel,sans-serif;
            Arial=arial,helvetica,sans-serif;
            Arial Black=arial black,gadget,avant garde;
            Comic Sans MS=comic sans ms,comic sans ms5,cursive;
            Courier New=courier new,courier;
            Crimson Text=crimson text,serif;
            Georgia=georgia, serif;
            Helvetica=helvetica,sans-serif;
            Impact=impact,charcoal,sans-serif;
            Kaisei Tokumin=kaisei tokumin,serif;
            Lucida Console=lucida console,monaco,monospace;
            Lucida Sans Unicode=lucida sans unicode,lucida grande,sans-serif;
            Open Sans=open sans,sans-serif;
            Open Sans Condensed=open sans condensed,sans-serif;
            Raleway=raleway,serif;
            Roboto=roboto,sans-serif;
            Tahoma=tahoma,geneva,sans-serif;
            Terminal=terminal,monospace;
            Times New Roman=times new roman,times,serif;
            Trebuchet MS=trebuchet ms,sans-serif;
            Verdana=verdana,geneva,sans-serif;
        `,
    };

    return (
        <div className="quick-reply-module-configuration">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* BUTTON GROUP HEADING */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <h5 className="shared-heading">
                        Button Group Heading
                    </h5>

                    <Row className="mt-2">
                        <Col>
                            <FormGroup className="mb-1">
                                <Input
                                    type="text"
                                    name="group_heading"
                                    id="group_heading"
                                    value={groupHeading || ''}
                                    onChange={(e) => handlePropChange(e.target.value, 'groupHeading')}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* BUTTON OPTIONS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {/* UNANSWERED */}
                    <Row className="mt-2">
                        <Col>
                            <h5 className="shared-heading">
                                Unanswered Buttons
                            </h5>
                        </Col>
                    </Row>

                    {/* UNANSWERED BUTTON COLORS */}
                    <Row className="mt-3">
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="unansweredButtonColor"
                                parentColor={unansweredButtonColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="unansweredTextColor"
                                parentColor={unansweredTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* UNANSWERED ICON COLORS */}
                    <Row className="mt-3">
                        <Col>
                            <ColorPicker
                                heading="Icon Color"
                                colorProp="unansweredIconColor"
                                parentColor={unansweredIconColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Icon Text Color"
                                colorProp="unansweredIconTextColor"
                                parentColor={unansweredIconTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* UNANSWERED BORDER COLOR */}
                    <Row className="mt-3">
                        <Col>
                            <ColorPicker
                                heading="Border Color"
                                colorProp="unansweredBorderColor"
                                parentColor={unansweredBorderColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                    </Row>

                    {/* ANSWERED */}
                    <Row className="mt-5">
                        <Col>
                            <h5 className="shared-heading">
                                Answered Buttons
                            </h5>
                        </Col>
                    </Row>

                    {/* ANSWERED BUTTON COLORS */}
                    <Row className="mt-3">
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="answeredButtonColor"
                                parentColor={answeredButtonColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="answeredTextColor"
                                parentColor={answeredTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* ANSWERED ICON COLORS */}
                    <Row className="mt-3">
                        <Col>
                            <ColorPicker
                                heading="Icon Color"
                                colorProp="answeredIconColor"
                                parentColor={answeredIconColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Icon Text Color"
                                colorProp="answeredIconTextColor"
                                parentColor={answeredIconTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* ANSWERED BORDER COLOR */}
                    <Row className="mt-3">
                        <Col>
                            <ColorPicker
                                heading="Border Color"
                                colorProp="answeredBorderColor"
                                parentColor={answeredBorderColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                    </Row>

                    {/* BACKGROUND */}
                    <Row className="mt-5">
                        <Col>
                            <h5 className="shared-heading">
                                Background
                            </h5>
                        </Col>
                    </Row>

                    {/* BACKGROUND COLOR */}
                    <Row className="mt-0">
                        <Col>
                            <ColorPicker
                                heading="Background Color"
                                colorProp="replyBackgroundColor"
                                parentColor={replyBackgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>

                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Border Color"
                                colorProp="replyBorderColor"
                                parentColor={replyBorderColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* CORNER ROUNDING */}
                    <Row className="mt-5">
                        <Col xl={6}>
                            <h6 className="shared-subheading">
                                Corner Rounding: &nbsp;
                                {buttonBorderRadius}
                                px
                            </h6>
                            <RangeSlider
                                value={buttonBorderRadius}
                                min={0}
                                max={30}
                                variant="secondary"
                                onChange={e => handlePropChange(e.target.value, 'buttonBorderRadius')}
                            />
                        </Col>
                    </Row>

                    {/* STACKED - FILL WIDTH */}
                    <Row className="mt-3">
                        <Col>
                            <h6 className="shared-subheading">
                                Stacked
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isStacked"
                                type="checkbox"
                                checked={isStacked}
                                onChange={() => handleButtonOptions('isStacked')}
                            />
                        </Col>

                        <Col>
                            <h6 className="shared-subheading">
                                <span style={{ color: isStacked ? '#000' : 'darkgray' }}>
                                    Fill Width
                                </span>
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isFilled"
                                type="checkbox"
                                checked={isFilled}
                                disabled={!isStacked}
                                onChange={() => handleButtonOptions('isFilled')}
                            />
                        </Col>
                    </Row>

                    {/* SHADOWS */}
                    <Row className="mt-3">
                        <Col>
                            <h6 className="shared-subheading">
                                Button Shadow
                            </h6>
                            <input
                                className="config-checkbox"
                                name="hasButtonShadow"
                                type="checkbox"
                                checked={hasButtonShadow}
                                onChange={() => handleButtonOptions('hasButtonShadow')}
                            />
                        </Col>
                        <Col>
                            <h6 className="shared-subheading">
                                Background Shadow
                            </h6>
                            <input
                                className="config-checkbox"
                                name="hasBackgroundShadow"
                                type="checkbox"
                                checked={hasBackgroundShadow}
                                onChange={() => handleButtonOptions('hasBackgroundShadow')}
                            />
                        </Col>
                    </Row>

                    {/* BUTTON GROUP */}
                    <Row className="mt-3">
                        <Col>
                            <h6 className="shared-subheading">
                                Keep button group after click
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isButtonGroup"
                                type="checkbox"
                                checked={isButtonGroup}
                                onChange={() => handleButtonOptions('isButtonGroup')}
                            />
                        </Col>
                    </Row>

                    {/* GLOBAL DEFAULT STYLES */}
                    <Row className="mt-3">
                        <Col>
                            <GlobalStylesToggle
                                moduleType="QuickReply"
                                heading="Set as default Button module options"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <Row className="shared-module-background">
                <Col>
                    <div
                        className="shared-heading"
                        style={{ margin: '0 0 12px -13px' }}
                    >
                        Button Configurations
                    </div>

                    {/* CONFIGURE BUTTONS */}
                    {node_branches && node_branches.map((nodeBranch, index) => (
                        <>
                            {/* OPTIONS & DROPDOWN */}
                            <Row className="mb-5 align-items-center">
                                {(nodeBranch.title || nodeBranch.title === '') &&
                                    <>
                                        {/* OPTIONS */}
                                        <Col className="m-0 p-0">
                                            {/* ICON INPUT */}
                                            <div className="shared-input with-text-editor">
                                                <Editor
                                                    apiKey={TINY_MCE_API_KEY}
                                                    init={initTinyMCEHeading_Icon}
                                                    value={nodeBranch.icon}
                                                    onEditorChange={(value) => handleEditorChange(value, index, 'icon')}
                                                    outputFormat="html"
                                                />
                                            </div>

                                            {/* TITLE INPUT */}
                                            <div className="shared-input with-text-editor">
                                                <Editor
                                                    apiKey={TINY_MCE_API_KEY}
                                                    init={initTinyMCEHeading_Title}
                                                    onEditorChange={(value) => handleEditorChange(value, index, 'title')}
                                                    outputFormat="html"
                                                />
                                            </div>

                                            {/* LINKED-TO DESCRIPTION */}
                                            {nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.title &&
                                                <div className="shared-heading">
                                                    {`Reply linked to button ${nodeBranch.linkingData.index + 1}`}
                                                </div>
                                            }

                                            {nodeBranch.externalURL &&
                                                <div className="shared-heading with-wrap">
                                                    {`Externally linked to ${nodeBranch.externalURL.length < 30 ?
                                                        nodeBranch.externalURL : `${nodeBranch.externalURL.slice(0, 30)}...`}`}
                                                </div>
                                            }

                                            {/* CONFIGURE PATH BUTTON */}
                                            {(!nodeBranch.linkingData || !nodeBranch.linkingData.nodeBranch) &&
                                                <>
                                                    {(nodeBranch.title === '' || nodeBranch.title === titlePlaceholder) ?
                                                        <>
                                                            <ConfigurePathButton
                                                                answer={nodeBranch.title}
                                                                reference={reference}
                                                                configureNodeBranchingPath={configureNodeBranchingPath}
                                                                id="warningMessageTooltip"
                                                                disabled
                                                            />
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target="warningMessageTooltip"
                                                            >
                                                                Please add a title first
                                                            </UncontrolledTooltip>
                                                        </> :

                                                        <ConfigurePathButton
                                                            answer={nodeBranch.title}
                                                            reference={reference}
                                                            configureNodeBranchingPath={configureNodeBranchingPath}
                                                        />
                                                    }
                                                </>
                                            }
                                        </Col>

                                        {/* DROPDOWN */}
                                        <Col
                                            sm="auto"
                                            className="m-0 p-0"
                                        >
                                            <Dropdown
                                                isOpen={index === actionDropDown.index && actionDropDown.open}
                                                toggle={() => toggleActionDropDown(index)}
                                            >
                                                <DropdownToggle>
                                                    <BsThreeDotsVertical size="22" color="#000"/>
                                                </DropdownToggle>

                                                <DropdownMenu>
                                                    {/* DELETE BUTTON */}
                                                    <DropdownItem>
                                                        <Row
                                                            className="m-1"
                                                            onClick={() => handleDeleteOfNodeBranch(nodeBranch, index)}
                                                        >
                                                            <Col className="pr-0" sm="auto">
                                                                <BsTrashFill
                                                                    className="pointer"
                                                                    size="20"
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <div className="shared-heading">
                                                                    Delete button
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </DropdownItem>

                                                    <DropdownItem divider/>

                                                    {/* MODULE IDENTIFIER */}
                                                    <DropdownItem toggle={false}>
                                                        <Row className="m-1">
                                                            <Col>
                                                                <FormGroup>
                                                                    <div className="shared-heading pl-1">
                                                                        <IoMdAnalytics
                                                                            size="18px"
                                                                            style={{ marginRight: '10px', color: '#000' }}
                                                                        />
                                                                        Button-click ID for analytics (recommended)
                                                                    </div>
                                                                    <Input
                                                                        className="shared-input"
                                                                        type="input"
                                                                        id={`module_indentifier_input_${actionDropDown.index}`}
                                                                        placeholder="Enter a unique module ID"
                                                                        value={nodeBranch.moduleIdentifier}
                                                                        onChange={
                                                                            (e) => handleModuleIdentifier(e.target.value, actionDropDown.index)
                                                                        }
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </DropdownItem>

                                                    <DropdownItem divider/>

                                                    {/* ADD EXTERNAL LINK */}
                                                    <DropdownItem toggle={false}>
                                                        <Row
                                                            className="m-1"
                                                            onClick={() => handleExternalLink(index, nodeBranch, tempExternalURL)}
                                                        >
                                                            <Col sm="auto" className="pr-0">
                                                                {!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                                    <BsLink45Deg className="pointer" size="20"/> :
                                                                    <BiUnlink className="pointer" size="20"/>
                                                                }
                                                            </Col>
                                                            <Col>
                                                                <div className="shared-heading">
                                                                    {!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                                        'Set external link' :
                                                                        'Remove external link'
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="m-1">
                                                            <Col>
                                                                <FormGroup>
                                                                    <Input
                                                                        type="text"
                                                                        name="message"
                                                                        id={`node_branch_${index}_external_url_input`}
                                                                        value={tempExternalURL !== null ? tempExternalURL : nodeBranch.externalURL}
                                                                        onChange={(e) => setTempExternalURL(e.target.value)}
                                                                        // onFocus={(e) => removeTextOnFocus(e, index)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        {tempExternalURL !== null && tempExternalURL.trim() === '' &&
                                                            <Row className="m-1">
                                                                <Col>
                                                                    <div
                                                                        className="shared-heading"
                                                                        style={{ fontStyle: 'italic' }}
                                                                    >
                                                                        Please choose a valid web address
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </DropdownItem>

                                                    <DropdownItem divider/>

                                                    {/* LINK TO OTHER BUTTON */}
                                                    {nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch ?
                                                        // UNLINK
                                                        <DropdownItem>
                                                            <Row
                                                                className="m-1"
                                                                onClick={() => handleUnlinkNoeBranch(nodeBranch, index)}
                                                            >
                                                                <Col sm="auto" className="pr-0">
                                                                    <BsFillDashCircleFill size="18px" className="pointer"/>
                                                                </Col>
                                                                <Col>
                                                                    <div className="shared-heading pl-1">
                                                                        {`Unlink from button ${nodeBranch.linkingData.index + 1}`}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </DropdownItem> :

                                                        // LINK
                                                        <DropdownItem className="no-pointer">
                                                            {!hasConfiguration(nodeBranch) ?
                                                                // LINK
                                                                <>
                                                                    <Row className="m-1">
                                                                        <Col sm="auto" className="pr-0">
                                                                            <GrTree size="18"/>
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="shared-heading pl-1">
                                                                                Link to other button:
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    {node_branches && node_branches.map((linkingOption, linkingOptionIndex) => (
                                                                        <span key={`linking_option_${linkingOptionIndex}`}>
                                                                            {(index !== linkingOptionIndex && !linkingOption.linkingData && hasConfiguration(linkingOption)) &&
                                                                                <Row
                                                                                    className="quick-reply-option-row m-1 ml-4"
                                                                                    onClick={() => handleLinkNodeBranch(nodeBranch, index, linkingOption, linkingOptionIndex)}
                                                                                >
                                                                                    <Col className="p-1 pl-3">
                                                                                        <div className="shared-heading">
                                                                                            {`Button ${linkingOptionIndex + 1}`}
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                            }
                                                                        </span>
                                                                    ))}
                                                                </> :

                                                                // NOT LINKABLE
                                                                <Row className="m-1">
                                                                    <Col>
                                                                        <div className="shared-heading">
                                                                            This button is already configured.
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </DropdownItem>
                                                    }

                                                    <DropdownItem divider/>

                                                    {/* ADD WALLPAPER IMAGE */}
                                                    <DropdownItem toggle={false}>
                                                        <ButtonWallpaper
                                                            key={`bwp_${actionDropDown.index}_${nodeBranch.title}`}
                                                            buttonIndex={actionDropDown.index}
                                                            nodeBranch={nodeBranch}
                                                            setSelectedMessage={setSelectedMessage}
                                                        />
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </>
                    ))}

                    {/* NO BUTTON WARNING */}
                    {!node_branches.length &&
                        <Row>
                            <Col>
                                <p className="text-danger">
                                    A button module must have at least one button.
                                </p>
                            </Col>
                        </Row>
                    }

                    <AddNewButton
                        handleClick={handleNewNodeBranch}
                        text="Add new button"
                    />
                </Col>
            </Row>

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default QuickReply;
