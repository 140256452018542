import React, { useEffect, useState } from 'react';
import ChatbotLayout from './ChatbotLayout';
import butterfingerMessageImage from '../../assets/images/butterfinger-message-image.png';
import butterfingerIcon from '../../assets/images/butterfinger-logo.png';
import butterfingerAvatar from '../../assets/images/butterfinger-avatar.png';
import MessageModule from './MessageModule';
import Typing from './Typing';
import { MODULE_TYPES } from '../Modules/constants';

const ChatbotButterfinger = () => {
    const [showTyping, setShowTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [campaign, setCampaign] = useState({});
    const [showCelebration, setShowCelebration] = useState(false);

    const testCampaign = {
        color: '#fadb4a',
        logo: butterfingerIcon,
        avatar: butterfingerAvatar,
    };
    const message1 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Hi 👋, nice to meet you!',
            showAvatar: true,
        },
    };
    const message2 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Introducing the better Butterfinger!',
        },
    };
    const message3 = {
        type: MODULE_TYPES.Image,
        props: {
            url: butterfingerMessageImage,
        },
    };
    const message4 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'More crispety, crunchety, peanut-buttery than ever! 🎉💪',
        },
    };
    const message5 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Like this:',
        },
    };
    const message6 = {
        type: MODULE_TYPES.Video,
        props: {
            url: 'https://www.youtube.com/watch?v=UN5o1PtpXAM',
        },
    };

    const message7 = {
        type: MODULE_TYPES.QuickReply,
        props: {
            quick_replies: [{
                title: 'Tap to Enter Sweeps!',
                payload: 'sweeps',
            }],
            showAvatar: true,
        },
    };

    const getCampaign = () => {
        setCampaign(testCampaign);
    };

    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };

    const renderMessage = (message) => <MessageModule
        key={JSON.stringify(message)}
        setMessages={setMessages}
        message={message}
        setShowCelebration={message.props.showCelebration && setShowCelebration}
        avatar={message.props.showAvatar && campaign.avatar}
    />;

    const populateBubbles = () => {
        setTimeout(() => {
            setShowTyping(false);
            addMessage(message1);
        }, 4300);

        setTimeout(() => {
            addMessage(message2);
        }, 5200);

        setTimeout(() => {
            addMessage(message3);
            addMessage(message4);
        }, 6300);

        setTimeout(() => {
            addMessage(message5);
            addMessage(message6);
        }, 7400);

        setTimeout(() => {
            addMessage(message7);
        }, 8500);
    };

    useEffect(() => {
        setShowTyping(true);
        getCampaign();
        populateBubbles();
    }, []);

    return (
        <ChatbotLayout campaign={campaign} showCelebration={showCelebration}>
            {messages.map((message) => renderMessage(message))}
            {showTyping && <Typing avatar={campaign.avatar}/>}
        </ChatbotLayout>
    );
};

export default ChatbotButterfinger;