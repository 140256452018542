import jwtDecode from 'jwt-decode';
import { history } from '../routes';
import { getBase64GUID } from './base64';

const loginHelper = (service, data, roles, provider) => service(data, provider)
    .then(response => {
        const decodedToken = jwtDecode(response.data.token);
        localStorage.setItem('logged-user', decodedToken.email);
        localStorage.setItem('x-access-token', response.data.token);
        localStorage.setItem('role', response.data.role);
        console.log({ response_data_campaign_limit: response.data.campaign_limit });
        localStorage.setItem('campaign-limit-for-user', 100);
        // localStorage.setItem('campaign-limit-for-user', response.data.campaign_limit);
        if (response.data.permissions) {
            localStorage.setItem('account-permissions', JSON.stringify(response.data.permissions));
        }
        if (response.data.company_name) {
            localStorage.setItem('company-name', response.data.company_name);
        } else {
            localStorage.setItem('company-name', `${decodedToken.email}_no-company_${getBase64GUID()}`);
        }

        // Currently the first configured role is 'admin'
        // Another configured roles, role1 and role2, will be considered as a user who has access to the user info page
        // TODO: Adjust the redirection in accordance the role's permission
        if (decodedToken.role === Object.keys(roles)[0]) {
            history.push('/admin');
            return;
        }
        history.push('/');
    })
    .catch(err => {
        throw err.response;
    });

export default loginHelper;