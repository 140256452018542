/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, dateFilter } from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import {
    BsFillCaretUpFill,
    BsFillCaretDownFill,
    BsFillCaretLeftFill,
    BsFillCaretRightFill,
    BsFillSkipStartFill,
    BsFillSkipEndFill,
    BsArrowClockwise,
    BsCloudDownload,
    BsCalendar,
} from 'react-icons/bs';
import * as AWS from 'aws-sdk';
import { ConfigurationOptions } from 'aws-sdk';
import { history } from '../../routes';
import { getCampaigns, getAnalytics } from '../../redux/campaign/actions';
import { changeScreen } from '../../redux/shared/actions';

import { getCampaignById } from '../../services/campaign';

// helper function for getting # of unique IDs from the id collection

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

// AWS Data Query ///

const configuration: ConfigurationOptions = {
    accessKeyId: process.env.REACT_APP_AWS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
    region: 'us-east-1',
};

AWS.config.update(configuration);

// const docClient = new AWS.DynamoDB.DocumentClient({ region: 'us-east-1' });

// / AWS Data Query END ///

const Insights = ({
    location,
    // campaigns,
    changeScreen,
    getCampaigns,
    // analytics,
    getAnalytics,
}) => {
    const [campaignName, setCampaignName] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const [firstPage, setFirstPage] = useState(0);
    const [data, setData] = useState([]);
    const [filename, setFilename] = useState('data.csv');
    const [downloadData, setDownloadData] = useState('');
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalEvents, setTotalEvents] = useState(0);
    const [isLoadingData, setLoadingData] = useState(true);
    const [isDataEmpty, setDataEmpty] = useState(false);
    const csvLink = useRef();

    const filters = {
        template: false,
        company_name: localStorage.getItem('company-name'),
    };

    // async function queryWithPartiQL({tableName, campaignNameFound}) {
    //     const statement = `SELECT * FROM ${tableName} WHERE "CampaignName" = ${campaignNameFound} and "title" = '${title}' `
    //     const results = await dynamoDB.executeStatement({Statement: statement}).promise();
    // }

    function reformateDate(theDatePassed) {
        const yyyy = theDatePassed.getFullYear().toString();
        const mnt = (theDatePassed.getMonth() + 1).toString();
        const dd = theDatePassed.getDate().toString();

        const hh = theDatePassed.getHours().toString();
        const mm = theDatePassed.getMinutes().toString();
        const ss = theDatePassed.getSeconds().toString();

        const mntChars = mnt.split('');
        const ddChars = dd.split('');

        const hhChars = hh.split('');
        const mmChars = mm.split('');
        const ssChars = ss.split('');

        // const meridian = hh * 60 + mm < 720 ? 'AM' : 'PM';

        const ampm = hh >= 12 ? 'pm' : 'am';

        /* const formattedDate = yyyy + '-' + (mntChars[1]?mnt:"0"+mntChars[0]) + '-' + (ddChars[1]?dd:"0"+ddChars[0]) +
        '-' + (hhChars[1]?hh:"0"+hhChars[0]) + '-' + (mmChars[1]?mm:"0"+mmChars[0]) + '-' + (ssChars[1]?ss:"0"+ssChars[0]); */

        const formattedDate = `${mntChars[1] ? mnt : `0${mntChars[0]}`}-${ddChars[1] ? dd : `0${ddChars[0]}`}-${yyyy
        }, ${hhChars[1] ? hh : `0${hhChars[0]}`}:${mmChars[1] ? mm : `0${mmChars[0]}`}:${ssChars[1] ? ss : `0${ssChars[0]
        } ${ampm}`}`;

        // console.log("The Test of time: " + Date.parse(theDatePassed));
        // console.log("formattedDate: " + formattedDate);

        return formattedDate;
    }

    const processAndPaginateData = (dataToProcess) => {
        const userAnalytics = [];
        const userIDCollection = [];
        const timeObj = new Date();
        const timeString = new Date().toString();
        const utcObj = Date.parse(timeObj);
        const utcString = Date.parse(timeString);
        console.log({ timeObj, timeString, utcObj, utcString });

        console.log('data Being Processed: ', dataToProcess);

        // setTotalUsers(data.Items["Count"]);

        if (dataToProcess.length <= 0) {
            setDataEmpty(true);
        }

        dataToProcess.forEach((userData, index) => {
            const userDataObject = {};
            // const userFullData = JSON.parse(userData.fullEventData);
            const userFullData = userData;
            // console.log({ dateType: typeof userData.TimeSubmitted, timeSubmitted: userData.TimeSubmitted });

            userDataObject.id = index + 1;
            userDataObject.userId = userFullData['User ID'];
            userIDCollection.push(userFullData['User ID']);
            userDataObject.userTime = userData.TimeSubmitted;
            userDataObject.userTimeFormatted = userData.FormattedDate;
            userDataObject.clickType = userFullData.TypeOfClick || 'N/A';

            if (userFullData.ModuleIdentifier) {
                if (userFullData['Email Reciever'] && userFullData.ModuleIdentifier === 'No Identifier') {
                    userDataObject.itemTapped = userFullData['Email Reciever'];
                } else if (userFullData['SMS Number'] && userFullData.ModuleIdentifier === 'No Identifier') {
                    userDataObject.itemTapped = userFullData['SMS Number'];
                } else if (userFullData['Image Clicked'] && userFullData.ModuleIdentifier === 'No Identifier') {
                    userDataObject.itemTapped = userFullData['Image Clicked'];
                } else if (userDataObject.clickType === 'ButtonClick' && userFullData.ModuleIdentifier === 'No Identifier') {
                    userDataObject.itemTapped = userFullData['Title Clicked'];
                } else if (userFullData['Title Clicked'] && userFullData.ModuleIdentifier === 'No Identifier') {
                    userDataObject.itemTapped = userFullData['Title Clicked'] || 'A new one';
                } else {
                    userDataObject.itemTapped = userFullData.ModuleIdentifier || 'N/A';
                }
            }

            userDataObject.externalLinkVisited = userFullData['ExternalLink Clicked'] || 'N/A';

            // Form Data  ///FIX THIS!!!

            userDataObject.formData = 'N/A'; // Default if no form / Dropdown data
            const keyValue = (input) => Object.entries(input).every(([key, value]) => {
                // these appear in all full data, anything outside of it would be form data
                if (key === 'Campaign URL' || key === 'User ID' || key === 'Campaign Name') { return false; }

                if (userDataObject.formData === 'N/A') { userDataObject.formData = []; }

                userDataObject.clickType = 'Filled Form / Dropdown';

                userDataObject.formData.push(` ${key} : ${value} `);

                // console.log(key,value)
                return true;
            });

            keyValue(userFullData);

            // why is some of this logic needed again as above?; needs another pass

            if (userFullData.TypeOfClick) {
                userDataObject.clickType = userFullData.TypeOfClick;
            } else if (userFullData.EnteredChat) {
                userDataObject.clickType = 'Entered Campaign';
            }

            // TODO: update this with a for --> key/value loop like with formdata

            if (userFullData['ExternalLink Clicked']) {
                userDataObject.externalLinkVisited = userFullData['ExternalLink Clicked'];
            }

            if (userFullData['Image Clicked']) {
                userDataObject.itemTapped = userFullData['Image Clicked'];
            }

            if (userFullData['Email Submission']) {
                userDataObject.itemTapped = userFullData['Email Reciever'];
            }

            if (userFullData['SMS Number']) {
                userDataObject.itemTapped = userFullData['SMS Number'];
            }

            if (userDataObject.clickType === 'ButtonClick' && userFullData['Title Clicked']) {
                userDataObject.itemTapped = userFullData['Title Clicked'];
            }

            if (userFullData.ModuleIdentifier && userFullData.ModuleIdentifier !== 'No Identifier') {
                userDataObject.itemTapped = userFullData.ModuleIdentifier;
            }

            // could maybe use this to parse / sort by the unique campaignID
            // const campaignURLFound = userFullData["Campaign URL"];

            userAnalytics.push(userDataObject);
            setTotalEvents(index + 1);
        });

        const uniqueIDs = userIDCollection.filter(onlyUnique);

        setTotalUsers(uniqueIDs.length);
        setData(userAnalytics);

        let csvContent = 'ID,User ID,Event Date,Event Time (GMT),Event Time (Formatted),' +
        'Click Type,Item Tapped,External Link Visited,Form Data\r\n';
        userAnalytics.forEach(rowObj => {
            const row = Object.values(rowObj);
            csvContent = `${csvContent}${row}\r\n`;
        });
        setDownloadData(csvContent);
        setLoadingData(false);
    };

    const fetchPartIQLData = async (tableName, campaignRefFound, campaignNameFound, NextQueryToken, dataPassed) => {
        let currentData = dataPassed;

        if (dataPassed == null) {
            currentData = [];
        }

        const dynamoDB = new AWS.DynamoDB();
        const campName = campaignNameFound.replace(/ /g, '_').toLowerCase();

        console.log('Querying Through Campaign:');
        console.log(campName);

        // const statement = `SELECT * FROM "${tableName}" WHERE begins_with("CampaignName", '${campName}')`; // original Query
        const statement = `SELECT * FROM "${tableName}" WHERE contains(userData,'"Campaign ID":"${campaignRefFound}"')`; // New Query
        let statementOBJ = {};

        if (NextQueryToken) {
            console.log('Continued Query');
            statementOBJ = {
                NextToken: NextQueryToken, Statement: statement,
            };
        } else {
            console.log('End of Query');
            statementOBJ = {
                Statement: statement,
            };
        }

        const data = await dynamoDB.executeStatement(statementOBJ).promise();
        // console.log('NEW DATA: ' + JSON.stringify(data));
        // continue scanning if we have more items
        // if (typeof data.NextToken !== 'undefined' && data.Items.length >= 1) { // prior passthrough... but flawed if [] in first try
        if (typeof data.NextToken !== 'undefined') {
            // console.log('Scanning for more...');
            // console.log(data.NextToken);
            // console.log(data.Items);
            // console.log('DrillingDown:');

            // Full Event Data pulled

            // const fullClickEventData = JSON.parse(data.Items[0].fullEventData.S);

            // Process DATE DATA HERE??

            // Adds the time data to the data object that will go to the tables

            // fullClickEventData[0].TimeSubmitted = data.Items[0].TimeSubmitted.S;
            // const cleanedData = fullClickEventData[0];

            // console.log('CLEANED DATA');
            // console.log(cleanedData);

            // console.log("TimeData:");
            // console.log(data.Items[0].TimeSubmitted['S']);

            for (let i = data.Items.length - 1; i >= 0; i--) {
                // data.Items[i].fullEventData['S'][0]["TimeSubmitted"] = data.Items[i].TimeSubmitted;
                // dataPassed.push(data.Items[i].fullEventData['S']);

                const fullClickEventData = JSON.parse(data.Items[i].fullEventData.S);

                fullClickEventData[0].TimeSubmitted = data.Items[i].TimeSubmitted.S;

                // create a more formated date
                const GMTTimeToDate = new Date(fullClickEventData[0].TimeSubmitted);
                fullClickEventData[0].FormattedDate = reformateDate(GMTTimeToDate);

                const cleanedData = fullClickEventData[0];

                // console.log("cleanedData: " + JSON.stringify(fullClickEventData));
                currentData.push(cleanedData);
            }

            fetchPartIQLData(tableName, campaignRefFound, campName, data.NextToken, currentData);

            // let nextData = await dynamoDB.executeStatement({NextToken:data.NextToken,Statement: statement}).promise();
            // console.log({nextData});
            // console.log(nextData.NextToken);
            // params.ExclusiveStartKey = results.LastEvaluatedKey;
            // docClient.scan(params, onScan);
        } else {
            // console.log('FINAL DATA: ' + JSON.stringify(data));
            console.log('END OF QUERY');
            console.log('TOTAL DATA');
            console.log(currentData);

            // console.log("Example data pull");
            // console.log(dataPassed[0]['User ID']);

            // PROCESS DATA HERE
            processAndPaginateData(currentData);
        }
        // console.log(AWS.DynamoDB.Converter.unmarshall(results.Items));
    };

    const currentCampaignInfo = async (id) => {
        const campaign = await getCampaignById(id);
        setCampaignName(campaign.data.name);
        setFilename(`${campaign.data.name} Insights.csv`);
        fetchPartIQLData('BrandChatInputFieldForms', campaign.data.ref, campaign.data.name, null, null);
        // await fetchAllData('BrandChatInputFieldForms', campaign.data.name);
        // await fetchData('BrandChatInputFieldForms', campaign.data.name);
        console.log(`Campaign found for id ${id}:`, campaign);
    };

    useEffect(() => {
        AWS.config.update(configuration);
        // console.log(`Config Data:${JSON.stringify(configuration)}`);
        changeScreen('insights');
        if (location.state) {
            currentCampaignInfo(location.state);
            getAnalytics(location.state);
            getCampaigns(filters);
        } else {
            history.push('/');
        }
    }, []);

    const handleDownload = () => {
        csvLink.current.link.click();
    };

    const countPages = (dataSize, sizePerPage) => {
        let pageCount = (Math.floor(dataSize / sizePerPage));
        if (dataSize % sizePerPage !== 0) {
            pageCount += 1;
        }
        return pageCount;
    };

    const handleRefreshCampaignData = ({ dataSize, sizePerPage }) => {
        AWS.config.update(configuration);
        if (location.state) {
            currentCampaignInfo(location.state);
            getAnalytics(location.state);
            getCampaigns(filters);
        }
        // getCampaigns(filters);
        const pageCount = countPages(dataSize, sizePerPage);
        setNumberOfPages(pageCount);
    };

    const sortCaret = (order) => {
        const iconSize = '11px';
        if (!order) {
            return (
                <span className="table-caret">
                    <BsFillCaretDownFill size={iconSize}/>
                    <BsFillCaretUpFill size={iconSize}/>
                </span>
            );
        } if (order === 'asc') {
            return (
                <span className="table-caret">
                    <BsFillCaretDownFill size={iconSize}/>
                    <BsFillCaretUpFill size={iconSize} color="#17F"/>
                </span>
            );
        } if (order === 'desc') {
            return (
                <span className="table-caret">
                    <BsFillCaretDownFill size={iconSize} color="#17F"/>
                    <BsFillCaretUpFill size={iconSize}/>
                </span>
            );
        }
        return null;
    };

    const defaultSorted = [{
        dataField: 'id',
        order: 'asc',
    }];

    const dateFormatter = (cell) => {
        const utcTime = Date.parse(cell);
        if (utcTime < Date.UTC(2021, 8, 23, 18, 30)) {
            // this is the time and date in GMT when the AWS DynamooDB timestamp method was changed
            // from date.toStringLocale() to date.toString()
            // if cell is from before this date, it can't use the new formatting below
            return cell;
        }

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December',
        ];
        const dateObj = new Date(utcTime);
        const year = dateObj.getFullYear();
        const month = months[dateObj.getMonth()];
        const date = dateObj.getDate();
        const getEstOffset = () => {
            const stdTimezoneOffset = () => {
                const jan = new Date(0, 1);
                const jul = new Date(6, 1);
                return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
            };
            const isDstObserved = (dateObj: Date) => dateObj.getTimezoneOffset() < stdTimezoneOffset();
            return isDstObserved(dateObj) ? -4 : -5;
        };
        const timezoneOffset = getEstOffset();
        const hours = Math.abs(dateObj.getUTCHours() + timezoneOffset);
        // const clockHours = hours % 12 || 12; // removed for military time
        const clockHours = hours; // changed for military time
        let minutes = dateObj.getMinutes().toString();
        // const meridian = hours * 60 + minutes < 720 ? 'AM' : 'PM';

        const mmChars = minutes.split('');
        minutes = (mmChars[1] ? minutes : `0${mmChars[0]}`);

        // return `${year} ${month} ${date}, ${clockHours}:${minutes} ${meridian} EST`; //removed for military time

        return `${month} ${date} ${year} @ ${clockHours}:${minutes}`;
    };

    const options = { custom: true };

    const columnProperties = {
        sort: true,
        sortCaret,
        filter: textFilter(),
        headerClasses: 'table-header',
        classes: 'table-column',
    };

    const columns = [
        {
            ...columnProperties,
            dataField: 'id',
            text: 'ID',
            classes: 'table-column narrow',
        },
        {
            ...columnProperties,
            dataField: 'userId',
            text: 'User ID',
            classes: 'table-column wide',
        },
        {
            ...columnProperties,
            dataField: 'userTime',
            text: 'Event Time ',
            filter: dateFilter(),
            formatter: dateFormatter,
        },
        // If showing more formatted date
        // {
        //     ...columnProperties,
        //     dataField: 'userTimeFormatted',
        //     text: 'Event Time',
        //     classes: 'table-column wide',
        // },
        {
            ...columnProperties,
            dataField: 'clickType',
            text: 'Type',
        },
        {
            ...columnProperties,
            dataField: 'itemTapped',
            text: 'Click Info',
        },
        {
            ...columnProperties,
            dataField: 'externalLinkVisited',
            text: 'External Link',
            classes: 'table-column wide',
        },
        {
            ...columnProperties,
            dataField: 'formData',
            text: 'Form Data',
            classes: 'table-column wide',
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        classes: 'table-select-row',
        headerColumnStyle: { borderWidth: '1px 0 1px 0', borderColor: '#CCC' },
        selectColumnStyle: { border: '0' },
    };

    const dateButtonIconStyles = {
        size: '19px',
        style: { margin: '-2px 7px 0 0', color: '#000' },
    };

    const tableButtonIconStyles = {
        size: '17px',
        style: { marginLeft: '7px', color: '#FFF' },
    };

    const paginationCarretIconStyles = {
        size: '18px',
        style: { margin: '5px 0 0 5px', color: '#000' },
    };

    const pageSkipCarretIconStyles = {
        size: '20px',
        style: { margin: '4px 0 0 5px', color: '#000' },
    };

    const CustomSizePerPageDropdown = ({
        onPageChange,
        sizePerPage,
        sizePerPageList,
        onSizePerPageChange,
    }) => {
        const toggle = () => setDropdownOpen(prevState => !prevState);

        const handleClick = (e, size) => {
            e.preventDefault();
            onSizePerPageChange(size);
            onPageChange(1);
        };

        return (
            <Dropdown
                isOpen={dropdownOpen}
                toggle={toggle}
            >
                <DropdownToggle
                    caret
                    outline
                >
                    {sizePerPage}
                </DropdownToggle>
                <DropdownMenu>
                    {sizePerPageList.map(size => (
                        <DropdownItem key={size} onClick={e => handleClick(e, size)}>
                            {size}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const CustomPaginationList = ({
        page,
        onPageChange,
        dataSize,
        sizePerPage,
    }) => {
        const pageCount = countPages(dataSize, sizePerPage);
        setNumberOfPages(pageCount);
        const range = 3;
        const pageSet = Math.floor((page - 1) / range);
        const lastSet = Math.floor((numberOfPages - 1) / range);

        const makeButtonListFromPageNumberClick = (pageButton, index) => {
            if (pageSet !== 0) {
                switch (index) {
                    case 0:
                        // handles FIRST page button in pagination list e.g. 4 out of [4, 5, 6, 7]
                        setFirstPage(Math.max(pageButton - (range + 1), 0));
                        break;
                    case range:
                        // handles LAST page button in pagination list e.g. 7 out of [4, 5, 6, 7]
                        setFirstPage(pageButton - 1);
                        break;
                    default:
                        // handles MIDDLE page buttons in pagination list e.g. 5, 6 out of [4, 5, 6, 7]
                        break;
                }
            } else if (pageButton === range + 1) {
                setFirstPage(pageButton - 1);
            } else {
                setFirstPage(0);
            }
        };

        const handleSetPage = (e, pageButton, index) => {
            e.preventDefault();
            onPageChange(pageButton);
            makeButtonListFromPageNumberClick(pageButton, index);
        };

        const handleNextPage = (e, page) => {
            e.preventDefault();
            if (page + 1 <= numberOfPages) {
                onPageChange(page + 1);
                const isLastPage = (page - 1) % range === 0;
                if (isLastPage) {
                    setFirstPage(page - 1);
                }
            }
        };

        const handlePreviousPage = (e, page) => {
            e.preventDefault();
            if (page - 1 > 0) {
                onPageChange(page - 1);
                const isFirstPage = (page - 1) % range === 0;
                if (isFirstPage) {
                    setFirstPage(page - (range + 1));
                }
            }
        };

        const handleSkipToStartOrEnd = (e, page) => {
            e.preventDefault();
            onPageChange(page);
            let pageToSet = page - 1;
            if (page % 3 === 0) {
                pageToSet = page - (range + 1);
            }
            setFirstPage(pageToSet);
        };

        // Creates list of buttons
        const pageList = [];
        for (let n = 0; n < numberOfPages; n++) {
            pageList.push(n + 1);
        }

        const PageButton = ({ pageNumber, handleClick }) => (
            <button
                className="pagination-button"
                type="submit"
                onClick={handleClick}
            >
                {page === pageNumber ? <strong>{pageNumber}</strong> : pageNumber}
            </button>
        );

        // renders button list if 4 or less pages
        if (numberOfPages < 5 || isNaN(numberOfPages)) {
            return (
                <div className="pagination-controls">
                    {!isNaN(numberOfPages) && <p>Page:</p>}
                    <div className="pagination-list">
                        {pageList.map((pageButton, index) => (
                            <PageButton
                                key={pageButton + index}
                                pageNumber={pageButton}
                                handleClick={e => handleSetPage(e, pageButton, index)}
                            />
                        ))}
                    </div>
                    {numberOfPages > 1 &&
                        <>
                            <BsFillCaretLeftFill
                                onClick={e => handlePreviousPage(e, page)}
                                {...paginationCarretIconStyles}
                            />
                            <BsFillCaretRightFill
                                onClick={e => handleNextPage(e, page)}
                                {...paginationCarretIconStyles}
                            />
                        </>
                    }
                </div>
            );
        }

        const pageButtonsList = pageList.slice(firstPage, firstPage + range + 1);
        const isLastSet = pageSet === lastSet;
        const isFirstPageInSet = (page - 1) % range === 0;
        const isNotFirstButton = page !== pageButtonsList[0];

        return (
            <div className="pagination-controls">
                <p>Page:</p>
                <div className="pagination-list">
                    {pageButtonsList.map((pageButton, index) => (
                        <PageButton
                            pageNumber={pageButton}
                            handleClick={e => handleSetPage(e, pageButton, index)}
                        />
                    ))}
                </div>
                <div className="pagination-last-page">
                    {(!isLastSet || (isLastSet && isFirstPageInSet && isNotFirstButton)) &&
                        <>
                            ...
                            <div className="mx-2">
                                <PageButton
                                    pageNumber={numberOfPages}
                                    handleClick={e => handleSkipToStartOrEnd(e, numberOfPages)}
                                />
                            </div>
                        </>
                    }
                </div>
                <BsFillSkipStartFill
                    onClick={e => handleSkipToStartOrEnd(e, 1)}
                    {...pageSkipCarretIconStyles}
                />
                <BsFillCaretLeftFill
                    onClick={e => handlePreviousPage(e, page)}
                    {...paginationCarretIconStyles}
                />
                <BsFillCaretRightFill
                    onClick={e => handleNextPage(e, page)}
                    {...paginationCarretIconStyles}
                />
                <BsFillSkipEndFill
                    onClick={e => handleSkipToStartOrEnd(e, numberOfPages)}
                    {...pageSkipCarretIconStyles}
                />
            </div>
        );
    };

    return (
        <div className="insights-container">
            <PaginationProvider
                pagination={paginationFactory(options)}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <>
                        {/* HEADER OPTIONS */}
                        <Row className="d-flex justify-content-between my-4 px-5">
                            <Col
                                sm={5}
                                className="d-flex justify-content-between"
                            >
                                <button
                                    className="date-button"
                                    type="submit"
                                >
                                    <BsCalendar {...dateButtonIconStyles}/>
                                    Today
                                </button>
                                <h6>{campaignName}</h6>
                                <h6>{` ${totalUsers} User${totalUsers === 1 ? '' : 's'} - `} {` ${totalEvents} Events`}</h6>
                            </Col>
                            <Col>
                                <BsArrowClockwise
                                    className="pointer"
                                    onClick={() => handleRefreshCampaignData(paginationProps)}
                                    size="22px"
                                    color="#000"
                                />
                            </Col>
                            <Col
                                sm={2}
                                className="text-right"
                            >
                                <button
                                    className="download-button"
                                    type="submit"
                                    onClick={() => handleDownload()}
                                >
                                    Download
                                    <BsCloudDownload {...tableButtonIconStyles}/>
                                </button>
                                <CSVLink
                                    data={downloadData}
                                    filename={filename}
                                    className="hidden"
                                    ref={csvLink}
                                    target="_blank"
                                />
                            </Col>
                        </Row>

                        {/* TABLE */}
                        <Row className="bootstrap-table-container ml-2">
                            <Col>
                                <BootstrapTable
                                    keyField="id"
                                    data={data}
                                    columns={columns}
                                    bootstrap4
                                    bordered={false}
                                    classes="table-container"
                                    rowClasses="table-row"
                                    selectRow={selectRow}
                                    filter={filterFactory()}
                                    defaultSorted={defaultSorted}
                                    {...paginationTableProps}
                                />
                                <>
                                    { isLoadingData &&
                                        <p>LOADING...</p>
                                    }
                                    { !isLoadingData && isDataEmpty &&
                                        <p>Currently No Data</p>
                                    }
                                </>
                            </Col>
                        </Row>

                        {/* PAGINATION CONTROLS */}
                        <Row className="d-flex justify-content-end mt-1">
                            <Col
                                xl={2}
                                className="d-flex justify-content-end"
                            >
                                <p>Views per page</p>
                            </Col>
                            <Col xl={1}>
                                <CustomSizePerPageDropdown {...paginationProps}/>
                            </Col>
                            <Col
                                xl={4}
                                className="d-flex justify-content-end"
                            >
                                <CustomPaginationList {...paginationProps}/>
                            </Col>
                        </Row>
                    </>
                )}
            </PaginationProvider>
        </div>
    );
};

export default connect((state) => ({
    language: state.language.selectedLanguage,
    campaigns: state.campaigns.all,
    screen: state.shared.screen,
    analytics: state.campaigns.analytics,
}), { changeScreen, getCampaigns, getAnalytics })(Insights);
