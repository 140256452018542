import * as languageConstants from './constants';
import initialState from '../initialState';

export default function reducer(state = initialState.language, action = {}) {
    switch (action.type) {
        case languageConstants.SET_LANGUAGE:
            return {
                ...state,
                selectedLanguage: action.payload.language,
            };
        default:
            return state;
    }
}