import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import { BsFillEyeSlashFill } from 'react-icons/bs';

const NodeBranchingOptions = ({
    nodeBranches,
    setSelectedMessage,
    // handleBranchingChange,
}) => {
    const [isTriggerModuleRemoved, setTriggerModuleRemoved] = useState(nodeBranches[0] && nodeBranches[0].isTriggerModuleRemoved);
    const [isTruncatedModules, setTruncatedModules] = useState(nodeBranches[0] && nodeBranches[0].isTruncatedModules);

    useEffect(() => {
        setTriggerModuleRemoved(nodeBranches[0] && nodeBranches[0].isTriggerModuleRemoved);
        setTruncatedModules(nodeBranches[0] && nodeBranches[0].isTruncatedModules);
    }, [nodeBranches]);

    const selectedColor = '#000';
    const unselectedColor = '#BBB';

    const handleBranchingChange = (property) => {
        setSelectedMessage(selectedMessage => {
            // selectedMessage.message.props.node_branches.forEach(nodeBranch => {
            //     const currentValue = nodeBranch[property];
            //     /* eslint-disable no-param-reassign */
            //     nodeBranch[property] = !currentValue;
            // });
            const newNodeBranches = selectedMessage.message.props.node_branches.map(nodeBranch => ({
                ...nodeBranch,
                [property]: !nodeBranch[property],
            }));
            const newSelectedMessage = {
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            };
            return newSelectedMessage;
        });
    };

    return (
        <Row className="shared-module-background">
            <Col className="shared-message-configuration m-0 p-0">
                {/* HIDE TRIGGER MODULE */}
                <FormGroup
                    check
                    className="m-0 px-1"
                >
                    <Label
                        check
                        className="shared-message-label"
                    >
                        <Input
                            type="checkbox"
                            checked={nodeBranches[0] && nodeBranches[0].isTriggerModuleRemoved}
                            onChange={() => {
                                handleBranchingChange('isTriggerModuleRemoved');
                                setTriggerModuleRemoved(!isTriggerModuleRemoved);
                            }}
                            className="pointer"
                        />
                        <BsFillEyeSlashFill
                            size="18px"
                            color={isTriggerModuleRemoved ? selectedColor : unselectedColor}
                        />
                        <div className="shared-message-checkbox-text">
                            Hide module on click
                        </div>
                    </Label>
                </FormGroup>

                {/* TERMINATE CHAT */}
                <FormGroup
                    check
                    className="m-0 px-1"
                >
                    <Label
                        check
                        className="shared-message-label"
                    >
                        <Input
                            type="checkbox"
                            checked={nodeBranches[0] && nodeBranches[0].isTruncatedModules}
                            onChange={() => {
                                handleBranchingChange('isTruncatedModules');
                                setTruncatedModules(!isTruncatedModules);
                            }}
                            className="pointer"
                        />
                        <BsFillEyeSlashFill
                            size="18px"
                            color={isTruncatedModules ? selectedColor : unselectedColor}
                        />
                        <div className="shared-message-checkbox-text">
                            Terminate chat modules on click
                        </div>
                    </Label>
                </FormGroup>
            </Col>
        </Row>
    );
};

export default NodeBranchingOptions;