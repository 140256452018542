import React, { useState } from 'react';
import { Col, Row, Form, Button } from 'reactstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Axios from 'axios';
import { sendSMSSubscription } from '../../services/sms';
import { MODULE_TYPES } from './constants';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const PhoneNumberField = ({
    campaign,
    text,
    smsNumber = 'Your Mobile Number',
    campaignName,
    horizontalImageUrl,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    backgroundColor,
    buttonBackgroundColor,
    buttonTextColor,
    containerClassName = '',
    country = 'us',
    // eslint-disable-next-line camelcase
    node_branches,
    moduleIndex,
    handleNodeBranching,
    loadWallpaperImage,
    chatbotActive,
    hasBeenClicked,
    userId,
    moduleIdentifier,
}) => {
    const [smsNumberValue, setPhoneNumberValue] = useState(smsNumber);
    const [isAnswered, setAnswered] = useState(hasBeenClicked);

    const data = {
        subscriptionType: 'sms',
        destinationNumber: smsNumberValue,
        source: campaignName,
        textToUser: text,
    };

    function formatPhoneNumber(phoneNumberString) {
        const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            const intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    const logUserClick = () => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campaignURL = window.location.href;

        userDataObject['User ID'] = userId;
        userDataObject['Campaign ID'] = campaign.ref;
        userDataObject['Campaign Name'] = campaign.name || testTitle;
        userDataObject['Campaign URL'] = campaignURL;
        userDataObject['SMS Number'] = formatPhoneNumber(smsNumberValue) || 'Invalid SMS number';
        userDataObject.TypeOfClick = 'SMS/Phone Number';
        userDataObject.ModuleIdentifier = moduleIdentifier || 'No Identifier';
        userAnalytics.push(userDataObject);
        // console.log('userData\n', JSON.stringify(userInput.userData));

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!:', response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleBranching = (e, nodeBranch) => {
        e.preventDefault();
        if (horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
        } else {
            loadWallpaperImage({
                horizontalImageUrl,
                horizontalBgSize,
                horizontalBgRepeat,
                wallpaperExternalLinkUrl,
            });
        }

        if (nodeBranch.payload && !hasBeenClicked) {
            const replyMessage = {
                type: MODULE_TYPES.Text,
                props: {
                    text: `<p><span style="color: ${buttonTextColor};">+${smsNumberValue}</span></p>`,
                    title: 'text',
                    textColor: backgroundColor,
                    hasBeenClicked: true,
                },
            };
            handleNodeBranching(nodeBranch, moduleIndex, replyMessage);
        }

        if (chatbotActive) {
            logUserClick();
            sendSMSSubscription(data);
            setAnswered(true);
        }
    };

    return (
        <>
            {!isAnswered &&
                <Row className="px-3 quick-reply-row">
                    <div
                        className={`chat-bubble bold w-100 ${containerClassName}`}
                        style={{ backgroundColor }}
                    >
                        <Form>
                            <Row className="p-1">
                                <Col>
                                    <PhoneInput
                                        country={country}
                                        preferredCountries={['us']}
                                        placeholder="Enter phone number"
                                        value={smsNumberValue}
                                        onChange={setPhoneNumberValue}
                                        required
                                        inputStyle={{ width: '100%' }}
                                        buttonStyle={{ backgroundColor: buttonBackgroundColor }}
                                        dropdownStyle={{ width: '550%' }}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3 p-1">
                                <Col>
                                    <Button
                                        style={{
                                            borderColor: buttonBackgroundColor,
                                            backgroundColor: buttonBackgroundColor,
                                            color: buttonTextColor,
                                        }}
                                        className={`answer-button w-100 ${hasBeenClicked ? 'answered' : 'unanswered'}`}
                                        onClick={e => !hasBeenClicked && handleBranching(e, node_branches[0])}
                                    >
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Row>
            }
        </>
    );
};

export default PhoneNumberField;