import React, { useState, useRef } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import { BsCheck, BsPencil, BsFiles } from 'react-icons/bs';
import { ClipboardCopy } from '../../containers/ChatbotConfiguration/SharedConfigurations';

const ModalUTM = ({ UTM, handleClose }) => {
    const [isEditable, setEditable] = useState(false);
    const [value, setValue] = useState(UTM);
    const [isCopied, setCopied] = useState(false);
    const inputFieldRef = useRef(null);

    const handleEdit = () => {
        setEditable(!isEditable);
        inputFieldRef.current.focus();
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <Modal
            isOpen
            centered
            size="xl"
            className="campaign-modal"
            toggle={handleClose}
        >
            {/* CAMPAIGN MODAL */}
            <ModalBody className="text-center p-5">
                {/* HEADER */}
                <Row className="p-0">
                    <Col
                        xl={10}
                        className="utm-text text-left"
                    >
                        <h4 className="mb-3">
                            Campaign URL created succesfully
                            <BsCheck
                                size="50px"
                                color="#228B22"
                                style={{ marginLeft: '15px' }}
                            />
                        </h4>
                        <p>
                            Use this URL in any promotional channels you want to be associated with this custom campaign:
                        </p>
                    </Col>
                </Row>

                {/* TEXT BOX */}
                <Row>
                    <Col>
                        <div className="utm-modal-text-box">
                            <div className="w-100 d-flex justify-content-end">
                                <ClipboardCopy
                                    text={value}
                                    onCopy={handleCopy}
                                >
                                    <div className="clipboard-copy-icon-box">
                                        <BsFiles
                                            size="18px"
                                            color="#000"
                                            title="Copy URL"
                                        />
                                    </div>
                                </ClipboardCopy>
                                <div
                                    className="clipboard-copy-icon-box"
                                    style={{ backgroundColor: isEditable ? '#000' : 'transparent' }}
                                >
                                    <BsPencil
                                        size="18px"
                                        color={isEditable ? '#FFF' : '#000'}
                                        onClick={() => handleEdit()}
                                        title="Edit"
                                    />
                                </div>
                            </div>
                            <textarea
                                className="utm-modal-input"
                                style={{ backgroundColor: isEditable ? '#EEE' : 'transparent' }}
                                type="text"
                                value={value}
                                rows="5"
                                onChange={(e) => setValue(e.target.value)}
                                readOnly={!isEditable}
                                disabled={!isEditable}
                                ref={inputFieldRef}
                            />
                        </div>
                    </Col>
                </Row>

                {/* COPY CONFIRMATION */}
                <Row className="utm-copy-confirmation-container mt-1">
                    <Col>
                        {isCopied &&
                            <div className="utm-modal-copy-confirmation">
                                <p>URL copied to clipboard!</p>
                            </div>
                        }
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

export default ModalUTM;
