import * as constants from './constants';
import initialState from '../initialState';

export default function reducer(state = initialState.shared, action = {}) {
    switch (action.type) {
        case constants.SET_ACTIVE_SCREEN:
            return {
                ...state,
                screen: action.payload,
            };
        default:
            return state;
    }
}
