import React from 'react';
import { Row, Col } from 'reactstrap';

// eslint-disable-next-line camelcase
const ListItemGroup = ({ listItems, setMessages }) => {
    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };

    const onClickListItem = (listItem) => {
        addMessage(listItem.onClickModule);
    };

    return (
        listItems && listItems.map((listItem, index) => (
            <Row
                className="list-item p-2 m-0 mt-2 w-100 d-flex align-items-center"
                key={`${listItem.title}${listItem.image_url}`}
                onClick={() => onClickListItem(listItem)}
            >
                {listItem.image_url &&
                    <Col xs={4}>
                        <img
                            alt={`list-item-${index}`}
                            src={listItem.image_url}
                            className="list-item-img"
                        />
                    </Col>
                }
                {listItem.title &&
                    <Col xs={8}>
                        <p className="m-0 list-item-title">{listItem.title}</p>
                    </Col>
                }
            </Row>
        ))
    );
};

export default ListItemGroup;