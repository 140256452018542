/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { BsLink45Deg } from 'react-icons/bs';
import {
    BranchingPath,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleIdentifier,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { VALID_IMAGE_TYPES } from '../../../config';
import { checkIfImageUrlValid, checkIfUrlIsValid } from '../../../helpers/regexHelpers';
import { checkSharedMessageError } from './errorHelper';

const Image = ({
    imageUrl,
    imageFile,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    configureNodeBranchingPath,
    reference,
    node_branches,
    externalLink,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    styles,
    animation,
    moduleIdentifier,
}) => {
    const [isFirstTime, setIsFirstTime] = useState(true);

    // EXTERNAL LINK
    const handleExternalLink = (e) => {
        const { value } = e.target;
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    externalLink: value,
                    error: checkSharedMessageError(selectedMessage.message.props) ||
                        (selectedMessage.message.props.file &&
                            !VALID_IMAGE_TYPES.includes(selectedMessage.message.props.file.type)) ||
                        (!selectedMessage.message.props.file && selectedMessage.message.props.url &&
                            !checkIfImageUrlValid(selectedMessage.message.props.url)) ||
                        (value && !checkIfUrlIsValid(value)),
                },
            },
        }));
    };

    return (
        <Row className="image-module-configuration mb-5">
            <Col>
                {/* MODULE HEADING */}
                {waterfallLayout &&
                    <HeadingEditor
                        moduleHeading={moduleHeading}
                        setSelectedMessage={setSelectedMessage}
                        isFirstTime={isFirstTime}
                        setIsFirstTime={setIsFirstTime}
                    />
                }

                {/* STYLES */}
                {!waterfallLayout &&
                    <ModuleStyles
                        styles={styles}
                        setSelectedMessage={setSelectedMessage}
                    />
                }

                {/* ANIMATIONS */}
                <ModuleAnimations
                    animation={animation}
                    setSelectedMessage={setSelectedMessage}
                />

                {/* IMAGE UPLOAD */}
                <ImageUpload
                    wallpaper={false}
                    heading="Module Image"
                    imageUrl={imageUrl}
                    imageFile={imageFile}
                    setSelectedMessage={setSelectedMessage}
                />

                {/* CONFIGURE NODE BRANCHING */}
                <BranchingPath
                    nodeBranches={node_branches}
                    reference={reference}
                    configureNodeBranchingPath={configureNodeBranchingPath}
                />

                <NodeBranchingOptions
                    nodeBranches={node_branches}
                    setSelectedMessage={setSelectedMessage}
                />

                {/* EXTERNAL URL */}
                <Row className="shared-module-background">
                    <Col className="m-0 p-0">
                        <FormGroup>
                            <h6 className="shared-heading">
                                <BsLink45Deg
                                    size="20px"
                                    color="#000"
                                    style={{ margin: '0 7px 0 3px' }}
                                />
                                Add external link (optional)
                            </h6>
                            <Input
                                className="shared-input"
                                type="input"
                                placeholder="Enter link URL"
                                value={externalLink || ''}
                                onChange={handleExternalLink}
                                style={{ borderRadius: '6px' }}
                            />
                        </FormGroup>
                    </Col>
                </Row>

                {/* UNIQUE IDENTIFIER */}
                <ModuleIdentifier
                    reference={reference}
                    moduleIdentifier={moduleIdentifier}
                    setSelectedMessage={setSelectedMessage}
                />

                {/* WALLPAPER HORIZONTAL LAYOUT UPLOAD */}
                <ImageUpload
                    wallpaper
                    imageUrl={horizontalImageUrl}
                    imageFile={horizontalImageFile}
                    horizontalBgSize={horizontalBgSize}
                    horizontalBgRepeat={horizontalBgRepeat}
                    wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                    setSelectedMessage={setSelectedMessage}
                />
            </Col>
        </Row>
    );
};

export default Image;
