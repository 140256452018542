import * as constants from './constants';

export function getUsersSuccess(users) {
    return {
        type: constants.GET_USERS_SUCCESS,
        payload: {
            users,
        },
    };
}

export function getUsersFail() {
    return {
        type: constants.GET_USERS_FAIL,
    };
}

export function getUserSuccess(user) {
    return {
        type: constants.GET_USER_SUCCESS,
        payload: { user },
    };
}

export function getUserFail() {
    return {
        type: constants.GET_USER_FAIL,
    };
}

export function getCurrentUserDataSuccess(user) {
    return {
        type: constants.GET_CURRENT_USER_SUCCESS,
        payload: { user },
    };
}

export function addUserSuccess(user) {
    return {
        type: constants.ADD_USER_SUCCESS,
        payload: user,
    };
}

export function addUserFail() {
    return {
        type: constants.ADD_USER_FAIL,
    };
}

export function editUserSuccess(user) {
    return {
        type: constants.EDIT_USER_SUCCESS,
        payload: {
            user,
        },
    };
}

export function editUserFail() {
    return {
        type: constants.EDIT_USER_FAIL,
    };
}

export function deleteUserSuccess(userId) {
    return {
        type: constants.DELETE_USER_SUCCESS,
        payload: {
            userId,
        },
    };
}

export function getRolesSuccess(roles) {
    return {
        type: constants.GET_ROLES_SUCCESS,
        payload: {
            roles,
        },
    };
}
