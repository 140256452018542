import React from 'react';
import { Row, Col, Label, Input, FormFeedback } from 'reactstrap';
import { IoIosRemoveCircleOutline } from 'react-icons/io';

const CustomizationField = ({
    name,
    heading,
    value = '',
    handleChangeField,
    handleDeleteField = null, // optional
    index = null, // optional
    cssClass = '', // optional
}) => (
    <Row className="mt-2 mx-0 p-0">
        <Col className="m-0 p-0">
            <Label
                className="w-100"
                htmlFor={name}
            >
                <div className="option-heading">
                    {heading}
                </div>
            </Label>
            <Row className="m-0 p-0">
                <Col
                    xl={handleDeleteField ? 10 : 12}
                    className="m-0 p-0"
                >
                    <Input
                        className={`shared-input ${cssClass}`}
                        valid={value.length > 0}
                        invalid={value.length === 0}
                        type="textarea"
                        name={name}
                        id={name}
                        value={value}
                        onChange={handleChangeField}
                    />
                </Col>
                {handleDeleteField &&
                    <Col className="m-0 py-0 pl-2 px-0">
                        <button
                            className="btn-secondary-custom"
                            type="submit"
                            onClick={(e) => handleDeleteField(e, index)}
                        >
                            <IoIosRemoveCircleOutline
                                size="18"
                                color="#756682"
                                style={{ marginTop: '-2px' }}
                            />
                        </button>
                    </Col>
                }
            </Row>
            <FormFeedback invalid="true">
                Field cannot be blank!
            </FormFeedback>
        </Col>
    </Row>
);

export default CustomizationField;