import React, { useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { updateProfilePicture } from '../../redux/user/actions';
import localization from '../../helpers/localizationHelper';
import { getBase64 } from '../../helpers/base64';
import { allowedMimeTypes } from '../../config';

const ImageUpload = ({ updateProfilePicture, toggle, isOpen }) => {
    const [profilePicture, setProfilePicture] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const onChange = async (e) => {
        try {
            const selectedPicture = await Array.from(e.target.files)[0];
            const { type: imgType } = selectedPicture;

            if (!allowedMimeTypes.includes(imgType)) {
                toastr.error(errorMessage);
                toggle();
            }

            if (errorMessage || errorMessage.length) {
                toastr.error(errorMessage);
                toggle();
            }

            getBase64(selectedPicture, (base64) => {
                setProfilePicture(base64);
            }, setErrorMessage(localization.imageUpload.errorMessage));
        } catch (err) {
            throw err;
        }
    };

    const saveProfilePicture = async () => {
        try {
            await updateProfilePicture(profilePicture);
            toggle();
            toastr.success(localization.imageUpload.successMessage);
        } catch (err) {
            toastr.error(localization.imageUpload.errorSave);
            throw err;
        }
    };

    return (
        <div>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>{localization.imageUpload.header}</ModalHeader>
                <ModalBody>
                    <input id="chooseFile" type="file" onChange={(e) => onChange(e)}/>
                </ModalBody>
                <ModalFooter>
                    <Button id="save" color="primary" onClick={() => saveProfilePicture()}>{localization.imageUpload.save}</Button>
                    <Button id="cancel" color="secondary" onClick={toggle}>{localization.imageUpload.cancel}</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default connect(state => ({
    language: state.language.selectedLanguage,
}),
{ updateProfilePicture })(ImageUpload);
