import React, { useRef, useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Axios from 'axios';
import { MODULE_TYPES } from './constants';
// import { IMAGE_PLACEHOLDER_URL } from '../../config';

const defaultQuickReply = [{ title: 'Set Path After Submission',
    content_type: 'text' }];

const SKUx = ({
    coordinatesFound = 'Invalid Coordinates',
    text = 'Enter Email For Offer',
    // wallpaperUrl,
    // handleQuickReply,
    // loadWallpaperImage,
    // wallpaperExternalLinkUrl,
    setMessages,
    sendMessage,
    // eslint-disable-next-line camelcase
    node_branches = defaultQuickReply,
    moduleIndex,
    backgroundColor,
    buttonColor = '#00C',
    callBack = null,
    answered = false,
    // hasBeenClicked,
}) => {
    const btnRef = useRef();
    const [value, setValue] = useState(coordinatesFound);
    const [isAnswered, setAnswered] = useState(answered);
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    const [status, setStatus] = useState('Loading...');
    const [apiData, setApiData] = useState({});

    // const handleReply = (quickReply) => {
    //     if (wallpaperUrl === IMAGE_PLACEHOLDER_URL) {
    //         loadWallpaperImage(null, null);
    //     } else {
    //         loadWallpaperImage(wallpaperUrl, wallpaperExternalLinkUrl);
    //     }

    //     if (quickReply.payload && !hasBeenClicked) {
    //         handleQuickReply(quickReply, moduleIndex);
    //     }
    // };

    const apiURL = 'https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/skuxdemo';

    // creates the message/carousel from the API data and adds it to the chat tree
    const handleBranching = (quickReply, formatedAPIData) => {
        const replyMessage = {
            type: MODULE_TYPES.ImageCarousel,
            props: {
                carouselCards: formatedAPIData,
                moreThanOne: false,
                answered: false,
                showTyping: true,
            },
        };

        if (setMessages) {
            // setMessages is setMessages in chatbotPreview AND liveChatbot
            setMessages(replyMessage, quickReply, moduleIndex);

            // sendMessage is handleQuickReply in chatbotPreview AND sendMessages in liveChatbot
            if (quickReply != null || quickReply !== '') {
                sendMessage(quickReply, moduleIndex);
            }
        }
    };

    // sets the data recieved that will be used for the Carousel
    const createCarousel = (dataPassed, quickReply) => {
        // setCurrentAPIData(dataPassed.messages);
        handleBranching(quickReply, dataPassed.messages);
    };

    // calls the SKUx API using the user's data
    const getSKUxData = (branchingNode, userInfo) => {
        const dataToPost = { customInfo: userInfo || 'test' };
        Axios.post(apiURL, dataToPost).then(res => {
            setApiData(res.data);
            setStatus('Ready! Tap your Offer Below!');
            setValue(status);
            createCarousel(res.data, branchingNode);
            if (apiData === {}) {
                setStatus('blank');
            }
        });
    };

    // called by the button press, asks for location then posts to SKUx API
    const position = async (quickReply, userInfo) => {
        if (btnRef.current) {
            btnRef.current.setAttribute('disabled', 'disabled');
        }
        setValue(status);
        setAnswered(true);
        /* eslint-env browser */
        await navigator.geolocation.getCurrentPosition(
            (/* position */) => {
                // uncomment to display or pass coordinateData
                // const coordinatesLatLong = [position.coords.latitude, position.coords.longitude];
                // setValue(coordinatesLatLong);

                if (callBack != null) {
                    callBack();
                } else if (setMessages) {
                    // TODO: pass coordinatesLatLong down the chain here for analytics / additional module options
                    getSKUxData(quickReply, userInfo);
                }
            },
            (error) => {
                // eslint-disable-next-line no-console
                console.error(`Error Code = ${error.code} - ${error.message}`);
            },
            options,
        );
    };

    const handleEmailSubmission = (userInfoSent) => {
        setAnswered(true);
        position(node_branches[0], userInfoSent);
    };

    useEffect(() => {
        const initialMessage = {
            type: MODULE_TYPES.EmailSubmissionForm,
            props: {
                emailMessage: 'Enjoy Your Royal Canin Offer!',
                emailFieldValue: text,
                answered: false,
                callBack: handleEmailSubmission,
                buttonColor,
                backgroundColor,
            },
        };

        if (setMessages) {
            // setMessages is setMessages in chatbotPreview AND liveChatbot
            setMessages(initialMessage, 'SKUx on load', moduleIndex);
        }
    }, []);

    // Uncomment To Debug
    // useEffect(() => {
    //     console.log(`Status Change: ${status}`);
    //     if (status === 'Ready! Tap your Offer Below!') {
    //         console.log('API Loaded');
    //         console.log('API DATA Found:');
    //         console.log(apiData);
    //     } else if (status === 'blank') {
    //         console.log('No Data found!');
    //     }
    // }, [status]);

    return (
        <div style={{ backgroundColor }} className="chat-bubble bold w-100 emailModule">
            {!isAnswered ?
                <>
                    <form>
                        <input
                            className="col-8"
                            name="email"
                            value={text}
                            disabled
                        />
                        <Button
                            style={{ backgroundColor: buttonColor, borderColor: buttonColor, minHeight: '35px' }}
                            className={`answer-button col-4 ${answered ? 'answered' : 'unanswered'}`}
                            type="submit"
                            disabled
                        >
                            Submit
                        </Button>
                    </form>
                </> :
                <div className="chat-bubble bold">
                    <p className="m-0">{value}</p>
                </div>
            }
        </div>
    );
};
export default SKUx;