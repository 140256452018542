import React from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';

const GlobalStylesToggle = ({
    moduleType,
    heading,
    isDefaultConfig,
    handleDefaultToggle,
}) => (
    <>
        <FormGroup className="m-0 p-0">
            <Label>
                <h6 className="shared-subheading">
                    {heading}
                </h6>
            </Label>
            <CustomInput
                type="switch"
                key={`${moduleType}_Module_Default`}
                id={`${moduleType}_Module_Default`}
                name={`${moduleType}_Module_Default`}
                onChange={() => handleDefaultToggle()}
                checked={isDefaultConfig}
            />
        </FormGroup>
    </>
);

export default GlobalStylesToggle;