import imageModulePlaceholder from '../assets/images/image-module-placeholder.png';

export const inputFieldDropdownPlaceholder = '__DROPDOWN__, Heading, Option 1, Option 2';

export const sliderPlaceholder = '__SLIDER__, Slider Label, 0, 100';

export const defaultCarouselCard = [{
    src: imageModulePlaceholder,
    altText: 'Header Text',
    caption: 'Subheader Text',
    imageLink: '',
    fileName: '',
    buttons: [
        {
            buttonID: 0,
            buttonText: 'Button #1',
            buttonCommand: '',
            buttonType: 'link',
        },
    ],
}];