/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
    Col,
    Row,
    FormGroup,
    Input,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import {
    BranchingPath,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleIdentifier,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';

const Rating = ({
    starCount,
    starColor,
    emptyStarColor,
    unselectedBgColor,
    selectedBgColor,
    ratingIcon,
    moduleHeading,
    waterfallLayout,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    configureNodeBranchingPath,
    setSelectedMessage,
    reference,
    node_branches,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule,
    moduleIdentifier,
}) => {
    const [isFirstTime, setIsFirstTime] = useState(true);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.Rating.animation,
                        starCount: moduleStyles.Rating.starCount,
                        starColor: moduleStyles.Rating.starColor,
                        emptyStarColor: moduleStyles.Rating.emptyStarColor,
                        unselectedBgColor: moduleStyles.Rating.unselectedBgColor,
                        selectedBgColor: moduleStyles.Rating.selectedBgColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                Rating: {
                    ...moduleStyles.Rating,
                    animation,
                    starCount,
                    starColor,
                    unselectedBgColor,
                    selectedBgColor,
                    emptyStarColor,
                },
            }));
        }
    }, [animation, starCount, starColor, emptyStarColor]);

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('Rating');
        } else {
            clearDefaultConfigs('Rating');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                Rating: {
                    ...moduleStyles.Rating,
                    animation,
                    starCount,
                    starColor,
                    unselectedBgColor,
                    selectedBgColor,
                    emptyStarColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    const handleStarCount = (num) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    starCount: num,
                },
            },
        }));
    };

    const iconChoices = ['★', '☆', '◆', '●', '◉', '✔', 'Custom'];

    const handleChangeRatingIcon = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    ratingIcon: value.trim(),
                },
            },
        }));
    };

    const handleIconChoice = (icon) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    ratingIcon: icon !== 'Custom' ? icon : '',
                },
            },
        }));
    };

    return (
        <div className="rating-module-configuration">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* UNIQUE IDENTIFIER */}
            <ModuleIdentifier
                reference={reference}
                moduleIdentifier={moduleIdentifier}
                setSelectedMessage={setSelectedMessage}
            />

            {/* NUMBER OF STARS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <div className="shared-heading">
                        Rating count and type
                    </div>
                    <Dropdown
                        isOpen={isDropdownOpen}
                        toggle={() => setDropdownOpen(!isDropdownOpen)}
                    >
                        <DropdownToggle
                            caret
                            outline
                        >
                            {`Rating Count: ${starCount}`}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={() => handleStarCount(1)}>
                                1
                            </DropdownItem>
                            <DropdownItem onClick={() => handleStarCount(2)}>
                                2
                            </DropdownItem>
                            <DropdownItem onClick={() => handleStarCount(3)}>
                                3
                            </DropdownItem>
                            <DropdownItem onClick={() => handleStarCount(4)}>
                                4
                            </DropdownItem>
                            <DropdownItem onClick={() => handleStarCount(5)}>
                                5
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>

            {/* RATINGS ICON */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <div className="shared-heading">
                        Rating Icon
                    </div>
                    <div className="icon-choices">
                        {iconChoices.map(icon => (
                            <button
                                type="button"
                                onClick={() => handleIconChoice(icon)}
                            >
                                {icon}
                            </button>
                        ))}
                    </div>
                    <FormGroup>
                        <Input
                            className="shared-input"
                            type="text"
                            name="ratingIconInputField"
                            id="ratingIconInputField"
                            placeholder="Enter character or emoji"
                            value={ratingIcon}
                            invalid={ratingIcon === ''}
                            onChange={(e) => handleChangeRatingIcon(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            {/* STAR OPTIONS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <Row>
                        <Col>
                            <ColorPicker
                                heading="Selected Star Color"
                                colorProp="starColor"
                                parentColor={starColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Unselected Star Color"
                                colorProp="emptyStarColor"
                                parentColor={emptyStarColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <ColorPicker
                                heading="Selected Color"
                                colorProp="selectedBgColor"
                                parentColor={selectedBgColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Unselected Color"
                                colorProp="unselectedBgColor"
                                parentColor={unselectedBgColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <GlobalStylesToggle
                                moduleType="Rating"
                                heading="Set as default Rating module colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <BranchingPath
                nodeBranches={node_branches}
                reference={reference}
                configureNodeBranchingPath={configureNodeBranchingPath}
            />

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />

            {/* WALLPAPER HORIZONTAL LAYOUT UPLOAD */}
            <ImageUpload
                wallpaper
                imageUrl={horizontalImageUrl}
                imageFile={horizontalImageFile}
                horizontalBgSize={horizontalBgSize}
                horizontalBgRepeat={horizontalBgRepeat}
                wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default Rating;