/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
    Col,
    FormGroup,
    Input,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledTooltip,
    Fade,
} from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import RangeSlider from 'react-bootstrap-range-slider';
import 'bootstrap/dist/css/bootstrap.css';
import {
    BsFillDashCircleFill,
    BsLink45Deg,
    BsThreeDotsVertical,
    BsTrashFill,
} from 'react-icons/bs';
import { IoMdAnalytics } from 'react-icons/io';
import { BiUnlink } from 'react-icons/bi';
import { GrTree } from 'react-icons/gr';
import {
    AddNewButton,
    ButtonWallpaper,
    ConfigurePathButton,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUploadButton,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import imageModulePlaceholder from '../../../assets/images/image-module-placeholder.png';
import { NODE_BRANCH_CONFIGURATION_STATUS } from '../../../config';

const Collage = ({
    node_branches,
    collageStyles = {
        borderRadius: 1,
        isStacked: false,
        isFilled: false,
    },
    replyBackgroundColor,
    replyBorderColor,
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    reference,
    campaign,
    configureNodeBranchingPath,
    deleteConfigurationForNodeBranch,
    getConfigurationStatus,
    linkNodeBranches,
    createExternalUrlLink,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [actionDropDown, setActionDropDown] = useState({
        open: false,
        index: -1,
    });
    const [tempExternalURL, setTempExternalURL] = useState('');
    useEffect(() => {
        setTempExternalURL(null);
    }, [actionDropDown]);

    const toggleActionDropDown = (index) => setActionDropDown(actionDropDown => ({
        index,
        open: !actionDropDown.open,
    }));

    useEffect(() => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    error: !node_branches.length,
                },
            },
        }));
    }, [node_branches]);

    // STYLE CHANGES
    const [isStacked, setIsStacked] = useState(collageStyles.isStacked);
    const [isFilled, setIsFilled] = useState(collageStyles.isFilled);
    const [isFirstTime, setIsFirstTime] = useState(true);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.Collage.animation,
                        replyBackgroundColor: moduleStyles.Collage.replyBackgroundColor,
                        replyBorderColor: moduleStyles.Collage.replyBorderColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                Collage: {
                    ...moduleStyles.Collage,
                    animation,
                    replyBackgroundColor,
                    replyBorderColor,
                },
            }));
        }
    }, [animation, replyBackgroundColor, replyBorderColor]);

    const handleNewNodeBranch = () => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = selectedMessage.message.props.node_branches ? [...selectedMessage.message.props.node_branches] : [];
            newNodeBranches.push({
                imageUrl: imageModulePlaceholder,
            });

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    const deleteNodeBranchFromNodeBranches = (index) => {
        setSelectedMessage(selectedMessage => {
            let newNodeBranches = [...selectedMessage.message.props.node_branches];
            const nodeBranchToRemove = newNodeBranches[index];

            newNodeBranches.splice(index, 1);

            // Delete linking data from linked node branch
            newNodeBranches = newNodeBranches.map(nodeBranch => {
                if (nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.title === nodeBranchToRemove.title) {
                    return {
                        ...nodeBranch,
                        linkingData: null,
                    };
                }
                return nodeBranch;
            });

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    // BUTTON OPTIONS
    const handleStackedChange = () => {
        setIsStacked(!isStacked);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    collageStyles: {
                        ...selectedMessage.message.props.collageStyles,
                        isStacked: !isStacked,
                    },
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleFilledChange = () => {
        setIsFilled(!isFilled);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    collageStyles: {
                        ...selectedMessage.message.props.collageStyles,
                        isFilled: !isFilled,
                    },
                    error: !node_branches.length,
                },
            },
        }));
    };

    // BUTTON CONFIGURATIONS
    const handleLinkNodeBranch = (nodeBranch, nodeBranchIndex, nodeBranchToLinkTo, nodeBranchToLinkToIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];

            newNodeBranch = {
                ...newNodeBranch,
                linkingData: {
                    index: nodeBranchToLinkToIndex,
                    nodeBranch: nodeBranchToLinkTo,
                },
                isTriggerModuleRemoved: node_branches[nodeBranchToLinkToIndex].isTriggerModuleRemoved,
                isTruncatedModules: node_branches[nodeBranchToLinkToIndex].isTruncatedModules,
            };
            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });

        linkNodeBranches(nodeBranch.imageUrl, nodeBranchToLinkTo.imageUrl, reference);
    };

    const handleUnlinkNodeBranch = (nodeBranch, nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            const newNodeBranch = newNodeBranches[nodeBranchIndex];

            delete newNodeBranch.linkingData;

            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });

        deleteConfigurationForNodeBranch(nodeBranch.imageUrl, reference);
    };

    const handleDeleteConfigurationAndNodeBranch = (index, nodeBranch) => {
        deleteNodeBranchFromNodeBranches(index);
        deleteConfigurationForNodeBranch(nodeBranch.imageUrl, reference);
    };

    const handleDeleteOfNodeBranch = (nodeBranch, index) => {
        if (!nodeBranch.payload) {
            deleteNodeBranchFromNodeBranches(index);
            return;
        }
        const deleteStatus = getConfigurationStatus(nodeBranch);
        switch (deleteStatus) {
            case NODE_BRANCH_CONFIGURATION_STATUS.HAS_CONFIGURATION:
                toastr.confirm('Are you sure you want to delete this configured path?', {
                    onOk: () => handleDeleteConfigurationAndNodeBranch(index, nodeBranch),
                    closeOnToastrClick: true,
                });
                break;
            case NODE_BRANCH_CONFIGURATION_STATUS.HAS_NODE_BRANCH_IN_CONFIGURATION:
                toastr.error('You have node branches in this path. Please delete them first.');
                break;
            case NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION:
                deleteNodeBranchFromNodeBranches(index);
                break;
            default:
                break;
        }
    };

    const handleSetExternalLink = (nodeBranchIndex, nodeBranch, tempExternalURL) => {
        if (tempExternalURL) {
            setSelectedMessage(selectedMessage => {
                const newNodeBranches = [...selectedMessage.message.props.node_branches];
                let newNodeBranch = newNodeBranches[nodeBranchIndex];
                newNodeBranch = {
                    ...newNodeBranch,
                    externalURL: tempExternalURL,
                };
                newNodeBranches[nodeBranchIndex] = newNodeBranch;

                return ({
                    ...selectedMessage,
                    message: {
                        ...selectedMessage.message,
                        props: {
                            ...selectedMessage.message.props,
                            node_branches: newNodeBranches,
                        },
                    },
                });
            });
            createExternalUrlLink(nodeBranchIndex, nodeBranch, tempExternalURL);
            setTempExternalURL(null);
        }
    };

    const handleRemoveExternalLink = (nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];

            newNodeBranch = {
                ...newNodeBranch,
                externalURL: null,
            };

            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
        setTempExternalURL(null);
    };

    const handleExternalLink = (nodeBranchIndex, nodeBranch, tempExternalURL) => {
        if (!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '')) {
            handleSetExternalLink(nodeBranchIndex, nodeBranch, tempExternalURL);
        } else {
            handleRemoveExternalLink(nodeBranchIndex);
        }
    };

    const hasConfiguration = (nodeBranch) => {
        if (!nodeBranch.payload) {
            return false;
        }
        return getConfigurationStatus(nodeBranch) !== NODE_BRANCH_CONFIGURATION_STATUS.NO_CONFIGURATION;
    };

    const handleCornerRounding = (value) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    collageStyles: {
                        ...selectedMessage.message.props.collageStyles,
                        borderRadius: value,
                    },
                    error: !node_branches.length,
                },
            },
        }));
    };

    const handleModuleIdentifier = (value, nodeBranchIndex) => {
        setSelectedMessage(selectedMessage => {
            const newNodeBranches = [...selectedMessage.message.props.node_branches];
            let newNodeBranch = newNodeBranches[nodeBranchIndex];
            newNodeBranch = {
                ...newNodeBranch,
                moduleIdentifier: value,
            };
            newNodeBranches[nodeBranchIndex] = newNodeBranch;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        node_branches: newNodeBranches,
                    },
                },
            });
        });
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('Collage');
        } else {
            clearDefaultConfigs('Collage');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                Collage: {
                    ...moduleStyles.Collage,
                    animation,
                    replyBackgroundColor,
                    replyBorderColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="quick-reply-module-configuration">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* IMAGE CONFIGURATIONS */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {/* BACKGROUND */}
                    <Row className="mt-1">
                        <Col>
                            <h5 className="shared-heading">
                                Background
                            </h5>
                        </Col>
                    </Row>

                    {/* BACKGROUND COLOR */}
                    <Row className="mt-0">
                        <Col>
                            <ColorPicker
                                heading="Background Color"
                                colorProp="replyBackgroundColor"
                                parentColor={replyBackgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Border Color"
                                colorProp="replyBorderColor"
                                parentColor={replyBorderColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* GLOBAL DEFAULT STYLES */}
                    <Row className="mt-5">
                        <Col>
                            <GlobalStylesToggle
                                moduleType="Collage"
                                heading="Set as default Collage module colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>

                    {/* BUTTON OPTIONS */}
                    <Row className="mt-5">
                        <Col>
                            <h6 className="shared-subheading">
                                Stacked
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isStacked"
                                type="checkbox"
                                checked={isStacked}
                                onChange={handleStackedChange}
                            />
                        </Col>

                        <Col>
                            <h6 className="shared-subheading">
                                <span style={isStacked ? {} : { color: 'darkgray' }}>
                                    Fill Width
                                </span>
                            </h6>
                            <input
                                className="config-checkbox"
                                name="isFilled"
                                type="checkbox"
                                checked={isFilled}
                                disabled={!isStacked}
                                onChange={handleFilledChange}
                            />
                        </Col>
                    </Row>

                    {/* BORDER ROUNDING */}
                    <Row>
                        <Col
                            xl={5}
                            className="ml-2"
                        >
                            <h6 className="shared-subheading">
                                Corner Rounding: &nbsp;
                                {collageStyles.borderRadius}
                                px
                            </h6>
                            <RangeSlider
                                value={collageStyles.borderRadius}
                                min={1}
                                max={20}
                                variant="secondary"
                                onChange={e => handleCornerRounding(e.target.value)}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            {node_branches && node_branches.map((nodeBranch, index) => (
                <Row
                    key={`collage_module_${nodeBranch.imageUrl}_${index}`}
                    className="shared-module-background"
                >
                    <Col>
                        {/* OPTIONS & DROPDOWN */}
                        <Row className="mb-2 align-items-center">
                            {nodeBranch.imageUrl &&
                                <>
                                    {/* IMAGE UPLOAD & OPTIONS */}
                                    <Col className="m-0 p-0">
                                        <div className="shared-heading">
                                            {`Image #${index + 1}`}
                                        </div>

                                        {/* IMAGE UPLOAD */}
                                        <ImageUploadButton
                                            campaign={campaign}
                                            reference={reference}
                                            branchIndex={index}
                                            imageUrl={nodeBranch.imageUrl}
                                            imageFile={nodeBranch.imageFile}
                                            setSelectedMessage={setSelectedMessage}
                                        />

                                        {/* LINKED-TO DESCRIPTION */}
                                        {nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch && nodeBranch.linkingData.nodeBranch.imageUrl &&
                                            <div className="shared-heading">
                                                {`Linked to Image #${nodeBranch.linkingData.index + 1}`}
                                            </div>
                                        }

                                        {/* CONFIGURE PATH BUTTON */}
                                        {(!nodeBranch.linkingData || !nodeBranch.linkingData.nodeBranch) &&
                                            <>
                                                {(nodeBranch.imageUrl === '' || nodeBranch.imageUrl === imageModulePlaceholder) ?
                                                    <>
                                                        <ConfigurePathButton
                                                            answer={nodeBranch.imageUrl}
                                                            reference={reference}
                                                            configureNodeBranchingPath={configureNodeBranchingPath}
                                                            id="warningMessageTooltip"
                                                            disabled
                                                        />
                                                        <UncontrolledTooltip
                                                            placement="top"
                                                            target="warningMessageTooltip"
                                                        >
                                                            Please add an image first
                                                        </UncontrolledTooltip>
                                                    </> :

                                                    <ConfigurePathButton
                                                        answer={nodeBranch.imageUrl}
                                                        reference={reference}
                                                        configureNodeBranchingPath={configureNodeBranchingPath}
                                                    />
                                                }
                                            </>
                                        }
                                    </Col>

                                    {/* DROPDOWN */}
                                    <Col
                                        sm="auto"
                                        className="m-0 p-0"
                                    >
                                        <Dropdown
                                            isOpen={index === actionDropDown.index && actionDropDown.open}
                                            toggle={() => toggleActionDropDown(index)}
                                        >
                                            <DropdownToggle>
                                                <BsThreeDotsVertical size="22" color="#000"/>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                {/* DELETE BUTTON */}
                                                <DropdownItem>
                                                    <Row
                                                        className="m-1"
                                                        onClick={() => handleDeleteOfNodeBranch(nodeBranch, index)}
                                                    >
                                                        <Col className="pr-0" sm="auto">
                                                            <BsTrashFill
                                                                className="pointer"
                                                                size="20"
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <div className="shared-heading">
                                                                Delete image
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </DropdownItem>

                                                <DropdownItem divider/>

                                                {/* MODULE IDENTIFIER */}
                                                <DropdownItem toggle={false}>
                                                    <Row className="m-1">
                                                        <Col>
                                                            <FormGroup>
                                                                <div className="shared-heading pl-1">
                                                                    <IoMdAnalytics
                                                                        size="18px"
                                                                        style={{ marginRight: '10px', color: '#000' }}
                                                                    />
                                                                    Button-click ID for analytics (recommended)
                                                                </div>
                                                                <Input
                                                                    className="shared-input"
                                                                    type="input"
                                                                    id={`module_indentifier_input_${actionDropDown.index}`}
                                                                    placeholder="Enter a unique module ID"
                                                                    value={nodeBranch.moduleIdentifier}
                                                                    onChange={
                                                                        (e) => handleModuleIdentifier(e.target.value, actionDropDown.index)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </DropdownItem>

                                                <DropdownItem divider/>

                                                {/* ADD EXTERNAL LINK */}
                                                <DropdownItem toggle={false}>
                                                    <Row
                                                        className="m-1"
                                                        onClick={() => handleExternalLink(index, nodeBranch, tempExternalURL)}
                                                    >
                                                        <Col className="pr-0" sm="auto">
                                                            {!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                                <BsLink45Deg className="pointer" size="20"/> :
                                                                <BiUnlink className="pointer" size="20"/>
                                                            }
                                                        </Col>
                                                        <Col>
                                                            <div className="shared-heading">
                                                                {!nodeBranch.externalURL || (tempExternalURL !== null && tempExternalURL.trim() !== '') ?
                                                                    'Set external link' :
                                                                    'Remove external link'
                                                                }
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="m-1">
                                                        <Col>
                                                            <FormGroup>
                                                                <Input
                                                                    type="text"
                                                                    name={`collage_node_branch_${index}_external_url_input`}
                                                                    id={`collage_node_branch_${index}_external_url_input`}
                                                                    value={tempExternalURL !== null ? tempExternalURL : nodeBranch.externalURL}
                                                                    onChange={(e) => setTempExternalURL(e.target.value)}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    {tempExternalURL !== null && tempExternalURL.trim() === '' &&
                                                        <Row className="m-1">
                                                            <Col>
                                                                <div
                                                                    className="shared-heading"
                                                                    style={{ fontStyle: 'italic' }}
                                                                >
                                                                    Please choose a valid web address
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                </DropdownItem>

                                                <DropdownItem divider/>

                                                {/* LINK TO OTHER BUTTON */}
                                                {nodeBranch.linkingData && nodeBranch.linkingData.nodeBranch ?
                                                    // UNLINK
                                                    <DropdownItem>
                                                        <Row
                                                            className="m-1"
                                                            onClick={() => handleUnlinkNodeBranch(nodeBranch, index)}
                                                        >
                                                            <Col sm="auto" className="pr-0">
                                                                <BsFillDashCircleFill size="18px" className="pointer"/>
                                                            </Col>
                                                            <Col>
                                                                <div className="shared-heading pl-1">
                                                                    {`Unlink from Image #${nodeBranch.linkingData.index + 1}`}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </DropdownItem> :

                                                    // LINK
                                                    <DropdownItem className="no-pointer">
                                                        {!hasConfiguration(nodeBranch) ?
                                                            // LINK
                                                            <>
                                                                <Row className="m-1">
                                                                    <Col sm="auto" className="pr-0">
                                                                        <GrTree size="18"/>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="shared-heading pl-1">
                                                                            Link to other image:
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                {node_branches && node_branches.map((linkingOption, linkingOptionIndex) => (
                                                                    <span key={`linking_option_${linkingOptionIndex}`}>
                                                                        {(index !== linkingOptionIndex && !linkingOption.linkingData && hasConfiguration(linkingOption)) &&
                                                                            <Row
                                                                                className="quick-reply-option-row m-1 ml-4"
                                                                                onClick={() => handleLinkNodeBranch(nodeBranch, index, linkingOption, linkingOptionIndex)}
                                                                            >
                                                                                <Col className="p-1 pl-3">
                                                                                    <div className="shared-heading">
                                                                                        {`Image #${linkingOptionIndex + 1}`}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                        }
                                                                    </span>
                                                                ))}
                                                            </> :

                                                            // NOT LINKABLE
                                                            <Row className="m-1">
                                                                <Col>
                                                                    <div className="shared-heading">
                                                                        This button is already configured.
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </DropdownItem>
                                                }

                                                <DropdownItem divider/>

                                                {/* ADD WALLPAPER IMAGE */}
                                                <DropdownItem toggle={false}>
                                                    <ButtonWallpaper
                                                        key={`bwp_${index}_${nodeBranch.title}`}
                                                        buttonIndex={actionDropDown.index}
                                                        nodeBranch={nodeBranch}
                                                        setSelectedMessage={setSelectedMessage}
                                                    />
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </>
                            }
                        </Row>

                        {/* EXTERNAL LINK SUBTEXT */}
                        <Row className="mb-2">
                            <Col>
                                {nodeBranch.externalURL &&
                                    <Fade
                                        in
                                        tag="p"
                                        className="font-italic"
                                    >
                                        {`Externally linked to ${nodeBranch.externalURL}`}
                                    </Fade>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            ))}

            {/* NO IMAGE WARNING */}
            {!node_branches.length &&
                <Row>
                    <Col>
                        <p className="text-danger">
                            A collage module must have at least one image.
                        </p>
                    </Col>
                </Row>
            }

            <Row>
                <Col>
                    <AddNewButton
                        handleClick={handleNewNodeBranch}
                        text="Add new button"
                    />
                </Col>
            </Row>

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default Collage;
