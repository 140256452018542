/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Col, Row, Input } from 'reactstrap';
import {
    BranchingPath,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { checkSharedMessageError } from './errorHelper';

const LocationButton = ({
    text = 'Get Location',
    buttonTextColor,
    buttonColor,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    moduleHeading,
    waterfallLayout,
    node_branches = [],
    setSelectedMessage,
    reference,
    configureNodeBranchingPath,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [textOnButton, setTextOnButton] = useState(text);
    const [isFirstTime, setIsFirstTime] = useState(true);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.LocationButton.animation,
                        buttonTextColor: moduleStyles.LocationButton.buttonTextColor,
                        buttonColor: moduleStyles.LocationButton.buttonColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                LocationButton: {
                    ...moduleStyles.LocationButton,
                    animation,
                    buttonTextColor,
                    buttonColor,
                },
            }));
        }
    }, [animation, buttonTextColor, buttonColor]);

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('LocationButton');
        } else {
            clearDefaultConfigs('LocationButton');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                LocationButton: {
                    ...moduleStyles.LocationButton,
                    animation,
                    buttonTextColor,
                    buttonColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    // STYLE CHANGES
    const emptyValue = ' ';

    const handleTextChange = (text) => {
        setTextOnButton(text.target);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    text,
                    error: (text === emptyValue) || checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    return (
        <div className="options-container">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* INPUT FIELD */}
            <Row className="shared-module-background text-input-field">
                <Col className="m-0 p-0">
                    <h6 className="shared-heading">
                        Button Text
                    </h6>
                    <Input
                        type="text"
                        value={textOnButton}
                        onChange={event => handleTextChange(event.target.value)}
                    />
                </Col>
            </Row>

            {/* COLOR PICKER */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {/* BUTTON COLORS */}
                    <Row>
                        <Col>
                            <ColorPicker
                                heading="Button Color"
                                colorProp="buttonColor"
                                parentColor={buttonColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Text Color"
                                colorProp="buttonTextColor"
                                parentColor={buttonTextColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    {/* GLOBAL DEFAULT STYLES */}
                    <Row className="mt-4">
                        <Col>
                            <GlobalStylesToggle
                                moduleType="LocationButton"
                                heading="Set as default Location button colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <BranchingPath
                nodeBranches={node_branches}
                reference={reference}
                configureNodeBranchingPath={configureNodeBranchingPath}
            />

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />

            {/* UPLOAD LEFT WALLPAPER HORIZONTAL LAYOUT */}
            <ImageUpload
                wallpaper
                imageUrl={horizontalImageUrl}
                imageFile={horizontalImageFile}
                horizontalBgSize={horizontalBgSize}
                horizontalBgRepeat={horizontalBgRepeat}
                wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                setSelectedMessage={setSelectedMessage}
            />
        </div>
    );
};

export default LocationButton;
