import React from 'react';
import Text from './Text';
import Image from './Image';

const LinkModal = ({ linkText, image, link }) => {
    const openLink = () => {
        window.open(link, '_blank');
    };

    return (
        <div className="modal-component">
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={() => openLink()} className="cursor-pointer">
                {image && <Image url={image}/>}
                {linkText && <Text text={linkText} textClassName="font-weight-bold"/>}
            </div>
        </div>
    );
};
export default LinkModal;