import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import checkPermission from '../../accessControl';

const decideOnComponent = (Component, Layout, props, rest) => {
    const authorizedUser = checkPermission(props.location.pathname);
    if (rest.loggedUser) {
        return authorizedUser ?
            <Layout component={<Component loggedUser={rest.loggedUser} {...props}/>}/> :
            <Redirect to={{ pathname: '/unauthorized' }}/>;
    }
    return <Redirect to={{ pathname: '/login', state: { from: props.location } }}/>;
};

const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={(props) => decideOnComponent(Component, Layout, props, rest)}
    />
);

function mapStateToProps(state) {
    return {
        loggedUser: state.auth.loggedUser,
    };
}

export default connect(mapStateToProps)(PrivateRoute);