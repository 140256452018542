import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Cookies from 'js-cookie';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
    Label,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Col,
    Collapse,
    Card,
    CardBody,
} from 'reactstrap';
import TagManager from 'react-gtm-module';
import ChatbotLayout from './ChatbotLayout';
import salesDemoIcon from '../../assets/images/salesdemo-logo.png';
import salesDemoAvatar from '../../assets/images/Brand_Chat_Logo.png';
import marketingImage from '../../assets/images/bc_marketing.jpg';
import MessageModule from './MessageModule';
import Typing from './Typing';
import CookieInfoModal from './CookieInfoModal';
import { GA_ID, GA_APP_WEB_ID } from '../../config';
import { MODULE_TYPES } from '../Modules/constants';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        // eslint-disable-next-line no-bitwise
        const r = Math.random() * 16 | 0;
        // eslint-disable-next-line no-bitwise, no-mixed-operators
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function getUserID() {
    let userId = Cookies.get('userId');
    if (!userId) {
        const days = 1;
        const d = new Date();
        Cookies.set('userId', uuidv4(), { expires: d.getTime() + (days * 24 * 60 * 60 * 1000) });
        userId = Cookies.get('userId');
    }
    return userId;
}

const eventId = uuidv4();
const eventTimestamp = new Date().getTime();

const tagManagerArgs = {
    gtmId: 'GTM-P5BWHX5',
};
TagManager.initialize(tagManagerArgs);

function getCookiesConfig() {
    return JSON.parse(Cookies.get('cookiesConfiguration'));
}

const ChatbotMarketingDemo = () => {
    const gaSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    const gaAppWebSrc = `https://www.googletagmanager.com/gtag/js?id=${GA_APP_WEB_ID}`;
    const [showTyping, setShowTyping] = useState(false);
    const [messages, setMessages] = useState([]);
    const [campaign, setCampaign] = useState({});
    const [showCelebration, setShowCelebration] = useState(false);
    const [cookieConfigurationDone, setCookieConfigurationDone] = useState(false);
    const [enableTrackingCookies, setEnableTrackingCookies] = useState(false);
    const [tmpEnableTrackingCookies, setTmpEnableTrackingCookies] = useState(false);
    const [showCookieInfoModal, setShowCookieInfoModal] = useState(true); // made true at start always for demo purposes
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [isOpenRequiredCookieInfo, setIsOpenRequiredCookieInfo] = useState(false);
    const [isOpenAnalyticCookieInfo, setIsOpenAnalyticCookieInfo] = useState(false);

    const testCampaign = {
        color: '#0e1a5b',
        logo: salesDemoIcon,
        avatar: salesDemoAvatar,
    };

    const message1 = {
        type: MODULE_TYPES.Image,
        props: {
            url: marketingImage,
        },
    };

    const message2 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Hi there 👋, and welcome to Brandchat! ' +
            "Let us show you how we can improve your campaign's ROI via chat!",
            showAvatar: true,
        },
    };

    const message3 = {
        type: MODULE_TYPES.Text,
        props: {
            text: 'Learn more about what we do in these areas...',
        },
    };

    const message4 = {
        type: MODULE_TYPES.QuickReply,
        props: {
            quick_replies: [{
                title: 'Events',
                payload: 'marketingDemoEvents',
            },
            {
                title: 'Shopping',
                payload: 'marketingDemoEcommerce',
            },
            {
                title: 'Marketing',
                payload: 'marketingDemoMarketing',
            }],
            showAvatar: false,
            showCelebration: false,
        },
    };

    const getCampaign = () => {
        // TODO Fetch data here
        setCampaign(testCampaign);
    };

    const addMessage = (message) => {
        setMessages(messageHistory => [...messageHistory, message]);
    };

    const renderMessage = (message) => <MessageModule
        key={JSON.stringify(message)}
        setMessages={setMessages}
        message={message}
        setShowCelebration={message.props.showCelebration && setShowCelebration}
        avatar={message.props.showAvatar && campaign.avatar}
    />;

    const populateBubbles = () => {
        setTimeout(() => {
            setShowTyping(true);
            addMessage(message1);
        }, 4000);

        setTimeout(() => {
            addMessage(message2);
        }, 5000);

        setTimeout(() => {
            addMessage(message3);
        }, 6200);

        setTimeout(() => {
            setShowTyping(false);
            addMessage(message4);
        }, 7400);
    };

    const updateUnfinishedCookieConfiguration = () => {
        const cookiesConfig = getCookiesConfig();
        if (!cookiesConfig.configurationDone) {
            Cookies.set(
                'cookiesConfiguration',
                { configurationDone: true, trackingCookiesEnabled: tmpEnableTrackingCookies },
                { expires: new Date().getTime() + (10 * 365 * 24 * 60 * 60 * 1000) },
            );
            setEnableTrackingCookies(tmpEnableTrackingCookies);
            setCookieConfigurationDone(true);
        }
    };

    const closeCookieInfoModal = () => {
        updateUnfinishedCookieConfiguration();
        setShowCookieInfoModal(!showCookieInfoModal);
    };

    const closeCookieModal = () => {
        updateUnfinishedCookieConfiguration();
        setShowCookieModal(!showCookieModal);
    };

    const changeCookiePreferences = () => {
        setShowCookieInfoModal(false);
        setShowCookieModal(true);
    };

    useEffect(() => {
        // eslint-disable-next-line no-undef
        setShowTyping(true);
        getCampaign();
        populateBubbles();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <title>{campaign && campaign.name && `${campaign.name} Chat`}</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            {cookieConfigurationDone && enableTrackingCookies &&
                <Helmet encodeSpecialCharacters>
                    {/* Global site tag (gtag.js) - Google Analytics (Legacy implementation / Dev) */}
                    <script async src={gaSrc}/>
                    <script>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('set', 'dimension1', '${eventId}');
                            gtag('set', 'dimension2', '${eventTimestamp}');
                            gtag('config', '${GA_ID}');
                        `}
                    </script>
                    {/* Global site tag (gtag.js) - Google Analytics  (Newer App + Web Implementation
                        DEV GA ID

                    Note: the id being G-JB39LPBB61 instead of UA-XXXXXXX-X is due to this being a new format of tracking that
                        GA is doing. */}
                    <script async src={gaAppWebSrc}/>
                    <script>
                        {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag() { dataLayer.push(arguments); }
                            gtag('js', new Date());
                            gtag('set', 'user_properties', {
                                event_id: '${eventId}',
                                event_timestamp: '${eventTimestamp}'
                            });
                            gtag('config', '${GA_APP_WEB_ID}', {
                                'user_id': '${getUserID()}'
                            });
                        `}
                    </script>
                </Helmet>
            }
            <ChatbotLayout campaign={campaign} showCelebration={showCelebration}>
                {showCookieInfoModal &&
                    <CookieInfoModal
                        onCloseEvent={closeCookieInfoModal}
                        title="Cookie Policy"
                        text="BrandChat works best with cookies enabled.
                            We use this information to improve and
                            customize your experience. To find out more
                            about the cookies we use, see our "
                    >
                        <a href="https://www.getbrandchat.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <Row>
                            <Col className="col-7 text-center change-cookie-settings">
                                <Button color="link" onClick={() => changeCookiePreferences()}>Change Settings</Button>
                            </Col>
                            <Col className="col-5 text-center accept-cookies">
                                <Button color="primary" onClick={() => closeCookieInfoModal()}>Accept</Button>
                            </Col>
                        </Row>
                    </CookieInfoModal>
                }
                {showCookieModal &&
                    <Modal
                        isOpen
                        size="lg"
                        backdrop={false}
                        toggle={() => setShowCookieModal(!showCookieModal)}
                        className="cookie-consent-modal h-100"
                    >
                        <ModalHeader toggle={() => closeCookieModal()}>
                            Cookie Settings
                        </ModalHeader>
                        <ModalBody className="custom-content">
                            <Row>
                                <Col className="col-9">
                                    <span className="mr-2">Strictly necessary</span>
                                    {!isOpenRequiredCookieInfo && <IoIosArrowDown
                                        onClick={() => setIsOpenRequiredCookieInfo(!isOpenRequiredCookieInfo)}
                                    />}
                                    {isOpenRequiredCookieInfo && <IoIosArrowUp
                                        onClick={() => setIsOpenRequiredCookieInfo(!isOpenRequiredCookieInfo)}
                                    />}
                                </Col>
                                <Col className="col-3">
                                    <Label className="switch">
                                        <input type="checkbox" checked disabled/>
                                        <span className="slider round"/>
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Collapse isOpen={isOpenRequiredCookieInfo}>
                                        <Card>
                                            <CardBody>
                                                These cookies or other identifiers are used for activities that are strictly
                                                necessary to operate or deliver the service you requested from us and, therefore,
                                                do not require you to consent.
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-9">
                                    <span className="mr-2">Analytics</span>
                                    {!isOpenAnalyticCookieInfo && <IoIosArrowDown
                                        onClick={() => setIsOpenAnalyticCookieInfo(!isOpenAnalyticCookieInfo)}
                                    />}
                                    {isOpenAnalyticCookieInfo && <IoIosArrowUp
                                        onClick={() => setIsOpenAnalyticCookieInfo(!isOpenAnalyticCookieInfo)}
                                    />}
                                </Col>
                                <Col className="col-3">
                                    <Label className="switch">
                                        <input
                                            onClick={() => setTmpEnableTrackingCookies(!tmpEnableTrackingCookies)}
                                            type="checkbox"
                                            checked={tmpEnableTrackingCookies}
                                        />
                                        <span className="slider round"/>
                                    </Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Collapse isOpen={isOpenAnalyticCookieInfo}>
                                        <Card>
                                            <CardBody>
                                                Analytics cookies are used to understand how visitors interact with the website.
                                                These cookies help provide information on metrics the number of visitors, bounce rate,
                                                traffic source, etc.
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <Button color="primary" onClick={() => closeCookieModal()}>Save Settings</Button>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
                {messages.map((message) => renderMessage(message))}
                {showTyping && <Typing avatar={campaign.avatar}/>}
            </ChatbotLayout>
        </HelmetProvider>
    );
};

export default ChatbotMarketingDemo;