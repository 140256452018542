import React, { useState } from 'react';
import { Button, Col, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import QRCode from 'qrcode.react';
import { BsFiles } from 'react-icons/bs';
import Lottie from 'react-lottie';
import { FRONTEND_URL } from '../../config';
import * as animationData from '../../assets/animations/copySuccess';
import { ClipboardCopy } from '../../containers/ChatbotConfiguration/SharedConfigurations';

const ModalQrCode = ({
    campaignRef,
    exitText,
    setupText,
    configureText,
    handleExit,
    handleSetup,
    handleConfigure,
    headerText,
    showSpinner,
    errorSaveCampaign,
    handleError,
    handleClose,
}) => {
    const [productionCopySuccess, setProductionCopySuccess] = useState(false);
    const [previewCopySuccess, setPreviewCopySuccess] = useState(false);

    const animationOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <Modal
            isOpen
            centered
            size="xl"
            className="campaign-modal"
            toggle={handleClose}
        >
            {/* SPINNER */}
            {showSpinner &&
                <Row>
                    <Col
                        md={{ size: 6, offset: 3 }}
                        className="text-center p-5"
                    >
                        <Spinner style={{ width: '100px', height: '100px' }}/>
                    </Col>
                </Row>
            }

            {/* ERROR */}
            {!showSpinner && errorSaveCampaign &&
                <ModalBody className="m-5">
                    <Row>
                        <Col>
                            <h3 className="text-center">Oops… Something went wrong :(</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Button
                                className="button-secondary m-3"
                                onClick={handleError}
                            >
                                Go back and try again
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            }

            {/* CAMPAIGN MODAL */}
            {!showSpinner && !errorSaveCampaign &&
                <ModalBody className="text-center p-5">
                    {/* HEADER */}
                    <Row className="p-0">
                        <Col>
                            <h3 className="mb-3">
                                {headerText && headerText}
                            </h3>
                        </Col>
                    </Row>

                    {/* QR CODE */}
                    <Row>
                        <Col>
                            <h6 className="m-3">Scan this QR-code to see your campaign:</h6>
                            <QRCode
                                value={`${FRONTEND_URL}/${campaignRef && campaignRef}`}
                                className="m-3 text-center"
                            />
                        </Col>
                    </Row>

                    {/* HYPERLINK */}
                    {campaignRef ?
                        <>
                            <Row className="row-hyperlink mb-3">
                                <Col className="text-center pr-0 my-auto">
                                    Campaign
                                    {' '}
                                    <a
                                        href={`${FRONTEND_URL}/${campaignRef}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {`${FRONTEND_URL}/${campaignRef}`}
                                    </a>
                                    {' '}
                                    {!productionCopySuccess ?
                                        <ClipboardCopy
                                            text={`${FRONTEND_URL}/${campaignRef}`}
                                            onCopy={() => { setProductionCopySuccess(true); setPreviewCopySuccess(false); }}
                                        >
                                            <BsFiles
                                                size="18px"
                                                color="#000"
                                            />
                                        </ClipboardCopy> :

                                        <Lottie
                                            options={animationOptions}
                                            height={30}
                                            width={30}
                                        />
                                    }
                                </Col>
                            </Row>

                            <Row className="row-hyperlink mb-3">
                                <Col className="text-center pr-0 my-auto">
                                    Preview
                                    {' '}
                                    <a
                                        href={`${FRONTEND_URL}/curr3nt-preview/${campaignRef}`}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        {`${FRONTEND_URL}/curr3nt-preview/${campaignRef}`}
                                    </a>
                                    {' '}
                                    {!previewCopySuccess ?
                                        <ClipboardCopy
                                            text={`${FRONTEND_URL}/curr3nt-preview/${campaignRef}`}
                                            onCopy={() => { setPreviewCopySuccess(true); setProductionCopySuccess(false); }}
                                        >
                                            <BsFiles
                                                size="18px"
                                                color="#000"
                                            />
                                        </ClipboardCopy> :

                                        <Lottie
                                            options={animationOptions}
                                            height={30}
                                            width={30}
                                        />
                                    }
                                </Col>
                            </Row>
                        </> :

                        <Row>
                            <Col>
                                <p>No campaign found</p>
                            </Col>
                        </Row>
                    }

                    {/* BUTTONS */}
                    <Row className="justify-content-center">
                        {exitText &&
                            <Col>
                                <Button
                                    className="button-secondary my-4"
                                    onClick={handleExit}
                                >
                                    {exitText}
                                </Button>
                            </Col>
                        }
                        {setupText &&
                            <Col>
                                <Button
                                    className="button-secondary my-4"
                                    onClick={handleSetup}
                                >
                                    {setupText}
                                </Button>
                            </Col>
                        }
                        {configureText &&
                            <Col>
                                <Button
                                    className="button-secondary my-4"
                                    onClick={handleConfigure}
                                >
                                    {configureText}
                                </Button>
                            </Col>
                        }
                    </Row>
                </ModalBody>
            }
        </Modal>
    );
};

export default ModalQrCode;
