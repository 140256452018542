import React, { useState, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardImg, CardSubtitle, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { Image } from 'react-bootstrap';
import { BsArrowCounterclockwise, BsArchive } from 'react-icons/bs';
import { iconQRcode } from '../../assets/images';
import { history } from '../../routes';
import campaignLogoPlaceholder from '../../assets/images/campaign-logo-placeholder.png';
import { archiveSelectedCampaign, restoreSelectedCampaign } from '../../redux/campaign/actions';

const CampaignCard = ({
    campaign,
    handleModal,
    archiveSelectedCampaign,
    restoreSelectedCampaign,
}) => {
    const [campaignNameFontSize, setCampaignNameFontSize] = useState('14px');

    useEffect(() => {
        if (campaign.name.length > 40) {
            setCampaignNameFontSize('9px');
        } else if (campaign.name.length > 25) {
            setCampaignNameFontSize('11.5px');
        } else {
            setCampaignNameFontSize('14px');
        }
    }, [campaign]);

    const handleSelectInsights = () => {
        history.push('/insights', campaign.id);
    };

    return (
        <Card className="campaign mx-0 my-4 p-0">
            {/* CARD BOX */}
            <CardBody className="card-body m-0 p-0">
                {/* TOP ROW ICONS */}
                <Row className="justify-content-between pt-1">
                    {!campaign.archived ?
                        <>
                            {/* QR CODE */}
                            <Col className="mx-3">
                                <Image
                                    className="pointer"
                                    src={iconQRcode}
                                    onClick={() => handleModal(campaign)}
                                    id={`qrcode_campaign_${campaign.id}`}
                                />
                                <UncontrolledTooltip
                                    target={`qrcode_campaign_${campaign.id}`}
                                >
                                    QR Code
                                </UncontrolledTooltip>
                            </Col>

                            {/* ARCHIVE */}
                            <Col
                                sm="auto"
                                className="mx-3"
                            >
                                <BsArchive
                                    className="react-icon pointer"
                                    size="17px"
                                    onClick={() => toastr.confirm('Are you sure you want to archive this campaign?',
                                        {
                                            onOk: () => archiveSelectedCampaign(campaign),
                                            closeOnToastrClick: true,
                                        })}
                                    id={`archive_campaign_${campaign.id}`}
                                />
                                <UncontrolledTooltip
                                    target={`archive_campaign_${campaign.id}`}
                                >
                                    Archive campaign
                                </UncontrolledTooltip>
                            </Col>

                            {/* INSIGHTS */}
                            {/* <Col sm="auto" className="mx-3">
                                <Image
                                    src={graphIcon}
                                    className="pointer"
                                    onClick={() => history.push('/insights', campaign.id)}
                                    id={`insights${campaign.id}`}
                                />
                                <UncontrolledTooltip target={`insights${campaign.id}`}>
                                    Insights
                                </UncontrolledTooltip>
                            </Col> */}

                            {/* CONFIGURE */}
                            {/* <Col sm="auto" className="mx-3">
                                <BsChatDots
                                    color="063476"
                                    className="pointer"
                                    onClick={() => history.push('campaign/chatbot-configuration', campaign)}
                                    id={`configure${campaign.id}`}
                                />
                                <UncontrolledTooltip target={`configure${campaign.id}`}>
                                    Configure chatbot
                                </UncontrolledTooltip>
                            </Col> */}

                            {/* EDIT */}
                            {/* <Col sm="auto" className="mx-3">
                                <Image
                                    src={editIcon}
                                    className="pointer"
                                    onClick={() => history.push('campaign/edit', campaign)}
                                    id={`edit${campaign.id}`}
                                />
                                <UncontrolledTooltip target={`edit${campaign.id}`}>
                                    Edit campaign
                                </UncontrolledTooltip>
                            </Col> */}
                        </> :

                        // RESTORE CAMPAIGN
                        <Col className="text-right m-0 pt-1">
                            <BsArrowCounterclockwise
                                className="react-icon pointer mr-3 p-0"
                                size="22px"
                                onClick={() => toastr.confirm('Are you sure you want to restore this campaign?',
                                    {
                                        onOk: () => restoreSelectedCampaign(campaign),
                                        closeOnToastrClick: true,
                                    })
                                }
                                id={`restore${campaign.id}`}
                            />
                            <UncontrolledTooltip
                                target={`restore${campaign.id}`}
                            >
                                Restore campaign
                            </UncontrolledTooltip>
                        </Col>
                    }
                </Row>

                {/* CAMPAIGN IMAGE */}
                <Row>
                    <Col className="image-col px-5">
                        <CardImg
                            className="border-secondary p-0 card-image text-center"
                            src={campaign && campaign.logo ? campaign.logo : campaignLogoPlaceholder}
                            alt={campaign.name}
                            onClick={() => handleSelectInsights()}
                            height="100%"
                            width="50%"
                            id={`campaign_analytics_${campaign.id}`}
                        />
                        <UncontrolledTooltip
                            target={`campaign_analytics_${campaign.id}`}
                            placement="bottom"
                        >
                            Campaign Analytics
                        </UncontrolledTooltip>
                    </Col>
                </Row>

                {/* CAMPAIGN NAME */}
                <Row>
                    <Col className="card-col">
                        <span
                            className="card-campaign-name"
                            style={{ fontSize: campaignNameFontSize }}
                        >
                            {campaign.name}
                        </span>
                    </Col>
                </Row>

                <Row className="card-divider m-0 p-0"/>

                {/* EDIT */}
                <Row>
                    <Col className="card-col">
                        <Button
                            className="card-edit"
                            id={`edit_${campaign.id}`}
                            onClick={() => history.push('campaign/edit', campaign)}
                        >
                            Edit
                        </Button>
                        <UncontrolledTooltip
                            target={`edit_${campaign.id}`}
                        >
                            Edit campaign
                        </UncontrolledTooltip>
                    </Col>
                </Row>
            </CardBody>

            {/* ACTVE/INACTIVE */}
            <Row className="m-0 pl-1 pt-2">
                <Col className="card-col">
                    <CardSubtitle
                        className={`card-subtitle font-weight-bold mt-0 ${campaign.active ? 'active' : 'inactive'}`}
                    >
                        {`${campaign.active ? 'Active' : 'Inactive'}`}
                    </CardSubtitle>
                </Col>
            </Row>
        </Card>
    );
};

export default connect(null, { archiveSelectedCampaign, restoreSelectedCampaign })(CampaignCard);
