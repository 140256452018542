import React from 'react';
import { Row, Col } from 'reactstrap';
import { Editor } from '@tinymce/tinymce-react';
import { checkSharedMessageError } from '../ModuleConfigurations/errorHelper';
import { TINY_MCE_API_KEY } from '../../../config';

const HeadingEditor = ({ moduleHeading, setSelectedMessage, isFirstTime, setIsFirstTime }) => {
    const initTinyMCEHeading = {
        height: 150,
        menubar: 'edit view format tools table tc',
        plugins: [
            'advlist autolink lists link image',
            'charmap print preview anchor help',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount',
        ],
        toolbar:
            `undo redo |
            bold italic underline strikethrough |
            fontselect fontsizeselect formatselect |
            alignleft aligncenter alignright alignjustify |
            outdent indent |
            numlist bullist checklist |
            forecolor backcolor casechange permanentpen formatpainter removeformat |
            pagebreak | charmap emoticons |
            fullscreen preview |
            link |
            a11ycheck ltr rtl |
            showcomments addcomment`,
        content_style: `
            @import url('https://fonts.googleapis.com/css2?family=Abel&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Kaisei+Tokumin:wght@400;700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@0,300;0,700;1,300&display=swap');
            @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700');
            @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');
            body { font-family: 'Roboto', sans-serif; } h1,h2,h3,h4,h5,h6 { font-family: 'Roboto', sans-serif; }
        `,
        font_formats: `
            Abel=abel,sans-serif;
            Arial=arial,helvetica,sans-serif;
            Arial Black=arial black,gadget,avant garde;
            Comic Sans MS=comic sans ms,comic sans ms5,cursive;
            Courier New=courier new,courier;
            Crimson Text=crimson text,serif;
            Georgia=georgia, serif;
            Helvetica=helvetica,sans-serif;
            Impact=impact,charcoal,sans-serif;
            Kaisei Tokumin=kaisei tokumin,serif;
            Lucida Console=lucida console,monaco,monospace;
            Lucida Sans Unicode=lucida sans unicode,lucida grande,sans-serif;
            Open Sans=open sans,sans-serif;
            Open Sans Condensed=open sans condensed,sans-serif;
            Raleway=raleway,serif;
            Roboto=roboto,sans-serif;
            Tahoma=tahoma,geneva,sans-serif;
            Terminal=terminal,monospace;
            Times New Roman=times new roman,times,serif;
            Trebuchet MS=trebuchet ms,sans-serif;
            Verdana=verdana,geneva,sans-serif;
        `,
    };
    const initialHeadingValue = '<p>Type heading here...</p>';

    const handleHeadingEditorChange = (content) => {
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    moduleHeading: content,
                    error: (!isFirstTime && moduleHeading === '') || checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
        setIsFirstTime(false);
    };

    return (
        <Row className="shared-module-background">
            <Col className="m-0 p-0">
                <div className="shared-heading">
                    Module Heading
                </div>
                <Editor
                    apiKey={TINY_MCE_API_KEY}
                    initialValue={initialHeadingValue}
                    value={moduleHeading}
                    init={initTinyMCEHeading}
                    onEditorChange={handleHeadingEditorChange}
                    outputFormat="html"
                />
            </Col>
        </Row>
    );
};

export default HeadingEditor;
