import { convertSpaceToUnderscore } from './regexHelpers';

export const converNodeBranchToUnderscore = (str) => str.replace(/ /g, '_');

// eslint-disable-next-line max-len
export const generateKeyword = (campaignName, campaignID, ref, answer) => `${convertSpaceToUnderscore(campaignName)}_${campaignID}_${ref}_${converNodeBranchToUnderscore(answer)}`;

export const generatePayload = (campaign, uid, answer) => {
    const payloadObject = {
        type: 'survey',
        campaignID: campaign.id,
        campaignName: convertSpaceToUnderscore(campaign.name),
        uid,
        answer,
    };

    return JSON.stringify(payloadObject);
};