/* eslint-disable camelcase */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Col, Row, Input } from 'reactstrap';
import {
    BranchingPath,
    ColorPicker,
    GlobalStylesToggle,
    HeadingEditor,
    ImageUpload,
    ModuleAnimations,
    ModuleStyles,
    NodeBranchingOptions,
} from '../SharedConfigurations';
import { checkSharedMessageError } from './errorHelper';

const SKUx = ({
    text = 'Enter Email For Offer',
    backgroundColor,
    buttonColor,
    horizontalImageUrl,
    horizontalImageFile,
    horizontalBgSize,
    horizontalBgRepeat,
    wallpaperExternalLinkUrl,
    node_branches = [],
    moduleHeading,
    waterfallLayout,
    setSelectedMessage,
    reference,
    configureNodeBranchingPath,
    styles,
    animation,
    moduleStyles,
    setModuleStyles,
    isDefaultConfig,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    existingModule = false,
}) => {
    const [textOnButton, setTextOnButton] = useState(text);
    const [isFirstTime, setIsFirstTime] = useState(true);

    useEffect(() => {
        if (!existingModule) {
            setSelectedMessage(selectedMessage => ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...selectedMessage.message.props,
                        existingModule: true,
                        animation: moduleStyles.SKUx.animation,
                        backgroundColor: moduleStyles.SKUx.backgroundColor,
                        buttonColor: moduleStyles.SKUx.buttonColor,
                    },
                },
            }));
        }
    }, []);

    useEffect(() => {
        if (isDefaultConfig) {
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                SKUx: {
                    ...moduleStyles.SKUx,
                    animation,
                    backgroundColor,
                    buttonColor,
                },
            }));
        }
    }, [animation, backgroundColor, buttonColor]);

    // STYLE CHANGES
    const emptyValue = ' ';

    const handleTextChange = (text) => {
        setTextOnButton(text.target);
        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    text,
                    emailFieldValue: textOnButton,

                    error: (text === emptyValue) || checkSharedMessageError(selectedMessage.message.props),
                },
            },
        }));
    };

    const handleDefaultToggle = () => {
        if (isDefaultConfig) {
            restoreDefaultModuleStyles('SKUx');
        } else {
            clearDefaultConfigs('SKUx');
            setModuleStyles(moduleStyles => ({
                ...moduleStyles,
                SKUx: {
                    ...moduleStyles.SKUx,
                    animation,
                    backgroundColor,
                    buttonColor,
                },
            }));
        }

        setSelectedMessage(selectedMessage => ({
            ...selectedMessage,
            message: {
                ...selectedMessage.message,
                props: {
                    ...selectedMessage.message.props,
                    isDefaultConfig: !isDefaultConfig,
                },
            },
        }));
    };

    return (
        <div className="options-container">
            {/* MODULE HEADING */}
            {waterfallLayout &&
                <HeadingEditor
                    moduleHeading={moduleHeading}
                    setSelectedMessage={setSelectedMessage}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            }

            {/* STYLES */}
            {!waterfallLayout &&
                <ModuleStyles
                    styles={styles}
                    setSelectedMessage={setSelectedMessage}
                />
            }

            {/* ANIMATIONS */}
            <ModuleAnimations
                animation={animation}
                setSelectedMessage={setSelectedMessage}
            />

            {/* INPUT FIELD */}
            <Row className="shared-module-background text-input-field">
                <Col className="m-0 p-0">
                    <h6 className="shared-heading">
                        Button Text
                    </h6>
                    <Input
                        className="shared-input"
                        type="text"
                        value={text}
                        onChange={event => handleTextChange(event.target.value)}
                    />
                </Col>
            </Row>

            {/* COLOR PICKER */}
            <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    <Row>
                        <Col>
                            <ColorPicker
                                heading="Background Color"
                                colorProp="backgroundColor"
                                parentColor={backgroundColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                            />
                        </Col>
                        <Col className="d-flex justify-content-end">
                            <ColorPicker
                                heading="Button Color"
                                colorProp="buttonColor"
                                parentColor={buttonColor}
                                setSelectedMessage={setSelectedMessage}
                                moduleStyles={moduleStyles}
                                setModuleStyles={setModuleStyles}
                                buttonPopoverStyles={{ left: '-95px' }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <GlobalStylesToggle
                                moduleType="SKUx"
                                heading="Set as default SKUx module colors"
                                isDefaultConfig={isDefaultConfig}
                                handleDefaultToggle={handleDefaultToggle}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>

            {/* CONFIGURE NODE BRANCHING */}
            <BranchingPath
                nodeBranches={node_branches}
                reference={reference}
                configureNodeBranchingPath={configureNodeBranchingPath}
            />
            {/* <Row className="shared-module-background">
                <Col className="m-0 p-0">
                    {node_branches && (node_branches[0].title || node_branches[0].title === '') &&
                    (!node_branches[0].linkingData || !node_branches[0].linkingData.nodeBranch) &&
                        <>
                            <Button
                                className="btn-secondary-custom py-2"
                                onClick={() => configureNodeBranchingPath(node_branches[0].title, reference)}
                            >
                                Configure Branching Path...
                            </Button>
                        </>
                    }
                </Col>
            </Row> */}

            <NodeBranchingOptions
                nodeBranches={node_branches}
                setSelectedMessage={setSelectedMessage}
            />

            {/* UPLOAD LEFT WALLPAPER HORIZONTAL LAYOUT */}
            <ImageUpload
                wallpaper
                imageUrl={horizontalImageUrl}
                imageFile={horizontalImageFile}
                horizontalBgSize={horizontalBgSize}
                horizontalBgRepeat={horizontalBgRepeat}
                wallpaperExternalLinkUrl={wallpaperExternalLinkUrl}
                setSelectedMessage={setSelectedMessage}
            />

        </div>
    );
};

export default SKUx;
