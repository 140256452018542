import React, { useState, useEffect } from 'react';
import { Row, Col, FormGroup, Input, Label } from 'reactstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { BsArrowsFullscreen, BsAspectRatio } from 'react-icons/bs';

const ImageSizing = ({
    stylesBgSize,
    stylesBgRepeat,
    setCoverValues,
    setButtonValues,
}) => {
    const [isCoverSize, setCoverSize] = useState(false);
    useEffect(() => {
        if (stylesBgRepeat && stylesBgRepeat === 'repeat') {
            setCoverSize(true);
        }
    }, [stylesBgRepeat]);

    const [sliderValue, setSliderValue] = useState(stylesBgSize && !isNaN(parseInt(stylesBgSize, 10)) ? parseInt(stylesBgSize, 10) : 100);
    useEffect(() => {
        if (stylesBgSize) {
            setSliderValue(parseInt(stylesBgSize, 10));
        }
    }, [stylesBgSize]);

    const handleSlider = (value) => {
        setSliderValue(value);
        setCoverValues(value);
    };

    const handleImageSize = (size, repeat) => {
        if (repeat === 'repeat') {
            setCoverSize(true);
        } else {
            setCoverSize(false);
        }
        setButtonValues(size, repeat);
    };

    return (
        <>
            <Row className="m-0 p-0">
                <Col className="m-0 p-0">
                    <div className="image-sizing-heading">
                        Image Sizing
                    </div>
                    <FormGroup
                        className="m-0 p-0"
                        tag="fieldset"
                        row
                    >
                        <Col
                            sm={8}
                            className="radio-button-container m-0 p-0"
                        >
                            <FormGroup
                                check
                                className="mb-2 pl-1"
                            >
                                <Label
                                    check
                                    className="d-flex justify-content-start"
                                >
                                    <Input
                                        type="radio"
                                        name="radioOuterBgImage"
                                        onChange={() => handleImageSize('100% 100%', 'no-repeat')}
                                    />
                                    <BsAspectRatio
                                        className="icon rotated"
                                        size="16px"
                                        color={isCoverSize ? '#999' : '#000'}
                                    />
                                    <h6 style={{ color: isCoverSize ? '#999' : '#000' }}>
                                        Stretch
                                    </h6>
                                </Label>
                            </FormGroup>
                            <FormGroup
                                check
                                className="mb-2 pl-1"
                            >
                                <Label
                                    check
                                    className="d-flex justify-content-start"
                                >
                                    <Input
                                        type="radio"
                                        name="radioOuterBgImage"
                                        onChange={() => handleImageSize('100% auto', 'repeat')}
                                    />
                                    <BsArrowsFullscreen
                                        className="icon compressed"
                                        size="16px"
                                        color={isCoverSize ? '#000' : '#999'}
                                    />
                                    <h6 style={{ color: isCoverSize ? '#000' : '#999' }}>
                                        Cover
                                    </h6>
                                </Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>

            {/* SLIDER */}
            {isCoverSize &&
                <Row>
                    <Col>
                        <RangeSlider
                            value={sliderValue}
                            min={20}
                            max={300}
                            variant="secondary"
                            onChange={e => handleSlider(e.target.value)}
                        />
                    </Col>
                </Row>
            }
        </>
    );
};

export default ImageSizing;