import React from 'react';
import { Col, Row } from 'reactstrap';
import _get from 'lodash.get';
import modules from './ModuleConfigurations';

const ModuleConfiguration = ({
    selectedMessage,
    setSelectedMessage,
    configureNodeBranchingPath,
    campaign,
    waterfallLayout,
    moduleStyles,
    setModuleStyles,
    linkNodeBranches,
    updateKeyword,
    deleteConfigurationForNodeBranch,
    createExternalUrlLink,
    getConfigurationStatus,
    clearDefaultConfigs,
    restoreDefaultModuleStyles,
    listRef,
}) => {
    const type = _get(selectedMessage, 'message.type', null);
    const ConfigurationComponent = modules[type];

    return (
        <Row>
            <Col>
                {ConfigurationComponent &&
                    <ConfigurationComponent
                        campaign={campaign}
                        configureNodeBranchingPath={configureNodeBranchingPath}
                        createExternalUrlLink={createExternalUrlLink}
                        deleteConfigurationForNodeBranch={deleteConfigurationForNodeBranch}
                        getConfigurationStatus={getConfigurationStatus}
                        linkNodeBranches={linkNodeBranches}
                        selectedMessage={selectedMessage}
                        setSelectedMessage={setSelectedMessage}
                        updateKeyword={updateKeyword}
                        waterfallLayout={waterfallLayout}
                        moduleStyles={moduleStyles}
                        setModuleStyles={setModuleStyles}
                        clearDefaultConfigs={clearDefaultConfigs}
                        restoreDefaultModuleStyles={restoreDefaultModuleStyles}
                        listRef={listRef}
                        {...selectedMessage.message.props}
                    />
                }
            </Col>
        </Row>
    );
};

export default ModuleConfiguration;