import React, { useEffect, useState } from 'react';
import ChatbotLogic from '../../components/ChatbotLogic';
import { getCampaignModulesConfigurationAsAnonymus } from '../../services/campaign';
import { CUSTOM_TOKEN, CAMPAIGN_NOT_ACTIVE_MESSAGE } from '../../config';

const ChatbotCore = ({
    campaign,
    chatbotActive,
    showPreview,
    setShowCelebration,
    setCelebration,
    enableScrollToBottom,
    setWallpaperImage,
    setWallpaperSize,
    setWallpaperRepeat,
    setWallpaperExternalLinkUrl,
}) => {
    const [configurationTriggers, setConfigurationTriggers] = useState([]);

    useEffect(() => {
        if (!campaign.id) {
            return;
        }

        if (!campaign.active) {
            setConfigurationTriggers([{ configuration: [CAMPAIGN_NOT_ACTIVE_MESSAGE] }]);
            return;
        }

        const getCampaignConfigurationTriggers = async () => {
            const configurationTriggers = (await getCampaignModulesConfigurationAsAnonymus(campaign.id, CUSTOM_TOKEN)).data;
            if (configurationTriggers && configurationTriggers[0] && configurationTriggers[0].configuration) {
                setConfigurationTriggers(configurationTriggers);
            }
        };
        getCampaignConfigurationTriggers();
    }, []);

    return (
        <ChatbotLogic
            campaign={campaign}
            chatbotActive={chatbotActive}
            showPreview={showPreview}
            configurationTriggers={configurationTriggers}
            setShowCelebration={setShowCelebration}
            setCelebration={setCelebration}
            setWallpaperImage={setWallpaperImage}
            setWallpaperSize={setWallpaperSize}
            setWallpaperRepeat={setWallpaperRepeat}
            setWallpaperExternalLinkUrl={setWallpaperExternalLinkUrl}
            enableScrollToBottom={enableScrollToBottom}
        />
    );
};

export default ChatbotCore;
