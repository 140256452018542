/* eslint-disable react/no-unknown-property */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import localization from '../../helpers/localizationHelper';

const NotFound = () => (
    <div justify="center" spacing={24}>
        <div xs={12}>
            <h1 className="static-text text-center"> 404 </h1>
            <h1 className="static-text text-center">
                {localization.notFoundScreen.notFound}
            </h1>
            <br/>
            <br/>
            <br/>
            <br/>
            <h3 className="static-back-button text-center">
                <Link to="/">
                    {localization.notFoundScreen.back}
                </Link>
            </h3>
        </div>
    </div>
);

export default connect((state) => ({ language: state.language.selectedLanguage }))(NotFound);