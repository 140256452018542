import uuidV4 from 'uuid';

export const getBase64 = (file, cb, onErrorFunction) => {
    const reader = new window.FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => cb(reader.result);
    reader.onerror = () => onErrorFunction();
};

export const convertToBase64 = (input) => Buffer.from(input).toString('base64');

export const getBase64GUID = () => convertToBase64(uuidV4());
