import React from 'react';
import Axios from 'axios';
import { Col, Row } from 'reactstrap';
import { detect } from 'detect-browser';
import { IMAGE_PLACEHOLDER_URL } from '../../config';

const QuickReply = ({
    // eslint-disable-next-line camelcase
    node_branches: nodeBranches,
    moduleIndex,
    collageStyles,
    replyBackgroundColor,
    replyBorderColor = '#DDDDDD',
    // horizontalImageUrl,
    // wallpaperExternalLinkUrl,
    handleNodeBranching,
    loadWallpaperImage,
    isHistory,
    chatbotActive,
    numberOfTimesRendered,
    hasBeenClicked,
    answeredIndex,
    setAnsweredIndex,
    userId,
}) => {
    const isSafari = detect().name === 'safari';
    const isStacked = collageStyles ? collageStyles.isStacked : false;
    const isFilled = collageStyles ? collageStyles.isFilled : false;

    const logUserClick = (nodeBranch, index) => {
        const userDataObject = {};

        // done this way to preserve the object array structure of the data form API
        const userAnalytics = [];

        // TODO: Campaign name is passing null, using document title for now
        const testTitle = document.title.replace(/ /g, '_').toLowerCase();

        // for the url and in the instances where the titles are the same
        const campURL = window.location.href;
        // console.log('testTitle\n', testTitle);

        // userDataObject['Campaign Name'] = campaignName;
        userDataObject['User ID'] = userId;
        userDataObject['Campaign Name'] = testTitle;
        userDataObject['Campaign URL'] = campURL;
        userDataObject['Title Clicked'] = nodeBranch.title || ' No Title';
        userDataObject['ExternalLink Clicked'] = nodeBranch.externalURL || 'No Link';
        userDataObject.ModuleIdentifier = nodeBranch.moduleIdentifier || 'No Identifier';
        userDataObject['Image Clicked'] = nodeBranch.image_url || 'No Image URL';
        userDataObject['Button Index'] = index || 'Nil';
        userDataObject.TypeOfClick = nodeBranch.externalURL ? 'ButtonExternalLinkClick' : 'ButtonClick';
        userAnalytics.push(userDataObject);

        // console.log('userData\n', JSON.stringify(userInput.userData));
        console.log({ userDataObject });

        Axios.post('https://5j9xbzsq01.execute-api.us-east-1.amazonaws.com/prod/HusseForms', userAnalytics)
            .then(response => {
                console.log('Success!:', response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleBranching = (nodeBranch, index) => {
        if (!chatbotActive) {
            return;
        }

        // track user click
        logUserClick(nodeBranch, index);

        if (nodeBranch.externalURL) {
            window.open(nodeBranch.externalURL, 'Quick Replies Redirect');
            // if (nodeBranch.externalURL.includes('http://') || nodeBranch.externalURL.includes('https://')) {
            //     window.open(nodeBranch.externalURL, 'Quick Replies Redirect');
            // } else {
            //     window.open(`https://${nodeBranch.externalURL}`, 'Quick Replies Redirect');
            // }
        }

        if (nodeBranch.horizontalImageUrl === IMAGE_PLACEHOLDER_URL) {
            loadWallpaperImage({
                horizontalImageUrl: null,
                horizontalBgSize: null,
                horizontalBgRepeat: null,
                wallpaperExternalLinkUrl: null,
            });
            // loadWallpaperImage(null, null);
        } else {
            const horizontalStyles = {
                horizontalImageUrl: nodeBranch.horizontalImageUrl,
                horizontalBgSize: nodeBranch.horizontalBgSize,
                horizontalBgRepeat: nodeBranch.horizontalBgRepeat,
                wallpaperExternalLinkUrl: nodeBranch.externalURL,
            };
            loadWallpaperImage(horizontalStyles);
            // console.log({ horizontalStyles });
            // loadWallpaperImage(nodeBranch.horizontalImageUrl, wallpaperExternalLinkUrl);
        }

        if (nodeBranch.payload && !hasBeenClicked) {
            handleNodeBranching(nodeBranch, moduleIndex);
            if (!nodeBranch.isTriggerModuleRemoved) {
                setAnsweredIndex(moduleIndex, index);
            }
        }
    };

    const getAnimationCssClass = () => {
        if (numberOfTimesRendered > 1) {
            return;
        }

        if (isHistory) {
            return 'float-right';
        }

        return isSafari ? 'short-animation-safari' : 'short-animation';
    };

    const imageStyles = {
        borderRadius: collageStyles ? `${collageStyles.borderRadius}px` : '0px',
    };

    const notBeenClickedStyles = {
        border: `0.5px solid ${replyBorderColor}`,
        backgroundColor: replyBackgroundColor,
    };

    const hasBeenClickedStyles = {
        backgroundColor: 'transparent',
        justifyContent: 'flex-end',
    };

    function Collage() {
        // ANSWERED IMAGE
        if (hasBeenClicked) {
            const nodeBranch = nodeBranches[answeredIndex];
            return (
                <Row className="m-0 p-0">
                    <Col
                        className="collage-col-answered"
                        key={`${nodeBranch.imageUrl}_${answeredIndex}`}
                    >
                        <img
                            className="collage-image"
                            src={nodeBranch.imageUrl}
                            style={imageStyles}
                            alt="Chosen collage item"
                        />
                    </Col>
                </Row>
            );
        }

        // UNANSWERED IMAGES
        return (
            <>
                {nodeBranches && nodeBranches.map((nodeBranch, index) => (
                    <div
                        className={`collage-buttons-container ${isFilled ? 'filled-width-button' : ''}`}
                        key={`${nodeBranch.image_url}_${index}`}
                    >
                        <button
                            className={`collage-button ${getAnimationCssClass()}`}
                            onClick={() => handleBranching(nodeBranch, index)}
                            type="button"
                        >
                            <img
                                className="collage-image"
                                src={nodeBranch.imageUrl}
                                alt={`Collage item choice ${index}`}
                                style={imageStyles}
                            />
                        </button>
                    </div>
                ))}
            </>
        );
    }

    return (
        <Row className="collage-row px-3">
            <Col
                className={`collage-background ${isStacked ? 'stacked-buttons' : ''}`}
                style={hasBeenClicked ? hasBeenClickedStyles : notBeenClickedStyles}
            >
                <Collage/>
            </Col>
        </Row>
    );
};

export default QuickReply;