/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import Slider, { Range } from 'rc-slider';

const AgeGateModal = ({
    logo,
    className,
    footerClassName = 'agegate-footer',
    closeAgeGate,
    startbuttonColor = '#E55F33',
    startbuttonBGColor = '#ffffff',
}) => {
    const [modal, setModal] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toolTipToggle = () => setTooltipOpen(!tooltipOpen);

    const toggle = () => setModal(!modal);

    const delayedStart = () => {
        setTimeout(() => {
            toggle();
        }, 800);
    };

    useEffect(() => {
        delayedStart();
    }, []);

    return (
        <div className={className}>
            <img src={logo} alt=""/>
            <Slider/>
            <Range/>
            <span className="agegate-header">SELECT YOUR AGE</span>
            <span className="slider-left-label">1</span>
            <input type="range" min="1" max="100" defaultValue="18" className="slider" id="myRange"/>
            <span className="slider-right-label">100</span>
            <Tooltip placement="top" isOpen={tooltipOpen} target="myRange" toggle={toolTipToggle}>
                Hello world!
            </Tooltip>
            <input type="checkbox" className="agegate-cookie-checkbox" name="age" defaultValue="unchecked"/>
            <span className={footerClassName}>
                You accept the use of cookies to enhance your user
                experience and collect information on the use of the website
            </span>
            <Button onClick={() => closeAgeGate()} style={{ backgroundColor: startbuttonBGColor, color: startbuttonColor, border: 'none' }}>
                ENTER
            </Button>
        </div>
    );
};

export default AgeGateModal;