import React from 'react';
import { Row, Col } from 'reactstrap';
import ConfigurePathButton from './ConfigurePathButton';

const BranchingPath = ({
    nodeBranches,
    reference,
    configureNodeBranchingPath,
}) => (
    <Row className="shared-module-background">
        <Col className="m-0 p-0">
            {nodeBranches && (nodeBranches[0].title || nodeBranches[0].title === '') &&
            (!nodeBranches[0].linkingData || !nodeBranches[0].linkingData.nodeBranch) &&
                <>
                    <ConfigurePathButton
                        answer={nodeBranches[0].title}
                        reference={reference}
                        configureNodeBranchingPath={configureNodeBranchingPath}
                    />
                </>
            }
        </Col>
    </Row>
);
export default BranchingPath;
