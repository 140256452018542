import React from 'react';
import { Row, Col } from 'reactstrap';
import { Image } from 'react-bootstrap';

const Typing = ({ showTyping, avatar, avatarDiameter, paddingLeft }) => (
    showTyping ?
        <Row className="my-2 mx-0 p-0 opacity-ease-in">
            <Col
                xs={2}
                className="m-0 p-0 d-flex justify-content-start"
            >
                <Image
                    className="avatar"
                    alt="avatar circle"
                    src={avatar}
                    roundedCircle
                    style={{ height: `${avatarDiameter}px`, width: `${avatarDiameter}px` }}
                />
            </Col>
            <Col
                xs={10}
                className={`pl-${paddingLeft} align-self-center`}
            >
                <div className="loading chat-bubble typing">
                    <div className="dot one"/>
                    <div className="dot two"/>
                    <div className="dot three"/>
                </div>
            </Col>
        </Row> :

        <Row className="my-2 mx-0 p-0">
            <Col>
                <div className="avatar"/>
            </Col>
        </Row>);

export default Typing;