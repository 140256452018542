import Axios from 'axios';
import { API_URL } from '../config';
import { getRequestHeader, getMultipartRequestHeader } from '../helpers/requestHelper';

export function postCampaign(campaign) {
    return Axios.post(`${API_URL}/api/campaigns`, campaign, getMultipartRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function duplicateCampaign(campaign) {
    return Axios.post(`${API_URL}/api/campaigns/duplicate`, campaign, getMultipartRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function uploadCampaignFile(file) {
    return Axios.post(`${API_URL}/api/campaigns/uploadFileToS3`, file, getMultipartRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function updateCampaignConfiguration(configuration, campaign) {
    return Axios.post(`${API_URL}/api/campaigns/updateConfiguration`, { configuration, campaign }, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getCampaignById(id) {
    return Axios.get(`${API_URL}/api/campaigns/byId/${id}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getCampaignByRef(ref, token) {
    return Axios.get(`${API_URL}/api/campaigns/byRef/${ref}/verifyCustomToken/${token}`, getRequestHeader(false))
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getCampaignsForCurrentUser(filters) {
    return Axios.get(`${API_URL}/api/campaigns`,
        {
            ...getRequestHeader(),
            params: {
                ...filters,
            },
        })
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getCampaignModulesConfiguration(id, onlyFirstConfig = false) {
    return Axios.get(
        `${API_URL}/api/campaigns/${id}/modulesConfiguration`,
        {
            ...getRequestHeader(),
            params: {
                onlyFirstConfig,
            },
        },
    )
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getCampaignModulesConfigurationAsAnonymus(id, token, onlyFirstConfig = false) {
    return Axios.get(
        `${API_URL}/api/campaigns/${id}/modulesConfiguration/verifyCustomToken/${token}`,
        {
            ...getRequestHeader(),
            params: {
                onlyFirstConfig,
            },
        },
    )
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getAnalyticsForCampaign(id) {
    return Axios.get(`${API_URL}/api/campaigns/analyticsForCampaign/${id}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getEngagementsByGender(id) {
    return Axios.get(`${API_URL}/api/campaigns/engagementsByGender/${id}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getEngagementsByCity(id) {
    return Axios.get(`${API_URL}/api/campaigns/engagementsByCity/${id}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getEngagementsByOperatingSystem(id) {
    return Axios.get(`${API_URL}/api/campaigns/engagementsByOperatingSystem/${id}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function getEngagementsByDeviceCategory(id) {
    return Axios.get(`${API_URL}/api/campaigns/engagementsByDeviceCategory/${id}`, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function updateCampaign(campaign) {
    return Axios.put(`${API_URL}/api/campaigns`, campaign, getMultipartRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function updateModuleStyles(campaign) {
    return Axios.put(`${API_URL}/api/campaigns/moduleStyles`, campaign, getMultipartRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function archiveCampaign(id) {
    return Axios.put(`${API_URL}/api/campaigns/archive`, { id }, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function restoreCampaign(id) {
    return Axios.put(`${API_URL}/api/campaigns/restore`, { id }, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}

export function permanentlyArchiveCampaign(id) {
    return Axios.put(`${API_URL}/api/campaigns/permanantlyArchiveCampaign/${id}`, {}, getRequestHeader())
        .then(response => response)
        .catch(err => {
            throw err;
        });
}