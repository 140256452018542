import Immutable from 'seamless-immutable';

const initialState = Immutable({
    language: {
        selectedLanguage: localStorage.getItem('language') ? localStorage.getItem('language') : 'en',
    },
    user: {
        users: [],
        user: {},
        currentUser: {},
    },
    auth: {
        started: false,
        loggedUser: localStorage.getItem('logged-user') && localStorage.getItem('logged-user'),
        status: null,
    },
    campaigns: {
        all: [],
        archived: null,
        analytics: {},
        genderAnalytics: [],
    },
    shared: {
        screen: {},
    },
});
export default initialState;
