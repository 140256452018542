/* eslint-disable prefer-destructuring */
export const API_URL = process.env.REACT_APP_API_URL;
export const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
export const CUSTOM_TOKEN = process.env.REACT_APP_CUSTOM_TOKEN;
export const CHAT_URL = process.env.REACT_APP_CHAT_URL;
export const GA_ID = process.env.REACT_APP_GA_ID;
export const GA_APP_WEB_ID = process.env.REACT_APP_GA_APP_WEB_ID;
export const FB_PIXEL_ID = process.env.REACT_APP_FB_PIXEL_ID;

export const allowedMimeTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

export const ONE_MONTH_LENGTH = 2419200000;

// removed so that we can correctly link campaigns on more than one mapped domain
// export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
let urlLink = `${window.location.protocol}//${window.location.hostname}`;
if (window.location.port) {
    urlLink = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
}

export const FRONTEND_URL = urlLink;
// 300 MB converted to bytes
export const VIDEO_FILE_SIZE_LIMIT = 3e+8;

export const VALID_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/bmp', 'image/gif', 'image/png'];
export const VALID_VIDEO_TYPES = ['video/mp4', 'video/mpeg'];

export const IMAGE_PLACEHOLDER_URL = '/static/media/image-module-placeholder.d2b1dab7.png';

export const NODE_BRANCH_CONFIGURATION_STATUS = {
    HAS_CONFIGURATION: 'HAS_CONFIGURATION',
    HAS_NODE_BRANCH_IN_CONFIGURATION: 'HAS_NODE_BRANCH_IN_CONFIGURATION',
    NO_CONFIGURATION: 'HAS_NO_CONFIGURATION',
};

export const BUTTERFINGER_IMAGE = 'https://brandchat-data-dev.s3.amazonaws.com/images/2020-04-30T12%3A25%3A12.110Z-butterfinger-logo.png';

export const CAMPAIGN_NOT_ACTIVE_MESSAGE = {
    type: 'Text',
    props: {
        text: 'This campaign is not active 🙁',
        showAvatar: true,
        showTyping: true,
        typingDuration: 1000,
    },
};

export const FILTERS = {
    archived: 'archived',
    active: 'active',
    demo: 'demo',
};

export const TINY_MCE_API_KEY = '6itpy669pibqjda6cr8z3j0n3sttgy07ds01u0jf0gcnx88g';