import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { BsCaretDownFill } from 'react-icons/bs';
import { checkSharedMessageError } from '../ModuleConfigurations/errorHelper';

const ModuleAnimations = ({ animation, setSelectedMessage }) => {
    const [animationChoice, setAnimationChoice] = useState(animation || 'Fade In');
    const animations = {
        fadeIn: {
            name: 'Fade In',
            type: 'fade-in',
        },
        dropIn: {
            name: 'Drop In',
            type: 'drop-in',
        },
        slideIn: {
            name: 'Slide In',
            type: 'slide-in',
        },
        popIn: {
            name: 'Pop In',
            type: 'pop-in',
        },
        expandIn: {
            name: 'Expand In',
            type: 'expand-in',
        },
    };

    useEffect(() => {
        switch (animation) {
            case 'drop-in':
                setAnimationChoice(animations.dropIn.name);
                break;
            case 'slide-in':
                setAnimationChoice(animations.slideIn.name);
                break;
            case 'pop-in':
                setAnimationChoice(animations.popIn.name);
                break;
            case 'expand-in':
                setAnimationChoice(animations.expandIn.name);
                break;
            case 'fade-in':
            default:
                setAnimationChoice(animations.fadeIn.name);
                break;
        }
    }, [animation]);

    const [animationMenu, setAnimationMenu] = useState(false);

    const handleSelectAnimation = (choice) => {
        setAnimationMenu(false);
        setAnimationChoice(choice.name);
        setSelectedMessage(selectedMessage => {
            const { props } = selectedMessage.message;
            props.animation = choice.type;

            return ({
                ...selectedMessage,
                message: {
                    ...selectedMessage.message,
                    props: {
                        ...props,
                        error: checkSharedMessageError(props),
                    },
                },
            });
        });
    };

    return (
        <Row className="shared-module-background">
            <Col className="m-0 p-0">
                <h5 className="shared-heading">
                    Choose Module Animation
                </h5>
                <button
                    className="shared-module-button"
                    type="button"
                    onClick={() => setAnimationMenu(!animationMenu)}
                >
                    <h6>{animationChoice}</h6>
                    <BsCaretDownFill
                        size="11px"
                        style={{ marginTop: '-4px' }}
                    />
                </button>

                {animationMenu &&
                    <div className="shared-menu animations">
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectAnimation(animations.fadeIn)}
                        >
                            Fade In
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectAnimation(animations.dropIn)}
                        >
                            Drop In
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectAnimation(animations.slideIn)}
                        >
                            Slide In
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectAnimation(animations.popIn)}
                        >
                            Pop In
                        </button>
                        <button
                            className="shared-item animations"
                            type="submit"
                            onClick={() => handleSelectAnimation(animations.expandIn)}
                        >
                            Expand In
                        </button>
                    </div>
                }
            </Col>
        </Row>
    );
};

export default ModuleAnimations;