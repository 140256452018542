import React from 'react';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

const TabBar = ({ screen, switchScreens }) => {
    const isConfigure = screen === 'configureCampaign';

    const handleClickSetup = (e) => {
        if (isConfigure) {
            switchScreens(e);
        }
    };

    const handleClickConfigure = (e) => {
        if (!isConfigure) {
            switchScreens(e);
        }
    };

    return (
        <Row className="create-campaign-tab-container my-1 ml-4">
            <Col
                sm="auto"
                className={isConfigure ? 'pointer' : 'default-cursor'}
                onClick={(e) => handleClickSetup(e)}
            >
                <h3
                    className="tab-header"
                    style={isConfigure ? { color: '#BBB' } : { color: '#000' }}
                >
                    Setup
                </h3>
            </Col>

            <Col
                sm="auto"
                className={!isConfigure ? 'pointer' : 'default-cursor'}
                onClick={(e) => handleClickConfigure(e)}
            >
                <h3
                    className="tab-header"
                    style={!isConfigure ? { color: '#BBB' } : { color: '#000' }}
                >
                    Configure
                </h3>
            </Col>
        </Row>
    );
};

export default connect(
    (state) => ({ screen: state.shared.screen }),
)(TabBar);
