import React from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { BsLock, BsUnlock, BsArchive } from 'react-icons/bs';
import { history } from '../../routes';
import { archiveSelectedCampaign, restoreSelectedCampaign } from '../../redux/campaign/actions';
import { campaignLimitForUser } from '../../helpers/accountSettingshelper';

const TemplateCard = ({ template, numberOfCampaigns, setShowModal, archiveSelectedCampaign }) => {
    const admin = localStorage.getItem('role') === 'admin';
    const customCampaign = 'Custom Campaign';

    const handleCreate = () => {
        if (admin || numberOfCampaigns < campaignLimitForUser) {
            history.push('/create-campaign');
        } else {
            setShowModal(true);
        }
    };

    const handleDuplicate = () => {
        if (admin || numberOfCampaigns < campaignLimitForUser) {
            history.push('/duplicate-template', template);
        } else {
            setShowModal(true);
        }
    };

    return (
        <Card className="campaign mx-0 my-4 p-0">
            <CardBody className="card-body m-0 p-0">
                {/* TOP ROW ICONS */}
                <Row className="justify-content-between pt-1">
                    {/* LOCK-UNLOCK */}
                    <Col
                        sm="auto"
                        className="ml-3"
                    >
                        {admin ?
                            <BsUnlock
                                className="react-icon"
                                size="18px"
                            /> :

                            <BsLock
                                className="react-icon"
                                size="18px"
                            />
                        }
                    </Col>

                    {/* ARCHIVE */}
                    <Col
                        sm="auto"
                        className="mr-3"
                    >
                        {template.name !== customCampaign &&
                            <>
                                <BsArchive
                                    className="react-icon pointer"
                                    size="17px"
                                    onClick={() => toastr.confirm('Are you sure you want to archive this template?',
                                        {
                                            onOk: () => archiveSelectedCampaign(template),
                                            closeOnToastrClick: true,
                                        })}
                                    id={`archive_template_${template.id}`}
                                />
                                <UncontrolledTooltip target={`archive_template_${template.id}`}>
                                    Archive campaign
                                </UncontrolledTooltip>
                            </>
                        }
                    </Col>
                </Row>

                {/* CAMPAIGN IMAGE */}
                <Row>
                    <Col className="template-col">
                        <h6>{template.name}</h6>
                    </Col>
                </Row>

                <Row className="card-divider m-0 p-0"/>

                {/* CREATE - DUPLICATE - EDIT */}
                <Row>
                    <Col className="card-col">
                        {template.name === customCampaign ?
                            <>
                                <Button
                                    className="card-edit"
                                    id={`create_${template.id}`}
                                    onClick={() => handleCreate()}
                                >
                                    Create
                                </Button>
                                <UncontrolledTooltip
                                    target={`create_${template.id}`}
                                >
                                    Create template
                                </UncontrolledTooltip>
                            </> :

                            <>
                                <Button
                                    className="card-edit"
                                    id={`duplicate_${template.id}`}
                                    onClick={() => handleDuplicate()}
                                    // onClick={() => history.push('/duplicate-template', template)}
                                >
                                    Duplicate
                                </Button>
                                <UncontrolledTooltip
                                    target={`duplicate_${template.id}`}
                                >
                                    Duplicate template
                                </UncontrolledTooltip>
                            </>
                        }

                        {template.name !== customCampaign && admin &&
                            <>
                                <Button
                                    className="card-edit"
                                    id={`edit_${template.id}`}
                                    onClick={() => { console.log({ template }); history.push('campaign/edit', template); }}
                                >
                                    Edit
                                </Button>
                                <UncontrolledTooltip
                                    target={`edit_${template.id}`}
                                >
                                    Edit template
                                </UncontrolledTooltip>
                            </>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

export default connect(null, { archiveSelectedCampaign, restoreSelectedCampaign })(TemplateCard);
