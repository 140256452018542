import React from 'react';
import PropTypes from 'prop-types';
import {
    Modal as ReactStrapModal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
} from 'reactstrap';
// import 'bootstrap/dist/css/bootstrap.css';

const Modal = ({
    header,
    description,
    children,
    showConfirmButton,
    confirmButtonDisabled,
    confirmButtonTitle,
    closeButtonTitle,
    handleConfirm,
    handleClose,
    size,
    showCloseButton,
    headerClassName,
    bodyClassName,
    footerClassName,
    confirmButtonClassName,
    cancelButtonClassName,
    showCloseIcon,
    handleError,
}) => {
    if (description && children) {
        console.warn('Warning: You cannot have both description and children props');
    }

    const onConfirmButtonClick = async () => {
        try {
            await handleConfirm();
            handleClose();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <ReactStrapModal
            isOpen
            toggle={handleClose}
            centered
            size={size}
        >
            {/* Logic inside close prop below is required because close prop expects PropType.object
             and showCloseIcon is a boolean that controls the display of close icon */}
            <ModalHeader
                close={showCloseIcon ? <></> : null}
                className={headerClassName}
                toggle={handleClose}
            >
                {header}
            </ModalHeader>
            <ModalBody className={bodyClassName}>
                {children ||
                    <p className="mb-0">
                        {description}
                    </p>
                }
            </ModalBody>
            <ModalFooter className={footerClassName}>
                {showCloseButton &&
                    <Button
                        className={cancelButtonClassName}
                        onClick={() => handleClose()}
                    >
                        {closeButtonTitle}
                    </Button>
                }
                {showConfirmButton &&
                    <Button
                        className={confirmButtonClassName}
                        disabled={confirmButtonDisabled}
                        onClick={onConfirmButtonClick}
                    >
                        {confirmButtonTitle}
                    </Button>
                }
            </ModalFooter>
        </ReactStrapModal>
    );
};

Modal.propTypes = {
    header: PropTypes.string,
    description: PropTypes.string,
    children: PropTypes.object,
    showConfirmButton: PropTypes.bool,
    confirmButtonDisabled: PropTypes.bool,
    handleClose: PropTypes.func,
    confirmButtonTitle: PropTypes.string,
    closeButtonTitle: PropTypes.string,
    size: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    confirmButtonClassName: PropTypes.string,
    cancelButtonClassName: PropTypes.string,
    showCloseIcon: PropTypes.bool,
    showCloseButton: PropTypes.bool,
    handleConfirm: PropTypes.func,
    handleError: PropTypes.func,
};

Modal.defaultProps = {
    header: '',
    confirmButtonTitle: 'Yes',
    closeButtonTitle: 'Cancel',
    size: 'md',
    showCloseIcon: true,
    showCloseButton: true,
    description: '',
    showConfirmButton: false,
    confirmButtonDisabled: false,
    children: null,
    headerClassName: '',
    bodyClassName: '',
    footerClassName: '',
    confirmButtonClassName: '',
    cancelButtonClassName: '',
    handleConfirm: () => {},
    handleClose: () => {},
    handleError: () => {},
};

export default Modal;