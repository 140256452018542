export const MODULE_TYPES = {
    AgeGateModal: 'AgeGateModal',
    Collage: 'Collage',
    EmailSubmissionForm: 'EmailSubmissionForm',
    Image: 'Image',
    ImageCarousel: 'ImageCarousel',
    InputField: 'InputField',
    LinkModal: 'LinkModal',
    ListItemGroup: 'ListItemGroup',
    LocationButton: 'LocationButton',
    MapQuest: 'MapQuest',
    Modal: 'Modal',
    PhoneNumberField: 'PhoneNumberField',
    ButtonReply: 'ButtonReply',
    QuickReply: 'QuickReply',
    Rating: 'Rating',
    Shop: 'Shop',
    SKUx: 'SKUx',
    Template: 'Template',
    Text: 'Text',
    Video: 'Video',
};

export const QUICK_ACTION_TYPE = {
    SAVE_RECIPE: 'SAVE_RECIPE',
    ADD_TO_SHOPPING_LIST: 'ADD_TO_SHOPPING_LIST',
};