import * as constants from './constants';

export function getCampaignSuccess(campaigns) {
    return {
        type: constants.GET_CAMPAIGN_SUCCESS,
        payload: {
            campaigns,
        },
    };
}

export function getCampaignFail() {
    return {
        type: constants.GET_CAMPAIGN_FAIL,
    };
}

export function getCampaignAnalyticsSuccess(analytics) {
    return {
        type: constants.GET_ANALYTICS_SUCCESS,
        payload: {
            analytics,
        },
    };
}

export function getEngagementsByGenderSuccess(genderAnalytics) {
    return {
        type: constants.GET_ENGAGEMENTS_BY_GENDER_SUCCESS,
        payload: {
            genderAnalytics,
        },
    };
}

export function getEngagementsByCitySuccess(analytics) {
    return {
        type: constants.GET_ENGAGEMENTS_BY_CITY_SUCCESS,
        payload: {
            analytics,
        },
    };
}

export function getEngagementsByOperatingSystemSuccess(analytics) {
    return {
        type: constants.GET_ENGAGEMENTS_BY_OPERATING_SYSTEM_SUCCESS,
        payload: {
            analytics,
        },
    };
}

export function getEngagementsByDeviceCategorySuccess(analytics) {
    return {
        type: constants.GET_ENGAGEMENTS_BY_DEVICE_CATEGORY_SUCCESS,
        payload: {
            analytics,
        },
    };
}

export function resetAnalyticsSuccess() {
    return {
        type: constants.RESET_ANALYTICS_SUCCESS,
    };
}

export function archiveCampaignSuccess(campaign) {
    return {
        type: constants.ARCHIVE_CAMPAIGN_SUCCESS,
        payload: {
            campaign,
        },
    };
}

export function restoreCampaignSuccess(campaign) {
    return {
        type: constants.RESTORE_CAMPAIGN_SUCCESS,
        payload: {
            campaign,
        },
    };
}

export function getArchivedCampaignsSuccess(campaigns) {
    return {
        type: constants.GET_ARCHIVED_CAMPAIGNS_SUCCESS,
        payload: {
            campaigns,
        },
    };
}

export function createCampaignSuccess(campaign) {
    return {
        type: constants.CREATE_CAMPAIGN_SUCCESS,
        payload: {
            campaign,
        },
    };
}

export function duplicateCampaignSuccess(campaign) {
    return {
        type: constants.CREATE_CAMPAIGN_SUCCESS,
        payload: {
            campaign,
        },
    };
}

export function editCampaignSuccess(campaign) {
    return {
        type: constants.EDIT_CAMPAIGN_SUCCESS,
        payload: {
            campaign,
        },
    };
}

export function editModuleStylesSuccess(campaign) {
    return {
        type: constants.EDIT_MODULE_STYLES_SUCCESS,
        payload: {
            campaign,
        },
    };
}

export function deleteCampaignSuccess() {
    return {
        type: constants.DELETE_CAMPAIGN_SUCCESS,
    };
}